// Package Imports
import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

// Content Imports
import { dataDiagnosticsModalContent } from '../../utils/consts';

// Component Interfaces
interface DataDiagnosticsModalProps {
  dataNarrative: string[][];
  handleDataNarrativeDialog: Function;
}

// Styled Component
const ModalContainer = styled.div`
  background: #fff;
  position: absolute;
  height: 85vh;
  width: 100%;
  max-width: 550px;
  margin: auto;
  align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1.5% 2%;
  border-radius: 2px;
  h5 {
    font-size: 1.1rem;
    line-height: 0.5;
  }
  .modal-text {
    font-size: 1.05rem;
    height: 90%;
    overflow: auto;
    margin: 25px 0px;
    padding: 0px 10px;
    h5 {
      font-size: 1.35rem;
      color: #4e73c3;
      margin-bottom: 10px;
    }
  }
  .close-modal {
    position: absolute;
    right: 15px;
    top: 10px;
    &:hover {
      background: #b1b5b5;
    }
  }
`;

// Component
const DataDiagnosticsModal = ({
  dataNarrative,
  handleDataNarrativeDialog,
}: DataDiagnosticsModalProps) => {
  const { t: translate } = useTranslation();

  return (
    <ModalContainer>
      <h4>{translate('dataDiagnosticsHeader')}</h4>
      <div className="modal-text">
        {dataNarrative.map((narrative, narrativeIdx) => (
          <div key={`narrative_${narrativeIdx}`}>
            <h5>{`${translate('dataDiagnosticsIndex')} ${
              narrativeIdx + 1
            }`}</h5>
            {narrative?.map((update, updateIdx) => (
              <div key={`narrative_update_${updateIdx}_${narrativeIdx}`}>
                {parse(update)}
              </div>
            ))}
          </div>
        ))}
        )
      </div>
      <button
        className="button close close-modal"
        onClick={() => handleDataNarrativeDialog()}
      >
        {translate('dataDiagnosticsModalContentClose')}
      </button>
    </ModalContainer>
  );
};

export default DataDiagnosticsModal;
