// Types Imports
import {
  SET_ZEPHYR,
  ADD_ZEPHYRS,
  SET_ZEPHYR_IN_EDIT_MODE,
  SET_HAS_INITIAL_SET,
} from '../actions/types';

// Type Imports
import { Zephyr } from '../utils/interface';

// Type Safety
interface ZephyrSet {
  editMode: boolean;
  set: boolean;
  zephyr: Zephyr | any;
  hasInitialSet: boolean;
}

interface SetZephyr {
  type: typeof SET_ZEPHYR | typeof SET_ZEPHYR_IN_EDIT_MODE | typeof SET_HAS_INITIAL_SET;
  payload: string[];
}

// State
const initialState: ZephyrSet = {
  editMode: false,
  set: false,
  zephyr: [],
  hasInitialSet: false,
};

// Reducer
export default function (state = initialState, action: SetZephyr) {
  const { type, payload } = action;

  switch (type) {
    case SET_ZEPHYR:
      return {
        ...state,
        zephyr: payload,
        editMode: false,
        set: true,
      };
    case ADD_ZEPHYRS:
      return {
        ...state,
        zephyrs: payload,
        editMode: false,
        set: true,
      };
    case SET_ZEPHYR_IN_EDIT_MODE:
      return {
        ...state,
        zephyr: payload,
        editMode: true,
        set: true,
      };
    case SET_HAS_INITIAL_SET:
      return {
        ...state,
        hasInitialSet: true
      }
    default:
      return state;
  }
}
