// Package Imports
import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';

// Util Imports
import { extentTabFinder } from '../../utils/functions/tabFinder';

// Type Imports
import {
  ActiveTab,
  AnnualAvgData,
  DisplayConfig,
  GetZephyrHistoricalData,
  ReduxState,
  SlotOptions,
  StrippedTab,
  Zephyr,
  ZephyrHistoryWithId,
} from '../../utils/interface';
import { slotValidator } from '../../utils/functions/slotValidator';
import { connect } from 'react-redux';
import { ThresholdExceedingSpecie } from '../views/Data';
import ExceededThreshold from './ExceededThreshold';

// Component Interfaces
interface DataGroupTabsProps {
  activeTab: ActiveTab | null;
  curSlot: SlotOptions;
  displayConfig: DisplayConfig;
  handleTabSwitch: Function;
  unitHistories: ZephyrHistoryWithId | null;
  zephyr: Zephyr;
  showingAnnualAverage: boolean;
  annualAvgData: AnnualAvgData;
  hasVirtualZephyr: Function;
  historicalData: GetZephyrHistoricalData;
  defaultActiveTab: number | null,
  setDefaultActiveTab: Function,
  setActiveTab: Function,
  thresholdExceedingSpecies: ThresholdExceedingSpecie[],
}

const DataGroupTabs = ({
  activeTab,
  curSlot,
  displayConfig,
  handleTabSwitch,
  unitHistories,
  zephyr,
  showingAnnualAverage,
  annualAvgData,
  historicalData,
  defaultActiveTab,
  setDefaultActiveTab,
  setActiveTab,
  thresholdExceedingSpecies
}: DataGroupTabsProps) => {
  const [tabs, setTabs] = useState<StrippedTab[]>([]);

  const annualAvgTabs = [
    { group: 'NO2', HTMLGroupLabel: 'NO<sub>2</sub>' },
    {
      group: 'PM',
      HTMLGroupLabel: 'M<sub>2.5</sub>',
    },
  ];

  const sortByGroup = (a: any, b: any) => {
    const lowerCaseA = a.group.toLowerCase();
    const lowerCaseB = b.group.toLowerCase();
    return lowerCaseA.localeCompare(lowerCaseB);
  }

  const sortButtonsByGroup = (arrObj: any) => {
    const getNO2PM = arrObj.filter((b: any) => b.group === 'NO2' || b.group === 'PM');
    if (getNO2PM.length > 0) {
      const getRest = arrObj.filter((b: any) => b.group !== 'NO2' && b.group !== 'PM');
      getRest.sort(sortByGroup);
      return getNO2PM.concat(getRest);
    }
    return arrObj;
  };

  const getExceededThresholdSpecie = () => {
    if (thresholdExceedingSpecies) {
      return thresholdExceedingSpecies.filter((z: any) => {
        const zNumber = Object.keys(z)[0];
        return z[zNumber].NO2 > 0 || z[zNumber].O3 > 0 || z[zNumber].particulatePM10 > 0 || z[zNumber].particulatePM25 > 0;
      });
    }
    return [];
  };

  const checkSpeciesThresholdResult = (group: string) => {
    let isThresholdExceeded = false;
    const species = getExceededThresholdSpecie();
    if (group === 'NO2') {
      for (let i = 0; i < species.length; i++) {
        const zNumber: any = Object.keys(species[i])[0];
        const unit: any = species[i];
        if (('NO2' in unit[zNumber] && unit[zNumber].NO2 > 0) || ('O3' in unit[zNumber] && unit[zNumber].O3 > 0)) { isThresholdExceeded = true; break; }
      }
    }
    if (group === 'PM') {
      for (let i = 0; i < species.length; i++) {
        const zNumber: any = Object.keys(species[i])[0];
        const unit: any = species[i];
        if (('particulatePM10' in unit[zNumber] && unit[zNumber].particulatePM10 > 0) || ('particulatePM25' in unit[zNumber] && unit[zNumber].particulatePM25 > 0)) { isThresholdExceeded = true; break; }
      }
    }
    return isThresholdExceeded;
  }


  useEffect(() => {
    if (!showingAnnualAverage &&
      activeTab &&
      unitHistories) {
      const availableSlots = slotValidator(historicalData.zephyrHistory as any);
      let secondSlot = availableSlots.length > 1 && curSlot && curSlot === 'slotA' ? 'slotB' : availableSlots.length > 1 && curSlot && curSlot === 'slotB' ? 'slotA' : availableSlots.length === 1 ? availableSlots[0] : null;
      let tabsFirstSlot = extentTabFinder(curSlot,
        displayConfig,
        unitHistories,
        zephyr.zNumber);
      let tabsSecondSlot = secondSlot ? extentTabFinder((secondSlot as SlotOptions),
        displayConfig,
        unitHistories,
        zephyr.zNumber) : null;
      const removeNewDataSetDuplicates = tabsSecondSlot ? tabsFirstSlot.concat(tabsSecondSlot).filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.HTMLGroupLabel === value.HTMLGroupLabel && t.group === value.group
        ))
      ) : null;
      if (tabsSecondSlot) {
        const sortedNewDataSetDuplicates = sortButtonsByGroup(removeNewDataSetDuplicates);
        setTabs(sortedNewDataSetDuplicates);
        return;
      }
      setTabs(sortButtonsByGroup(tabsFirstSlot));
    }
  }, [displayConfig, unitHistories, zephyr, historicalData])
  if (showingAnnualAverage) return null;



  return (
    <header className="filter-types">
      <div>
        <nav className="tourSpeciesSwitch">
          <ul>
            {showingAnnualAverage && activeTab
              ? annualAvgTabs.map((tab) => (
                <li
                  key={tab.group}
                  className={`${tab.group} ${tab.group === 'temp'}`}
                >
                  <button
                    className={activeTab.group === tab.group ? 'active' : ''}
                    onClick={() => {
                      handleTabSwitch(tab.group);
                    }}
                  >
                    {parse(`${tab.HTMLGroupLabel}`)}
                  </button>
                </li>
              ))
              : null}

            {!showingAnnualAverage &&
              activeTab &&
              unitHistories &&
              tabs.map((tab, idx) => (
                <li
                  style={{ position: 'relative' }}
                  key={tab.group}
                  className={`${tab.group} ${tab.group === 'temp'}`}
                >
                  <button
                    className={defaultActiveTab === null ? activeTab.group === tab.group ? 'active' : '' : idx === defaultActiveTab ? 'active' : ''}
                    onClick={() => {
                      if (tab.curSlot) { handleTabSwitch(tab.group, tab.curSlot); return; }
                      handleTabSwitch(tab.group);
                    }}
                  >
                    {parse(`${tab.HTMLGroupLabel}`)}
                  </button>
                  {getExceededThresholdSpecie() && checkSpeciesThresholdResult(tab.group) && <ExceededThreshold tab={tab} thresholdExceedingSpecies={thresholdExceedingSpecies} />}
                </li>
              ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  historicalData: state.getZephyrHistoricalData,
});

export default connect(mapStateToProps, {})(DataGroupTabs);
