import { Dispatch } from "redux";
import { ModularFeatures } from "../reducers/modularFeatures";
import { SET_MODULAR_FEATURES } from "./types";

// Action Creators
const setNewModularFeaturesState = (modularFeatures: ModularFeatures) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_MODULAR_FEATURES,
        payload: modularFeatures,
    });
};

export {
    setNewModularFeaturesState
};