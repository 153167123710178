// Package Imports
import React, { useEffect, useRef } from 'react';
import CanasJSReact from '../../canvasjs.react';

// Component Imports
import DataChartDownloadButton from './DataChartDownloadButton';

// Util Imports
import {
  getSecondaryAxisConfigs,
  wrangleLineChartData,
} from '../../utils/functions/chartHelper';

// Const Imports
import { placeholderAxis } from '../../utils/consts';

// Type Imports
import {
  AveragingPeriod,
  ChartData,
  DisplayConfig,
} from '../../utils/interface';

interface DataLineChartProps {
  averagingOption: AveragingPeriod;
  chartData: ChartData;
  displayConfig: DisplayConfig;
  downloadOn: boolean | null;
  handleChartDownload: Function;
  handleUnitSpeciesFilter: Function;
  isPublic: boolean;
  showingAnnualAverage: boolean;
}

const DataLineChart = ({
  averagingOption,
  chartData,
  displayConfig,
  downloadOn,
  handleChartDownload,
  handleUnitSpeciesFilter,
  isPublic,
  showingAnnualAverage,
}: DataLineChartProps) => {
  // Consts
  const wrangledChartData = wrangleLineChartData(
    chartData,
    handleUnitSpeciesFilter,
    averagingOption,
    displayConfig,
  );

  const secondaryAxisConfig = getSecondaryAxisConfigs(chartData);

  // Refs
  const chart = useRef<any | null>(null);

  return (
    <div className="chart-wrapper">
      <div className="line-chart-container">
        <CanasJSReact.CanvasJSChart
          options={{
            animationEnabled: false,
            zoomEnabled: !isPublic,
            backgroundColor: 'transparent',
            data: wrangledChartData,
            toolTip: {
              borderColor: '#222539',
              cornerRadius: 3,
              fontFamily: 'Open Sans',
              contentFormatter: (e: any) => {
                const formattedDate = CanasJSReact.CanvasJS.formatDate(
                  e.entries[0].dataPoint.x,
                  e.entries[0].dataSeries.xValueFormatString,
                );
                const content = `${e.entries[0].dataSeries.name} (<small>${formattedDate}</small>)<br/><strong>${e.entries[0].dataPoint.y}</strong> ${e.entries[0].dataSeries.label}`;
                return content;
              },
            },
            axisY: {
              gridColor: '#cbcdcf',
              gridThickness: 0,
              labelFontFamily: 'Open Sans',
            },
            axisY2: [
              secondaryAxisConfig.length && !secondaryAxisConfig[0].void
                ? {
                  gridColor: '#cbcdcf',
                  gridThickness: 0,
                  labelFontFamily: 'Open Sans',
                  title: secondaryAxisConfig[0].title,
                  titleFontColor: '#222539',
                  titleFontSize: 15,
                  titleFontFamily: 'Open Sans',
                  minimum: secondaryAxisConfig[0].minimum,
                  maximum: secondaryAxisConfig[0].maximum,
                }
                : placeholderAxis,
              secondaryAxisConfig.length > 1 && !secondaryAxisConfig[1].void
                ? {
                  gridColor: '#cbcdcf',
                  gridThickness: 0,
                  labelFontFamily: 'Open Sans',
                  title: secondaryAxisConfig[1].title,
                  titleFontColor: '#222539',
                  titleFontSize: 15,
                  titleFontFamily: 'Open Sans',
                  minimum: secondaryAxisConfig[1].minimum,
                  maximum: secondaryAxisConfig[1].maximum,
                }
                : placeholderAxis,
            ],
            axisX: {
              labelFontFamily: 'Open Sans',
              valueFormatString: 'HH:mm DD-MM-YY',
              labelAngle: 0,
              labelAutoFit: true,
              labelWrap: true,
              interval: showingAnnualAverage ? 1 : undefined,
              intervalType: showingAnnualAverage ? 'year' : undefined,
              labelMaxWidth: 75,
              prefix: '‎‎‏‏‎ ‎  ',
            },
          }}
        />
        <DataChartDownloadButton
          handleChartDownload={handleChartDownload}
          on={downloadOn}
        />
      </div>
    </div>
  );
};

export default DataLineChart;
