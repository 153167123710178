// Package Imports
import React from 'react';
import { useTranslation } from 'react-i18next';

// Content Imports
import { DataDownloadButtonContent } from '../../utils/consts';

// Type Imports
import { ZephyrHistoryWithId } from '../../utils/interface';

// Component Interfaces
interface DataDownloadButtonProps {
  diagnosticMode: boolean;
  handleDataNarrativeDialog: Function;
  handleDownloadDialog: Function;
  on: boolean | null;
}

const DataDownloadButton = ({
  diagnosticMode,
  handleDataNarrativeDialog,
  handleDownloadDialog,
  on,
}: DataDownloadButtonProps) => {
  const { t: translate } = useTranslation();

  return (
    <div className="downloadData">
      {on ? (
        <>
          <button
            onClick={() => handleDownloadDialog()}
            className="button tertiary tourDataDownload"
          >
            {translate('DataDownloadButtonText')}
          </button>
          {diagnosticMode ? (
            <button
              onClick={() => handleDataNarrativeDialog()}
              className="button tertiary diagnostics"
            >
              {translate('DataDiagnosticsButtonText')}
            </button>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DataDownloadButton;
