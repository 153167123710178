// Package Imports
import React from 'react';
import parse from 'html-react-parser';
// @ts-ignore
import html2canvas from 'html2canvas';
// @ts-ignore
import canvasToImage from 'canvas-to-image';
import { useTranslation } from 'react-i18next';

// Component Imports
import AQTemporalChart from './AQTemporalChart';
import AQTemporalChartModal from './AQTemporalChartModal';
import Modal from '../shared_components/Modal';

// Util Imports
import analyticsEventFirer from '../../utils/functions/analyticsEventFirer';

// Const Imports
import { gtmEventIdentifiers, mappairLink } from '../../utils/consts';

// Type Imports
import { ChartData, Modal as ModalType, Species } from '../../utils/interface';

// Component Interfaces
interface InitState {
  chartModalOpen: boolean;
}

interface AQTemporalProps {
  curSpecies: Species;
  isOn: boolean;
  hdms: string;
  modal: ModalType;
  setMappairTimeseriesModalOn: Function;
  setModalsOff: Function;
  temporalModelData: ChartData | null;
}

// Component
const AQTemporal = ({
  curSpecies,
  isOn,
  hdms,
  modal,
  setMappairTimeseriesModalOn,
  setModalsOff,
  temporalModelData,
}: AQTemporalProps) => {
  const { t: translate } = useTranslation();
  // Refs
  const compRef = React.createRef<any>();

  // Functions
  const handleChartDownload = () => {
    const fileName = `mappairChartExport_${curSpecies.label}_${hdms}`;
    const item = document.querySelector('#aqm-modal-chart-container') as any;
    item.parentNode.style.overflow = 'visible';
    const html2canvasOptions = {
      backgroundColor: null,
      ignoreElements: () => false,
      logging: false,
    };
    html2canvas(item as HTMLElement, html2canvasOptions).then((canvas) => {
      canvasToImage(canvas, {
        name: fileName,
        type: 'png',
        quality: 1,
      });
    });
  };

  return (
    <>
      {isOn && temporalModelData ? (
        <div className="aqm-container">
          <div className="aqm-header">
            <h4>
              {translate('AQChartHeader')}
              <br />
              <span className="aq-model-unit">
                <a href={mappairLink.url} target={mappairLink.target}>
                  {mappairLink.content}
                </a>
                {parse(curSpecies.HTMLLabel)} {curSpecies.HTMLShortUnitLabel}
              </span>
            </h4>
            <button
              onClick={() => {
                setMappairTimeseriesModalOn();
                // Analytics
                analyticsEventFirer(
                  gtmEventIdentifiers.timeseriesExpansion,
                  curSpecies.dataIdentifier,
                );
              }}
              className="aqm-expand-to-modal"
              data-html2canvas-ignore="true"
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="#000"
              >
                <path d="M8.465 16.95l2.828 3.05h-7.293v-7.293l3.051 2.829 8.484-8.486-2.828-3.05h7.293v7.292l-3.051-2.828z" />
              </svg>
            </button>
          </div>
          <div className="aqm-chart-container">
            <AQTemporalChart chartDataset={temporalModelData} intervals={10} />
          </div>
          <Modal
            on={modal.mappairTimeseries}
            modalComponent={
              <AQTemporalChartModal
                chartDataset={temporalModelData}
                curSpecies={curSpecies}
                setModalsOff={setModalsOff}
                handleChartDownload={handleChartDownload}
                hdms={hdms}
                parentRef={compRef}
              />
            }
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default AQTemporal;
