// Types Imports
import { Dispatch } from 'redux';
import { SET_TODAY_DATA } from './types';

// Action Creators
export const setTodayData = (todayData: any) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_TODAY_DATA,
    payload: todayData,
  });
};
