// Package Imports
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Component Imports
import Confirmation from '../shared_components/Confirmation';
import Dropdown from '../shared_components/Dropdown';

// Async Imports
import utilsRequest from '../../utils/request';

// Const Imports
import {
  defaultContactForm,
  detailsContactFormLookup,
  enquiryContactForm,
  HeaderContactModalContent,
} from '../../utils/consts';

// Type Imports
import { ContactForm, ReduxState } from '../../utils/interface';
import { connect } from 'react-redux';

// Component Interfaces
interface InitState {
  confirmationScreen: boolean;
}

interface HeaderContactModalProps {
  contactFormOptions: ContactForm;
  handleContactDialog: Function;
  bearerToken: string | null;
}

// Component
const HeaderContactModal = ({
  contactFormOptions,
  handleContactDialog,
  bearerToken,
}: HeaderContactModalProps) => {
  const { t: translate } = useTranslation();

  // State
  const initState: InitState = {
    confirmationScreen: false,
  };

  const [confirmationScreen, setConfirmationScreen] = useState(
    initState.confirmationScreen,
  );
  const [formData, setFormData] = useState(contactFormOptions.formData);
  const [userMessage, setUserMessage] = useState(
    contactFormOptions.userMessage,
  );

  // Functions
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { firstName, lastName, email, company, enquiry } = formData;
    if (firstName && lastName && email && company && enquiry) {
      const response = await utilsRequest.submitContactForm(formData, bearerToken);
      if (response) {
        setFormData(defaultContactForm.formData);
        setConfirmationScreen(true);
      } else {
        setUserMessage(translate('messageProblem'));
      }
    } else {
      setUserMessage(translate('messageMissingFields'));
    }
  };

  const handleDropdown = (id: string, selected: string) => {
    const updatedFormData = { ...formData, [id]: selected };
    setFormData(updatedFormData);
  };

  return (
    <>
      {confirmationScreen ? (
        <Confirmation
          confirmationOpen={handleContactDialog}
          header={translate('confirmationHeader')}
          showTick
          text={translate('confirmationText')}
        />
      ) : (
        <div className="modal-container header-contact-modal-container">
          <h4>{translate('contactHeader')}</h4>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="form-content-container">
              <div className="row">
                <div className="col-12 col-md-6">
                  <label className="contact-form__label" htmlFor="firstName">
                    {translate('contactFirstName')}
                  </label>
                  <input
                    className="contact-form__input"
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={(e) =>
                      setFormData({ ...formData, firstName: e.target.value })
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label className="contact-form__label" htmlFor="lastName">
                    {translate('contactLastName')}
                  </label>
                  <input
                    className="contact-form__input"
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={(e) =>
                      setFormData({ ...formData, lastName: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label className="contact-form__label" htmlFor="email">
                    {translate('contactEmail')}
                  </label>
                  <input
                    className="contact-form__input"
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label className="contact-form__label" htmlFor="company">
                    {translate('contactCompany')}
                  </label>
                  <input
                    className="contact-form__input"
                    type="text"
                    name="company"
                    value={formData.company}
                    onChange={(e) =>
                      setFormData({ ...formData, company: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label className="contact-form__label" htmlFor="enquiry">
                    {translate('contactEnquiry')}
                  </label>
                  <Dropdown
                    additionalClass="custom-dropdown-enquiry-container"
                    currentSelectionValue={formData.enquiry}
                    handleSelection={handleDropdown}
                    placeholderSelectionLabel="Select"
                    id="enquiry"
                    selections={enquiryContactForm}
                  />
                </div>
                <div className="col">
                  <label className="contact-form__label" htmlFor="enquiry">
                    {translate('contactDetails')}
                  </label>
                  <Dropdown
                    additionalClass="custom-dropdown-enquiry-container"
                    currentSelectionValue={formData.details}
                    handleSelection={handleDropdown}
                    placeholderSelectionLabel="Select"
                    id="details"
                    selections={
                      detailsContactFormLookup[formData.enquiry] || [
                        { value: 'None', label: 'None' },
                      ]
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label className="contact-form__label" htmlFor="comments">
                    {translate('contactComments')}
                  </label>
                  <textarea
                    className="contact-form__textarea"
                    name="comments"
                    value={formData.comments}
                    onChange={(e) =>
                      setFormData({ ...formData, comments: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col text-left validation-dialog">
                <p className="air-alerts-error-item">
                  {userMessage || translate('requiredFields')}
                </p>
              </div>
            </div>
            <button type="submit" className="button primary send-modal">
              {translate('contactButton')}
            </button>
          </form>
          <button
            className="button close close-modal"
            onClick={() => handleContactDialog(false)}
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 24 24"
              fill="#000"
            >
              <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
            </svg>
          </button>
        </div>
      )}
    </>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  bearerToken: state.auth.bearerToken,
});

export default connect(mapStateToProps, {})(HeaderContactModal);
