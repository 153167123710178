// Const Imports
import { defaultSpecies } from '../consts';

// Type Imports
import {
  DisplayConfig,
  LatestPublicMeasurement,
  ZephyrLastHourInd,
} from '../interface';

/* Function
DESC: latestMeasurementMapper returns a flattened array of non-species-grouped latest measurements with
      displayConfig goodies such as HTML labels and unit labels
ARGS: displayConfig (API derived), current Zephyr
*/
const latestMeasurementMapper = (
  displayConfig: DisplayConfig,
  lastHourUnitData: ZephyrLastHourInd,
  targetSpecies?: string,
) => {
  const mappedMeasurements: LatestPublicMeasurement[] = [];
  displayConfig.tabs.forEach((group) =>
    group.species.forEach((species) => {
      if (
        typeof lastHourUnitData[species.label] === 'number' &&
        (!targetSpecies || species.label === targetSpecies)
      ) {
        const measurement = {
          identifier: species.dataIdentifier,
          HTMLLabel: species.HTMLLabel,
          HTMLUnitLabel: species.HTMLShortUnitLabel,
          value: lastHourUnitData[species.label] as number,
        };
        mappedMeasurements.push(measurement);
      }
    }),
  );
  // Horrible hardcoding to account for case where AQI not in displayConfig but want to show in LMs
  // Case is we have AQI in connection data and is in Overlays but not displayConfig
  // TODO add AQI to public DCs and check for knock-on implications elsewhere
  if (targetSpecies === 'AQI' && typeof lastHourUnitData.AQI === 'number') {
    const measurement = {
      identifier: defaultSpecies.dataIdentifier,
      HTMLLabel: defaultSpecies.HTMLLabel,
      HTMLUnitLabel: '',
      value: lastHourUnitData[defaultSpecies.label] as number,
    };
    mappedMeasurements.push(measurement);
  }
  return mappedMeasurements;
};

export { latestMeasurementMapper };
