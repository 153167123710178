// Package Imports
import React from 'react';
import parse from 'html-react-parser';

// Type Safety
interface TooltipProps {
  containerClassName: string;
  content: string;
  tooltipMarkerContent: string;
}

// Component
const Tooltip = ({
  containerClassName,
  content,
  tooltipMarkerContent,
}: TooltipProps) => {
  return (
    <div className={containerClassName} data-html2canvas-ignore="true">
      <div className="tooltip-marker">
        <div className="tooltip-marker-content">{tooltipMarkerContent}</div>
        <div className="tooltip-content">{parse(content)}</div>
      </div>
    </div>
  );
};

export default Tooltip;
