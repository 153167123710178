import React, { useCallback, useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { v4 as uuidv4 } from 'uuid';

import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';
import { config } from '../../../utils/consts';
import {
  AveragingPeriod,
  GridItemInfo,
  LayoutItem,
  LayoutMapItem,
} from '../../../utils/interface';
import DynamicChart from './DynamicChart';
import { defaultLayoutGenerator } from '../../../utils/functions/dataAnalytics';

const gridTypes = {
  highcharts: [
    'exceedances',
    'weatherWind',
    'weather',
    'weatherWindRose',
    'precipitation',
    'summary',
  ],
  others: ['map', 'table', 'pollutantAvg', 'pollutants', 'pollutants2'],
};

// Function to randomly select a type from gridTypes
const getRandomType = () => {
  const allTypes = [...gridTypes.highcharts, ...gridTypes.others];
  return allTypes[Math.floor(Math.random() * allTypes.length)];
};

export const iconsTypeMap = {
  'fa-chart-pie': 'exceedances',
  'fa-chart-line': 'weatherWind',
  'fa-chart-bar': 'weather',
  'fa-chart-area': 'weatherWindRose',
  'fa-font': 'precipitation',
  'fa-image': 'summary',
  'fa-list': 'map',
  'fa-table': 'table',
  'fa-tachometer-alt': 'pollutantAvg',
  'fa-layer-group': 'pollutants',
  'fa-layer-group2': 'pollutants2',
};

const isDevModeEnabled = false;

function getFromLS(valueProp: string, key: string): any {
  let ls: { [key: string]: any } = [];
  if (localStorage && localStorage.getItem(key)) {
    ls = JSON.parse(localStorage.getItem(key)!);
  }
  return ls[valueProp];
}

function saveToLS(valueKey: string, value: any, key: string): void {
  if (localStorage) {
    localStorage.setItem(
      key,
      JSON.stringify({
        [valueKey]: value,
      }),
    );
  }
}

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default function AddRemoveLayout({ ...chartProps }) {
  const { localStorageKeysToSaveTo, selectedView, zephyrs, comparisonlayout, comparisonItems, unitHistories, userInfo } = chartProps;
  const [isDragging, setIsDragging] = useState(false); // Track dragging state
  const [items, setItems] = useState<any>([]);
  const [layoutMap, setLayoutMap] = useState<any>([]);
  const [userId, setUserId] = useState('');
  const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
  const [config, setConfig] = useState({
    compactType: 'horizontal' || null,
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 },
    // cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    rowHeight: 30,
    isDraggable: !userInfo.isPublic && isDevModeEnabled,
    isResizable: !userInfo.isPublic && isDevModeEnabled,
  });
  const [newCounter, setNewCounter] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [draggedItem, setDraggedItem] = useState<string | null>(null);

  // Update screenWidth when window is resized
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    if (userInfo.isPublic) {
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize)
      };
    }
  }, []);

  const defaultLayout: any = defaultLayoutGenerator(userInfo.isPublic, screenWidth);

  if (selectedView === 'comparisonView') {
    defaultLayout[localStorageKeysToSaveTo[0]] = comparisonlayout;
    defaultLayout[localStorageKeysToSaveTo[1]] = comparisonItems;
  }

  const updateLayout = useCallback(() => {
    const user = localStorage.getItem('user') || '';
    const userId = user
      .split('')
      .map((c) => c.charCodeAt(0))
      .join('-');
    setUserId(userId);

    if (!userId) return;
    let layoutSaved;
    let layoutMapSaved;

    if (selectedView === 'comparisonView') {
      layoutSaved = defaultLayout[localStorageKeysToSaveTo[0]];
      layoutMapSaved = defaultLayout[localStorageKeysToSaveTo[1]];
    } else {
      layoutSaved = userInfo.isPublic ? defaultLayout[localStorageKeysToSaveTo[0]] : getFromLS(
        localStorageKeysToSaveTo[0],
        `rgl-7-${userId}-${selectedView}`
      ) || defaultLayout[localStorageKeysToSaveTo[0]];

      layoutMapSaved = userInfo.isPublic ? defaultLayout[localStorageKeysToSaveTo[1]] : getFromLS(
        localStorageKeysToSaveTo[1],
        `rgl-7.1-${userId}-${selectedView}`
      ) || defaultLayout[localStorageKeysToSaveTo[1]];
    }

    setItems(layoutSaved);
    setLayoutMap(layoutMapSaved);
  }, [unitHistories, screenWidth]);

  useEffect(() => {
    updateLayout();

    return () => {
      setUserId('999');
      setItems(defaultLayout[localStorageKeysToSaveTo[0]]);
      setLayoutMap(defaultLayout[localStorageKeysToSaveTo[1]]);
    };
  }, [updateLayout]);

  useEffect(() => {
    const removeRglKeys = () => {
      for (let i = localStorage.length - 1; i >= 0; i--) {
        const key = localStorage.key(i);
        if (key && key.startsWith('rgl')) {
          localStorage.removeItem(key);
        }
      }
    };

    const handleBeforeUnload = () => {
      removeRglKeys();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const getGridItemInfo = (el: LayoutItem): LayoutMapItem | undefined => {
    let itemInfo = (layoutMap as GridItemInfo[])?.find(
      (item: LayoutMapItem) => el.i === item.i,
    );
    if (!itemInfo) {
      itemInfo = { i: el.i, uid: uuidv4(), type: '', name: 'not found' };
    }
    return itemInfo;
  };

  // const onToggleStatic = (i: string) => {
  //   const newLayout = items.slice(0);
  //   const index = newLayout.findIndex((item) => item.i === i);
  //   if (index === -1) return;
  //   const prevStaticVal = newLayout[index].static;
  //   newLayout[index].static = !prevStaticVal;
  //   setItems(newLayout);
  //   saveToLS(localStorageKeysToSaveTo[0], newLayout, `rgl-7-${userId}-${selectedView}`);
  // };

  const createElement = (el: LayoutItem) => {
    const contentStyle = {
      height: 'inherit',
    };

    const i = el.add ? '+' : el.i;

    // const newY = el.y == null ? Infinity : el.y;
    // el.y = newY;
    const itemInfo = getGridItemInfo(el);
    const itemKey = itemInfo?.uid;

    return (
      <div
        // style={{ height: '100%', width: '100%', position: 'absolute' }}
        key={itemKey}
        data-grid={el}
        className={`react-grid-item chart-${itemInfo?.name}-${itemKey}`}
      >
        {el.add ? (
          <span>
            <button
              type="button"
              onClick={(e) => onAddItem(e)}
              title="You can add an item by clicking here, too."
            >
              Add +
            </button>
          </span>
        ) : (
          <div style={contentStyle}>
            <DynamicChart type={itemInfo?.name} {...chartProps} isDragging={isDragging} draggedItem={draggedItem} />
          </div>
        )}
        <button
          type="button"
          // className={isDevModeEnabled ? '' : 'd-none'}
          className={'d-none'}
          style={{
            position: 'absolute',
            right: '2px',
            top: 0,
            cursor: 'pointer',
            width: '20px',
            height: '20px',
            backgroundColor: 'red',
            zIndex: 9999,
          }}
          onClick={() => onRemoveItem(i)}
        >
          x
        </button>
      </div>
    );
  };

  const onAddItem = (event?: React.MouseEvent): void => {
    const name = getRandomType();
    const chartType =
      typeof name === 'string' && name.length !== 0
        ? iconsTypeMap[name as keyof typeof iconsTypeMap]
        : gridTypes.highcharts[
        (Math.random() * gridTypes.highcharts.length) | 0
        ];

    const uid = uuidv4();
    const i = uid;

    const oldLayout = items;
    const cols = 12;
    const width = 2;
    const newItem = {
      i,
      x: (items.length * width) % cols,
      y: Infinity, // puts it at the bottom
      w: width,
      h: 2,
    };
    const all_items = oldLayout.concat(newItem);

    const old_MappedItem = layoutMap;
    const new_MappedItem = {
      uid,
      type: chartType,
      name: typeof name === 'string' ? name : '',
      i,
    };

    // @ts-ignore
    const all_MappedItem = old_MappedItem.concat(new_MappedItem);
    setItems(all_items);
    setNewCounter(newCounter + 1);
    setLayoutMap(all_MappedItem);
  };

  // We're using the cols coming back from this to calculate where to add new items.
  const onBreakpointChange = (breakpoint: string, cols: any) => {
    setCurrentBreakpoint(breakpoint);
    // setCols(cols);
  };

  const onLayoutChange = (layout: LayoutItem | any) => {
    // setItems(layout);
    if (!userId) return;
    saveToLS(
      localStorageKeysToSaveTo[0],
      layout,
      `rgl-7-${userId}-${selectedView}`,
    );
    saveToLS(
      localStorageKeysToSaveTo[1],
      layoutMap,
      `rgl-7.1-${userId}-${selectedView}`,
    );
    debouncedResizeEventToFixPlotlyScroll();
  };

  const onRemoveItem = (i: string) => {
    const oldItems = items;
    const newItems = oldItems.filter((item: any) => item.i !== i);

    setItems(newItems);
    saveToLS(
      localStorageKeysToSaveTo[0],
      newItems,
      `rgl-7-${userId}-${selectedView}`,
    );
    updateLayoutMap({
      action: 'remove',
      type: { UID: null, module: null, i, gridItem_i: i },
    });
  };

  const resetLayout = () => {
    const layoutSaved = defaultLayout[localStorageKeysToSaveTo[0]];
    const layoutMapSaved = defaultLayout[localStorageKeysToSaveTo[1]];
    setItems(layoutSaved);
    setLayoutMap(layoutMapSaved);

    saveToLS(
      localStorageKeysToSaveTo[0],
      layoutSaved,
      `rgl-7-${userId}-${selectedView}`,
    );
    saveToLS(
      localStorageKeysToSaveTo[1],
      layoutMapSaved,
      `rgl-7.1-${userId}-${selectedView}`,
    );
  };

  const updateLayoutMap = (obj: any) => {
    const { type } = obj;
    const { gridItem_i } = type;
    const { i } = type;

    let newLayoutMap = [...localStorageKeysToSaveTo[1]];
    const index = newLayoutMap.findIndex((item) => item.i === gridItem_i);

    switch (obj.action) {
      case 'remove':
        if (index === -1) return;
        newLayoutMap = newLayoutMap.filter((item) => item.i !== i);
        break;

      default:
        break;
    }

    if (obj.action) {
      setLayoutMap(newLayoutMap);
      saveToLS(
        localStorageKeysToSaveTo[1],
        newLayoutMap,
        `rgl-7.1-${userId}-${selectedView}`,
      );
    }
  };

  const handleDragStart = (layout: any, oldItem: any) => {
    setIsDragging(true);

    // Detect the dragged item's ID or chart type
    const draggedItemId = oldItem.i;

    // Find the chart type using the items list and the unique ID
    const draggedWidget = layoutMap.find((item: any) => item.i === draggedItemId);
    const { name } = draggedWidget
    if (draggedItem !== name) setDraggedItem(name);
  };

  const handleDragStop = () => {
    setIsDragging(false);
  };


  return (
    <div>
      <div
        // className={isDevModeEnabled ? '' : 'd-none'}
        className={'d-none'}
        style={{
          zIndex: 9999,
          position: 'absolute',
          right: 0,
        }}
      >
        <div>
          <button type="button" onClick={resetLayout}>
            ⟳
          </button>
        </div>
        <div>
          <button
            type="button"
            onClick={(e) => {
              onAddItem(e);
            }}
          >
            +
          </button>
        </div>
      </div>

      <ResponsiveReactGridLayout
        className="layout"
        draggableHandle=".drag-handle"
        breakpoints={config.breakpoints}
        cols={config.cols}
        rowHeight={config.rowHeight}
        isDraggable={config.isDraggable}
        isResizable={config.isResizable}
        // compactType="horizontal"
        onLayoutChange={onLayoutChange}
        onBreakpointChange={onBreakpointChange}
        // onDragStart={handleDragStart}
        // onDragStop={handleDragStop}
      >
        {items.map((el: any) => createElement(el))}
      </ResponsiveReactGridLayout>

      {/* <div className={isDevModeEnabled ? '' : 'd-none'}>
        <div>
          Current Breakpoint:
          {currentBreakpoint}
          {' -'}(
          {currentBreakpoint &&
            config?.cols[currentBreakpoint as keyof typeof config.cols]}
          columns)
        </div>
      </div> */}
    </div>
  );
}

export const ChartNoDependencyDataMessage = ({ text = "No data available", proxyVZText = "Choose '1 Hour', '8 Hour' or '24 Hour' time average to see this data", HasMetStation, selectedAveragingPeriod }: { text?: string, proxyVZText?: string, HasMetStation?: number | null | undefined, selectedAveragingPeriod?: AveragingPeriod | undefined }) => {
  const updatedText = typeof HasMetStation === 'number' && HasMetStation === 0 && selectedAveragingPeriod && selectedAveragingPeriod.labelHTML !== '1 Hour' && selectedAveragingPeriod.labelHTML !== '8 Hour' && selectedAveragingPeriod.labelHTML !== '24 Hour' ? proxyVZText : text;
  return (
    <div className="d-grid align-content-center h-100">
      <p className="center-text">{updatedText}</p>
    </div>
  );
};

export const getSelectedZephyrObj = (
  unitHistories: any,
  selectedWeatherZephyr: any,
  zephyrs: any,
) => {
  const { historiesList } = unitHistories;
  const { id } = selectedWeatherZephyr;
  const unit = historiesList.find(
    (zephyr: any) => +(Object.keys(zephyr) as any)[0] === +id,
  );
  const unitKey = Number(Object.keys(unit)[0]);
  const { selectedAveragingPeriod, data } = unit[unitKey];

  const selectedZephyr = zephyrs.find(
    (zephyr: any) => zephyr.zNumber === unitKey,
  );
  return { selectedZephyr, selectedAveragingPeriod, data };
};

export const getZephyrType = (zType: number): string => {
  switch (zType) {
    case 0:
      return 'Zephyr';
    case 1:
      return 'AURNS';
    case 100:
      return 'Virtual Zephyr';

    default:
      return '';
  }
};

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number,
): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout | null;

  return function (...args: Parameters<T>): void {
    const later = () => {
      timeout = null;
      func(...args);
    };

    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, wait);
  };
}

const debouncedResizeEventToFixPlotlyScroll = debounce(() => {
  window.dispatchEvent(new Event('resize'));
}, 250);
