// Package Imports
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';

// Component Imports
import FeedbackQuestions from './FeedbackQuestions';
import FeedbackTag from './FeedbackTag';

// Action Imports
import {
  setFeedback,
  setFeedbackIdx,
  setFeedbackOff,
  setFeedbackOn,
} from '../../actions/feedback';

// Type Imports
import {
  Alerts,
  Feedback as FeedbackType,
  Modal,
  ReduxState,
  Tour,
  UserInfo,
} from '../../utils/interface';

// Component Interfaces
interface FeedbackProps {
  alerts: Alerts;
  feedback: FeedbackType;
  modal: Modal;
  setFeedback: Function;
  setFeedbackIdx: Function;
  setFeedbackOff: Function;
  setFeedbackOn: Function;
  tour: Tour;
  userInfo: UserInfo;
}

// Component
const Feedback = ({
  alerts,
  feedback,
  modal,
  setFeedback,
  setFeedbackIdx,
  setFeedbackOff,
  setFeedbackOn,
  tour,
  userInfo,
}: FeedbackProps) => {
  const location = useLocation();
  
  return (
    <>
      {!userInfo.isPublic &&
      !userInfo.isInternal &&
        feedback.questions.length > 0 &&
      !location.pathname.includes('analysis') ?  (
        <div className="feedback-container">
          {feedback.on &&
          !alerts.on &&
          !tour.openTour &&
          !modal.contact &&
          !modal.data &&
          !modal.mappairQuote &&
          !modal.mappairTimeseries &&
          !modal.zephyrQuote ? (
            <FeedbackQuestions
              feedback={feedback}
              on={feedback.on}
              setFeedback={setFeedback}
              setFeedbackIdx={setFeedbackIdx}
              setFeedbackOff={setFeedbackOff}
            />
          ) : (
            <FeedbackTag
              on={
                !feedback.on ||
                alerts.on ||
                tour.openTour ||
                modal.contact ||
                modal.data ||
                modal.mappairQuote ||
                modal.mappairTimeseries ||
                modal.zephyrQuote
              }
              setFeedbackOn={setFeedbackOn}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  alerts: state.alerts,
  feedback: state.feedback,
  modal: state.modal,
  tour: state.tour,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setFeedback,
      setFeedbackIdx,
      setFeedbackOff,
      setFeedbackOn,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
