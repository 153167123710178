// Package Imports
import { Dispatch } from 'redux';

// Types Imports
import { SET_AIR_ALERTS } from './types';

// Type Imports
import { AirAlerts } from '../utils/interface';

// Action Creators
export const setAirAlerts = (airAlerts: AirAlerts) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_AIR_ALERTS,
    payload: airAlerts,
  });
};
