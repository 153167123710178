// Package Imports
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { HashLink } from 'react-router-hash-link';
import { connect } from 'react-redux';
import parse from 'html-react-parser';

// Hooks
import { useHistory, useLocation } from 'react-router-dom';

// Asset Imports
import { AirQuality101Content } from '../../utils/consts';

// Type Imports
import { ReduxState, UserInfoSection } from '../../utils/interface';

// Type Safety
interface AirQuality101Props {
  authorityInfo: UserInfoSection | null;
  handleAQ101Modal: Function;
  sectionId: string;
  standardInfo: UserInfoSection | null;
}

interface InitState {
  sectionsInView: string[];
}

// Component
const AirQuality101 = ({
  authorityInfo,
  handleAQ101Modal,
  sectionId,
  standardInfo,
}: AirQuality101Props) => {
  const { t: translate } = useTranslation();

  // State
  const initState: InitState = {
    sectionsInView: [],
  };
  const [sectionsInView, setSectionsInview] = useState(
    initState.sectionsInView,
  );

  // Consts
  const history = useHistory();
  const location = useLocation();

  // Effects
  useEffect(() => {
    const startingSection = 'AirQuality';
    const startingSectionsInView = [startingSection];
    setSectionsInview(startingSectionsInView);
  }, []);

  useEffect(() => {
    const sectionEl = document.getElementById(sectionId);
    if (sectionEl) {
      sectionEl.scrollIntoView();
    }
  }, [sectionId]);

  // Functions
  const checkActiveSection = (section: string) => {
    const numSectionsInView = sectionsInView.length;
    if (
      numSectionsInView &&
      sectionsInView[numSectionsInView - 1] === section
    ) {
      return true;
    }
    return false;
  };

  const handleAQ101Close = () => {
    handleAQ101Modal(false);
    history.push(location.pathname);
  };

  const updateElementsInView = (e: React.SyntheticEvent) => {
    if (
      standardInfo &&
      standardInfo.sections &&
      authorityInfo &&
      authorityInfo.sections
    ) {
      const updatedSectionsInView: string[] = [];
      const sections = [
        ...standardInfo.sections.map((s) => s.id),
        ...authorityInfo.sections.map((s) => s.id),
      ];
      const checkElementInViewport = (element: HTMLElement) => {
        const rect = element.getBoundingClientRect();
        const viewHeight = Math.max(
          document.documentElement.clientHeight,
          window.innerHeight,
        );
        const ans = !(rect.bottom < 0 || rect.top - viewHeight >= 0);
        return ans;
      };
      sections.forEach((section) => {
        const el = document.getElementById(section);
        if (el) {
          const inViewport = checkElementInViewport(el);
          if (inViewport) {
            updatedSectionsInView.push(section);
          }
        }
      });
      setSectionsInview(updatedSectionsInView);
    }
  };

  return (
    <div className="public-info-container">
      <nav>
        <div className="public-info-hero-header">
          {translate('AQ101header')}
        </div>
        <div>
          <ul>
            {standardInfo &&
              standardInfo.sections.map((section: any) => (
                <HashLink
                  key={section.id}
                  to={`${location.pathname}#${section.id}`}
                  scroll={(el) =>
                    el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                  }
                >
                  <li
                    className={`${
                      checkActiveSection(section.id) ? 'active' : ''
                    }`}
                  >
                    {section.header}
                  </li>
                </HashLink>
              ))}
            {authorityInfo &&
              authorityInfo.sections.map((section: any) => (
                <HashLink
                  key={section.id}
                  to={`${location.pathname}#${section.id}`}
                  scroll={(el) =>
                    el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                  }
                >
                  <li
                    className={`${
                      checkActiveSection(section.id) ? 'active' : ''
                    }`}
                  >
                    {section.header}
                  </li>
                </HashLink>
              ))}
          </ul>
          <button className="button close" onClick={() => handleAQ101Close()}>
            {AirQuality101Content.AQ101CloseModal}
          </button>
        </div>
      </nav>
      <div
        className="public-info-main"
        onScroll={(e) => updateElementsInView(e)}
      >
        {standardInfo &&
          standardInfo.sections.map((section: any) => (
            <div key={section.id} id={section.id}>
              {parse(section.html)}
            </div>
          ))}
      </div>
    </div>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  authorityInfo: state.auth.userInfo.userCustomInfo,
  sectionId: state.aq101.sectionId,
  standardInfo: state.auth.userInfo.userStandardInfo,
});

export default connect(mapStateToProps, {})(AirQuality101);
