// Package Imports
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

// Component Imports
import AQSA from './AQSA';
import AQTemporal from './AQTemporal';
import AQWind from './AQWind';
import Spinner from '../shared_components/Spinner';

// Action Imports
import { setMappairTimeseriesModalOn, setModalsOff } from '../../actions/modal';
import { setMapAirPointValueHistory } from '../../actions/mapAirPointValueHistory';

// Type Imports
import {
  AQModelDataTabs,
  ChartData,
  Modal as ModalType,
  PointValues,
  ReduxState,
  SAChartData,
  Species,
  WindRoseData,
  MapAirPointValueHistoryData,
} from '../../utils/interface';

// Const Imports
import { aqmOptions } from '../../utils/consts';

// Component Interfaces
interface AQModelDataProps {
  aqmIsOn: AQModelDataTabs;
  changeMapAirModelTab: Function;
  toggleAccordion: Function;
  curSpecies: Species;
  isOn: boolean;
  modal: ModalType;
  points: PointValues | null;
  saData: SAChartData | null;
  setMappairTimeseriesModalOn: Function;
  setModalsOff: Function;
  temporalModelData: ChartData | null;
  tourAQModelDataOpen: boolean;
  tourOpen: boolean;
  windModelData: WindRoseData[] | null;
  mapAirPointValueHistory: MapAirPointValueHistoryData;
}

// Component
const AQModelData = ({
  aqmIsOn,
  changeMapAirModelTab,
  toggleAccordion,
  curSpecies,
  isOn,
  modal,
  points,
  saData,
  setMappairTimeseriesModalOn,
  setModalsOff,
  temporalModelData,
  tourAQModelDataOpen,
  tourOpen,
  windModelData,
  mapAirPointValueHistory,
}: AQModelDataProps) => {
  const { t: translate } = useTranslation();

  // Effects
  useEffect(() => {
    if (
      !(aqmIsOn.aqmWind && windModelData) &&
      !(aqmIsOn.aqmSA && saData) &&
      !(aqmIsOn.aqmTemp && temporalModelData)
    ) {
      if (temporalModelData) {
        switchAQMTab('aqmTemp');
      } else if (saData) {
        switchAQMTab('aqmSA');
      } else if (windModelData) {
        switchAQMTab('aqmWind');
      }
    }
  }, [temporalModelData, saData, windModelData]);

  // Functions
  const switchAQMTab = (tab: string) => {
    if (!aqmIsOn[tab]) {
      changeMapAirModelTab(tab);
    }
  };

  // Component
  return (
    <li className="tourModelData">
      <button
        className={`${(isOn && !tourOpen) || (tourOpen && tourAQModelDataOpen)
          ? 'active'
          : ''
          }`}
        onClick={() => toggleAccordion('aqModelData')}
      >
        {translate('AQModelDataButton')}
      </button>

      <>
        {(isOn && !tourOpen) || (tourOpen && tourAQModelDataOpen) ? (
          <div className="aqm">
            {mapAirPointValueHistory.status === 'succeeded' &&
              (saData || temporalModelData || windModelData) ? (
              <>
                {[temporalModelData, windModelData, saData].filter((d) => d)
                  .length > 0 ? (
                  <>
                    {[temporalModelData, windModelData, saData].filter((d) => d)
                      .length > 1 ? (
                      <nav className="aq-nav">
                        <ul>
                          {aqmOptions
                            .filter(
                              (aqmO) =>
                                (aqmO.value === 'aqmTemp' &&
                                  temporalModelData) ||
                                (aqmO.value === 'aqmWind' && windModelData) ||
                                (aqmO.value === 'aqmSA' && saData),
                            )
                            .map((op) => (
                              <li
                                key={op.label}
                                onClick={() => switchAQMTab(op.value)}
                                className={aqmIsOn[op.value] ? 'active' : ''}
                              >
                                {op.label}
                              </li>
                            ))}
                        </ul>
                      </nav>
                    ) : null}

                    {(points !== null) ? (

                      <AQTemporal
                        curSpecies={curSpecies}
                        hdms={points!.hdms}
                        modal={modal}
                        setMappairTimeseriesModalOn={setMappairTimeseriesModalOn}
                        setModalsOff={setModalsOff}
                        temporalModelData={temporalModelData}
                        isOn={aqmIsOn.aqmTemp}

                      />

                    ) :
                      <></>
                    }

                    <AQWind isOn={aqmIsOn.aqmWind} windData={windModelData} />
                    <AQSA saData={saData} isOn={aqmIsOn.aqmSA} />
                  </>
                ) : (
                  <div className="aqm-placeholder">
                    <div>{translate('AQModelNoData')}</div>
                  </div>
                )}
              </>
            ) : (
              <div className="aqm-placeholder">
                {mapAirPointValueHistory.status === 'loading' ? (
                  <>
                    <div>{translate('AQModelDataLoading')}</div>
                    <Spinner
                      additionalClass="header-spinner dark-spinner see-through"
                      on
                    />
                  </>
                ) : null}

                {mapAirPointValueHistory.status === 'idle' ? (
                  <div>{translate('AQModelDataClickPrompt')}</div>
                ) : null}
              </div>
            )}
          </div>
        ) : null}
      </>
    </li>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  modal: state.modal,
  tourAQModelDataOpen: state.tour.openAQIndex.openAQModelData,
  tourOpen: state.tour.openTour,
  mapAirPointValueHistory: state.setMapAirPointValueHistory,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setModalsOff,
      setMappairTimeseriesModalOn,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AQModelData);
