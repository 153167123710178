// Types Imports
import { Dispatch } from 'redux';
import { SET_ANNUAL_AVERAGES } from './types';

// Action Creators
export const setAnnualAverages = (analytics: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_ANNUAL_AVERAGES,
    payload: analytics,
  });
};
