// Types Imports
import { OPEN_ASIDE, CLOSE_ASIDE } from '../actions/types';

// Type Safety
interface AsideState {
  on: boolean;
}

interface Aside {
  type: typeof OPEN_ASIDE | typeof CLOSE_ASIDE;
  payload: boolean;
}

// State
const initState: AsideState = {
  on: true,
};

// Reducer
export default function (state = initState, action: Aside) {
  const { type } = action;

  switch (type) {
    case OPEN_ASIDE:
      return {
        on: true,
      };

    case CLOSE_ASIDE:
      return {
        on: false,
      };

    default:
      return state;
  }
}
