// Types Imporrts
import {
  GET_ZEPHYR_HISTORICAL_DATA,
  SET_ZEPHYR_HISTORICAL_DATA_LOADING,
} from '../actions/types';

// Type Imports
import { ZephyrHistory } from '../utils/interface';

// Type Safety
interface ZephyrHistoricalData {
  zephyrHistory: ZephyrHistory | null;
  loading: boolean | null;
  zType?: number | null;
  isHistoricalDataNull: boolean;
}

interface getZephyrHistoricalData {
  type:
  | typeof GET_ZEPHYR_HISTORICAL_DATA
  | typeof SET_ZEPHYR_HISTORICAL_DATA_LOADING;
  payload: {
    data: ZephyrHistory | false | null,
    zType: number | null
    isHistoricalDataNull: boolean,
  };
}

// State
const initialState: ZephyrHistoricalData = {
  zephyrHistory: null,
  loading: null,
  zType: null,
  isHistoricalDataNull: false,
};

// Reducer
export default function (
  state = initialState,
  action: getZephyrHistoricalData,
) {
  const { type, payload } = action;

  switch (type) {
    case GET_ZEPHYR_HISTORICAL_DATA:
      return {
        ...state,
        zephyrHistory: payload.data,
        zType: payload.zType,
        isHistoricalDataNull: payload.isHistoricalDataNull
      };
    case SET_ZEPHYR_HISTORICAL_DATA_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
}
