// Package Imports
import React, { useState, useEffect, useRef } from 'react';

// Component Interfaces
interface ProgressBarProps {
  additionalClass?: string;
  dataLoading: boolean;
  setProgressBarOn: Function;
  timeEstimate: number;
}

// Component
const ProgressBar = ({
  additionalClass,
  dataLoading,
  setProgressBarOn,
  timeEstimate,
}: ProgressBarProps) => {
  // State
  const [time, setTime] = useState(0);

  useEffect(() => {
    setProgressBarOn(true);
  }, []);

  useEffect(() => {
    if (!dataLoading) {
      setProgressBarOn(false);
    }
  }, [dataLoading]);

  // Hooks
  const useInterval = (callback: Function, delay: number | null) => {
    const savedCallback: any = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      const tick = () => {
        savedCallback.current();
      };
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };
  // Credit Dan Abramov: https://overreacted.io/making-setinterval-declarative-with-react-hooks/

  // Timer
  useInterval(
    () => {
      setTime(time + 100);
    },
    dataLoading ? 100 : 0.000001,
  );

  return (
    <>
      {dataLoading || time < timeEstimate ? (
        <div className={`progress-container ${additionalClass || ''}`}>
          <div className="progress-bar-outter">
            <div
              className="progress-bar-inner"
              style={{
                width: `${
                  Math.round((time / timeEstimate) * 100) < 100
                    ? Math.round((time / timeEstimate) * 100)
                    : 99
                }%`,
              }}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ProgressBar;
