// Type Imports
import { SET_USER_CONFIG } from '../actions/types';

// Type Safety
interface UserConfig {
  showTour: boolean;
}

interface SetUserConfig {
  type: typeof SET_USER_CONFIG;
  payload: UserConfig;
}

// State
const initState: UserConfig = {
  showTour: false,
};

// Reducer
export default function (state = initState, action: SetUserConfig) {
  const { type, payload } = action;

  switch (type) {
    case SET_USER_CONFIG:
      return {
        ...payload,
      };

    default:
      return state;
  }
}
