import React from 'react';

interface ComparisonTooltipProps {
    setIsWarningTooltipOpened: Function;
    setErrorZephyr: Function;
};

const ComparisonTooltip: React.FC<ComparisonTooltipProps> = ({ setIsWarningTooltipOpened, setErrorZephyr }) => {

    const closeTooltip = () => {
        setIsWarningTooltipOpened(false);
        setErrorZephyr(null);
    };

    return (
        <div className='comparison-tooltip'>
            <div className='comparison-tooltip-header'>
                <p>Device limit reached</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" onClick={closeTooltip}>
                    <path d="M12 4L4 12M4 4L12 12" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
            <div className='comparison-tooltip-advice'>
                <p>Remove a device to add another to comparison.</p>
                <span>4 out of 4</span>
            </div>
        </div>
    )
};

export default ComparisonTooltip;