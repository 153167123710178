// Package imports
import { ExportToCsv } from 'export-to-csv';

// Util Imports
import { slotFinder } from './slotFinder';
import { getDateFromTimestamp, getShortDateFromISO } from './dateFinder';
import { speciesObjFinder } from './tabFinder';
import { convertRawData } from './chartHelper';

// Type Imports
import {
  AveragingPeriod,
  ChartData,
  CsvData,
  DisplayConfig,
  SlotOptions,
  Species,
  SpeciesDataIdentifiers,
  Zephyr,
  ZephyrHistoryWithId,
} from '../interface';

// Consts
const defaultOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: false,
  title: '',
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
};

/* Function
DESC: csvGenerator generates and downloads data for the main Data tab 
ARGS: averaging option, current slot, unit list, unit histories
*/
const csvGenerator = (
  averagingOption: AveragingPeriod,
  displayConfig: DisplayConfig,
  isPublic: boolean,
  slot: SlotOptions,
  unitList: Zephyr[],
  unitHistories: ZephyrHistoryWithId,
) => {
  if (unitHistories && Object.keys(unitHistories).length) {
    const data: CsvData[] = [];
    const oneTimeColumns: string[] = ['dateTime', 'UTS', 'localDateTime'];
    // Map over each unit until we find one with dates
    let dates: string[] = [];
    let activeUnitIdx: number = 0;
    let activeUnitSlot: string = '';
    for (let i = 0; i <= unitList.length - 1; i += 1) {
      const unit = unitHistories[unitList[i].zNumber];
      const processSlot = i === 0 && slot ? slot : slotFinder(unit)[0];
      if (unit && unit[averagingOption.averagingOption][processSlot]) {
        dates = unitHistories[unitList[i].zNumber][
          averagingOption.averagingOption
        ][processSlot]!.dateTime.data;
        activeUnitIdx = i;
        activeUnitSlot = processSlot;
        break;
      }
    }
    if (dates.length) {
      dates.forEach((dt, dtIdx) => {
        // Create starter object
        let newDataset: CsvData = {
          'Timestamp(UTC)': dt,
          'Timestamp(UTS)': unitHistories[unitList[activeUnitIdx].zNumber][
            averagingOption.averagingOption
          ][activeUnitSlot]!.UTS.data[dtIdx],
          'Timestamp(Local)': unitHistories[unitList[activeUnitIdx].zNumber][
            averagingOption.averagingOption
          ][activeUnitSlot]!.localDateTime.data[dtIdx],
        };
        // Map over each unit
        unitList.forEach((unit) => {
          if (unitHistories[unit.zNumber]) {
            const availableSlots = slotFinder(unitHistories[unit.zNumber]);
            // Add any data that is consistent across slots e.g. lat/long
            const slotAgnosticData = ['latitude', 'longitude'];
            if (!isPublic) {
              slotAgnosticData.forEach((sAD) => {
                const head = unitHistories[unit.zNumber][
                  averagingOption.averagingOption
                ]['head'];
                if (head) {
                  const sADItem = head![sAD];
                  if (
                    sADItem.data.length &&
                    sADItem.data.find((dp) => dp !== null) !== null &&
                    sADItem.data.find((dp) => dp !== null) !== undefined
                  ) {
                    newDataset[`${unit.name}-${sADItem.header.HTMLLabel}`] =
                      sADItem.data[dtIdx];
                  }
                }
              });
            }
            // Map over each metric for each slot with data in unit history
            const dataHolder: any[] = [];
            availableSlots.forEach((availableSlot) => {
              const unitData =
                unitHistories[unit.zNumber][averagingOption.averagingOption][
                availableSlot
                ];

              if (unitData) {
                // Add a placeholder value for each qualifying metric
                for (const metricKey in unitData) {
                  if (!oneTimeColumns.includes(metricKey)) {
                    // Check whether species is in the display config or not
                    const speciesInDisplayConfig = speciesObjFinder(
                      displayConfig,
                      metricKey as SpeciesDataIdentifiers,
                    );
                    if (
                      (!isPublic || speciesInDisplayConfig) &&
                      !slotAgnosticData.includes(metricKey)
                    ) {
                      dataHolder.push(null);
                    }
                  }
                }
                // Push qualifying datasets that contain data
                for (const [metricKey, metricValues] of Object.entries(
                  unitData,
                )) {
                  const speciesInDisplayConfig = speciesObjFinder(
                    displayConfig,
                    metricKey as SpeciesDataIdentifiers,
                  );
                  if (
                    !oneTimeColumns.includes(metricKey) &&
                    (!isPublic || speciesInDisplayConfig) &&
                    !slotAgnosticData.includes(metricKey) &&
                    metricValues.data.length &&
                    metricValues.data.find((dp) => dp !== null) !== null &&
                    metricValues.data.find((dp) => dp !== null) !== undefined
                  ) {
                    let header = `${metricValues.header.label}(${metricValues.header.units
                      })${isPublic ? '' : `-${availableSlot}`}`;
                    let data = metricValues.data[dtIdx];
                    // Apply any data conversions as needed
                    const speciesObj = speciesObjFinder(
                      displayConfig,
                      metricKey as SpeciesDataIdentifiers,
                    );
                    // longitude/latitude etc. will be undefined
                    if (speciesObj) {
                      const convertedData = convertRawData(
                        [metricValues.data[dtIdx]],
                        speciesObj,
                      );
                      if (
                        convertedData.length &&
                        metricValues.data[dtIdx] !== convertedData[0]
                      ) {
                        header = `${metricValues.header.label}${speciesObj.HTMLShortUnitLabel
                          }${isPublic ? '' : `-${availableSlot}`}`;
                        data = convertedData[0];
                      }
                    }
                    dataHolder[metricValues.header.CSVOrder] = {
                      data,
                      header,
                    };
                  }
                }
              }
              const finalDataHolder = dataHolder.filter((dh) => dh !== null);
              finalDataHolder.forEach((unitMetricData) => {
                if (unitMetricData) {
                  newDataset = {
                    ...newDataset,
                    [`${unit.name}-${unitMetricData.header}`]: unitMetricData.data,
                  };
                }
              });
            });
          }
        });
        data.push(newDataset);
      });

      const options = {
        ...defaultOptions,
        filename: `${unitList.length === 1 ? unitList[0].name : 'multipleUnits'
          }_${averagingOption.labelHTML}_${getDateFromTimestamp(
            data[0]['Timestamp(UTC)'],
          )}_${getDateFromTimestamp(data[data.length - 1]['Timestamp(UTC)'])}`,
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(data);
    }
  }
};

/* Function
DESC: mappairCsvGenerator generates and downloads data for MappAir timeseries
ARGS: chartDataset, curSpecies
*/
const mappairCsvGenerator = (
  chartDataset: ChartData,
  curSpecies: Species,
  hdms?: string,
) => {
  const data: CsvData[] = [];
  chartDataset.datasets[0].data.forEach((d: any, idx: any) => {
    const newDataPoint = {
      'Timestamp(UTC)': chartDataset.labels[idx].toISOString(),
      [`${curSpecies.HTMLUnitLabel}`]: d,
    };
    data.push(newDataPoint);
  });
  // Fix hdms string for filename purposes
  let formattedHdms = '';
  if (hdms) {
    formattedHdms = hdms
      .replace(/(°)/g, 'D')
      .replace(/(′)/g, 'M')
      .replace(/(″)/g, 'S');
  }

  const options = {
    ...defaultOptions,
    filename: `MappAir_${formattedHdms}_${curSpecies.HTMLUnitLabel
      }_${getShortDateFromISO(data[0]['Timestamp(UTC)'])}_${getShortDateFromISO(
        data[data.length - 1]['Timestamp(UTC)'],
      )}`,
  };
  const csvExporter = new ExportToCsv(options);
  csvExporter.generateCsv(data);
};

export { csvGenerator, mappairCsvGenerator };
