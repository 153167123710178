// Package Imports
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Action Imports
import { setThresholdTab } from '../../actions/stylegroups';

// Util Imports
import theresholdFormatter from '../../utils/functions/thresholdFormatter';
import analyticsEventFirer from '../../utils/functions/analyticsEventFirer';

// Const Imports
import {
  gtmEventIdentifiers,
} from '../../utils/consts';

// Asset Imports
import kebab from '../../assets/images/icon-kebab-sg.svg';

// Type Imports
import { ReduxState, ShowAQMALayer, Stylegroup } from '../../utils/interface';

// Component Interfaces
interface AQColourscaleProps {
  activeOverlay: string;
  activeStylegroup: string;
  toggleAccordion: Function;
  isOn: boolean;
  isPublic: boolean;
  setThresholdTab: Function;
  stylegroups: Stylegroup[];
  tourAQColourscaleOpen: boolean;
  tourOpen: boolean;
  mappairOptions: ShowAQMALayer;
  thresholdTab: string,
}

// Styled Component
const StylegroupSelectorWrapper = styled.div`
  .sg-container {
    max-height: 40vh;
    overflow: auto;
  }
  background-color: #fff;
  ol {
    padding: 25px 20px 5px 20px;
  }
  .band {
    border: none;
    margin-bottom: 20px;
    font-size: 1.1rem;
  }
  .band-identifier {
    margin-right: 10px;
    height: 16px;
    width: 16px;
    background: red;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
  }
  .band-description {
    float: right;
    font-size: 1rem;
    max-width: 135px;
    text-align: right;
  }
  .sg-option-list {
    display: none;
    position: absolute;
    background-color: #fff;
    left: 0;
    width: 100%;
    z-index: 10;
  }
  .sg-option-list-active {
    display: block;
  }
  .sg-option {
    padding: 15px 20px;
    font-size: 1.3rem;
    cursor: pointer;
    &:hover {
      background: #efefef;
      transition: 0.3s all;
    }
  }
  .sg-option-inactive {
    color: #92949a;
    background: #efefef;
    cursor: auto;
  }
  .sg-selector {
    padding: 15px 20px;
    font-size: 1.6rem;
    cursor: pointer;
    color: #4e73c3;
    background: #fff;
    border-bottom: 1.5px solid #efefef;
    &:hover {
      background: #efefef;
      transition: 0.3s all;
    }
    .sg-selector-button {
      padding: 0px;
      padding-right: 15px;
      background: url(${kebab});
      background-repeat: no-repeat;
      background-position: 50%;
      height: 20px;
      width: 7px;
      display: inline-block;
      border: none;
      float: right;
      &:after {
        display: none;
      }
    }
  }
  .sg-selector.no-options {
    cursor: auto;
    &:hover {
      background: #fff;
    }
    .sg-selector-button {
      background: none;
    }
  }
  .sg-selector.no-options.public {
    display: none;
  }
  .public {
    .band {
      margin-bottom: 13.5px;
    }
  }
`;

// Component
const AQColourscale = ({
  activeOverlay,
  activeStylegroup,
  toggleAccordion,
  isOn,
  isPublic,
  setThresholdTab,
  stylegroups,
  tourAQColourscaleOpen,
  tourOpen,
  mappairOptions,
  thresholdTab,
}: AQColourscaleProps) => {
  const { t: translate } = useTranslation();

  // State
  const initState = {
    activeStylegroup: '',
    stylegroupMenuActive: false,
  };

  const [stylegroupMenuActive, setStylegroupMenuActive] = useState(
    initState.stylegroupMenuActive,
  );

  // Functions
  const closeStyleGroupMenu = () => {
    setStylegroupMenuActive(false);
  };

  const handleStylegroupChange = (stylegroup: Stylegroup) => {
    if (stylegroup.thresholds[activeOverlay]) {
      closeStyleGroupMenu();
      setThresholdTab(stylegroup.name);
      // Analytics
      analyticsEventFirer(
        gtmEventIdentifiers.colourscaleSelect,
        stylegroup.name,
      );
    }
  };

  const handleStylegroupMenu = () => {
    setStylegroupMenuActive(!stylegroupMenuActive);
  };

  return (
    <div className="tourColourscale" data-user-tour="step-3">
      <li className="remove-mobile">
        <button
          className={
            (isOn && !tourOpen) || (tourOpen && tourAQColourscaleOpen)
              ? 'active'
              : ''
          }
          onClick={() => toggleAccordion('aqColourscale')}
        >
          {!isPublic ? translate('AQCButton') : translate('AQCButtonPublic')}
        </button>
        <div
          className={
            (isOn && !tourOpen) || (tourOpen && tourAQColourscaleOpen)
              ? 'aqs-index-content active'
              : 'aqs-index-content ninja'
          }
        >
          <div className="aqs-index">
            <nav className="aqs-index__nav">
              {activeStylegroup && activeOverlay && stylegroups.length ? (
                <StylegroupSelectorWrapper>
                  <div className={`${isPublic ? 'public' : ''}sg-container`}>
                    <div
                      className={`sg-selector ${stylegroups.length < 2 ? 'no-options' : ''
                        } ${isPublic ? 'public' : ''}`}
                      onClick={handleStylegroupMenu}
                    >
                      {
                        stylegroups.filter(
                          (sg) => sg.name === activeStylegroup,
                        )[0].displayedNameHTML
                      }
                      <button className="sg-selector-button" />
                    </div>
                    <ul
                      className={`sg-option-list ${stylegroupMenuActive && !tourOpen
                        ? 'sg-option-list-active'
                        : ''
                        }`}
                      onMouseLeave={closeStyleGroupMenu}
                    >
                      {stylegroups
                        .filter((sg) => sg.name !== activeStylegroup)
                        .map((sg, idx) => (
                          <li
                            key={idx}
                            className={`sg-option ${sg.thresholds[activeOverlay]
                              ? ''
                              : 'sg-option-inactive'
                              }`}
                            onClick={() => handleStylegroupChange(sg)}
                          >
                            {sg.displayedNameHTML}
                          </li>
                        ))}
                    </ul>
                    <ol>
                      {stylegroups.filter(
                        (sg) => sg.name === activeStylegroup,
                      )[0].thresholds[activeOverlay] && theresholdFormatter(
                        stylegroups.filter(
                          (sg) => sg.name === activeStylegroup,
                        )[0].thresholds[activeOverlay],
                        activeOverlay
                      ).map((t, idx, tArr) => (
                        <li key={idx} className="band">
                          {
                            // TODO: Find means of passing props to SC with TS
                          }

                          <span
                            className="band-identifier"
                            style={{
                              backgroundColor: `rgb(${t.r}, ${t.g}, ${t.b})`,
                            }}
                          />

                          {`${t.min}${t.max ? (t.min === t.max ? '' : `-${t.max}`) : '+'
                            }`}

                          {isPublic && activeOverlay !== 'UK AQI' ? (
                            <span> µg/m³</span>
                          ) : null}

                          <span className="band-description">
                            {
                              // Display the threshold name if one exists and it hasn't already been displayed in a previous band
                              //`${
                              //  (idx === 0 ||
                              //    (tArr[idx - 1] &&
                              //      tArr[idx - 1].name !== t.name)) &&
                              //  t.name !== null
                              //    ? t.name
                              //    : ''
                              //}`
                              `${t.name}`
                            }
                          </span>
                        </li>
                      ))}
                    </ol>
                  </div>
                </StylegroupSelectorWrapper>
              ) : (
                <></>
              )}
            </nav>
          </div>
        </div>
      </li>
      {activeStylegroup && activeOverlay && stylegroups.length ? (
        <div className="scale-bar-container only-mobile">
          <div
            className="scale-bar"
            style={{
              backgroundImage: `linear-gradient(to right, ${stylegroups
                .filter((sg) => sg.name === activeStylegroup)[0]
                .thresholds[activeOverlay].map(
                  (sg) => `rgb(${sg.r},${sg.g},${sg.b})`,
                )})`,
            }}
          />
          <div className="scale-bar-extremes">
            <div>{translate('Low')}</div>
            <div>{translate('High')}</div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  tourAQColourscaleOpen: state.tour.openAQIndex.openAQColourscale,
  tourOpen: state.tour.openTour,
  mappairOptions: state.showAQMALayer,
  thresholdTab: state.setThresholdTab.tab,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setThresholdTab,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AQColourscale);
