// Type Imports
import {
  SET_AQ101_OFF,
  SET_AQ101_ON,
  SET_AQ101_SECTION,
} from '../actions/types';

// Type Safety
interface InitState {
  on: boolean;
  sectionId: string;
}

interface SetAQ101 {
  type: typeof SET_AQ101_OFF | typeof SET_AQ101_ON | typeof SET_AQ101_SECTION;
  payload: string;
}

// State
const initState: InitState = { on: false, sectionId: '' };

// Reducer
export default function (state = initState, action: SetAQ101) {
  const { type, payload } = action;

  switch (type) {
    case SET_AQ101_OFF:
      return { ...state, on: false };
    case SET_AQ101_ON:
      return { ...state, on: true };
    case SET_AQ101_SECTION:
      return { ...state, sectionId: payload };

    default:
      return state;
  }
}
