// Package Imports
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Util Imports
import { aqMetMapper } from '../../utils/functions/tabFinder';

// Type Imports
import { DisplayConfig, Zephyr } from '../../utils/interface';

// Component Interfaces
interface AQOtherProps {
  displayConfig: DisplayConfig;
  isOn: boolean;
  zephyr: Zephyr;
}

const AQOther = ({ displayConfig, isOn, zephyr }: AQOtherProps) => {
  const { t: translate } = useTranslation();

  return (
    <>
      {isOn ? (
        <>
          {zephyr &&
            zephyr.type !== 100 &&
            zephyr.externalMetData &&
            zephyr.externalMetData.windDir ? (
            <>
              <h4>
                {zephyr.externalMetData.siteId === 0
                  ? translate('AQGFSDesc')
                  : `${zephyr.externalMetData.siteName
                    .toLowerCase()
                    .replace(/(^\w{1})|(\s+\w{1})/g, (match) =>
                      match.toUpperCase(),
                    )} ${translate('AQMetDesc')}`}
                <span className="aq-period">
                  <br />
                  {zephyr.externalMetData.siteId === 0
                    ? translate('AQPeriodGFS')
                    : translate('AQPeriodMet')}
                </span>
              </h4>
              <div className="species-table">
                {aqMetMapper &&
                  aqMetMapper(displayConfig, zephyr.externalMetData)?.map(
                    (group, idx, arr) => (
                      <div key={group.group}>
                        {group.species.length > 0 ? (
                          <ul
                            className={`species-table-data ${idx === arr.length - 1 ? 'last' : ''
                              }`}
                          >
                            {group.species.map((species) => (
                              <li key={species.dataIdentifier}>
                                <div className="aq-species">
                                  <div className="table-score">
                                    {
                                      zephyr.externalMetData![
                                      species.dataIdentifier
                                      ]
                                    }
                                    <div className="table-data-label">
                                      {species.HTMLLabel}
                                    </div>
                                    <div className="table-data-unit">
                                      <p className="unit">
                                        {species.HTMLExposedUnitLabel}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <></>
                        )}
                      </div>
                    ),
                  )}
              </div>
              <div className="aq-data-disclaimer">
                {zephyr.externalMetData.siteId === 0
                  ? translate('AQDisclaimerGFS')
                  : translate('AQDisclaimerMet')}
              </div>
            </>
          ) : zephyr.type === 100 ? (
            <h5>Regional met data for Virtual Zephyrs is in development and will be available soon.</h5>
          ) : <h3>Loading Met Data...</h3>}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AQOther;
