// Util Imports
import {
  speciesColourFinder,
  speciesObjFinder,
  speciesFinder,
} from './tabFinder';
import { slotFinder } from './slotFinder';

// Const Imports
import {
  borderDashConfigs,
  borderDashConfigs2,
  placeholderAxisConfig,
} from '../consts';

// Type Imports
import {
  AveragingPeriod,
  ActiveTab,
  ChartData,
  LineChartData,
  LineChartDataEnriched,
  LineChartDataPoints,
  DisplayConfig,
  SecondaryAxisConfig,
  SlotOptions,
  Species,
  SpeciesDataIdentifiers,
  Zephyr,
  ZephyrHistory,
  ZephyrHistoryWithId,
} from '../interface';

const convertRawData = (dataArr: any[], speciesObj: Species) => {
  // Currently rounds to 1dp - scope to add rounding param to displayConfig
  const processedRawData = dataArr.map((rD) => {
    if (typeof rD === 'number' && rD !== 0) {
      if (
        speciesObj.conversionDivider &&
        typeof speciesObj.conversionDivider === 'number'
      ) {
        const convertedNum =
          Math.round((rD / speciesObj.conversionDivider) * 10) / 10;
        return convertedNum;
      }
      if (
        speciesObj.conversionDivider &&
        typeof speciesObj.conversionMultiplier === 'number'
      ) {
        const convertedNum =
          Math.round(rD * speciesObj.conversionMultiplier * 10) / 10;
        return convertedNum;
      }
    }
    return rD;
  });
  return processedRawData;
};

const getChartDataset = (
  averagingOption: AveragingPeriod,
  activeTab: ActiveTab,
  activeTabIdx: number,
  displayConfig: DisplayConfig,
  slot: SlotOptions,
  species: SpeciesDataIdentifiers,
  unitId: number,
  unitIdx: number,
  unitList: Zephyr[],
  zephyrHistory: ZephyrHistory,
) => {
  const availableSpecies = speciesFinder(displayConfig, slot, zephyrHistory);
  const targetTab = displayConfig.tabs.filter(
    (t) =>
      t.species.filter((tS) =>
        tS.dataIdentifiers.includes(activeTab.tab[activeTabIdx]),
      ).length,
  )[0];
  const targetSpecies = availableSpecies.filter(
    (aS) =>
      targetTab.species.filter(
        (tDS) =>
          tDS.dataIdentifiers.includes(aS as SpeciesDataIdentifiers) &&
          tDS.dataIdentifier === activeTab.tab[activeTabIdx],
      ).length,
  )[0];
  if (
    zephyrHistory[averagingOption.averagingOption][slot] &&
    zephyrHistory[averagingOption.averagingOption][slot]![targetSpecies] &&
    zephyrHistory[averagingOption.averagingOption][slot]![targetSpecies].data
      .length
  ) {
    const unit = unitList.filter((u) => u.zNumber === unitId)[0];
    const speciesObj = speciesObjFinder(displayConfig, species);
    const speciesColour = speciesColourFinder(displayConfig, species, unitIdx);
    const rawData = zephyrHistory[averagingOption.averagingOption][slot]![
      targetSpecies
    ].data;
    let processedData = rawData;
    if (speciesObj.conversionDivider || speciesObj.conversionMultiplier) {
      processedData = convertRawData(rawData, speciesObj);
    }
    const dataset: LineChartData = {
      unitId,
      species,
      label: `${unit.name}-${activeTab.labels[activeTabIdx]}-${slot}`,
      speciesLabel: `${activeTab.labels[activeTabIdx]}-${slot}`,
      unitNameLabel: unit.name,
      hidden: false,
      defaultColour: '#fff',
      featureColour: speciesColour,
      borderDash: borderDashConfigs[unitIdx],
      borderDash2: borderDashConfigs2[unitIdx],
      data: processedData,
      speciesObj,
    };
    return dataset;
  }
  return null;
};

const getIdxOfUnit = (unitId: number, unitList: Zephyr[]) => {
  let unitIdx: number = 0;
  unitList.forEach((unit, idx) => {
    if (unit.zNumber === unitId) {
      unitIdx = idx;
    }
  });
  return unitIdx;
};

const getSlotData = (
  averagingOption: AveragingPeriod,
  slot: SlotOptions,
  zephyrHistory: ZephyrHistory,
) => {
  let slotData = null;
  if (slot && zephyrHistory[averagingOption.averagingOption]) {
    slotData = zephyrHistory[averagingOption.averagingOption][slot];
  } else {
    // if selected averagingOption not exist, find another averagingOption
    for (const avgKey in zephyrHistory) {
      const avgInfo = zephyrHistory[avgKey];
      if (avgInfo[slot]) {
        slotData = avgInfo[slot];
        break;
      }
    }
  }

  return slotData;
};

// we are only going in the slot to find the timezone type
const getTzConfig = (
  averagingOption: AveragingPeriod,
  slot: SlotOptions,
  zephyrHistory: ZephyrHistory,
) => {
  let tzType = '';
  let tzSpecific = '';
  if (zephyrHistory) {
    const slotData = getSlotData(averagingOption, slot, zephyrHistory);

    if (slotData) {
      tzType =
        slotData.localDateTime &&
          slotData.localDateTime.data.length === slotData.dateTime.data.length &&
          !slotData.localDateTime.data.includes(null)
          ? 'localDateTime'
          : 'dateTime';

      if (tzType === 'localDateTime') {
        const { tzHistory } = slotData.localDateTime;
        if (tzHistory && tzHistory.length === 1) {
          tzSpecific = tzHistory[0].timezone;
        } else {
          tzSpecific = 'Mixed';
        }
      } else {
        tzSpecific = 'UTC';
      }
    }
  }
  return { tzType, tzSpecific };
};

const getTzConfigFromAllHistories = (
  averagingOption: AveragingPeriod,
  slot: SlotOptions,
  unitHistories: ZephyrHistoryWithId,
  zephyr: Zephyr,
) => {
  let tzConfig = {
    tzType: '',
    tzSpecific: '',
  };
  for (const [unitId, unitHistory] of Object.entries(unitHistories)) {
    const firstSlot =
      unitId === zephyr.zNumber.toString() && slot
        ? slot
        : slotFinder(unitHistory).length
          ? slotFinder(unitHistory)[0]
          : null;
    if (firstSlot) {
      const unitTzConfig = getTzConfig(averagingOption, firstSlot, unitHistory);
      if (unitTzConfig) {
        if (!tzConfig.tzType && !tzConfig.tzSpecific) {
          tzConfig = unitTzConfig;
        } else if (unitTzConfig.tzType === 'dateTime') {
          tzConfig = unitTzConfig;
        } else if (
          unitTzConfig.tzType === 'localDateTime' &&
          unitTzConfig.tzSpecific !== tzConfig.tzSpecific
        ) {
          tzConfig = {
            tzType: 'dateTime',
            tzSpecific: 'UTC',
          };
        }
      }
    }
  }
  return tzConfig;
};

const getDateObjectArray = (dates: string[]) =>
  dates.map((date) => new Date(date));

const updateChartDatasets = (
  chartDatasets: LineChartData[],
  displayConfig: DisplayConfig,
  unitList: Zephyr[],
) => {
  const updatedDatasets: LineChartData[] = chartDatasets.map((dataset) => {
    const unitIdx = getIdxOfUnit(dataset.unitId, unitList);
    const speciesColour = speciesColourFinder(
      displayConfig,
      dataset.species,
      unitIdx,
    );
    return {
      ...dataset,
      backgroundColor: speciesColour,
      borderColor: speciesColour,
      pointBorderColor: speciesColour,
      pointHoverBorderColor: speciesColour,
    };
  });
  return updatedDatasets;
};

const hasAvailableData = (datasets: LineChartData[]) => {
  const datasetsWithData = datasets.filter((dataset: any) => {
    const valReturned = dataset.data.find((dp: any) => dp !== null);
    return valReturned !== null && valReturned !== undefined;
  });
  return datasetsWithData;
};

const getSecondaryAxisConfigs = (chartData: ChartData) => {
  // Check if any data lies outside of the min and max configs
  const allDataIsInRange = (
    // TODO: understand why we can't run a .find on ds.data as LineChartData
    ds: any,
    max: number | boolean,
    min: number | boolean,
  ) => {
    const allDateInRange =
      ds.data.find(
        (d: any) => d !== null && ((max && d > max) || (min && d < min)),
      ) === undefined;
    return allDateInRange;
  };
  const secondaryAxisDS: any[] = [];
  chartData.datasets
    .filter((ds) => ds.speciesObj.secondaryAxis)
    .forEach((ds, idx) => {
      if (!ds.hidden) {
        const dataInRange = allDataIsInRange(
          ds,
          ds.speciesObj.maximum,
          ds.speciesObj.minimum,
        );
        const secondaryAxisConfig = {
          idx,
          maximum:
            ds.speciesObj.maximum && !ds.hidden && dataInRange
              ? ds.speciesObj.maximum
              : null,
          minimum:
            ds.speciesObj.minimum && !ds.hidden
              ? dataInRange
                ? ds.speciesObj.minimum
                : null
              : 0,
          title: ds.speciesLabel,
        };
        secondaryAxisDS.push(secondaryAxisConfig);
      } else {
        const voidedAxisConfig = {
          ...placeholderAxisConfig[0],
          idx,
          title: ds.speciesLabel,
          void: true,
        };
        secondaryAxisDS.push(voidedAxisConfig);
      }
    });
  if (secondaryAxisDS.length) {
    // For each species, take the extent of the ranges across all units
    const finalSecondaryAxisDs: SecondaryAxisConfig[] = [];
    secondaryAxisDS.forEach((sAD) => {
      const inConfig = finalSecondaryAxisDs.filter(
        (fSAD: any) =>
          fSAD.title === sAD.title && fSAD.idx !== sAD.idx && !sAD.void,
      );
      if (inConfig.length) {
        const comparisonConfig = inConfig[0];
        if (
          comparisonConfig.void ||
          (comparisonConfig.minimum &&
            sAD.minimum &&
            sAD.minimum < comparisonConfig.minimum) ||
          !sAD.minimum
        ) {
          finalSecondaryAxisDs[comparisonConfig.idx].minimum = sAD.minimum as
            | null
            | number;
          finalSecondaryAxisDs[comparisonConfig.idx].void = false;
        }
        if (
          comparisonConfig.void ||
          (comparisonConfig.maximum &&
            sAD.maximum &&
            sAD.maximum > comparisonConfig.maximum)
        ) {
          finalSecondaryAxisDs[comparisonConfig.idx].maximum = sAD.maximum as
            | null
            | number;
          finalSecondaryAxisDs[comparisonConfig.idx].void = false;
        }
      } else {
        finalSecondaryAxisDs.push(sAD as SecondaryAxisConfig);
      }
    });
    return finalSecondaryAxisDs;
  }
  // Defaults returned will never be displayed - serve as null
  return placeholderAxisConfig;
};

const determineSecondaryAxis = (
  chartData: ChartData,
  species: SpeciesDataIdentifiers,
  displayConfig: DisplayConfig,
) => {
  let secondaryAxis = false;
  const dataset = chartData.datasets.filter((ds) => ds.species === species)[0];
  if (dataset.speciesObj.secondaryAxis) {
    // check to see if there are datasets of primary species in the same group across all units
    const group = displayConfig.tabs.filter(
      (t) =>
        t.species.filter((s) => s.dataIdentifiers.includes(species)).length,
    )[0];
    const otherSpeciesList = group.species
      .filter((t) => !t.dataIdentifiers.includes(species) && !t.secondaryAxis)
      .map((s) => s.dataIdentifier);
    if (otherSpeciesList.length) {
      const primaryAxisDatasets = chartData.datasets.filter((ds) =>
        otherSpeciesList.includes(ds.species),
      );
      const primaryAxisDatasetsWithData = hasAvailableData(primaryAxisDatasets);
      if (primaryAxisDatasetsWithData.length) {
        secondaryAxis = true;
      }
    }
  }
  return secondaryAxis;
};

const wrangleLineChartData = (
  chartData: ChartData,
  handleUnitSpeciesFilter: Function,
  // without displayConfig, wrangleLineChartData doesn't apply processing like secondary axis determination
  averagingOption?: AveragingPeriod,
  displayConfig?: DisplayConfig,
) => {
  const wrangledData: LineChartDataEnriched[] = [];
  chartData.datasets.forEach((ds) => {
    let updatedMap: LineChartDataPoints[] = [];
    const secondaryAxis = displayConfig
      ? determineSecondaryAxis(chartData, ds.species, displayConfig)
      : false;
    ds.data.forEach((dd: any, idx: any) => {
      const date = chartData.labels[idx];
      if (date) {
        updatedMap.push({ x: date, y: dd });
      }
    });
    if (averagingOption && averagingOption.averagingOption === 'Unaveraged') {
      const nullifiedUpdatedMap: LineChartDataPoints[] = [];
      updatedMap.forEach((um, idx, arr) => {
        nullifiedUpdatedMap.push(um);
        if (idx < arr.length - 1) {
          const curDate = um.x;
          const nextDate = arr[idx + 1].x;

          const diff = ((nextDate as any) - (curDate as any)) / 60000;

          if (diff > 3) {
            // make up a date in-between the start and end of the data gap
            const halfwayDate = new Date(
              um.x.getTime() + Math.round((diff / 2) * 60000),
            );
            nullifiedUpdatedMap.push({ x: halfwayDate, y: null });
          }
        }
      });
      if (nullifiedUpdatedMap.length !== updatedMap.length) {
        updatedMap = nullifiedUpdatedMap;
      }
    }
    wrangledData.push({
      type: 'line',
      axisYType: secondaryAxis ? 'secondary' : 'primary',
      axisYIndex: secondaryAxis
        ? wrangledData.filter(
          (wD) =>
            wD.axisYType === 'secondary' && wD.label === ds.speciesLabel,
        ).length
          ? wrangledData.filter(
            (wD) =>
              wD.axisYType === 'secondary' && wD.label === ds.speciesLabel,
          )[0].axisYIndex
          : wrangledData.filter((wD) => wD.axisYType === 'secondary').length
            ? 1
            : 0
        : undefined,
      dataPoints: updatedMap,
      lineColor: ds.featureColour,
      lineDashType: ds.borderDash2,
      markerSize: 0,
      markerColor: ds.defaultColour,
      markerBorderColor: ds.featureColour,
      markerBorderThickness: 2,
      label: ds.speciesLabel,
      name: ds.unitNameLabel,
      visible: !ds.hidden,
      click: () => handleUnitSpeciesFilter(ds.label, ds.speciesLabel),
      xValueFormatString: 'DD-MM-YY HH:mm',
      cursor: 'pointer',
    });
  });
  return wrangledData;
};

export {
  convertRawData,
  getChartDataset,
  getIdxOfUnit,
  getSecondaryAxisConfigs,
  getSlotData,
  getTzConfig,
  getTzConfigFromAllHistories,
  getDateObjectArray,
  hasAvailableData,
  updateChartDatasets,
  wrangleLineChartData,
};
