// Util Imports
import utilsRequest from '../request';

// Const Imports
import { gtmEvents } from '../consts';

const analyticsEventFirer = (
  eventIdentifier: string,
  labelEnrichment?: string,
) => {
  const eventProps = { ...gtmEvents[eventIdentifier] };
  if (labelEnrichment) {
    eventProps.label = `${eventProps.label} - ${labelEnrichment}`;
  }
  const { category, action, label, value } = eventProps;
  utilsRequest.fireTrackingEvent(action, category, label, value);
};

export default analyticsEventFirer;
