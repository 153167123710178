// Package Imports
import parse from 'html-react-parser';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';

// Const Imports
import { alertThresholdOptions } from '../consts';

// Type Imports
import { SpeciesAlert, BatteryAlert, AlertObject, DisplayConfig, SpeciesData } from '../interface';

/* Function
DESC: alertsFormatters returns formatted and parsed text to describe an alert
ARGS: an alert object
*/
const alertsFormatter = (alert: AlertObject, displayConfig: DisplayConfig) => {
  // Loop through the display Config to order air alerts
  let description = SpeciesFormatter(alert, displayConfig)
  const parsedDescription = parse(description);
  return parsedDescription;
};

function SpeciesFormatter(alert: AlertObject, displayConfig: DisplayConfig){
  let airAlerts = alert.airAlerts;
  let description: string = '';
  // map over the air alerts array
  airAlerts.map((airAlert, idx) => {
    if (idx === 0) {
      description += '<span>Species:</span>';
    }
    // List all alerts after a Species: if there are any
    // Get species label from Display Config (look up data identifier)
    let speciesLabel = '';
    let speciesUnit = '';
    displayConfig.tabs.map((tab) =>
      tab.species.map((species) => {
        if (species.dataIdentifier === airAlert.Species) {
          speciesLabel = species.HTMLLabel;
          speciesUnit = species.HTMLShortUnitLabel;
        }
      }),
    );

    description += ` ${airAlert.Species} `;
    // Get the correct comparator symbol using the alertThresholdOptions object
    description += `${airAlert.Comparator} `;
    // Append the value
    description += `${airAlert.Threshold}`;
    // Append the units
    description += `<span class="air-alert-unit-label"> ${speciesUnit}</span>`;
    // Append a comma unless this is the last airAlert there are no zephyr alerts
    if (!(idx === airAlerts.length - 1)) {
      description += ',';
    }
  });
  return description;
}

function BatteryFormatter(batteryAlert: BatteryAlert){
  return 'Battery: ' + batteryAlert.comparator + ' than ' + batteryAlert.batteryCharge + '%'
}

export { alertsFormatter };
