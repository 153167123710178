// Package Imports
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Component Imports
import AreaOfInterest from './AreaOfInterest';
import Confirmation from '../shared_components/Confirmation';

// Async Imports
import utilsRequest from '../../utils/request';

// Const Imports
import {
  defaultAreaConfig,
  defaultMappairForm,
  detailsContactFormLookup,
  mappairLink,
  mappairMeasurementOptions,
} from '../../utils/consts';

// Type Imports
import { AreaConfig, ContactFormMappair, ReduxState } from '../../utils/interface';
import { connect } from 'react-redux';

// Component Interfaces
interface InitState {
  areaOfInterestScreen: boolean;
  confirmationScreen: boolean;
}

interface MappairQuoteProps {
  contactFormOptions: ContactFormMappair;
  setModalsOff: Function;
  bearerToken: string | null;
}

// Component
const MappairQuoteModal = ({
  contactFormOptions,
  setModalsOff,
  bearerToken,
}: MappairQuoteProps) => {
  const { t: translate } = useTranslation();

  // State
  const initState: InitState = {
    areaOfInterestScreen: false,
    confirmationScreen: false,
  };
  const [areaOfInterestScreen, setAreaOfInterestScreen] = useState(
    initState.areaOfInterestScreen,
  );
  const [confirmationScreen, setConfirmationScreen] = useState(
    initState.confirmationScreen,
  );
  const [formData, setFormData] = useState(contactFormOptions.formData);
  const [userMessage, setUserMessage] = useState(
    contactFormOptions.userMessage,
  );

  // Functions
  const handleAreaOfInterest = (areaConfig: AreaConfig) => {
    setAreaOfInterestScreen(false);
    const updatedFormData = { ...formData, area: areaConfig };
    setFormData(updatedFormData);
  };

  const handleSpeciesFilter = (species: string) => {
    let newPollutants = [];
    if (!formData.pollutants.includes(species)) {
      newPollutants = [...formData.pollutants, species];
    } else {
      newPollutants = formData.pollutants.filter((p) => p !== species);
    }
    const updatedFormData = { ...formData, pollutants: newPollutants };
    setFormData(updatedFormData);
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      company,
      comments,
      area,
      pollutants,
    } = formData;
    if (firstName && lastName && email && company) {
      const enquiry = 'Sales';
      const details = detailsContactFormLookup.Sales[1].value;
      let formattedPollutants = '';
      pollutants.forEach((p) => {
        formattedPollutants += `${p}`;
      });
      const formattedComments = `Pollutants: ${
        formattedPollutants || 'None selected'
      }, Area coordinates: ${
        !area.latLngGeometry
          ? 'None specified'
          : (area as any).latLngGeometry.flatCoordinates
      }, Area size (km2): ${area.areaSize || 'None specified'}, Comments: ${
        comments || 'None'
      }`;
      const formSubmission = {
        firstName,
        lastName,
        email,
        company,
        enquiry,
        details,
        comments: formattedComments,
      };
      const response = await utilsRequest.submitContactForm(formSubmission, bearerToken);
      if (response) {
        setFormData(defaultMappairForm.formData);
        setConfirmationScreen(true);
      } else {
        setUserMessage(translate('mappairMessageProblem'));
      }
    } else {
      setUserMessage(translate('mapairMessageMissingFields'));
    }
  };

  return (
    <>
      {confirmationScreen ? (
        <Confirmation
          confirmationOpen={setModalsOff}
          header={translate('MappairQuoteConfirmationHeader')}
          showTick
          text={translate('MappairQuoteConfirmationText')}
        />
      ) : areaOfInterestScreen ? (
        <AreaOfInterest handleAreaOfInterest={handleAreaOfInterest} />
      ) : (
        <div className="modal-container header-contact-modal-container">
          <h4>{translate('MappairQuoteContactHeader')}</h4>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="form-content-container">
              <div className="row">
                <div className="col-12 col-md-6">
                  <label className="contact-form__label" htmlFor="firstName">
                    {translate('MappairQuoteContactFirstName')}
                  </label>
                  <input
                    className="contact-form__input"
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={(e) =>
                      setFormData({ ...formData, firstName: e.target.value })
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label className="contact-form__label" htmlFor="lastName">
                    {translate('MappairQuoteContactLastName')}
                  </label>
                  <input
                    className="contact-form__input"
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={(e) =>
                      setFormData({ ...formData, lastName: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label className="contact-form__label" htmlFor="email">
                    {translate('MappairQuoteContactEmail')}
                  </label>
                  <input
                    className="contact-form__input"
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label className="contact-form__label" htmlFor="company">
                    {translate('MappairQuoteContactCompany')}
                  </label>
                  <input
                    className="contact-form__input"
                    type="text"
                    name="company"
                    value={formData.company}
                    onChange={(e) =>
                      setFormData({ ...formData, company: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="optional-section">
                <div className="optional-section-content">
                  <div className="aoi-sub mappair-desc">
                    {translate('mappairLinkPre')}
                    <span>
                      <a href={mappairLink.url} target={mappairLink.target}>
                        {mappairLink.content}
                      </a>
                    </span>
                    {translate('mappairLinkPost')}
                  </div>
                  <div className="aoi">
                    <h5 className="aoi-header">{translate('aoi')}</h5>
                    <div className="aoi-content">
                      {formData.area.latLngGeometry ? (
                        <div className="aoi-selected-container">
                          <div className="aoi-selected-area">
                            <span>{translate('polygon')}</span>
                            {translate('centre')}
                            {formData.area.centreHdms}
                          </div>
                          <div className="air-alert-actions-container">
                            <svg
                              onClick={() =>
                                handleAreaOfInterest(defaultAreaConfig)
                              }
                              width="21"
                              height="21"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm-14-2.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5h-14v16.5zm5-18.5h4v-1h-4v1z" />
                            </svg>
                          </div>
                        </div>
                      ) : (
                        <button
                          type="button"
                          className="aoi-button button primary send-modal"
                          onClick={() => setAreaOfInterestScreen(true)}
                        >
                          {translate('selectArea')}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="aoi-sub">
                    <label className="contact-form__label" htmlFor="company">
                      {translate('pollutants')}
                    </label>
                    <header className="filter-legends mappair-filter-legends">
                      <div>
                        {mappairMeasurementOptions.map((mMO) => (
                          <label className="checkbox" key={mMO.value}>
                            {mMO.label}
                            <input
                              type="checkbox"
                              checked={formData.pollutants.includes(mMO.label)}
                              onChange={() => handleSpeciesFilter(mMO.label)}
                            />
                            <span className="checkmark" />
                          </label>
                        ))}
                      </div>
                    </header>
                  </div>
                  <div className="aoi-sub mappair-comments">
                    <label className="contact-form__label" htmlFor="company">
                      {translate('comments')}
                    </label>
                    <textarea
                      placeholder={translate(
                        'mappairOptionalCommentsPlaceholder',
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col text-left validation-dialog">
                <p className="air-alerts-error-item">
                  {userMessage || translate('mapairRequiredFields')}
                </p>
              </div>
            </div>
            <button type="submit" className="button primary send-modal">
              {translate('MappairQuoteContactButton')}
            </button>
          </form>
          <button
            className="button close close-modal"
            onClick={() => setModalsOff()}
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 24 24"
              fill="#000"
            >
              <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
            </svg>
          </button>
        </div>
      )}
    </>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  bearerToken: state.auth.bearerToken,
});

export default connect(mapStateToProps, {})(MappairQuoteModal);