// Package Imports
import React from 'react';
import CanasJSReact from '../../canvasjs.react';

// Type Imports
import { SAChartData } from '../../utils/interface';

// Component Interfaces
interface AQSAChartProps {
  chartDataset: SAChartData;
}

const AQSAChart = ({ chartDataset }: AQSAChartProps) => (
  <CanasJSReact.CanvasJSChart
    containerProps={{
      height: '300px',
    }}
    options={{
      ...chartDataset,
      axisY: {
        gridThickness: 0,
        minimum: 0,
      },
      axisX: {
        reversed: true,
        tickLength: 0,
        labelPlacement: 'inside',
        labelMaxWidth: 250,
      },
      toolTip: {
        borderColor: '#222539',
        cornerRadius: 3,
        fontFamily: 'Open Sans',
        contentFormatter: (e: any) => {
          const content = `${e.entries[0].dataPoint.y} tonnes/annum`;
          return content;
        },
      },
    }}
  />
);

export default AQSAChart;
