import * as React from 'react';
import { AnchorObject } from '../view_components/FilterBar/FilterBar1';
import { useEffect } from 'react';

export default function BasicPopover({ buttonText, children, anchorElement, anchorElements, setAnchorElements }: any) {

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { anchorId } = anchorElement;
    const anchorElementsCopy = [...anchorElements];
    for (const el of anchorElementsCopy) {
      if (el.anchorId === anchorId) {
        el.anchorEl = el.anchorEl ? null : event.currentTarget;
      } else {
        el.anchorEl = null;
      }
    }
    setAnchorElements(anchorElementsCopy);
  };

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement;
      const anchorElementsCopy = [...anchorElements];
      const currentAnchorElement = anchorElementsCopy.find((anchorEl: AnchorObject) => anchorEl.anchorId === anchorElement.anchorId);
      if (!targetElement.closest('.anchor-popover') && !targetElement.closest('.selectionButton') && currentAnchorElement.anchorEl) {
        currentAnchorElement.anchorEl = null;
        setAnchorElements(anchorElementsCopy);
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);


  const open = Boolean(anchorElement.anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="selectionButton">
      <button
        type="button"
        aria-describedby={id}
        onClick={handleClick}
        className="dropdown-toggle h-40 d-flex justify-content-between align-items-center w-100 gap-4"
      >
        {buttonText}
        <span className={`chevron-icon ${open ? 'open' : 'close'}`} />
      </button>

      <div id={id} className={`anchor-popover ${open ? 'opened' : ''}`}>
        {children}
      </div>
    </div>
  );
}