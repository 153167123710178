// Types Imports
import { SHOW_MAP_AIR_FILTERS, SET_SELECTED_FILTER } from '../actions/types';

// Type Imports
import { MappAirFilters } from '../utils/interface';

interface mappAirFilters {
  type: typeof SHOW_MAP_AIR_FILTERS | typeof SET_SELECTED_FILTER;
  payload: boolean | string[];
}

// State
const initialState: MappAirFilters = {
  showMappAirFilters: false,
  selectedFilters: [
    // { name: 'zephyr', showAllLabels: false, showSelectedLabel: true },
    { name: 'virtual', showAllLabels: true, showSelectedLabel: true },
  ],
};

// Reducer
export default function (state = initialState, action: mappAirFilters) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_MAP_AIR_FILTERS:
      return {
        ...state,
        showMappAirFilters: payload ? payload : !state.showMappAirFilters,
      };
    case SET_SELECTED_FILTER:
      return {
        ...state,
        selectedFilters: action.payload,
      };
    default:
      return state;
  }
}
