// Const Imports
import { cardinalDirectionsPlus, SALabels } from '../consts';

// Util Imports
import { cardinalDirectionFinder } from './cardinalDirectionFinder';
import {
  convertUnitTimeToLocalTime,
  getCurrentTimeZoneAbbreviation,
  tzConverter,
  utcConvertor,
} from './dateFinder';

// Type Imports
import {
  ChartData,
  DisplayConfig,
  LineChartData,
  SAData,
  SAChartData,
  Species,
  TodayData,
  WindPackagedData,
  WindRoseData,
  WindCategory,
  ZephyrHistory,
  AveragingPeriod,
  Pollutant,
  Zephyr,
} from '../interface';
import { slotValidator } from './slotValidator';
import { checkSlotABData } from './getAvailableAveragingOption';
import { Options } from '../../components/views/DataAnalyticsContainer';
import {
  chartWatermarkConfigs,
  commonLegend,
  plotlyFont,
  plotlyMargin,
  plotlyPrecipitationMargin,
  plotlyTHPMargin,
  staticPlotlyChartLayout,
} from '../plotySharedConfig';
import { validate } from 'uuid';
import { slotFinder } from './slotFinder';
import { colDict } from '../plotySharedConfig';
import { table } from 'console';

// Common JS Imports
const moment = require('moment');

const lineTypes = ['solid', 'dash', 'dot', 'dashdot']

const temperatureHumidityPressure = [
  {
    HTMLLabel: 'Temperature',
    HTMLShortUnitLabel: '°C',
    HTMLUnitLabel: 'Temperature(°C)',
    dataIdentifier: 'ambTempC',
    speciesExtentIdentifier: 'BT',
  },
  {
    HTMLLabel: 'Humidity',
    HTMLShortUnitLabel: '%',
    HTMLUnitLabel: 'Humidity(%)',
    dataIdentifier: 'ambHumidity',
    speciesExtentIdentifier: 'BRH',
  },
  // {
  //   HTMLLabel: 'Pressure',
  //   HTMLShortUnitLabel: 'Pa',
  //   HTMLUnitLabel: 'Pressure(Pa)',
  //   dataIdentifier: 'ambPressure',
  //   speciesExtentIdentifier: 'BP',
  // },
];

/* Function
  DESC: mappairDateParser returns a JS parsed date
  ARGS: date in the form YYYY-MM-DDZHH:mm:ss typically returned in points requests
*/
const mappairDateParser = (date: string) => {
  const year = parseInt(date.slice(6, 10));
  const month = parseInt(date.slice(3, 5)) - 1;
  const day = parseInt(date.slice(0, 2));
  const hours = parseInt(date.slice(11, 13));
  const minutes = parseInt(date.slice(14, 16));
  const seconds = parseInt(date.slice(17, 19));
  const newDate = new Date(year, month, day, hours, minutes, seconds);
  const mDate = moment(newDate).format('YYYYMMDDHHmm');
  const utcMDate = moment(tzConverter(mDate), 'YYYYMMDDHHmm').toDate();
  return utcMDate;
};

export const populateObjectWithZephyrHistory = (
  selectedAveragingPeriod: AveragingPeriod,
  unit: any,
) => {
  const updatedZephyrHistory: any = {};
  const zephyrHistory: any = {};

  const zephyrData: ZephyrHistory = unit[Object.keys(unit)[0]].data;
  if (zephyrData && selectedAveragingPeriod) {
    updatedZephyrHistory[selectedAveragingPeriod.averagingOption] =
      zephyrData[selectedAveragingPeriod.averagingOption];
    zephyrHistory[parseInt(Object.keys(unit)[0])] = updatedZephyrHistory;
  }
  return { updatedZephyrHistory, zephyrHistory };
};

export const getSlotDataFromZephyrHistory = (
  unitHistories: any,
  selectedWeatherZephyr: any,
  selectedAveragingPeriod: any,
) => {
  const { historiesList } = unitHistories;
  if (!selectedWeatherZephyr || !selectedAveragingPeriod) return;
  const { id } = selectedWeatherZephyr;
  const unit = historiesList.find(
    (zephyr: any) => +(Object.keys(zephyr) as any)[0] === +id,
  );
  const unitKey = Object.keys(unit)[0];
  const { data, zType } = unit[unitKey];
  const {
    updatedZephyrHistory,
    zephyrHistory,
  } = populateObjectWithZephyrHistory(selectedAveragingPeriod, unit);

  const slots = updatedZephyrHistory[selectedAveragingPeriod.averagingOption]
    ? slotValidator(updatedZephyrHistory, zephyrHistory)
    : null;
  if (
    slots &&
    selectedAveragingPeriod.averagingOption in data &&
    slots[0] in data[selectedAveragingPeriod.averagingOption] &&
    zType !== 1
  ) {
    if (slots.includes('head') && zType === 0)
      slots.unshift((slots as any).pop());

    const slotData = data[selectedAveragingPeriod.averagingOption][slots[0]];
    return slotData;
  }
  return null;
};

/* Function
  DESC: windRoseDataPackagerFromMetData returns packaged wind data for wind rose consumption
  ARGS: point data
*/
const categorizeWindDataByDirectionAndSpeed = (
  windCategories: any,
  packagedWindData: {
    dateTime: string[];
    direction: number[];
    speed: number[];
  },
) => {
  // Create a map for quick lookup of wind category configurations
  const windCategoryConfigMap = new Map(
    windCategories.map((wCC: any) => [`${wCC.name} m/s`, wCC]),
  );

  const data: WindRoseData[] = [];
  windCategories.forEach((wCC: any) => {
    const { b, g, r } = wCC.colour;
    const dataTemplate = {
      r: new Array(cardinalDirectionsPlus.length).fill(0),
      theta: cardinalDirectionsPlus.map((cD) => cD.dirName),
      name: `${wCC.name} ${wCC.unit}`,
      marker: { color: `rgb(${r},${g},${b})` },
      type: 'barpolar',
      hoverinfo: 'skip',
      unit: wCC.unit,
    };
    data.push(dataTemplate);
  });

  packagedWindData.direction.forEach((dir, idx) => {
    const cardinal = cardinalDirectionFinder(dir);
    const thetaIdx = cardinalDirectionsPlus.indexOf(cardinal);
    const speed = packagedWindData.speed[idx];

    data.forEach((bin: any) => {
      const config = windCategoryConfigMap.get(bin.name) as WindCategory;
      // Check for speed = 0
      if (speed && speed >= config.minVal) {
        bin.r[thetaIdx] += 1;
      }
    });
  });

  return data;
};

const windRoseDataPackagerFromMetData = (
  displayConfig: DisplayConfig,
  pointData: TodayData,
) => {
  let packagedWindRoseData: WindRoseData[] | null = null;
  const packagedWindData = windDataPackager(pointData);
  if (packagedWindData) {
    const data: WindRoseData[] = [];

    packagedWindRoseData = categorizeWindDataByDirectionAndSpeed(
      displayConfig.windCategories,
      packagedWindData,
    );
  }
  return packagedWindRoseData;
};

/* Function
  DESC: windDataPackager returns packaged wind data as a simple object containing windDi,windSpeed arrays
  ARGS: point data
*/

// Optimizing the code by removing both filter methods and running just a single iteration via forEach method
const windCheck = (
  pointDataResultsArr: any,
): { isWDir: any; isWSpeed: any } | null => {
  let isWDir = null;
  let isWSpeed = null;
  pointDataResultsArr.forEach((el: any) => {
    if (el.species === 'winddir') isWDir = el;
    if (el.species === 'windspeed') isWSpeed = el;
  });
  return isWDir && isWSpeed
    ? {
      isWDir: isWDir,
      isWSpeed: isWSpeed,
    }
    : null;
};

const windDataPackager = (pointData: TodayData) => {
  let windData: WindPackagedData | null = null;
  const todayDT = new Date();
  if (
    pointData &&
    pointData.results &&
    pointData.results[0].values_coordinates.length
  ) {
    const wDirArr: number[] = [];
    const wSpeedArr: number[] = [];
    const wDateTimeArr: string[] = [];

    //check windspeed and winddir call was successful
    let wDirExists = windCheck(pointData.results);
    if (wDirExists) {
      if (
        wDirExists.isWDir.values_coordinates &&
        wDirExists.isWSpeed.values_coordinates
      ) {
        const wDirValues =
          wDirExists.isWDir.values_coordinates[0].values_timestamps;
        const wSpeedValues =
          wDirExists.isWSpeed.values_coordinates[0].values_timestamps;

        // Remove forecast data
        pointData.meta.timestamps.forEach((ts, idx) => {
          const parsedDT = mappairDateParser(ts);
          if (parsedDT <= todayDT && wDirValues && wSpeedValues) {
            const formattedDT = moment(parsedDT).format('YYYY-MM-DDTHH:mm:ss');
            wDirArr.push(wDirValues[idx].value);
            wSpeedArr.push(wSpeedValues[idx].value);
            wDateTimeArr.push(formattedDT);
          }
        });
        const minimumResultsCount = 10;
        if (
          wSpeedArr &&
          wSpeedArr.length > minimumResultsCount &&
          wDirArr &&
          wDirArr.length > minimumResultsCount
        ) {
          windData = {
            direction: wDirArr,
            speed: wSpeedArr,
            dateTime: wDateTimeArr,
          };
        }
      }
    }
  }
  return windData;
};

/* Function
  DESC: pointDataPackager returns packaged point data or null where it doesn't exist
  ARGS: point data and species name
*/
const pointDataPackager = (
  pointData: TodayData,
  speciesData: Species,
  annual?: boolean | undefined,
) => {
  if (pointData?.results && pointData.results[0].values_coordinates.length) {
    let checkExclusions = ['windspeed', 'winddir'];

    if (annual)
      checkExclusions = speciesData.label === 'PM2p5' ? ['pm2p5'] : ['no2'];

    const hasData =
      pointData.meta.species_list.filter(
        (ms: string) => !checkExclusions.includes(ms),
      ).length > 0 &&
      pointData.results[0].values_coordinates[0].values_timestamps.find(
        (val) => val.value >= 0,
      );

    if (hasData) {
      const firstTimeStamp = mappairDateParser(pointData.meta.timestamps[0]);
      const labels: Date[] = [];
      const data: number[] = [];

      if (speciesData.label === 'PM2p5' && annual) {
        const pm2p5Obj = pointData.results.find(
          (res) => res.species === 'pm2p5',
        );

        if (pm2p5Obj) {
          pm2p5Obj.values_coordinates[0].values_timestamps.forEach((rvt, i) => {
            if (rvt.value !== -999 && rvt.value !== 32767) {
              const date = rvt.timestamp;
              const newDate = annual
                ? mappairDateParser(pointData.meta.timestamps[i])
                : mappairDateParser(date);

              if (newDate.getHours() === firstTimeStamp.getHours()) {
                labels.push(newDate);
                data.push(Math.round(rvt.value));
              }
            }
          });
        }
      } else {
        pointData.results[0].values_coordinates[0].values_timestamps.forEach(
          (rvt, i) => {
            if (rvt.value !== -999 && rvt.value !== 32767) {
              const date = rvt.timestamp;
              const newDate = annual
                ? mappairDateParser(pointData.meta.timestamps[i])
                : mappairDateParser(date);

              if (newDate.getHours() === firstTimeStamp.getHours()) {
                labels.push(newDate);
                data.push(Math.round(rvt.value));
              }
            }
          },
        );
      }

      const dataFinder = data.find((d) => d !== null);
      if (dataFinder !== null && dataFinder !== undefined) {
        const dataset: LineChartData = {
          unitId: 0,
          species: speciesData.dataIdentifier,
          label: speciesData.HTMLUnitLabel,
          speciesLabel: speciesData.HTMLUnitLabel,
          unitNameLabel: 'NA',
          hidden: false,
          featureColour: 'rgb(255,45,85)',
          defaultColour: '#fff',
          borderDash: [],
          borderDash2: 'solid',
          data,
          speciesObj: speciesData,
        };
        if (data.filter((d) => d !== 0).length > 1) {
          const packagedPointData: ChartData = { datasets: [dataset], labels };
          return packagedPointData;
        }
      }
    }
  }
  return null;
};

/* Function
  DESC: saDataPackager returns packaged SA data or null where it doesn't exist
  ARGS: display config and sa dataset as returned by the dc and SA API endpoints
  */
const saDataPackager = (displayConfig: DisplayConfig, saData: SAData) => {
  const excludedFromAnalysis: string[] = ['total', 'totalarea'];
  let totalValue = 0;
  if (saData && saData.results && saData.results.length) {
    const dataset = saData.results.map((sp) => {
      if (!excludedFromAnalysis.includes(sp.species)) {
        const values = sp.values_coordinates;
        if (values.length) {
          const tsValues = values[0];
          if (tsValues && tsValues.values_timestamps.length) {
            const spData = tsValues.values_timestamps[0];
            const packagedSpData = {
              name: saData.auxinfo.filter(
                (auxSP) => auxSP.species === sp.species,
              )[0].layer_names[0][0],
              data: spData.value >= 0 ? spData.value : 0,
              species: sp.species,
            };
            if (spData.value >= 0) {
              totalValue += spData.value;
            }
            return packagedSpData;
          }
        }
      }
    });
    // Sort the dataset in decending order for charting
    const sortedDataset = dataset
      .filter((ds) => ds && ds.data !== 0)
      .slice()
      .sort((a, b) => b!.data - a!.data);
    if (false) {
      //sortedDataset.length
      const colourSet = sortedDataset.map(
        (ds) =>
          displayConfig.sources.filter((s) => ds!.species === s.source)[0]
            .colour,
      );
      const chartDataset: SAChartData = {
        data: [
          {
            color: '',
            cursor: 'pointer',
            dataPoints: [],
            dataPointMinWidth: 2,
            type: 'bar',
          },
        ],
        meta: {
          total: totalValue,
        },
      };
      sortedDataset.forEach((sDs, idx) => {
        if (sDs) {
          const newDataPoint = {
            y: sDs.data,
            label: `‏‏‎‎‎‏‏‎‏‏‎‎${sDs.name}: ${(
              (sDs.data / totalValue) *
              100
            ).toFixed(1)}%`,
          };
          chartDataset.data[0].dataPoints.push(newDataPoint);
          if (!chartDataset.data[0].color) {
            chartDataset.data[0].color = `rgba(${colourSet[idx].r}, ${colourSet[idx].g}, ${colourSet[idx].b}, 0.2)`;
          }
        }
      });
      return chartDataset;
    }
  }
  return null;
};

const getMinOrMax = (a: number | null, b: number | null, operator: string) => {
  if (a === null) return b;
  if (b === null) return a;
  if (operator === 'min') return a < b ? a : b;
  if (operator === 'max') return a > b ? a : b;
};

const calculatePercentile = (sortedArray: number[], percentile: number) => {
  if (sortedArray.length === 0) return null; // Handle empty array case

  const index = (percentile / 100) * (sortedArray.length - 1);
  const lower = Math.floor(index);
  const upper = Math.ceil(index);
  const weight = index - lower;

  if (lower === upper) {
    // If lower and upper are the same, return that value (no need for interpolation)
    return sortedArray[lower];
  }

  // Linearly interpolate between the two values
  return sortedArray[lower] * (1 - weight) + sortedArray[upper] * weight;
};

const sortArray = (arr: any[]) => {
  return arr.sort((a, b) => a - b);
};

const generateMinMaxMeanStatisticsObject = (data: any[], pollutant: string, selectedOption: string): any => {
  const aggData: any = {};
  data.forEach((row) => {
    const timestamp = row.timestamp;
    const value = row[pollutant];
    if (!aggData[timestamp]) {
      aggData[timestamp] = selectedOption === 'minMeanMax' ? { values: [value], min: value, max: value } : { values: [value] };
    } else {
      aggData[timestamp].values.push(value);
      if (selectedOption === 'minMeanMax') {
        aggData[timestamp].min = getMinOrMax(aggData[timestamp].min, value, 'min');
        aggData[timestamp].max = getMinOrMax(aggData[timestamp].max, value, 'max');
      }
    }
  });
  const timestamps = Object.keys(aggData);
  if (selectedOption === 'minMeanMax') {
    const meanValues = timestamps.map(
      (timestamp) =>
        aggData[timestamp].values.reduce((acc: any, val: any) => acc + val, 0) /
        aggData[timestamp].values.filter((val: number) => typeof val === 'number').length,
    );
    const minValues = timestamps.map((timestamp) => aggData[timestamp].min);
    const maxValues = timestamps.map((timestamp) => aggData[timestamp].max);
    return {
      timestamps,
      meanValues,
      minValues,
      maxValues,
    };
  }
  const fivePercentile = timestamps.map((timestamp) => calculatePercentile(sortArray(aggData[timestamp].values.filter((val: number | null) => typeof val === 'number')), 5));
  const median = timestamps.map((timestamp) => calculatePercentile(sortArray(aggData[timestamp].values.filter((val: number | null) => typeof val === 'number')), 50));
  const ninetyFivePercentile = timestamps.map((timestamp) => calculatePercentile(sortArray(aggData[timestamp].values.filter((val: number | null) => typeof val === 'number')), 95));
  return {
    timestamps,
    fivePercentile,
    median,
    ninetyFivePercentile
  };
};

export const pollutantNameMapping: { [key: string]: string } = {
  O3: "O 3",
  NO2: "NO 2",
  PM1: "PM 1",
  PM10: "PM 10",
  PM25: "PM 2.5",
  CO2: "CO 2",
  SO2: "SO 2",
  H2S: "H 2 S",
}

const getMinMaxMeanStatisticsPlotlyDataAndLayout = (
  finalObject: any,
  selectedZephyrs: number,
  selectedOption: string
) => {
  const data = Object.keys(finalObject).map((pollutant: string) => {
    let timestamps, meanValues, minValues: number[], maxValues: number[], fivePercentile: number[], median: number[], ninetyFivePercentile: number[];
    maxValues = [];
    minValues = [];
    maxValues = [];
    fivePercentile = [];
    median = [];
    ninetyFivePercentile = [];
    if (selectedOption === 'minMeanMax') {
      ({ timestamps, meanValues, minValues, maxValues } = finalObject[pollutant]);
    } else {
      ({ timestamps, fivePercentile, median, ninetyFivePercentile } = finalObject[pollutant]);
    }
    let pollutantName = pollutant;
    if (pollutant.includes('particulate')) {
      [, pollutantName] = pollutant.split('particulate');
    }
    pollutantName = pollutantNameMapping[pollutantName]?.replace(/\s+/g, '') || pollutantName;
    const pollutantUnit = finalObject[pollutant]?.unit;
    const pollutantUnitText = pollutantUnit ? `(${pollutantUnit})` : '';
    return [
      {
        x: timestamps,
        y: selectedOption === 'minMeanMax' ? meanValues : median,
        type: 'scattergl',
        mode: 'lines',
        name: `${pollutantName} ${selectedOption === 'minMeanMax' ? 'Mean' : 'Median'} ${pollutantUnitText}`,
        customdata: timestamps.map((_: Date, i: number) => selectedOption === 'minMeanMax' ? [maxValues[i], minValues[i]] : [ninetyFivePercentile[i], fivePercentile[i]]),
        hovertemplate: `<b>${selectedOption === 'minMeanMax' ? 'Min' : '5%'}</b>: %{customdata[1]:.1f}` +
          `<br><b>${selectedOption === 'minMeanMax' ? 'Mean' : 'Median'}</b>: %{y:.1f}` +
          `<br><b>${selectedOption === 'minMeanMax' ? 'Max' : '95%'}</b>: %{customdata[0]:.1f}`,
        line: { color: `rgb(${colDict[pollutant]?.join(',')})` },
        legendgroup: pollutantName,
      },
      {
        x: timestamps,
        y: selectedOption === 'minMeanMax' ? maxValues : ninetyFivePercentile,
        type: 'scatter',
        mode: 'lines',
        name: `${pollutantName} ${selectedOption === 'minMeanMax' ? 'Max' : '95%'} ${pollutantUnitText}`,
        line: { color: `rgba(${colDict[pollutant]?.join(',')}, 0.2)` },
        opacity: 0.2,
        hoverinfo: 'skip',
        showlegend: false,
        legendgroup: pollutantName,
      },
      {
        x: timestamps,
        y: selectedOption === 'minMeanMax' ? minValues : fivePercentile,
        type: 'scatter',
        mode: 'lines',
        name: `${pollutantName} ${selectedOption === 'minMeanMax' ? 'Min' : '5%'} ${pollutantUnitText}`,
        line: { color: `rgba(${colDict[pollutant]?.join(',')}, 0.2)` },
        fill: selectedZephyrs > 1 && 'tonexty',
        fillcolor: selectedZephyrs > 1 && `rgba(${colDict[pollutant]?.join(',')}, 0.2)`,
        hoverinfo: 'skip',
        showlegend: false,
        legendgroup: pollutantName,
      },
    ];
  });


  const flattenedData = data.flat();
  return {
    data: flattenedData,
    layout: {
      title: `` /* yaxis: { title: `${pollutant}` } */,
      margin: plotlyMargin,
      font: plotlyFont,
      images: chartWatermarkConfigs,
      responsive: true,
      autosize: true,
      legend: commonLegend,
      polar: staticPlotlyChartLayout().polar,
    },
  };
};

const getMaxNumber = (arr: any[]) => {
  return [...arr].sort((a: number, b: number) => a - b)[arr.length - 1];
};

const getPopulatedAveragingPeriods = (historiesList: any, data: any) => {
  let averagingPeriods: any =
    historiesList.length === 1 && data ? Object.keys(data) : [];
  if (historiesList.length > 1) {
    historiesList.forEach((unit: any) => {
      if ((Object.values(unit)[0] as any).data) {
        const avPeriods = Object.keys((Object.values(unit) as any)[0].data);
        averagingPeriods = averagingPeriods.concat(avPeriods);
      }
    });
  }
  return averagingPeriods;
};

const generateChartConfigurations = (
  unit: any,
  selectedAveragingPeriod: any,
  pollutants: Pollutant[],
  options: Options,
  chartType: string,
) => {
  const { name, zType } = Object.values(unit)[0] as any;
  const {
    updatedZephyrHistory,
    zephyrHistory,
  } = populateObjectWithZephyrHistory(selectedAveragingPeriod, unit);
  const slots = updatedZephyrHistory[selectedAveragingPeriod.averagingOption]
    ? slotValidator(updatedZephyrHistory, zephyrHistory).filter(
      (slot: string) => slot !== 'head',
    )
    : null;
  if (slots) {
    options.slots = slots;
    slots.forEach((slot: string) => {
      pollutants
        .filter((pollutant: Pollutant) => {
          if (chartType === 'particulate') {
            return pollutant.dataIdentifier.includes(chartType);
          }
          return !pollutant.dataIdentifier.includes('particulate');
        })
        .forEach((pollutant: Pollutant) => {
          const selectedPollutantName: string = pollutant.dataIdentifier;
          const dataCheck =
            pollutant.dataIdentifier in
              updatedZephyrHistory[selectedAveragingPeriod.averagingOption][slot]
              ? !
                updatedZephyrHistory[selectedAveragingPeriod.averagingOption][
                  slot
                ][pollutant.dataIdentifier].allNull

                ? [
                  ...updatedZephyrHistory[
                    selectedAveragingPeriod.averagingOption
                  ][slot][pollutant.dataIdentifier].data,
                ]
                : []
              : [];
          const slotName_beta =
            zType === 0
              ? slot === 'slotA'
                ? 'A'
                : 'B'
              : zType === 100
                ? 'VZ'
                : slot;
          options.headers =
            dataCheck.length > 0
              ? [
                ...options.headers,
                pollutant.dataIdentifier.includes('particulate')
                  ? `${pollutant.dataIdentifier === 'particulatePM25'
                    ? `PM2.5(${pollutant.HTMLShortUnitLabel})`
                    : pollutant.dataIdentifier.replace(
                      'particulate',
                      '',
                    ) + `(${pollutant.HTMLShortUnitLabel})`
                  }`
                  : `${pollutant.HTMLUnitLabel}`,
              ]
              : [...options.headers];
          if (options.timeStamps.length === 0)
            options.timeStamps = [
              ...updatedZephyrHistory[selectedAveragingPeriod.averagingOption][
                slot
              ].dateTime.data,
            ];
          if (dataCheck.length > 0) {
            if (getMaxNumber(dataCheck) > options.yMax)
              options.yMax = getMaxNumber(dataCheck);
          }
          const series = {
            // name: pollutant.dataIdentifier.includes('particulate')
            //   ? `${pollutant.dataIdentifier === 'particulatePM25'
            //     ? `PM2.5(${pollutant.HTMLShortUnitLabel})`
            //     : pollutant.dataIdentifier.replace('particulate', '') +
            //     `(${pollutant.HTMLShortUnitLabel})`
            //   }`
            //   : `${pollutant.HTMLUnitLabel}`,
            name: pollutant.HTMLUnitLabel,
            dataIdentifier: pollutant.dataIdentifier,
            type: 'line',
            lineStyle: {
              color: `rgba(${colDict[selectedPollutantName]?.join(',')}, 0.2)`,
            },
            showSymbol: false,
            data: dataCheck,
            symbol: 'none',
            symbolSize: 0,
            yAxisIndex: 0,
            slot
          };
          options.series = [...options.series, { ...series }];
        });
    });
  }
};

export const getAvgPollutantsForSelectedTimePeriod = (
  unitHistories: any,
  selectedZephyrName: string,
  selectedAveragingPeriodd: any,
) => {
  const { historiesList } = unitHistories;
  const unitKey = Object.keys(historiesList[0])[0];
  const { selectedAveragingPeriod, data } = historiesList[0][unitKey];
  const options: Options = {
    headers: [],
    timeStamps: [],
    series: [],
    yAxis: [],
    yMax: 0,
  };
  const averagingPeriods = getPopulatedAveragingPeriods(historiesList, data);
  const pollutants = getSelectedPollutants(unitHistories);
};

const truncateLegendName = (name: string, maxLength: number) => {
  return name.length > maxLength ? name.substring(0, maxLength - 3) + '...' : name;
};

const getCommonUnits = (historiesList: any, selectedZephyrNumbers: any, averagingOption: string, pollutant: string) => {
  return historiesList.filter((unit: any) => {
    const unitName = Object.keys(unit)[0];
    const { data } = Object.values(unit)[0] as any;
    const slotWithValidData = data && data[averagingOption] ? Object.keys(data[averagingOption]).find((slot: string) => {
      return slot !== 'head' && data[averagingOption][slot] && data[averagingOption][slot].hasOwnProperty(pollutant) && data[averagingOption][slot][pollutant].data.some((val: number | null) => typeof val === 'number');
    }) : null;
    return selectedZephyrNumbers.includes(unitName) && slotWithValidData;
  })
};

const plotlyPollutantComparisonGenerator = (unitHistories: any, comparisonViewZephyrs: any, pollutant: string) => {
  const { historiesList } = unitHistories;
  const selectedZephyrNumbers = comparisonViewZephyrs.map((z: any) => z.id);
  const { selectedAveragingPeriod: { averagingOption } } = historiesList[0][Object.keys(historiesList[0])[0]];
  const commonUnits = getCommonUnits(historiesList, selectedZephyrNumbers, averagingOption, pollutant);
  const series: any[] = [];
  commonUnits.forEach((unit: any, id: number) => {
    const { data, name } = Object.values(unit)[0] as any;
    if (data) {
      const slots = Object.keys(data[averagingOption]).filter((slot: string) => {
        return slot !== 'head' && data[averagingOption][slot] && data[averagingOption][slot].hasOwnProperty(pollutant)
      });
      if (slots.length) {
        slots.forEach((slot: string, slotId: number) => {
          const { dateTime: { data: timeStamp } } = data[averagingOption][slot];
          const { data: pollutantData } = data[averagingOption][slot][pollutant];
          series.push({
            x: timeStamp,
            y: pollutantData,
            mode: 'lines',
            type: 'scattergl',
            name: truncateLegendName(name, 20),
            legendgroup: name,
            showlegend: slotId > 0 ? false : true,
            hovertemplate: slots.length > 1 ? `%{y} (${slot.split('slot')[1]})` : `%{y}`,
            line: {
              color: `rgba(${colDict[pollutant]?.join(',')},1)`,
              width: 2,
              dash: lineTypes[id]
            },
          })
        });
      }
    }
  });
  return series;
};

const plotlyChartDataGenerator = (
  unitHistories: any,
  selectedZephyrNumber: string,
  chartType: string,
) => {
  const { historiesList } = unitHistories;
  const unitKey = Object.keys(historiesList[0])[0];
  const { selectedAveragingPeriod, data } = historiesList[0][unitKey];
  const options: Options = {
    headers: [],
    timeStamps: [],
    series: [],
    yAxis: [],
    yMax: 0,
    color: [],
    slots: [],
  };
  const averagingPeriods = getPopulatedAveragingPeriods(historiesList, data);
  const pollutants = getSelectedPollutants(unitHistories);
  if (
    averagingPeriods.includes(selectedAveragingPeriod.averagingOption) &&
    pollutants
  ) {
    const unit = historiesList.find(
      (zephyr: any) =>
        +(Object.keys(zephyr) as any)[0] === +selectedZephyrNumber,
    );
    generateChartConfigurations(
      unit,
      selectedAveragingPeriod,
      pollutants,
      options,
      chartType,
    );
  }
  const { timeStamps, series, slots } = options;
  const serieNames: string[] = [];
  return series.filter((serie: any) => serie.data.length > 0 && serie.data.some((val: number | null) => typeof val === 'number')).map((serie: any) => {
    const data = {
      x: timeStamps,
      y: serie.data,
      mode: 'lines',
      type: 'scattergl',
      name: serie.name,
      legendgroup: serie.name,
      showlegend: serieNames.includes(serie.name) ? false : true,
      hovertemplate: slots?.filter(slot => slot !== 'head').length === 2 ? `%{y} (${serie.slot.split('slot')[1]})` : `%{y}`,
      line: {
        color: `rgba(${colDict[serie.dataIdentifier]?.join(',')},1)`,
        width: 2,
      },
    };
    if (!serieNames.includes(serie.name)) serieNames.push(serie.name);
    return data;
  });
};

const getSelectedPollutants = (unitHistories: any) => {
  const { historiesList } = unitHistories;
  const unitKey = Object.keys(historiesList[0])[0];
  const { selectedPollutants } = historiesList[0][unitKey];
  return selectedPollutants;
};

const plotlyTHPDataGenerator = (
  unitHistories: any,
  selectedZephyrNumber: string,
) => {
  const { historiesList } = unitHistories;
  const unitKey = Object.keys(historiesList[0])[0];
  const { selectedAveragingPeriod, data } = historiesList[0][unitKey];
  const options: Options = {
    headers: [],
    timeStamps: [],
    series: [],
    yAxis: [],
    yMax: 0,
    color: [],
    slots: [],
  };
  let averagingPeriods: any =
    historiesList.length === 1 && data ? Object.keys(data) : [];
  if (historiesList.length > 1) {
    historiesList.forEach((unit: any) => {
      if ((Object.values(unit)[0] as any).data) {
        const avPeriods = Object.keys((Object.values(unit) as any)[0].data);
        averagingPeriods = averagingPeriods.concat(avPeriods);
      }
    });
  }
  const unit = historiesList.find(
    (zephyr: any) =>
      +(Object.keys(zephyr) as any)[0] === +selectedZephyrNumber,
  );
  if (averagingPeriods.includes(selectedAveragingPeriod.averagingOption)) {
    const {
      updatedZephyrHistory,
      zephyrHistory,
    } = populateObjectWithZephyrHistory(selectedAveragingPeriod, unit);
    const slots = updatedZephyrHistory[selectedAveragingPeriod.averagingOption]
      ? slotValidator(updatedZephyrHistory, zephyrHistory)
      : null;
    if (slots) {
      options.slots = slots;
      slots
        .filter((slot: string) => slot !== 'head')
        .forEach((slot: string) => {
          temperatureHumidityPressure.forEach((pollutant: Pollutant) => {
            const selectedPollutantName: string = pollutant.dataIdentifier;

            const slotName_beta =
              unit[Object.keys(unit)[0]].zType === 0
                ? slot === 'slotA'
                  ? 'A'
                  : 'B'
                : unit[Object.keys(unit)[0]].zType === 100
                  ? 'VZ'
                  : slot;
            const dataCheck =
              pollutant.dataIdentifier in
                updatedZephyrHistory[selectedAveragingPeriod.averagingOption][
                slot
                ]
                ? !updatedZephyrHistory[
                  selectedAveragingPeriod.averagingOption
                ][slot][pollutant.dataIdentifier].allNull
                  ? [
                    ...updatedZephyrHistory[
                      selectedAveragingPeriod.averagingOption
                    ][slot][pollutant.dataIdentifier].data,
                  ]
                  : []
                : [];
            options.headers =
              dataCheck.length > 0
                ? [
                  ...options.headers,
                  `${pollutant.dataIdentifier === 'ambTempC'
                    ? 'Temperature'
                    : pollutant.dataIdentifier === 'ambHumidity'
                      ? 'Humidity'
                      : pollutant.dataIdentifier === 'ambPressure'
                        ? 'Pressure'
                        : pollutant.dataIdentifier
                  }`,
                ]
                : [...options.headers];
            if (options.timeStamps.length === 0)
              options.timeStamps = [
                ...updatedZephyrHistory[
                  selectedAveragingPeriod.averagingOption
                ][slot].dateTime.data,
              ];
            if (dataCheck.length > 0) {
              if (getMaxNumber(dataCheck) > options.yMax)
                options.yMax = getMaxNumber(dataCheck);
            }
            const series = {
              chartType: 'weather',
              name: `${pollutant.dataIdentifier === 'ambTempC'
                ? 'Temperature'
                : pollutant.dataIdentifier === 'ambHumidity'
                  ? 'Humidity'
                  : pollutant.dataIdentifier === 'ambPressure'
                    ? 'Pressure'
                    : pollutant.dataIdentifier
                }`,
              type: 'line',
              lineStyle: {
                type: 'line',
                color: `rgba(${colDict[selectedPollutantName]?.join(',')},1)`,
              },
              showSymbol:
                dataCheck.filter(
                  (val: number | null) => typeof val === 'number',
                ).length === 1
                  ? true
                  : false,
              data: dataCheck,
              slot,
              yAxisIndex:
                unit[Object.keys(unit)[0]].zType === 100
                  ? pollutant.dataIdentifier === 'ambTempC'
                    ? 0
                    : 1
                  : pollutant.dataIdentifier === 'ambTempC'
                    ? 0
                    : pollutant.dataIdentifier === 'ambHumidity'
                      ? 1
                      : 2,
              symbol:
                dataCheck.filter(
                  (val: number | null) => typeof val === 'number',
                ).length === 1
                  ? 'circle'
                  : 'none',
              symbolSize:
                dataCheck.filter(
                  (val: number | null) => typeof val === 'number',
                ).length === 1
                  ? 3
                  : 0,
            };
            options.color = [
              ...options.color!,
              `rgba(${colDict[selectedPollutantName]?.join(',')},1)`,
            ];
            options.series = [...options.series, { ...series }];
          });
        });
    }
  }
  const layout =
    unit[Object.keys(unit)[0]].zType === 100
      ? {
        title: '',
        margin: plotlyTHPMargin,
        font: plotlyFont,
        xaxis: {
          domain: [0, 0.9],
          showline: true,
        },
        yaxis: {
          title: {
            text: 'Temperature (°C)',
            standoff: 1,
          },
          // position: 0.05,
          titlefont: { color: '#1a202c' },
          side: 'left',
          showline: true,
        },
        yaxis2: {
          title: 'Humidity (%)',
          titlefont: { color: '#1a202c' },
          overlaying: 'y',
          side: 'right',
          position: 0.9,
          showline: true,
        },
        // legend: {
        //   x: 0,
        //   y: -0.3,
        //   orientation: 'h',
        // },
      }
      : {
        title: '',
        margin: plotlyTHPMargin,
        font: plotlyFont,
        xaxis: {
          domain: [0, 0.9],
          showline: true,
        },
        yaxis: {
          title: {
            text: 'Temperature (°C)',
            standoff: 1,
          },
          // position: 0.05,
          titlefont: { color: '#1a202c' },
          side: 'left',
          showline: true,
        },
        yaxis2: {
          title: 'Humidity (%)',
          titlefont: { color: '#1a202c' },
          overlaying: 'y',
          side: 'right',
          position: 0.9,
          showline: true,
        },
        // yaxis3: {
        //   title: 'Pressure (hPa)',
        //   titlefont: { color: '#0d81fe' },
        //   overlaying: 'y',
        //   side: 'right',
        //   position: 1,
        //   showline: true,
        // },
        // legend: {
        //   x: 0,
        //   y: -0.3,
        //   orientation: 'h',
        // },
      };
  const { timeStamps, series, slots } = options;
  const serieNames: string[] = [];
  const thpSeries = series.filter((serie: any) => serie.data.length > 0 && serie.data.some((val: number | null) => typeof val === 'number')).map((serie: any) => {
    const data = {
      x: timeStamps,
      y: serie.data,
      mode: 'lines',
      type: 'scattergl',
      name: serie.name,
      legendgroup: serie.name,
      showlegend: serieNames.includes(serie.name) ? false : true,
      hovertemplate: slots?.filter(slot => slot !== 'head').length === 2 ? `%{y} (${serie.slot.split('slot')[1]})` : `%{y}`,
      line: {
        color: `rgba(${colDict[serie.name]?.join(',')}, 1)`,
        width: 2,
      },
      yaxis:
        serie.yAxisIndex === 0 ? 'y1' : serie.yAxisIndex === 1 ? 'y2' : 'y3',
    };
    if (!serieNames.includes(serie.name)) serieNames.push(serie.name);
    return data;
  });
  return {
    thpSeries,
    layout,
  };
};

const plotlyWindDirectionSpeedGenerator = (
  unitHistories: any,
  selectedWeatherZephyrNumber: string,
  zephyrs: Zephyr[]
) => {
  const windSeries: {
    time: Date[] | string[];
    speed: number[];
    direction: number[];
  } = { time: [], speed: [], direction: [] };
  const { historiesList } = unitHistories;
  const zephyrNumber = +selectedWeatherZephyrNumber;
  const unit = historiesList.find(
    (zephyr: any) => +(Object.keys(zephyr) as any)[0] === zephyrNumber,
  );
  const unitKey = Object.keys(unit)[0];
  const { HasMetStation } = zephyrs.find((zephyr: Zephyr) => zephyr.zNumber === +unitKey) as Zephyr;
  const { selectedAveragingPeriod, data, zType, proxyVZData: { data: proxyVZData = {} } = {} } = unit[unitKey];
  const {
    updatedZephyrHistory,
    zephyrHistory,
  } = populateObjectWithZephyrHistory(selectedAveragingPeriod, unit);
  const slots = updatedZephyrHistory[selectedAveragingPeriod.averagingOption]
    ? slotValidator(updatedZephyrHistory, zephyrHistory)
    : [];
  const proxyVZSlots = Object.keys(proxyVZData).length > 0 && proxyVZData[selectedAveragingPeriod.averagingOption] ? Object.keys(proxyVZData[selectedAveragingPeriod.averagingOption]).reverse().filter((slot: string) => proxyVZData[selectedAveragingPeriod.averagingOption][slot] && slot !== 'head') : [];
  const validation = ((HasMetStation && slots.length > 0 && selectedAveragingPeriod.averagingOption in data && slots[0] in data[selectedAveragingPeriod.averagingOption]) || (!HasMetStation && proxyVZSlots.length > 0 && selectedAveragingPeriod.averagingOption in proxyVZData) || zType === 100) && zType !== 1;
  if (validation) {
    if (HasMetStation && slots.length > 0 && slots.includes('head') && zType === 0) { slots.unshift((slots as any).pop()); }
    const slotData = HasMetStation || zType === 100 ? data[selectedAveragingPeriod.averagingOption][slots[0]] : proxyVZData[selectedAveragingPeriod.averagingOption][proxyVZSlots[0]];
    if (
      (zType === 0 && ((HasMetStation && 'relWindSpeed' in slotData && 'relWindDir' in slotData) || (!HasMetStation && 'WINDSPEED' in slotData && 'WINDDIR' in slotData))) ||
      (zType === 100 && 'WINDDIR' in slotData && 'WINDSPEED' in slotData)
    ) {

      const relWindSpeed =
        zType === 0 && HasMetStation ? slotData['relWindSpeed'] : slotData['WINDSPEED'];
      const relWindDir =
        zType === 0 && HasMetStation ? slotData['relWindDir'] : slotData['WINDDIR'];
      const { dateTime } = slotData;
      const { data: relWindSpeedData } = relWindSpeed;
      const { data: relWindDirData } = relWindDir;
      const { data: dateTimeStamps } = dateTime;
      windSeries.time = dateTimeStamps.map((timestamp: string) =>
        timestamp.replace(/\+00:00\+00:00$/, '+00:00')
      );
      windSeries.speed = relWindSpeedData;
      windSeries.direction = relWindDirData;
    }
  }
  return windSeries;
};

const plotlyPrecipitationGenerator = (
  unitHistories: any,
  selectedWeatherZephyrNumber: string,
  zephyrs: Zephyr[]
) => {
  const precipitationSeries: {
    precipitation: number[];
    time: Date[] | string[];
  } = { precipitation: [], time: [] };
  const { historiesList } = unitHistories;
  const zephyrNumber = +selectedWeatherZephyrNumber;
  const unit = historiesList.find(
    (zephyr: any) => +(Object.keys(zephyr) as any)[0] === zephyrNumber,
  );
  const unitKey = Object.keys(unit)[0];
  const { HasMetStation } = zephyrs.find((zephyr: Zephyr) => zephyr.zNumber === +unitKey) as Zephyr;
  const { selectedAveragingPeriod, data, zType, proxyVZData: { data: proxyVZData = {} } = {} } = unit[unitKey];
  const {
    updatedZephyrHistory,
    zephyrHistory,
  } = populateObjectWithZephyrHistory(selectedAveragingPeriod, unit);
  const slots: string[] = updatedZephyrHistory[selectedAveragingPeriod.averagingOption]
    ? slotValidator(updatedZephyrHistory, zephyrHistory)
    : [];
  const proxyVZSlots = Object.keys(proxyVZData).length > 0 && proxyVZData[selectedAveragingPeriod.averagingOption] ? Object.keys(proxyVZData[selectedAveragingPeriod.averagingOption]).reverse().filter((slot: string) => proxyVZData[selectedAveragingPeriod.averagingOption][slot] && slot !== 'head') : [];
  const validation = ((HasMetStation && slots.length > 0 && selectedAveragingPeriod.averagingOption in data && slots[0] in data[selectedAveragingPeriod.averagingOption]) || (!HasMetStation && proxyVZSlots.length > 0 && selectedAveragingPeriod.averagingOption in proxyVZData) || zType === 100) && zType !== 1;
  if (validation) {
    if (HasMetStation && slots.length > 0 && slots.includes('head') && zType === 0) { slots.unshift((slots as any).pop()); }
    const slotData = HasMetStation || zType === 100 ? data[selectedAveragingPeriod.averagingOption][slots[0]] : proxyVZData[selectedAveragingPeriod.averagingOption][proxyVZSlots[0]];
    if (
      (zType === 0 && ((HasMetStation && 'precipitationIntensity' in slotData) || (!HasMetStation && 'RAINFALL' in slotData))) ||
      (zType === 100 && 'RAINFALL' in slotData)
    ) {
      const precipitation =
        zType === 0 && HasMetStation
          ? slotData['precipitationIntensity'].data
          : slotData['RAINFALL'].data;
      const { dateTime } = slotData;
      const { data: dateTimeStamps } = dateTime;
      precipitationSeries.precipitation = precipitation;
      precipitationSeries.time = dateTimeStamps.map((timestamp: string) =>
        timestamp.replace(/\+00:00\+00:00$/, '+00:00')
      );;
    }
  }
  const plotlyData = {
    precipitationSerie: [
      {
        type: 'bar',
        x: precipitationSeries.time,
        y: precipitationSeries.precipitation,
        marker: { color: '#636efa' },
      },
    ],
    precipitationLayout: {
      yaxis: { title: 'Rainfall (mm/hr)', automargin: true, hoverformat: '.3r' },
      margin: plotlyPrecipitationMargin,
      font: plotlyFont,
    },
  };
  return plotlyData;
};

const plotlyMinMaxMeanStatisticsGenerator = (unitHistories: any, chartType: string, selectedOption: string) => {
  if (!unitHistories || !('historiesList' in unitHistories)) {
    return;
  }
  const { historiesList } = unitHistories;

  const unitKey = Object.keys(historiesList[0])[0];
  const {
    selectedAveragingPeriod,
    data,
    selectedPollutants,
  } = historiesList[0][unitKey];
  const averagingPeriods = getPopulatedAveragingPeriods(historiesList, data);
  const pollutants = selectedPollutants.filter((pollutant: Pollutant) => {
    if (chartType === 'networkViewParticulates') {
      return pollutant.dataIdentifier.includes('particulate');
    }
    return !pollutant.dataIdentifier.includes('particulate');
  });

  const pollutantUnits: { [pollutant: string]: string } = {};

  if (averagingPeriods.includes(selectedAveragingPeriod.averagingOption)) {
    let obj: any = {};
    const unitList = historiesList.filter(
      (unit: any) =>
        unit[Object.keys(unit)[0]].data !== null &&
        unit[Object.keys(unit)[0]].data !== false,
    );
    unitList.forEach((unit: any, unitIdx: number) => {
      const { name, zType } = Object.values(unit)[0] as any;
      const {
        updatedZephyrHistory,
        zephyrHistory,
      } = populateObjectWithZephyrHistory(selectedAveragingPeriod, unit);
      const slots = updatedZephyrHistory[
        selectedAveragingPeriod.averagingOption
      ]
        ? slotValidator(updatedZephyrHistory, zephyrHistory).filter(
          (slot: string) => slot !== 'head',
        )
        : null;
      if (slots && slots.length > 0) {
        const unitZNumber = Object.keys(unit)[0];
        const unitData = unit[unitZNumber].data;
        slots.forEach((slot: string) => {
          pollutants.forEach((p: Pollutant) => {
            const pollutant = p.dataIdentifier;
            pollutantUnits[pollutant] = p.HTMLShortUnitLabel;
            if (
              pollutant in
              unitData[selectedAveragingPeriod.averagingOption][slot]
            ) {
              const { data: pollutantData } = unitData[
                selectedAveragingPeriod.averagingOption
              ][slot][pollutant];
              const { data: dateTimeStamps } = unitData[
                selectedAveragingPeriod.averagingOption
              ][slot].dateTime;
              obj[pollutant] =
                pollutant in obj
                  ? [
                    ...obj[pollutant],
                    ...dateTimeStamps.map((ts: string, idx: number) => {
                      return {
                        timestamp: ts,
                        [pollutant]: pollutantData[idx],
                      };
                    }),
                  ]
                  : [
                    ...dateTimeStamps.map((ts: string, idx: number) => {
                      return {
                        timestamp: ts,
                        [pollutant]: pollutantData[idx],
                      };
                    }),
                  ];
            }
          });
        });
      }
    });
    const finalObject: any = {};
    Object.keys(obj).forEach((key: string) => {
      const pollutant = key;
      const formattedData = obj[key];
      let timestamps, meanValues, minValues, maxValues, fivePercentile, median, ninetyFivePercentile;

      if (selectedOption === 'minMeanMax') {
        ({ timestamps, meanValues, minValues, maxValues } = generateMinMaxMeanStatisticsObject(formattedData, pollutant, selectedOption));
      } else {
        ({ timestamps, fivePercentile, median, ninetyFivePercentile } = generateMinMaxMeanStatisticsObject(formattedData, pollutant, selectedOption));
      }
      finalObject[pollutant] = selectedOption === 'minMeanMax' ? {
        timestamps,
        meanValues,
        minValues,
        maxValues,
        unit: pollutantUnits[pollutant],
      } : { timestamps, median, fivePercentile, ninetyFivePercentile, unit: pollutantUnits[pollutant] };
    });
    const { data, layout } = getMinMaxMeanStatisticsPlotlyDataAndLayout(
      finalObject,
      historiesList.length,
      selectedOption
    );
    return { data, layout };
  }
  return [];
};

const getSourceApportionmentConfigurations = (unitHistories: any, selectedZephyr: Zephyr, isNO2: boolean) => {
  let config: { [key: string]: number } = {};
  const { zNumber } = selectedZephyr;
  const zephyr = unitHistories.historiesList.find((z: any) => +Object.keys(z)[0] === zNumber);
  if (zephyr) {
    const { proxyVZSourceApportionmentData, selectedAveragingPeriod: { averagingOption } } = Object.values(zephyr)[0] as any;
    if (proxyVZSourceApportionmentData && proxyVZSourceApportionmentData.data && proxyVZSourceApportionmentData.data[averagingOption] && Object.keys(proxyVZSourceApportionmentData.data[averagingOption]).length) {
      const { data: proxyVZData } = proxyVZSourceApportionmentData;
      const averagingData = proxyVZData[averagingOption];
      const sa = isNO2 ? 'nox' : 'pm2p5';
      const slot = 'slotB';
      if (slot in averagingData && averagingData[slot] && Object.keys(averagingData[slot]).length) {
        const filteredSAObject = Object.fromEntries(
          Object.entries(averagingData[slot]).filter(([key, _]) => key.includes(sa))
        );
        const saObject = Object.values(filteredSAObject);
        saObject.forEach(saData => {
          const { data: average, allNull, keyword } = saData as any;
          if (!allNull && average !== null && average !== undefined) {
            const label = SALabels[keyword.split('_')[1] as keyof typeof SALabels];
            config[`${label}${average === 0 || +average.toFixed() === 0 ? ' (0%)' : ''}`] = +average.toFixed();
          }
        })
      }
    }
  }
  if (Object.keys(config).length) {
    config = Object.fromEntries(
      Object.entries(config).sort(([, a], [, b]) => b - a)
    );
  }
  return Object.keys(config).length ? config : null;
};

/* Summary Table */

const roundedToFixed = (input: number, digits: number) => {
  const rounder = Math.pow(10, digits);
  return (Math.round(input * rounder) / rounder).toFixed(digits);
};

const getMinMaxMean = (arr: any[]) => {
  const sortedArr =
    arr && arr.length > 0
      ? [...arr]
        .filter((el: number | null) => el !== null)
        .sort((a: number, b: number) => a - b)
      : [];
  const mean =
    sortedArr.reduce((a: number, b: number) => a + b, 0) / sortedArr.length;
  return sortedArr.length > 0 && arr
    ? {
      min: sortedArr[0],
      max: sortedArr[sortedArr.length - 1],
      mean,
      sortedArr,
    }
    : { min: 'N/A', max: 'N/A', mean: 'N/A', sortedArr };
};

const formatCustomDate = (date: string) => {
  return date
    ? `${date.substring(8, 6)}/${date.substring(6, 4)}/${date.substring(
      0,
      4,
    )} ${date.substring(10, 8)}:${date.substring(10)}`
    : 'N/A';
};

const getLocalTimeStamp = (timeStamp: string) => {
  const utcStartTime = utcConvertor(timeStamp);
  const unSyncedStartDate: Date = new Date(
    `${utcStartTime
      .toString()
      .substring(0, 4)}-${utcStartTime
        .toString()
        .substring(4, 6)}-${utcStartTime
          .toString()
          .substring(6, 8)}T${utcStartTime
            .toString()
            .substring(8, 10)}:${utcStartTime.toString().substring(10, 12)}`,
  );

}

const getDateTime = (start: string, end: string, labelHTML: string) => {
  if (labelHTML !== 'Custom') {
    const utcStartTime = utcConvertor(start);
    const utcEndTime = utcConvertor(end);
    const unSyncedStartDate: Date = new Date(
      `${utcStartTime
        .toString()
        .substring(0, 4)}-${utcStartTime
          .toString()
          .substring(4, 6)}-${utcStartTime
            .toString()
            .substring(6, 8)}T${utcStartTime
              .toString()
              .substring(8, 10)}:${utcStartTime.toString().substring(10, 12)}`,
    );
    const unSyncedEndDate: Date = new Date(
      `${utcEndTime
        .toString()
        .substring(0, 4)}-${utcEndTime
          .toString()
          .substring(4, 6)}-${utcEndTime
            .toString()
            .substring(6, 8)}T${utcEndTime
              .toString()
              .substring(8, 10)}:${utcEndTime.toString().substring(10, 12)}`,
    );
    const startDate = moment(
      convertUnitTimeToLocalTime(unSyncedStartDate),
    ).format('DD-MM-YYYY HH:mm');
    const endDate = moment(convertUnitTimeToLocalTime(unSyncedEndDate)).format(
      'DD-MM-YYYY HH:mm',
    );
    return { startDate, endDate };
  }
  const startDate = formatCustomDate(start);
  const endDate = formatCustomDate(end);
  return { startDate, endDate };
};

const formatDate = (dateToFormat: Date) => {
  // Create a Date object from the ISO 8601 string
  const date = new Date(dateToFormat);

  // Extract components from the date
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() returns month from 0-11
  const year = date.getUTCFullYear();
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  // Format the date and time as DD/MM/YYYY HH:mm
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const summaryTableGenerator = (unitHistories: any, zephyrs: Zephyr[], csv: boolean) => {
  if (!unitHistories || !('historiesList' in unitHistories)) {
    return;
  }
  const { historiesList: selectedZephyrs } = unitHistories;
  const averagingPeriod: AveragingPeriod = (Object.values(
    selectedZephyrs[0],
  )[0] as any).selectedAveragingPeriod;
  const { selectedPollutants } = Object.values(selectedZephyrs[0])[0] as any;
  const tableTitles: { title: string, type?: string }[] | string[] = !csv ? [
    { title: 'Source' },
    { title: 'ID', type: 'num' },
    { title: 'Name' },
    { title: 'Slot' },
  ] : ['Source', 'Site ID', 'Site Name', 'Slot Number', 'Latitude', 'Longitude'];
  const tableData: any[] = [];
  let titlesPushed = false;
  selectedZephyrs.forEach((zephyrData: any, zIdx: number) => {
    const zephyr = zephyrs
      .filter((zephyr: Zephyr) => zephyr.zNumber)
      .find(
        (zephyr: Zephyr) =>
          zephyr.zNumber.toString() === Object.keys(zephyrData)[0],
      );
    const { latitude, longitude } = zephyr!;
    const {
      name,
      selectedDate: { start, end },
      selectedTimePeriod: { labelHTML },
      zType,
    } = Object.values(zephyrData)[0] as any;
    const zephyrType =
      zType === 0 ? 'Zephyr' : zType === 100 ? 'Virtual Zephyr' : 'AURN';
    const { startDate, endDate } = getDateTime(start, end, labelHTML);
    const selectedDate: string = `${startDate} - ${endDate}`;
    if ((Object.values(zephyrData)[0] as any).data) {
      if (
        averagingPeriod.averagingOption in
        (Object.values(zephyrData)[0] as any).data
      ) {
        const averagingOptionData = (Object.values(zephyrData)[0] as any).data[
          averagingPeriod.averagingOption
        ];
        const slots = Object.keys(averagingOptionData).reverse().filter((slot: string) => averagingOptionData[slot] && slot !== 'head');
        slots.forEach((slot: string, sIdx: number) => {
          if (averagingOptionData[slot]) {
            const zephyrID = Object.keys(zephyrData)[0];
            const tableRowData: any = !csv ? [
              zephyrType,
              zephyrID,
              name,
              zephyrType === 'Zephyr' ? slot.split(/(?=[A-Z])/)[1] : 'N/A',
            ] : [
              zephyrType,
              zephyrID,
              name,
              zephyrType === 'Zephyr' ? slot.split(/(?=[A-Z])/)[1] : 'N/A',
              latitude,
              longitude
            ];
            selectedPollutants.forEach((selectedPollutant: Pollutant, pIdx: number) => {
              let pollutant = selectedPollutant.dataIdentifier.includes('particulate') ? selectedPollutant.dataIdentifier.split('particulate')[1] : selectedPollutant.dataIdentifier;
              pollutant = pollutantNameMapping[pollutant]?.replace(/\s+/g, '') || pollutant;
              if (!csv && !(tableTitles as { title: string, type?: string }[]).find((pollutantTitleObj: { title: string, type?: string }) => pollutantTitleObj.title === pollutant)) (tableTitles as { title: string, type?: string }[]).push({ title: pollutant });
              const pollutantData =
                selectedPollutant.dataIdentifier in averagingOptionData[slot]
                  ? averagingOptionData[slot][selectedPollutant.dataIdentifier]
                    .data
                  : null;
              const {
                dateTime: { data: dateTimeArr },
              } = averagingOptionData[slot];
              const { max, mean, sortedArr } = getMinMaxMean(pollutantData);
              if (!csv) tableRowData.push([]);
              tableRowData.push(
                sortedArr.length > 0 ? roundedToFixed(+mean, 1) : mean,
                sortedArr.length > 0 ? roundedToFixed(+max, 1) : max,
                sortedArr.length > 0
                  ? formatDate(dateTimeArr[pollutantData.indexOf(max)])
                  : 'N/A',
                selectedPollutant.HTMLShortUnitLabel,
              );
              if (!titlesPushed) {
                (tableTitles as any).push(
                  !csv ? {
                    title: `Mean`,
                    type: 'num'
                  } : `${selectedPollutant.dataIdentifier.includes('particulate') ? selectedPollutant.dataIdentifier.split('particulate')[1] === 'PM25' ? 'PM2.5' : selectedPollutant.dataIdentifier.split('particulate')[1] : selectedPollutant.dataIdentifier} Mean`,
                  !csv ? {
                    title: `Max`,
                    type: 'num'
                  } : `${selectedPollutant.dataIdentifier.includes('particulate') ? selectedPollutant.dataIdentifier.split('particulate')[1] === 'PM25' ? 'PM2.5' : selectedPollutant.dataIdentifier.split('particulate')[1] : selectedPollutant.dataIdentifier} Max`,
                  !csv ? {
                    // title: `Date Time (${getCurrentTimeZoneAbbreviation()})`,
                    title: `Date Time (UTC)`,
                  } : `${selectedPollutant.dataIdentifier.includes('particulate') ? selectedPollutant.dataIdentifier.split('particulate')[1] === 'PM25' ? 'PM2.5' : selectedPollutant.dataIdentifier.split('particulate')[1] : selectedPollutant.dataIdentifier} ${!csv ? '' : 'Max'} Date Time (UTC)`,
                  !csv ? {
                    title: `Unit`
                  } : `${selectedPollutant.dataIdentifier.includes('particulate') ? selectedPollutant.dataIdentifier.split('particulate')[1] === 'PM25' ? 'PM2.5' : selectedPollutant.dataIdentifier.split('particulate')[1] : selectedPollutant.dataIdentifier} Unit`
                );
                if (pIdx === selectedPollutants.length - 1) titlesPushed = true;
              }
            });
            tableData.push([...tableRowData]);
          }
        });
      } else {
        const zephyrID = Object.keys(zephyrData)[0];
        const tableRowData: any = !csv ? [
          zephyrType,
          zephyrID,
          name,
          'No data for selected averaging option'
        ] : [
          zephyrType,
          zephyrID,
          name,
          'No data for selected averaging option',
          latitude,
          longitude,
        ];
        selectedPollutants.forEach(
          (selectedPollutant: Pollutant, pIdx: number) => {
            tableRowData.push(
              'No data for selected averaging option',
              'No data for selected averaging option',
              'No data for selected averaging option',
              'No data for selected averaging option',
            );
            if (zIdx === 0 && pIdx === 0)
              (tableTitles as any).push(
                !csv ? {
                  title: `Mean`,
                } : `${selectedPollutant.dataIdentifier.includes('particulate') ? selectedPollutant.dataIdentifier.split('particulate')[1] === 'PM25' ? 'PM2.5' : selectedPollutant.dataIdentifier.split('particulate')[1] : selectedPollutant.dataIdentifier} Mean`,
                !csv ? {
                  title: `Max`,
                } : `${selectedPollutant.dataIdentifier.includes('particulate') ? selectedPollutant.dataIdentifier.split('particulate')[1] === 'PM25' ? 'PM2.5' : selectedPollutant.dataIdentifier.split('particulate')[1] : selectedPollutant.dataIdentifier} Max`,
                !csv ? {
                  title: `Date Time (UTC)`,
                } : `${selectedPollutant.dataIdentifier.includes('particulate') ? selectedPollutant.dataIdentifier.split('particulate')[1] === 'PM25' ? 'PM2.5' : selectedPollutant.dataIdentifier.split('particulate')[1] : selectedPollutant.dataIdentifier} ${!csv ? '' : 'Max'} Date Time (UTC)`,
                !csv ? {
                  title: `Unit`
                } : `${selectedPollutant.dataIdentifier.includes('particulate') ? selectedPollutant.dataIdentifier.split('particulate')[1] === 'PM25' ? 'PM2.5' : selectedPollutant.dataIdentifier.split('particulate')[1] : selectedPollutant.dataIdentifier} Unit`
              );
          },
        );
        tableData.push([...tableRowData]);
      }
    } else {
      // push no data for selected time period
    }
  });
  return { tableData, tableTitles };
};

const isProxyVZDataValid = (proxyVZData: any, proxyVZSlots: string[], averagingOption: string) => {
  let isProxyVZDataValid = false;
  if (proxyVZSlots.length > 0) {
    for (let i = 0; i < proxyVZSlots.length; i++) {
      if (proxyVZData[averagingOption][proxyVZSlots[i]].WINDSPEED.data.some((val: number | null) => typeof val === 'number') || proxyVZData[averagingOption][proxyVZSlots[i]].WINDDIR.data.some((val: number | null) => typeof val === 'number') || proxyVZData[averagingOption][proxyVZSlots[i]].RAINFALL.data.some((val: number | null) => typeof val === 'number')) {
        isProxyVZDataValid = true;
        break;
      }
    };
    return isProxyVZDataValid;
  }
  return isProxyVZDataValid;
};

const comparisonViewCSVGenerator = (unit: any, zephyrs: Zephyr[]) => {
  // const unitZNumber = +Object.keys(unit)[0];
  // const { selectedPollutants, data, selectedAveragingPeriod: { averagingOption } } = Object.values(unit)[0] as any;
  // const constantTitles = ['Date (Local)', 'Time (Local)', 'UTC Time Stamp', 'Latitude', 'Longitude'];
  // let pollutantTitles = [...selectedPollutants.map((pollutant: Pollutant) => [`${pollutant.dataIdentifier.includes('particulate') ? pollutant.dataIdentifier.split('particulate')[1] === 'PM25' ? 'PM2.5' : pollutant.dataIdentifier.split('particulate')[1] : pollutant.dataIdentifier} Concentration`, `${pollutant.dataIdentifier.includes('particulate') ? pollutant.dataIdentifier.split('particulate')[1] === 'PM25' ? 'PM2.5' : pollutant.dataIdentifier.split('particulate')[1] : pollutant.dataIdentifier} Unit`])].flat(1);
  // const content = [];
  // const curZephyr = zephyrs
  //   .filter((zephyr: Zephyr) => zephyr.zNumber)
  //   .find(
  //     (zephyr: Zephyr) =>
  //       zephyr.zNumber === unitZNumber,
  //   );
  // const { latitude, longitude } = curZephyr!;
  // if (data && data.hasOwnProperty(averagingOption)) {
  //   const slots = data[averagingOption];
  //   const filteredSlots = Object.keys(slots).reverse().filter((slot: string) => slots[slot] && slot !== 'head');

  // }

  let proxyVZData: any = {};
  const unitZNumber = +Object.keys(unit)[0];
  const unitData: any = Object.values(unit)[0];
  const { selectedPollutants, selectedAveragingPeriod: averagingPeriod, data } = unitData;
  if (unitData.hasOwnProperty('proxyVZData')) proxyVZData = unitData.proxyVZData.data;
  const species = [...selectedPollutants.map((pollutant: Pollutant) => {
    const { dataIdentifier } = pollutant;
    return { [dataIdentifier]: 0 }
  })];
  const tableTitlesBeforePollutants: string[] = ['Date (Local)', 'Time (Local)', 'UTC Time Stamp', 'Latitude', 'Longitude'];
  let tablePollutantsTitles: string[] = [...selectedPollutants.map((pollutant: Pollutant) => [`${pollutant.dataIdentifier.includes('particulate') ? pollutant.dataIdentifier.split('particulate')[1] === 'PM25' ? 'PM2.5' : pollutant.dataIdentifier.split('particulate')[1] : pollutant.dataIdentifier} Concentration`, `${pollutant.dataIdentifier.includes('particulate') ? pollutant.dataIdentifier.split('particulate')[1] === 'PM25' ? 'PM2.5' : pollutant.dataIdentifier.split('particulate')[1] : pollutant.dataIdentifier} Unit`])].flat(1);
  const tableData: any[] = [];
  if (data) {
    if (averagingPeriod.averagingOption in data) {
      const averagingOptionData = data[averagingPeriod.averagingOption];
      const { latitude: { data: latList }, longitude: { data: lonList } } = averagingOptionData['head'];
      const slots = Object.keys(averagingOptionData).reverse().filter((slot: string) => averagingOptionData[slot] && slot !== 'head');
      // const proxyVZSlots = Object.keys(proxyVZData).length > 0 ? Object.keys(proxyVZData[averagingPeriod.averagingOption]).reverse().filter((slot: string) => proxyVZData[averagingPeriod.averagingOption][slot] && slot !== 'head') : [];
      const proxyVZSlots = Object.keys(proxyVZData).length > 0 && proxyVZData[averagingPeriod.averagingOption] ?
        Object.keys(proxyVZData[averagingPeriod.averagingOption]).reverse().filter((slot: string) => proxyVZData[averagingPeriod.averagingOption][slot] && slot !== "head") : [];
      if (slots.length > 1) tablePollutantsTitles = [...selectedPollutants.map((pollutant: Pollutant) => [`${pollutant.dataIdentifier.includes('particulate') ? pollutant.dataIdentifier.split('particulate')[1] : pollutant.dataIdentifier} Concentration (A)`, `${pollutant.dataIdentifier.includes('particulate') ? pollutant.dataIdentifier.split('particulate')[1] : pollutant.dataIdentifier} Concentration (B)`, `${pollutant.dataIdentifier.includes('particulate') ? pollutant.dataIdentifier.split('particulate')[1] : pollutant.dataIdentifier} Unit`])].flat(1);
      const { dateTime: { data: dateTimeValues } } = slots.length > 0 ? averagingOptionData[slots[0]] : proxyVZData[averagingPeriod.averagingOption][proxyVZSlots[0]];
      dateTimeValues.forEach((time: Date | string, idx: number) => {
        const localDate = moment(
          convertUnitTimeToLocalTime(time.toString().split('+')[0]),
        ).format('DD-MM-YYYY');
        const localTime = moment(
          convertUnitTimeToLocalTime(time.toString().split('+')[0]),
        ).format('HH:mm:ss');
        const tableRowData: any = [localDate, localTime, time, latList[idx] ?? 'N/A', lonList[idx] ?? 'N/A'];
        species.forEach((specie: { [key: string]: number }) => {
          const key = Object.keys(specie)[0];
          const value = Object.values(specie)[0];
          if (value === 0) {
            if (slots.length === 0) {
              value === 0 ? tableRowData.push('N/A', 'N/A') : tableRowData.push('N/A');
            }
            else if (slots.length === 1) {
              if (key in averagingOptionData[slots[0]] && typeof averagingOptionData[slots[0]][key].data[idx] === 'number') {
                value === 0 ? tableRowData.push(averagingOptionData[slots[0]][key].data[idx], selectedPollutants.find((pollutant: Pollutant) => pollutant.dataIdentifier === key).HTMLShortUnitLabel) : tableRowData.push(averagingOptionData[slots[0]][key].data[idx]);
              } else {
                value === 0 ? tableRowData.push('N/A', selectedPollutants.find((pollutant: Pollutant) => pollutant.dataIdentifier === key).HTMLShortUnitLabel) : tableRowData.push('N/A');
              }
            } else {
              if (key in averagingOptionData[slots[0]] && typeof averagingOptionData[slots[0]][key].data[idx] === 'number' && key in averagingOptionData[slots[1]] && typeof averagingOptionData[slots[1]][key].data[idx] === 'number') {
                value === 0 ? tableRowData.push(averagingOptionData[slots[0]][key].data[idx], averagingOptionData[slots[1]][key].data[idx], selectedPollutants.find((pollutant: Pollutant) => pollutant.dataIdentifier === key).HTMLShortUnitLabel) : tableRowData.push(averagingOptionData[slots[0]][key].data[idx], averagingOptionData[slots[1]][key].data[idx]);
              } else if (key in averagingOptionData[slots[0]] && typeof averagingOptionData[slots[0]][key].data[idx] === 'number') {
                value === 0 ? tableRowData.push(averagingOptionData[slots[0]][key].data[idx], 'N/A', selectedPollutants.find((pollutant: Pollutant) => pollutant.dataIdentifier === key).HTMLShortUnitLabel) : tableRowData.push(averagingOptionData[slots[0]][key].data[idx], 'N/A');
              } else if (key in averagingOptionData[slots[1]] && typeof averagingOptionData[slots[1]][key].data[idx] === 'number') {
                value === 0 ? tableRowData.push('N/A', averagingOptionData[slots[1]][key].data[idx], selectedPollutants.find((pollutant: Pollutant) => pollutant.dataIdentifier === key).HTMLShortUnitLabel) : tableRowData.push('N/A', averagingOptionData[slots[1]][key].data[idx]);
              } else {
                value === 0 ? tableRowData.push('N/A', 'N/A', selectedPollutants.find((pollutant: Pollutant) => pollutant.dataIdentifier === key).HTMLShortUnitLabel) : tableRowData.push('N/A', 'N/A');
              }
            }
          }
        });
        tableData.push([...tableRowData]);
      });

    } else {
      // push no data for selected time average
    }
  } else {
    // push no data for selected time period
  }
  const tableTitles: string[] = [...tableTitlesBeforePollutants, ...tablePollutantsTitles];

  return { tableData, tableTitles };
}

const deviceCSVGenerator = (unitHistories: any, zephyrObj: any, id: number, zephyrs: Zephyr[], isPublic: boolean) => {
  if (!unitHistories || !('historiesList' in unitHistories)) return;
  let proxyVZData: any = {};
  const { selectedPollutants, selectedAveragingPeriod: averagingPeriod, data, selectedDate: { start, end }, selectedTimePeriod: { labelHTML } } = zephyrObj;
  if (zephyrObj.hasOwnProperty('proxyVZData') && !isPublic) proxyVZData = zephyrObj.proxyVZData.data;
  const species = [...selectedPollutants.map((pollutant: Pollutant) => {
    const { dataIdentifier } = pollutant;
    return { [dataIdentifier]: 0 }
  }), ...(!isPublic ? [{ ambTempC: 1 }, { ambHumidity: 1 }, /*{ ambPressure: 1 },*/ { relWindSpeed: 2 }, { relWindDir: 2 }, { precipitationIntensity: 2 }, { WINDSPEED: 3 }, { WINDDIR: 3 }, { RAINFALL: 3 }] : [])];
  const tableTitlesBeforePollutants: string[] = ['Date (Local)', 'Time (Local)', 'UTC Time Stamp', 'Latitude', 'Longitude'];
  let tablePollutantsTitles: string[] = [...selectedPollutants.map((pollutant: Pollutant) => [`${pollutant.dataIdentifier.includes('particulate') ? pollutant.dataIdentifier.split('particulate')[1] === 'PM25' ? 'PM2.5' : pollutant.dataIdentifier.split('particulate')[1] : pollutant.dataIdentifier} Concentration`, `${pollutant.dataIdentifier.includes('particulate') ? pollutant.dataIdentifier.split('particulate')[1] === 'PM25' ? 'PM2.5' : pollutant.dataIdentifier.split('particulate')[1] : pollutant.dataIdentifier} Unit`]), ...(!isPublic ? ['Temperature (°C)', 'Relative Humidity (%)'] : [])].flat(1);
  const tableMetDataTitles: string[] = !isPublic ? ['Measured (External) Wind Speed (m/s)', 'Measured (External) Wind Direction (°)', 'Measured (External) Rainfall (mm/hr)'] : [];
  let tableProxyVZephyrMetDataTitles: string[] = [];
  const tableData: any[] = [];
  const zephyr = zephyrs
    .filter((zephyr: Zephyr) => zephyr.zNumber)
    .find(
      (zephyr: Zephyr) =>
        zephyr.zNumber === id,
    );
  if (data) {
    if (averagingPeriod.averagingOption in data) {
      const averagingOptionData = data[averagingPeriod.averagingOption];
      const { latitude: { data: latList }, longitude: { data: lonList } } = averagingOptionData['head'];
      const slots = Object.keys(averagingOptionData).reverse().filter((slot: string) => averagingOptionData[slot] && slot !== 'head').sort();
      const proxyVZSlots = Object.keys(proxyVZData).length > 0 && proxyVZData[averagingPeriod.averagingOption] ?
        Object.keys(proxyVZData[averagingPeriod.averagingOption]).reverse().filter((slot: string) => proxyVZData[averagingPeriod.averagingOption][slot] && slot !== "head") : [];
      if (slots.length > 1) tablePollutantsTitles = [...selectedPollutants.map((pollutant: Pollutant) => [`${pollutant.dataIdentifier.includes('particulate') ? pollutant.dataIdentifier.split('particulate')[1] : pollutant.dataIdentifier} Concentration (A)`, `${pollutant.dataIdentifier.includes('particulate') ? pollutant.dataIdentifier.split('particulate')[1] : pollutant.dataIdentifier} Concentration (B)`, `${pollutant.dataIdentifier.includes('particulate') ? pollutant.dataIdentifier.split('particulate')[1] : pollutant.dataIdentifier} Unit`]), `Temperature (°C) (A)`, `Temperature (°C) (B)`, `Relative Humidity (%) (A)`, `Relative Humidity (%) (B)`].flat(1);
      if (zephyr?.type === 0 && (averagingPeriod.averagingOption === 'Hourly average on the hour' || averagingPeriod.averagingOption === '8 hour average at midnight and 8am and 4pm' || averagingPeriod.averagingOption === 'Daily average at midnight') && proxyVZSlots.length > 0 && isProxyVZDataValid(proxyVZData, proxyVZSlots, averagingPeriod.averagingOption)) {
        if (proxyVZSlots.length > 1) tableProxyVZephyrMetDataTitles = ['Modelled Wind Speed (m/s) (A)', 'Modelled Wind Speed (m/s) (B)', 'Modelled Wind Direction (°) (A)', 'Modelled Wind Direction (°) (B)', 'Modelled Rainfall (mm/hr) (A)', 'Modelled Rainfall (mm/hr) (B)'];
        else tableProxyVZephyrMetDataTitles = ['Modelled Wind Speed (m/s)', 'Modelled Wind Direction (°)', 'Modelled Rainfall (mm/hr)'];
      }
      const { dateTime: { data: dateTimeValues } } = slots.length > 0 ? averagingOptionData[slots[0]] : proxyVZData[averagingPeriod.averagingOption][proxyVZSlots[0]];
      dateTimeValues.forEach((time: Date | string, idx: number) => {
        const localDate = moment(
          convertUnitTimeToLocalTime(time.toString().split('+')[0]),
        ).format('DD-MM-YYYY');
        const localTime = moment(
          convertUnitTimeToLocalTime(time.toString().split('+')[0]),
        ).format('HH:mm:ss');
        const tableRowData: any = [localDate, localTime, time, latList[idx] ?? 'N/A', lonList[idx] ?? 'N/A'];
        species.forEach((specie: { [key: string]: number }) => {
          const key = Object.keys(specie)[0];
          const value = Object.values(specie)[0];
          if (value !== 3) {
            if (value === 0 || value === 1) {
              if (slots.length === 0) {
                value === 0 ? tableRowData.push('N/A', 'N/A') : tableRowData.push('N/A');
              }
              else if (slots.length === 1) {
                if (key in averagingOptionData[slots[0]] && typeof averagingOptionData[slots[0]][key].data[idx] === 'number') {
                  value === 0 ? tableRowData.push(averagingOptionData[slots[0]][key].data[idx], selectedPollutants.find((pollutant: Pollutant) => pollutant.dataIdentifier === key).HTMLShortUnitLabel) : tableRowData.push(averagingOptionData[slots[0]][key].data[idx]);
                } else {
                  value === 0 ? tableRowData.push('N/A', selectedPollutants.find((pollutant: Pollutant) => pollutant.dataIdentifier === key).HTMLShortUnitLabel) : tableRowData.push('N/A');
                }
              } else {
                if (key in averagingOptionData[slots[0]] && typeof averagingOptionData[slots[0]][key].data[idx] === 'number' && key in averagingOptionData[slots[1]] && typeof averagingOptionData[slots[1]][key].data[idx] === 'number') {
                  value === 0 ? tableRowData.push(averagingOptionData[slots[0]][key].data[idx], averagingOptionData[slots[1]][key].data[idx], selectedPollutants.find((pollutant: Pollutant) => pollutant.dataIdentifier === key).HTMLShortUnitLabel) : tableRowData.push(averagingOptionData[slots[0]][key].data[idx], averagingOptionData[slots[1]][key].data[idx]);
                } else if (key in averagingOptionData[slots[0]] && typeof averagingOptionData[slots[0]][key].data[idx] === 'number') {
                  value === 0 ? tableRowData.push(averagingOptionData[slots[0]][key].data[idx], 'N/A', selectedPollutants.find((pollutant: Pollutant) => pollutant.dataIdentifier === key).HTMLShortUnitLabel) : tableRowData.push(averagingOptionData[slots[0]][key].data[idx], 'N/A');
                } else if (key in averagingOptionData[slots[1]] && typeof averagingOptionData[slots[1]][key].data[idx] === 'number') {
                  value === 0 ? tableRowData.push('N/A', averagingOptionData[slots[1]][key].data[idx], selectedPollutants.find((pollutant: Pollutant) => pollutant.dataIdentifier === key).HTMLShortUnitLabel) : tableRowData.push('N/A', averagingOptionData[slots[1]][key].data[idx]);
                } else {
                  value === 0 ? tableRowData.push('N/A', 'N/A', selectedPollutants.find((pollutant: Pollutant) => pollutant.dataIdentifier === key).HTMLShortUnitLabel) : tableRowData.push('N/A', 'N/A');
                }
              }
            } else {
              if (averagingOptionData['head'] && key in averagingOptionData['head'] && typeof averagingOptionData['head'][key].data[idx] === 'number') {
                tableRowData.push(averagingOptionData['head'][key].data[idx]);
              } else {
                tableRowData.push('N/A');
              }
            }
          } else {
            if (tableProxyVZephyrMetDataTitles.length > 0) {
              if (proxyVZSlots.length === 0) {
                tableRowData.push('N/A');
              }
              else if (proxyVZSlots.length === 1) {
                if (key in proxyVZData[averagingPeriod.averagingOption][proxyVZSlots[0]] && typeof proxyVZData[averagingPeriod.averagingOption][proxyVZSlots[0]][key].data[idx] === 'number') {
                  tableRowData.push(proxyVZData[averagingPeriod.averagingOption][proxyVZSlots[0]][key].data[idx]);
                } else {
                  tableRowData.push('N/A');
                }
              } else {
                if (key in proxyVZData[averagingPeriod.averagingOption][proxyVZSlots[0]] && typeof proxyVZData[averagingPeriod.averagingOption][proxyVZSlots[0]][key].data[idx] === 'number' && key in proxyVZData[averagingPeriod.averagingOption][proxyVZSlots[1]] && typeof proxyVZData[averagingPeriod.averagingOption][proxyVZSlots[1]][key].data[idx] === 'number') {
                  tableRowData.push(proxyVZData[averagingPeriod.averagingOption][proxyVZSlots[0]][key].data[idx], proxyVZData[averagingPeriod.averagingOption][proxyVZSlots[1]][key].data[idx]);
                } else if (key in proxyVZData[averagingPeriod.averagingOption][proxyVZSlots[0]] && typeof proxyVZData[averagingPeriod.averagingOption][proxyVZSlots[0]][key].data[idx] === 'number') {
                  tableRowData.push(proxyVZData[averagingPeriod.averagingOption][proxyVZSlots[0]][key].data[idx], 'N/A');
                } else if (key in proxyVZData[averagingPeriod.averagingOption][proxyVZSlots[1]] && typeof proxyVZData[averagingPeriod.averagingOption][proxyVZSlots[1]][key].data[idx] === 'number') {
                  tableRowData.push('N/A', proxyVZData[averagingPeriod.averagingOption][proxyVZSlots[1]][key].data[idx]);
                } else {
                  tableRowData.push('N/A', 'N/A');
                }
              }
            }
          }
        });
        tableData.push([...tableRowData]);
      });

    } else {
      // push no data for selected time average
    }
  } else {
    // push no data for selected time period
  }
  const tableTitles: string[] = [...tableTitlesBeforePollutants, ...tablePollutantsTitles, ...tableMetDataTitles, ...tableProxyVZephyrMetDataTitles];

  return { tableData, tableTitles };
};

export {
  pointDataPackager,
  saDataPackager,
  windDataPackager,
  windRoseDataPackagerFromMetData,
  categorizeWindDataByDirectionAndSpeed,
  plotlyChartDataGenerator,
  plotlyTHPDataGenerator,
  plotlyWindDirectionSpeedGenerator,
  plotlyPrecipitationGenerator,
  plotlyMinMaxMeanStatisticsGenerator,
  summaryTableGenerator,
  deviceCSVGenerator,
  plotlyPollutantComparisonGenerator,
  comparisonViewCSVGenerator,
  getSourceApportionmentConfigurations
};