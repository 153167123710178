// Package Imports
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

// Const Imports
import { RemoveZephyrModalContent } from '../../utils/consts';
// Type Imports
import { Zephyr, VZephyrsConfig } from '../../utils/interface';

import { addZephyrs } from '../../actions/zephyrs';
import { setContactModalOn } from '../../actions/modal';
// Component Interfaces
interface removeZephyrModalProps {
  setSelectedVZephyrToDelete: Function;
  unSubSelectedVZephyr: Function;
  selectedVZephyrToDelete: Zephyr | null;
}

export interface AddZephyrForm {
  name: string;
}

// Component
const RemoveZephyrModal = ({
  selectedVZephyrToDelete,
  setSelectedVZephyrToDelete,
  unSubSelectedVZephyr,
}: removeZephyrModalProps) => {
  const { t: translate } = useTranslation();

  const confirmDelete = async (e: React.SyntheticEvent) => {
    e.preventDefault();
  };

  return (
    <div className="modal-container zephyr-modal-container d-flex flex-column">
      <button
        className="button close close-modal"
        onClick={() => setSelectedVZephyrToDelete(null)}
        type="button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 24 24"
          fill="#000"
        >
          <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
        </svg>
      </button>
      <h4>{translate('RemoveZephyrHeaderText')}</h4>
      <form onSubmit={(e) => confirmDelete(e)}>
        <div className="form-content-container pb-2">
          <div className="row pb-5">
            <p>
              {translate('confirmationTextPrefix')}
              {selectedVZephyrToDelete?.name || ''}{' '}
              {translate('confirmationTextPostFix')}
            </p>
            <p className="text-warning"> {translate('warningText')}</p>
          </div>

          <div className="row d-flex justify-content-between">
            <button
              type="button"
              className="button tertiary"
              onClick={() => setSelectedVZephyrToDelete(null)}
            >
              {translate('RemoveZephyrSecondaryCta')}
            </button>

            <button
              className="button primary"
              onClick={(e) => {
                unSubSelectedVZephyr();
              }}
            >
              {translate('primaryCta')}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

// Redux
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addZephyrs,
      setContactModalOn,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(RemoveZephyrModal);
