import React, { Suspense, useMemo } from 'react';
import { ChartProps } from '../../../utils/interface';
import { ChartTitleBar } from '../DataAnalyticsChartCtas';

const networkViewComponents: any = {
  networkViewMap: {
    component: React.lazy(() => import('../Charts/MiniMap')),
    // Data Analytics version 2.1- add chart widget. the below will be use as a preview with text...
    image: '/path/to/map/image.png',
    title: 'Network Map',
    description: 'Display the network map with relevant information.',
    html: '<div>Network Map</div>',
  },
  networkViewParticulates: {
    component: React.lazy(() => import('../Charts/MinMaxMeanStatisticsChartPlotly')),
  },
  networkViewGases: {
    component: React.lazy(() => import('../Charts/MinMaxMeanStatisticsChartPlotly')),
  },
  networkViewPollutantSummary: {
    component: React.lazy(() => import('../Charts/PollutantSummary')),
  },
};

const deviceViewComponents: any = {
  // weatherWindRose: {
  //   component: React.lazy(() => import('../Charts/WindChart')),
  // },
  precipitation: {
    component: React.lazy(() => import('../Charts/Precipitation')),
  },
  map: { component: React.lazy(() => import('../Charts/MiniMap')) },
  // pollutantAvg: {
  //   component: React.lazy(() => import('../Charts/PollutantAvg')),
  // },
  particulate: {
    component: React.lazy(() => import('../Charts/PollutantChartPlotly')),
  },
  gases: {
    component: React.lazy(() => import('../Charts/PollutantChartPlotly')),
  },
  thpChartPlotly: {
    component: React.lazy(() => import('../Charts/THPChartPlotly')),
  },
  windSpeedDirChartPlotly: {
    component: React.lazy(() => import('../Charts/WindSpeedDirChartPlotly')),
  },
  gasAverages: {
    component: React.lazy(() => import('../Charts/PollutantAverages')),
  },
  particulateAverages: {
    component: React.lazy(() => import('../Charts/PollutantAverages')),
  },
  sourceApportionmentNO2: {
    component: React.lazy(() => import('../Charts/SourceApportionmentPieChart')),
  },
  sourceApportionmentPM25: {
    component: React.lazy(() => import('../Charts/SourceApportionmentPieChart')),
  },
};

export const lazyComponents = {
  ...networkViewComponents,
  ...deviceViewComponents,
};


const DynamicChart: React.FC<{ type: string | undefined; props?: ChartProps; isDragging?: boolean; draggedItem?: string | null }> = React.memo(({ type = '', isDragging, ...props }) => {
  const { selectedView, unitHistories, comparisonViewZephyrs, draggedItem } = props as ChartProps;

  const ChartComponent = useMemo(() => {
    if (selectedView === 'comparisonView') {
      lazyComponents[type] = {
        component: React.lazy(() => import('../Charts/ComparisonPollutant'))
      };
    }
    return lazyComponents[type]?.component;
  }, [unitHistories, comparisonViewZephyrs]);

  if (!ChartComponent) {
    // Handle unrecognized chart type
    return (
      <div className={`chart ${type} p-0`}>
        <ChartTitleBar title={`chart ${type}`} />
        <div className="chart center-text p-0">Unknown chart: {type}</div>
      </div>
    );
  }

  let title: string | undefined;
  if (type === 'gasAverages') {
    title = 'Gas Averages';
  } else if (type === 'particulateAverages') {
    title = 'Particulate Averages';
  }
  const p = {
    ...props,
    title,
  };

  return (
    <Suspense fallback={<div className="chart center-text">Loading...</div>}>
      <div className={`chart ${type} ${selectedView === 'comparisonView' ? 'comparisonView' : undefined}`}>
        <ChartComponent {...p} type={type} isDragging={isDragging} draggedItem={draggedItem} />
      </div>
    </Suspense>
  );
});

export default DynamicChart;
