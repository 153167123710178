// Types Imports
import { MAPS_LOCATION_LOADED } from '../actions/types';

// Type Safety
interface MapsLocationLoaded {
  mapsLoaded: boolean;
}

interface mapLocationLoaded {
  type: typeof MAPS_LOCATION_LOADED;
  payload: boolean;
}

// State
const initialState: MapsLocationLoaded = {
  mapsLoaded: false,
};

// Reducer
export default function (state = initialState, action: mapLocationLoaded) {
  const { type, payload } = action;

  switch (type) {
    case MAPS_LOCATION_LOADED:
      return {
        ...state,
        mapsLoaded: payload,
      };
    default:
      return state;
  }
}
