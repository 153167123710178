// Types Imports
import { Dispatch } from 'redux';
import { SET_MAPPAIR_POINT_VALUE_HISTORY } from './types';

// Action Creators
export const setMapAirPointValueHistory = (payload: any) => (
  dispatch: Dispatch,
) => {
  dispatch({
    type: SET_MAPPAIR_POINT_VALUE_HISTORY,
    payload,
  });
};
