const generateCSVName = (
  unitHistories: any,
  name?: string,
  selectedWeatherZephyr?: any,
  selectedView?: string
) => {
  const { historiesList } = unitHistories;
  const { id } = selectedWeatherZephyr;
  const { selectedAveragingPeriod: { labelHTML } } = Object.values(historiesList[0])[0] as any;
  const formattedAveraginOption = `${labelHTML.split(' ')[0]}${labelHTML.toLowerCase().includes('min') ? 'min' : 'hr'}`;
  const {
    selectedDate: { start, end },
  } = historiesList[0][Object.keys(historiesList[0])[0]];
  return `${selectedView === 'deviceView' ? `device_${id}` : selectedView === 'networkView' ? 'network' : 'comparison'}_${start}_${end}_${formattedAveraginOption}.${selectedView !== 'comparisonView' ? 'csv' : 'zip'}`
};

const splitByFirstNumber = (pollutant: string) => {
  let result = pollutant.match(/\d/);

  // If a number is found, split the string at that index
  if (result) {
    let index = result.index;
    let part1 = pollutant.slice(0, index);
    let part2 = pollutant.slice(index);
    return [part1, part2];
  }
  return pollutant;
};

const defaultLayoutGenerator = (isPublic: boolean, screenWidth: number) => {
  // Logic to choose different layouts based on screen width (e.g., 767px for mobile)
  const isMobile = screenWidth <= 767;

  if (isMobile && isPublic) {
    // Return the mobile layout if screen width is 767px or below
    return {
      items: [
        {
          w: 12,
          h: 8,
          x: 0,
          y: 0,
          i: '37e4d98c-21fa-4a58-bfec-fdf0003a3fdb', // map
          moved: false,
          static: false,
        },
        {
          w: 12,
          h: 8,
          x: 0,
          y: 8,
          i: '15f7ede1-8fa9-410a-9f84-98dad5a9877f', // gases
          moved: false,
          static: false,
        },
        {
          w: 12,
          h: 8,
          x: 0,
          y: 16,
          i: 'abed7973-655f-4083-8687-3423c2e57b03', // particulates
          moved: false,
          static: false,
        },
      ],
      layoutMap: [
        {
          uid: '15f7ede1-8fa9-410a-9f84-98dad5a9877f',
          name: 'gases',
          i: '15f7ede1-8fa9-410a-9f84-98dad5a9877f',
        },
        {
          uid: 'abed7973-655f-4083-8687-3423c2e57b03',
          name: 'particulate',
          i: 'abed7973-655f-4083-8687-3423c2e57b03',
        },
        {
          uid: '37e4d98c-21fa-4a58-bfec-fdf0003a3fdb',
          name: 'map',
          i: '37e4d98c-21fa-4a58-bfec-fdf0003a3fdb',
        },
      ],
    };
  }

  // Default or desktop layout for wider screens
  return isPublic
    ? {
      items: [
        {
          w: 8,
          h: 8,
          x: 0,
          y: 0,
          i: '15f7ede1-8fa9-410a-9f84-98dad5a9877f',
          moved: false,
          static: false,
        },
        {
          w: 8,
          h: 8,
          x: 0,
          y: 8,
          i: 'abed7973-655f-4083-8687-3423c2e57b03',
          moved: false,
          static: false,
        },
        {
          w: 4,
          h: 16,
          x: 8,
          y: 0,
          i: '37e4d98c-21fa-4a58-bfec-fdf0003a3fdb',
          moved: false,
          static: false,
        },
      ],
      layoutMap: [
        {
          uid: '15f7ede1-8fa9-410a-9f84-98dad5a9877f',
          name: 'gases',
          i: '15f7ede1-8fa9-410a-9f84-98dad5a9877f',
        },
        {
          uid: 'abed7973-655f-4083-8687-3423c2e57b03',
          name: 'particulate',
          i: 'abed7973-655f-4083-8687-3423c2e57b03',
        },
        {
          uid: '37e4d98c-21fa-4a58-bfec-fdf0003a3fdb',
          name: 'map',
          i: '37e4d98c-21fa-4a58-bfec-fdf0003a3fdb',
        },
      ],
    }
    : {
      items: [
        // map
        {
          w: 12,
          h: 8,
          x: 8,
          y: 0,
          i: '37e4d98c-21fa-4a58-bfec-fdf0003a3fdb',
          moved: false,
          static: false,
          maxH: 18,
          minH: 3,
          minW: 4
        },
        // gases
        {
          w: 8,
          h: 10,
          x: 0,
          y: 8,
          i: '15f7ede1-8fa9-410a-9f84-98dad5a9877f',
          moved: false,
          static: false,
          maxH: 18,
          minH: 4,
          minW: 4,
        },
        // gas averages
        {
          w: 4,
          h: 4,
          x: 8,
          y: 8,
          i: 'gas-averages',
          moved: false,
          static: false,
          maxH: 4,
          minH: 4,
          minW: 4,
        },
        // source apportionment no2
        {
          w: 4,
          h: 6,
          x: 8,
          y: 12,
          i: '4167415b-25f5-464d-803f-5e904e219a12',
          moved: false,
          static: false,
          maxH: 6,
          minH: 6,
          minW: 4
        },

        // particulates
        {
          w: 8,
          h: 10,
          x: 0,
          y: 18,
          i: 'abed7973-655f-4083-8687-3423c2e57b03',
          moved: false,
          static: false,
          maxH: 18,
          minH: 4,
          minW: 4,
        },
        // particulates averages
        {
          w: 4,
          h: 4,
          x: 8,
          y: 18,
          i: 'particulate-averages',
          moved: false,
          static: false,
          maxH: 4,
          minH: 4,
          minW: 4,
        },
        // source apportionment pm2.5
        {
          w: 4,
          h: 6,
          x: 8,
          y: 22,
          i: '4167415b-25f5-464d-803f-5e904e2q1912',
          moved: false,
          static: false,
          maxH: 6,
          minH: 6,
          minW: 4,
        },
        // precipitation
        {
          w: 4,
          h: 8,
          x: 8,
          y: 28,
          i: '5ba2a578-9c05-4b26-87ca-01cde2c41a69',
          moved: false,
          static: false,
          maxH: 18,
          minH: 4,
          minW: 4,
        },
        // thpChartPlotly
        {
          w: 4,
          h: 8,
          x: 0,
          y: 28,
          i: 'b19635e4-5ae9-43f1-beff-b7a4baa01a71',
          moved: false,
          static: false,
          maxH: 18,
          minH: 4,
          minW: 4,
        },
        // windSpeedDirChartPlotly
        {
          w: 4,
          h: 8,
          x: 4,
          y: 28,
          i: '4167415b-25f5-464d-803f-5e904e2483a2',
          moved: false,
          static: false,
          maxH: 18,
          minH: 4,
          minW: 4,
        },
      ],
      layoutMap: [
        {
          uid: 'gas-averages',
          name: 'gasAverages',
          i: 'gas-averages',
        },
        {
          uid: 'particulate-averages',
          name: 'particulateAverages',
          i: 'particulate-averages',
        },

        {
          uid: '5ba2a578-9c05-4b26-87ca-01cde2c41a69',
          name: 'precipitation',
          i: '5ba2a578-9c05-4b26-87ca-01cde2c41a69',
        },
        {
          uid: '37e4d98c-21fa-4a58-bfec-fdf0003a3fdb',
          name: 'map',
          i: '37e4d98c-21fa-4a58-bfec-fdf0003a3fdb',
        },
        {
          uid: 'abed7973-655f-4083-8687-3423c2e57b03',
          name: 'particulate',
          i: 'abed7973-655f-4083-8687-3423c2e57b03',
        },
        {
          uid: 'b19635e4-5ae9-43f1-beff-b7a4baa01a71',
          name: 'thpChartPlotly',
          i: 'b19635e4-5ae9-43f1-beff-b7a4baa01a71',
        },
        {
          uid: '4167415b-25f5-464d-803f-5e904e2483a2',
          name: 'windSpeedDirChartPlotly',
          i: '4167415b-25f5-464d-803f-5e904e2483a2',
        },
        {
          uid: '15f7ede1-8fa9-410a-9f84-98dad5a9877f',
          name: 'gases',
          i: '15f7ede1-8fa9-410a-9f84-98dad5a9877f',
        },
        {
          uid: '4167415b-25f5-464d-803f-5e904e219a12',
          name: 'sourceApportionmentNO2',
          i: '4167415b-25f5-464d-803f-5e904e219a12',
        },
        {
          uid: '4167415b-25f5-464d-803f-5e904e2q1912',
          name: 'sourceApportionmentPM25',
          i: '4167415b-25f5-464d-803f-5e904e2q1912',
        },
      ],
      itemsNetworkView: [
        { w: 8, h: 9, x: 0, y: 0, i: '111', moved: false, static: false, maxH: 18, minH: 4, minW: 4 },
        { w: 8, h: 9, x: 0, y: 9, i: '222', moved: false, static: false, maxH: 18, minH: 4, minW: 4 },
        { w: 12, h: 10, x: 0, y: 18, i: '333', moved: false, static: false, maxH: 18, minH: 4, minW: 4 },
        { w: 4, h: 18, x: 8, y: 0, i: '444', moved: false, static: false, maxH: 18, minH: 4, minW: 4 },
      ],
      layoutMapNetworkView: [
        { uid: '111', name: 'networkViewGases', i: '111' },
        { uid: '222', name: 'networkViewParticulates', i: '222' },
        { uid: '333', name: 'networkViewPollutantSummary', i: '333' },
        { uid: '444', name: 'networkViewMap', i: '444' },
      ],
    };
};


export { generateCSVName, splitByFirstNumber, defaultLayoutGenerator };
