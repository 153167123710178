// Package Imports
import React from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

// Type Imports
import {
  AQLatestMeasurementDataTabs,
  DisplayConfig,
  Zephyr,
  ZephyrLastHourInd,
  ZephyrTypes,
} from '../../utils/interface';

// Util Imports
import { aqTabFinder } from '../../utils/functions/tabFinder';
import { convertRawData } from '../../utils/functions/chartHelper';

// Component Interfaces
interface AQUnitProps {
  displayConfig: DisplayConfig;
  isOn: boolean;
  tabs: AQLatestMeasurementDataTabs;
  zephyr: Zephyr;
}

export const checkIfSensorData = (zephyr: Zephyr) => {
  const isSensorData = !!(
    zephyr &&
    zephyr.averageOfLastHourOfData &&
    Object.values(zephyr.averageOfLastHourOfData).filter((val) => val !== null)
      .length
  );
  return isSensorData;
};

// Component
const AQUnit = ({ displayConfig, isOn, tabs, zephyr }: AQUnitProps) => {
  const { t: translate } = useTranslation();

  if (!zephyr) return null;
  return (
    <>
      {isOn ? (
        <>
          <h4>
            {zephyr.name}{' '}
            <span className="aq-period">
              <br />
              {translate('AQPeriod')}
            </span>
          </h4>
          {checkIfSensorData(zephyr) ? (
            <div className="species-table">
              {aqTabFinder(
                displayConfig,
                zephyr.averageOfLastHourOfData as ZephyrLastHourInd,
              )
                .filter(
                  (rawGroup) =>
                    rawGroup.species.length && tabs[rawGroup.context],
                )
                .map((group, idx, arr) => (
                  <div key={group.group}>
                    {group.species.length > 0 ? (
                      <div
                        className={`species-table-data ${
                          idx === arr.length - 1 ? 'last' : ''
                        }`}
                      >
                        {group.species.map((species) => (
                          <div key={species.label} className="aq-species-col">
                            <div className="aq-species">
                              <TableScore zephyr={zephyr} species={species} />
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
            </div>
          ) : (
            <>
              <div className="no-data">
                {zephyr.type === 0
                  ? !zephyr.userEndTimeDate
                    ? translate('AQNoData')
                    : translate('AQNoOwnership')
                  : translate('AQNoData')}
              </div>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AQUnit;

const TableScore = ({ zephyr, species }: any) => {
  const getData = () => {
    if (
      zephyr.averageOfLastHourOfData &&
      zephyr.averageOfLastHourOfData[species.label] !== null
    ) {
      return convertRawData(
        [zephyr.averageOfLastHourOfData[species.label]],
        species,
      )[0];
    }
    return 'NA';
  };

  const data = getData();
  if (zephyr.type === ZephyrTypes.virtual && data === -999) {
    return null;
  }

  return (
    <div className="table-score">
      {data}

      <div className="table-data-label">{parse(species.HTMLLabel)}</div>
      <div className="table-data-unit">
        <p className="unit">{parse(species.HTMLExposedUnitLabel)}</p>
      </div>
    </div>
  );
};
