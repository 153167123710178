// Package Imports
import React, { useEffect } from 'react';

// Component Imports
import DataSlots from './DataSlots';

// Util Imports
import { speciesFilterFinder } from '../../utils/functions/tabFinder';

// Type Imports
import {
  AveragingOptions,
  ChartData,
  SlotOptions,
  SpeciesDataLabels,
  Zephyr,
  ZephyrHistoryWithId,
} from '../../utils/interface';

// Component Interfaces
interface DataSpeciesFiltersProps {
  chartData: ChartData;
  handleSpeciesFilter: Function;
  progressBarOn: boolean;
  speciesFiltered: SpeciesDataLabels[];
  // Temporary Data Slot
  handleSlotChange: Function;
  curSlot: SlotOptions;
  timeAveragingOptions: AveragingOptions;
  unitHistories: ZephyrHistoryWithId | null;
  zephyr: Zephyr;
  isDSEEnabled: boolean;
}

const DataSpeciesFilters = ({
  chartData,
  handleSpeciesFilter,
  progressBarOn,
  speciesFiltered,
  handleSlotChange,
  curSlot,
  timeAveragingOptions,
  unitHistories,
  zephyr,
  isDSEEnabled,
}: DataSpeciesFiltersProps) => {

  return (
    <header className={`filter-legends ${isDSEEnabled ? 'dse-enabled' : ''}`}>
      <div>
        {speciesFilterFinder(chartData.datasets, speciesFiltered).map(
          (data, idx) => {
            return (
              <label key={`toggleChartsCheckbox${idx}`} className="checkbox">
                {data.label}
                <input
                  type="checkbox"
                  checked={!data.hidden}
                  onChange={() => {
                    handleSpeciesFilter(data.label);
                  }}
                  disabled={progressBarOn}
                />
                <span className="checkmark"></span>
              </label>
            );
          },
        )}
      </div>
      {/* Temporary data slots location until overplotting v2 */}
      {/* <div>
        <DataSlots
          handleSlotChange={handleSlotChange}
          curSlot={curSlot}
          progressBarOn={progressBarOn}
          timeAveragingOptions={timeAveragingOptions}
          unitHistories={unitHistories}
          zephyr={zephyr}
        />
      </div> */}
    </header>
  );
};

export default DataSpeciesFilters;
