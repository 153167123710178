import * as React from 'react';
import Tour, { ReactourStep } from 'reactour';
import { useSelector, useDispatch } from 'react-redux';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { ReduxState } from '../../utils/interface';

const stepStyle: React.CSSProperties = {
  height: 250,
  width: 330,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

type Props = {
  onEnd?: () => void;
};

export default function AlertsTour({ onEnd }: Props) {
  const tourRef = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const [isWaiting, setIsWaiting] = React.useState(false);
  const tourOpen = useSelector((state: ReduxState) => state.alertsTourOpen);
  const dispatch = useDispatch();

  const disableBody = (target: HTMLElement) => disableBodyScroll(target);
  const enableBody = (target: HTMLElement) => enableBodyScroll(target);

  const waitForElement = (selector: string, timeout = 10000) => {
    return new Promise((resolve, reject) => {
      const startTime = Date.now();

      const checkElement = () => {
        const element = document.querySelector(selector);
        if (element) {
          resolve(element);
        } else if (Date.now() - startTime > timeout) {
          reject(new Error(`Timed out waiting for element: ${selector}`));
        } else {
          requestAnimationFrame(checkElement);
        }
      };

      checkElement();
    });
  };

  const handleAsyncAction = async () => {
    const btn = document.getElementById('AAOCreateNew');
    if (btn) {
      setIsWaiting(true);
      btn.click();
      try {
        await waitForElement('[data-alerts-tour="step-3"]', 30000); // 30 seconds timeout

        const firstUnitButton = await waitForElement(
          '.air-alert-configurator-unit-list.add-units > li:first-child > button',
          10000,
        );
        if (firstUnitButton instanceof HTMLElement) {
          firstUnitButton.click();
        } else {
          throw new Error('First unit button not found');
        }
      } catch (error) {
        console.error('Timed out waiting for element to load', error);
        // Handle timeout (e.g., show an error message to the user)
      } finally {
        setIsWaiting(false);
      }
    }
  };

  const steps: ReactourStep[] = [
    {
      selector: '',
      content: () => (
        <div className="tour-content">
          Alerts allow you to set notifications when a parameter breaches
          specific threshold values.
        </div>
      ),
      style: stepStyle,
    },

    {
      selector: '[data-alerts-tour="step-2"]',
      position: 'center',
      content: () => (
        <div className="tour-content">
          Select “Create an alert” to add a new alert.
        </div>
      ),
      style: stepStyle,
      action() {
        onEnd?.();
      },
    },

    {
      selector: '[data-alerts-tour="step-3"]',
      // position: 'center',
      content: () => (
        <div className="tour-content">
          {isWaiting ? (
            <>
              <p>Loading... Please wait while we prepare the next step.</p>
            </>
          ) : (
            <>
              <p>
                Select the devices you wish to be included. Alerts are actioned
                at a device level so many devices can be added to one alert.
              </p>
            </>
          )}
        </div>
      ),
      style: stepStyle,
      action: () => {
        handleAsyncAction();
      },
    },

    {
      selector: '[data-alerts-tour="step-4"]',
      position: 'center',
      content: () => (
        <div className="tour-content">
          Select all the parameters you wish to alert on, along with the value
          and definition of greater or lower than. Alerts are actioned for each
          parameter, so feel free to add multiple parameters
        </div>
      ),
      action() {
        // dispatch(setChartViewMode('networkView'));
      },
      style: stepStyle,
    },

    {
      selector: '[data-alerts-tour="step-5"]',
      position: 'center',
      content: () => (
        <div className="tour-content">
          Choose the averaging period of the alerts threshold (e.g 15min =
          threshold based on a 15min time-weighted average)
        </div>
      ),
      action(node) {},
      style: stepStyle,
    },

    {
      selector: '[data-alerts-tour="step-6"]',
      position: 'center',
      content: () => (
        <div className="tour-content">Give the alert a memorable name.</div>
      ),
      action() {},
      style: stepStyle,
    },

    {
      selector: '[data-alerts-tour="step-7"]',
      position: 'center',
      content: () => (
        <div className="tour-content">
          Specify the alert recipients using email addresses or phone numbers,
          pressing the plus icon to confirm each entry.
        </div>
      ),
      style: stepStyle,
    },

    {
      selector: '[data-alerts-tour="step-8"]',
      position: 'center',
      content: () => (
        <div className="tour-content">
          Select “Create alert” to activate it.
        </div>
      ),
      style: stepStyle,
    },
    {
      selector: '[data-analysis-tour="step-9"]',
      position: 'center',
      content: () => (
        <div className="tour-content">
          Revisit this tour or the tours on other pages anytime from within the
          Help menu.
        </div>
      ),
      style: stepStyle,
    },
  ];

  return (
    <>
      <Tour
        ref={tourRef}
        steps={steps}
        isOpen={tourOpen || show}
        onRequestClose={() => {
          setShow(false);
          dispatch({ type: 'alerts_tour_toggle' });
        }}
        onAfterOpen={disableBody}
        onBeforeClose={(target) => {
          enableBody(target);
          onEnd?.();
        }}
        startAt={0}
        closeWithMask={false}
        disableInteraction
        disableKeyboardNavigation={isWaiting}
        disableDotsNavigation={isWaiting}
        showNavigationNumber={false}
        lastStepNextButton={<p style={{ margin: 0, fontSize: 16 }}>Finish</p>}
        badgeContent={(curr) => `${curr}/${steps.length}`}
        accentColor="#4e73c3"
        rounded={3}
        update={isWaiting.toString()}
        showButtons={!isWaiting}
      />
    </>
  );
}
