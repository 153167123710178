import { SET_THRESHOLDS } from '../actions/types';
import type { ThresholdsList } from '../utils/interface'
import { thresholdLimitsInitState } from './dataAnalytics';

interface SetThresholds {
  type: typeof SET_THRESHOLDS;
  payload?: any;
}

const initialState: ThresholdsList = {
  gases: thresholdLimitsInitState,
  particulate: thresholdLimitsInitState,
  networkViewGases: thresholdLimitsInitState,
  networkViewParticulates: thresholdLimitsInitState,
}

function thresholdsReducer(state = initialState, action: SetThresholds) {
  switch (action.type) {
    case SET_THRESHOLDS:
      if (typeof action.payload === 'function') {
        return action.payload(state);
      }
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default thresholdsReducer;