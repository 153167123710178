// Package Imports
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Component Imports
import Confirmation from '../shared_components/Confirmation';

// Util Imports
import utilsRequest from '../../utils/request';

// Type Imports
import {
  Feedback as FeedbackType,
  FeedbackQuestion,
  ReduxState,
} from '../../utils/interface';
import { connect } from 'react-redux';

// Component Interfaces
interface InitState {
  comments: string;
  email: string;
  score: number | null;
  scoreClicked: boolean;
  screen: number;
}

interface FeedbackQuestionsProps {
  feedback: FeedbackType;
  on: boolean;
  setFeedback: Function;
  setFeedbackIdx: Function;
  setFeedbackOff: Function;
  bearerToken: string | null;
}

// Component
const FeedbackQuestions = ({
  feedback,
  on,
  setFeedback,
  setFeedbackIdx,
  setFeedbackOff,
  bearerToken,
}: FeedbackQuestionsProps) => {
  const { t: translate } = useTranslation();

  // State
  const initState: InitState = {
    comments: '',
    email: '',
    score: null,
    scoreClicked: false,
    screen: 0,
  };

  const [comments, setComments] = useState(initState.comments);
  const [email, setEmail] = useState(initState.email);
  const [score, setScore] = useState(initState.score);
  const [scoreClicked, setScoreClicked] = useState(initState.scoreClicked);
  const [screen, setScreen] = useState(initState.screen);

  // Functions
  const handleConfirmationClose = async (state: boolean) => {
    if (!state) {
      await handleCloseModal();
      setFeedbackOff();
    }
  };

  const handleCloseModal = async () => {
    await handleFeedbackSubmit();
    setComments(initState.comments);
    setEmail(initState.email);
    setScore(initState.score);
    setFeedbackOff();
  };

  const handleFeedbackSubmit = async () => {
    if (scoreClicked && score) {
      const { mufId } = feedback.questions[feedback.idx];
      await utilsRequest.postUserFeedback(mufId, score, comments, email, bearerToken);
      localStorage.removeItem('feedbackDueDate');
      const nextIdx = feedback.idx + 1;
      if (nextIdx <= feedback.questions.length - 1) {
        setFeedbackIdx(nextIdx);
      } else {
        const feedbackQuestions: FeedbackQuestion[] = await utilsRequest.getUserFeedbackQuestions(bearerToken);
        if (feedbackQuestions.length > 0) {
          const sortedFeedbackQuestions = feedbackQuestions.sort((a, b) =>
            a.order > b.order ? 1 : -1,
          );
          setFeedbackIdx(0);
          setFeedback(sortedFeedbackQuestions);
        } else {
          setFeedback([]);
        }
      }
    }
  };

  const handleScore = (s: number) => {
    setScore(s);
    setScoreClicked(true);
    handleScreenIncrement();
  };

  const handleScreenIncrement = () => {
    const updatedScreen = screen + 1;
    setScreen(updatedScreen);
  };

  return (
    <>
      {on ? (
        <div className="feedback-questions">
          {screen !== 3 ? (
            <div className="feedback-questions-header">
              <h5>{translate('feedbackQuestionsHeader')}</h5>
              <button
                className="button close close-modal"
                onClick={() => {
                  localStorage.removeItem('feedbackDueDate');
                  handleCloseModal();
                }}
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 24 24"
                  fill="#fff"
                >
                  <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
                </svg>
              </button>
            </div>
          ) : (
            <></>
          )}
          <div className="feedback-content">
            {screen === 0 ? (
              <>
                {feedback.questions[feedback.idx].question}
                <ul className="feedback-score">
                  {feedback.questions[feedback.idx].name === 'NPS' ? (
                    <>
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((s) => (
                        <li key={s}>
                          <button
                            className={`${feedback.questions[feedback.idx].name
                              } ${typeof score === 'number' && s <= score
                                ? 'selected'
                                : 'unselected'
                              }`}
                            onMouseEnter={() => setScore(s)}
                            onFocus={() => setScore(s)}
                            onClick={() => handleScore(s)}
                            type="button"
                          >
                            {s}
                          </button>
                        </li>
                      ))}
                    </>
                  ) : (
                    <>
                      {[1, 2, 3, 4, 5].map((s) => (
                        <li key={s}>
                          <button
                            onMouseEnter={() => setScore(s)}
                            onFocus={() => setScore(s)}
                            onClick={() => handleScore(s)}
                            type="button"
                            className={feedback.questions[feedback.idx].name}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="64"
                              height="56"
                              viewBox="0 0 24 24"
                              fill={
                                typeof score === 'number' && s <= score
                                  ? '#FFD701'
                                  : '#E1E4E6'
                              }
                            >
                              <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
                            </svg>
                          </button>
                        </li>
                      ))}
                    </>
                  )}
                </ul>
                {feedback.questions[feedback.idx].name === 'NPS' ? (
                  <div className="score-labels">
                    <div>{translate('feedbackScoreLabelsLow')}</div>
                    <div>{translate('feedbackScoreLabelsHigh')}</div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : screen === 1 ? (
              <>
                {feedback.questions[feedback.idx].commentsQuestion}
                <textarea
                  onChange={(e) => setComments(e.target.value)}
                  value={comments}
                  className="comments-input"
                  placeholder={translate('feedbackCommentsPlaceholder')}
                />
                <button
                  onClick={() => handleScreenIncrement()}
                  className="next-button button primary send-modal confirmation-button"
                >
                  {translate('feedbackButtonNext')}
                </button>
              </>
            ) : screen === 2 ? (
              <div className="email-content">
                <div className="email-content-header">
                  <div>{translate('feedbackEmailHeader')}</div>
                  <div className="email-content-optional">Optional</div>
                </div>
                <input
                  type="text"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className="email-input"
                />
                <button
                  onClick={() => handleScreenIncrement()}
                  className="next-button button primary send-modal confirmation-button"
                >
                  {translate('feedbackButtonNext')}
                </button>
              </div>
            ) : (
              <Confirmation
                buttonText="Done"
                confirmationOpen={handleConfirmationClose}
                header={translate('feedbackConfirmationHeader')}
                showTick
                text={translate('feedbackConfirmationText')}
              />
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  bearerToken: state.auth.bearerToken,
});

export default connect(mapStateToProps, {})(FeedbackQuestions);
