// Package Imports
import React from 'react';
import { useTranslation } from 'react-i18next';

// Component Imports
import AQWindChart from './AQWindChart';

// Type Imports
import { WindRoseData } from '../../utils/interface';

// Component Interfaces
interface AQWindProps {
  isOn: boolean;
  windData: WindRoseData[] | null;
}

// Component
const AQWind = ({ isOn, windData }: AQWindProps) => {
  const { t: translate } = useTranslation();

  // State
  return (
    <>
      {isOn && windData ? (
        <>
          <div className="aqm-container">
            <div className="aqm-header">
              <h4>
                {translate('AQChartHeaderOne')}
                <br />
                <span className="aq-model-unit">
                  {translate('AQChartSubheader')}
                </span>
              </h4>
            </div>
          </div>
          <AQWindChart windData={windData} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AQWind;
