// Types Imports
import {
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  USER_LOADED,
  USERINFO_LOADED,
  SET_MASTER_AUTH_BEARER_TOKEN,
} from '../actions/types';

// Type Imports
import { UserInfo } from '../utils/interface';

// Type Safety
interface AuthState {
  isAuthenticated: null | boolean;
  loading: boolean;
  loginFailed: boolean;
  token: null | string;
  user: null | string;
  userInfo: UserInfo;
  bearerToken: null | string;
}

interface Auth {
  type:
  | typeof AUTH_ERROR
  | typeof LOGIN_FAIL
  | typeof LOGIN_SUCCESS
  | typeof LOGOUT
  | typeof USER_LOADED
  | typeof USERINFO_LOADED
  | typeof SET_MASTER_AUTH_BEARER_TOKEN;
  payload: { userInfo: UserInfo; user: string; key: string; token: string, bearerToken: string };
}

// State
const initialState: AuthState = {
  isAuthenticated: null,
  loading: true,
  loginFailed: false,
  token: localStorage.getItem('token'),
  user: localStorage.getItem('user'),
  userInfo: {
    appIntro: null,
    authorityLink: '',
    caching: 0,
    info: '',
    isInternal: false,
    isPublic: false,
    logo: null,
    maxLat: 0,
    maxLng: 0,
    minLat: 0,
    minLng: 0,
    projectLink: '',
    showDetailedZephyrInfo: false,
    showSmileyFaces: false,
    subtitle: '',
    startingLat: 0,
    startingLng: 0,
    startingZoom: 10,
    today: false,
    todayCustomInfo: null,
    todaySpecies: null,
    title: '',
    userCustomInfo: { sections: [] },
    userStandardInfo: { sections: [] },
    mappair: false,
  },
  bearerToken: null,
};

// Reducer
export default function (state = initialState, action: Auth) {
  const { type, payload } = action;

  switch (type) {
    case USERINFO_LOADED:
      return {
        ...state,
        userInfo: payload.userInfo,
      };

    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        userInfo: payload.userInfo,
      };

    case LOGIN_SUCCESS:
      localStorage.setItem('key', payload.key);
      localStorage.setItem('user', payload.user);
      return {
        ...state,
        ...payload,
        loginFailed: false,
        isAuthenticated: true,
        loading: false,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
      return {
        ...state,
        loginFailed: true,
      };
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('key');
      localStorage.removeItem('user');
      localStorage.removeItem('data');
      localStorage.removeItem('isPublic');

      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        userInfo: null,
      };
    case SET_MASTER_AUTH_BEARER_TOKEN:
      return {
        ...state,
        bearerToken: payload,
      }
    default:
      return state;
  }
}
