// Package Imports
import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import Spinner from '../shared_components/Spinner';

// Const Imports
import { config } from '../../utils/consts';

// Type Imports
import { ReduxState, Version } from '../../utils/interface';

// Component Interfaces
interface HeaderVersionHistoryModalProps {
  handleVersionDialog: Function;
  versionHistory: Version[];
}

// Component
const HeaderVersionHistoryModal = ({
  handleVersionDialog,
  versionHistory,
}: HeaderVersionHistoryModalProps) => {
  const { t: translate } = useTranslation();

  return versionHistory ? (
    <div className="modal-container header-version-history-modal-container">
      <h4>{translate('versionHistoryHeader')}</h4>
      <div className="modal-text">
        {versionHistory
          .slice()
          .reverse()
          .map((v) => (
            <div key={v.versionId}>{parse(v.versionNotes)}</div>
          ))}
      </div>
      <button
        className="button close close-modal"
        onClick={() => handleVersionDialog(false)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 24 24"
          fill="#000"
        >
          <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
        </svg>
      </button>
    </div>
  ) : <Spinner on={true} />;
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  versionHistory: state.setVersions,
});

export default connect(mapStateToProps, {})(HeaderVersionHistoryModal);
