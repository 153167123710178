// Package Imports
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Util Imports
import { alertsFormatter } from '../../utils/functions/alertsFormatter';

// Type Imports
import { AirAlerts, BatteryAlert, DisplayConfig } from '../../utils/interface';

// Type Safety
interface AirAlertsOverviewProps {
  airAlerts: AirAlerts | null;
  changeAlertStatus: Function;
  deleteAlert: Function;
  displayConfig: DisplayConfig;
  setAlertConfigurator: Function;
  startNewAlert: Function;
  asideOn: boolean;
}

// Component
const AirAlertsOverview = ({
  airAlerts,
  changeAlertStatus,
  deleteAlert,
  displayConfig,
  setAlertConfigurator,
  startNewAlert,
  asideOn
}: AirAlertsOverviewProps) => {
  const { t: translate } = useTranslation();

  // Functions

  const asterixOutPhoneDigits = (phoneNumber: string[]): string[] => {
    return phoneNumber.map((pNum: string) => {
      const firstThreeDigits = pNum.slice(0, 3);
      const phoneNumSplit = pNum.split('').slice(firstThreeDigits.length, pNum.length - 3); // create an array of digits and removing the last 3
      const lastThreeDigits = pNum.slice(-3); // getting last three digits
      const digitsArr = phoneNumSplit.map((digit: string) => { digit = '*'; return digit; }); // replace each digit with asterix
      const hashedNumber = firstThreeDigits.concat('', digitsArr.join('').concat('', lastThreeDigits)); // convert the array of digits to string and concatenate it with the last three digits
      return hashedNumber;
    });
  };

  return (
    <div className="air-alerts-overview">
      <div className={`air-alerts-overview-header ${!asideOn ? 'inventory-expanded-header' : ''}`}>
        <h4>{translate('AAOHeader')}</h4>
        <button onClick={() => startNewAlert()} data-alerts-tour="step-2" id="AAOCreateNew">
          {translate('AAOCreateNew')}
        </button>
      </div>
      <div className="air-alerts-overview-table">
        <table>
          <tbody>
            <tr>
              <th className="air-alerts-name-column">
                {translate('AAONameColumn')}
              </th>
              <th className="air-alerts-name-column">Zephyr Numbers</th>
              <th className="air-alerts-data-column">
                {translate('AAOAlertDataColumn')}
              </th>
              <th className="air-alerts-status-column">
                {translate('AAOStatusColumn')}
              </th>
              <th className="air-alerts-status-column">Targets</th>
              <th className="air-alers-actions-column">
                {translate('AAOActionscolumn')}
              </th>
            </tr>
            {airAlerts && airAlerts.alerts.length ? (
              <>
                {airAlerts.alerts.map((alert) => (
                  // TODO: id will exist once the alert is created and id is returned from API
                  <tr key={alert.id as any}>
                    <td>{alert.name}</td>
                    <td>{alert.units.join(',')}</td>
                    <td className="air-alert-description">
                      {alertsFormatter(alert, displayConfig)}
                    </td>
                    <td>
                      <label className="toggle">
                        <input
                          type="checkbox"
                          checked={alert.status}
                          onChange={() => changeAlertStatus(alert.id)}
                        />
                        <span className="toggle-slider"></span>
                      </label>
                    </td>
                    <td>{alert.email.join('\n')}<br /><div style={{ maxHeight: 250, overflowX: 'hidden', overflowY: 'auto' }}>{asterixOutPhoneDigits(alert.phoneNumbers).join(' | ')}</div></td>
                    <td>
                      <div className="air-alert-actions-container">
                        <svg
                          onClick={() => setAlertConfigurator(alert.id)}
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="21"
                          viewBox="0 0 24 24"
                        >
                          <path d="M18.311 2.828l2.861 2.861-15.033 15.032-2.859-2.862 15.031-15.031zm0-2.828l-16.873 16.872-1.438 7.127 7.127-1.437 16.873-16.873-5.689-5.689z" />
                        </svg>
                        <svg
                          onClick={() => deleteAlert(alert.id)}
                          width="21"
                          height="21"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm-14-2.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5h-14v16.5zm5-18.5h4v-1h-4v1z" />
                        </svg>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr className="center-content">
                <td colSpan={4}>{translate('AAONoAlerts')}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AirAlertsOverview;
