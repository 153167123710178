// Package Imports
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Component Imports
import AQColorscale from './AQColourscale';
import AQLatestData from './AQLatestData';
import AQModelData from './AQModelData';
import AnnualAveragesAccordion from './AnnualAveragesAccordion';

// Util Imports
import {
  pointDataPackager,
  saDataPackager,
  windRoseDataPackagerFromMetData,
} from '../../utils/functions/saDataPackager';
import { mappairSpeciesFinder } from '../../utils/functions/tabFinder';
import analyticsEventFirer from '../../utils/functions/analyticsEventFirer';

// Const Imports
import { gtmEventIdentifiers } from '../../utils/consts';

// Type Imports
import {
  AQLatestMeasurementDataTabs,
  AnnualAvgTabs,
  AQModelDataTabs,
  AQTabs,
  DisplayConfig,
  Loading,
  Overlay,
  PointValues,
  ReduxState,
  ShowAQMALayer,
  Stylegroup,
  UserInfo,
  Zephyr,
  AnnualAvgData,
  MapAirPointValueHistoryData,
} from '../../utils/interface';

// Component Interfaces
interface AQIIndexProps {
  curOverlayName: string;
  displayConfig: DisplayConfig;
  loading: Loading;
  mappairOptions: ShowAQMALayer;
  overlays: Overlay[];
  points: PointValues | null;
  stylegroups: Stylegroup[];
  threshold: string;
  userInfo: UserInfo;
  zephyr: Zephyr;
  annualAvgData: AnnualAvgData | null;
  mapAirPointValueHistory: MapAirPointValueHistoryData;
}

interface InitState {
  aqIsOn: AQTabs;
  aqlIsOn: AQLatestMeasurementDataTabs;
  aqmIsOn: AQModelDataTabs;
  aaIsOn: AnnualAvgTabs;
}

// View Component
const AQIIndex = ({
  curOverlayName,
  displayConfig,
  loading,
  mappairOptions,
  overlays,
  points,
  stylegroups,
  threshold,
  userInfo,
  zephyr,
  annualAvgData,
  mapAirPointValueHistory,
}: AQIIndexProps) => {
  // State
  const initState: InitState = {
    // accordion
    aqIsOn: {
      aqData: false,
      aqColourscale: userInfo.isPublic,
      aqModelData: true,
      annualAvg: true,
    },
    // tab
    aqlIsOn: {
      zephyr: false,
      met: false,
      other: false,
      chart: false,
    },
    aqmIsOn: {
      aqmSA: false,
      aqmTemp: false,
      aqmWind: false,
    },
    aaIsOn: {
      chart: false,
      list: false,
    },
  };

  const [aqIsOn, setAQIsOn] = useState(initState.aqIsOn);
  const [aqlIsOn, setAQLIsOn] = useState(initState.aqlIsOn);
  const [aqmIsOn, setAQMIsOn] = useState(initState.aqmIsOn);
  const [aaIsOn, setAAIsOn] = useState(initState.aaIsOn);

  // Consts
  const saData =
    mapAirPointValueHistory && mapAirPointValueHistory.saValue
      ? saDataPackager(displayConfig, mapAirPointValueHistory.saValue)
      : null;

  const curSpecies = mappairSpeciesFinder(
    curOverlayName,
    displayConfig,
    overlays,
  );

  const temporalDataset =
    mapAirPointValueHistory && mapAirPointValueHistory.valueHistory
      ? pointDataPackager(mapAirPointValueHistory.valueHistory, curSpecies)
      : null;

  const windDataset =
    mapAirPointValueHistory && mapAirPointValueHistory.valueHistory
      ? windRoseDataPackagerFromMetData(
          displayConfig,
          mapAirPointValueHistory.valueHistory,
        )
      : null;

  // Effects
  useEffect(() => {
    const updatedAQIsOn = { ...initState.aqIsOn, aqData: true };
    setAQIsOn(updatedAQIsOn);
  }, [zephyr]);

  // Functions
  const toggleAccordion = (tab: string) => {
    const updatedAQIsOn = { ...aqIsOn, [tab]: !aqIsOn[tab] };
    setAQIsOn(updatedAQIsOn);
    // Analytics
    if (!aqIsOn[tab]) {
      analyticsEventFirer(gtmEventIdentifiers.aqiIndexSwitch, tab);
    }
  };

  // change tab in accordion (Latest Measurement)
  const changeLatestMeasurementTab = (tab: string) => {
    const updatedAQLIsOn = { ...initState.aqlIsOn, [tab]: !aqlIsOn[tab] };
    setAQLIsOn(updatedAQLIsOn);
    // Analytics
    if (!aqlIsOn[tab]) {
      analyticsEventFirer(gtmEventIdentifiers.aqlIndexSwitch, tab);
    }
  };

  // change tab in accordion (MappAir Model Data)
  const changeMapAirModelTab = (tab: string) => {
    const updatedAQMIsOn = { ...initState.aqmIsOn, [tab]: !aqmIsOn[tab] };
    setAQMIsOn(updatedAQMIsOn);
    // Analytics
    if (!aqmIsOn[tab]) {
      const labelEnrichment = `${tab}_${curSpecies.dataIdentifier}`;
      analyticsEventFirer(gtmEventIdentifiers.aqmIndexSwitch, labelEnrichment);
    }
  };

  // change tab in accordion (MappAir Model Data)
  const changeMapAnnualAverageTab = (tab: string) => {
    const updatedTabisOn = { ...initState.aaIsOn, [tab]: !aaIsOn[tab] };
    setAAIsOn(updatedTabisOn);
    // Analytics
    if (!aaIsOn[tab]) {
      analyticsEventFirer(gtmEventIdentifiers.aqmIndexSwitch, tab);
    }
  };

  return (
    <>
      {!loading.initApp ? (
        <aside className="aqi-index tourAQTab">
          <nav className="index-dropdown">
            <ul>
              <AQColorscale
                activeOverlay={curOverlayName}
                activeStylegroup={threshold}
                toggleAccordion={toggleAccordion}
                isOn={aqIsOn.aqColourscale}
                isPublic={userInfo.isPublic}
                stylegroups={stylegroups}
              />
              {!userInfo.isPublic ? (
                <>
                  <AQLatestData
                    aqlIsOn={aqlIsOn}
                    toggleAccordion={toggleAccordion}
                    changeLatestMeasurementTab={changeLatestMeasurementTab}
                    isOn={aqIsOn.aqData}
                    zephyr={zephyr}
                  />
                  {mappairOptions.showOverlayLayer ? (
                    <>
                      <AQModelData
                        aqmIsOn={aqmIsOn}
                        changeMapAirModelTab={changeMapAirModelTab}
                        toggleAccordion={toggleAccordion}
                        curSpecies={curSpecies}
                        isOn={aqIsOn.aqModelData}
                        points={points}
                        saData={saData}
                        temporalModelData={temporalDataset}
                        windModelData={windDataset}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {mappairOptions.showAALayer ? (
                    <AnnualAveragesAccordion
                      aaIsOn={aaIsOn}
                      toggleAccordion={toggleAccordion}
                      changeMapAnnualAverageTab={changeMapAnnualAverageTab}
                      isOn={aqIsOn.annualAvg}
                      zephyr={zephyr}
                      points={points}
                      annualAvgData={annualAvgData}
                      curSpecies={curSpecies}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </ul>
          </nav>
        </aside>
      ) : (
        <></>
      )}
    </>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  zephyr: state.setZephyr.zephyr,
  mapAirPointValueHistory: state.setMapAirPointValueHistory,
});

export default connect(mapStateToProps, {})(AQIIndex);
