// Types Imports
import { SET_ANNUAL_AVERAGES } from '../actions/types';
import { AnnualAvgData } from '../utils/interface';
import { AnnualAvgDataInitialState } from '../utils/consts';

// Type Safety
interface setAnnualAverages {
  type: typeof SET_ANNUAL_AVERAGES;
  payload: AnnualAvgData;
}

// Reducer
export default function (
  state = AnnualAvgDataInitialState,
  action: setAnnualAverages,
) {
  const { type, payload } = action;
  switch (type) {
    case SET_ANNUAL_AVERAGES:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}
