// Type Imports
import { Overlay } from 'ol';
import { EnhancedThreshold, Threshold } from '../interface';

/* ===== Function =====
@DESC: findDecimal returns the decimal denomination for a given number
@ARGS: integer
*/
const thresholdDecimalFinder = (nextMin: number, convertToLastDP: boolean) => {
  let stringAns: string = '';
  nextMin
    .toString()
    .split('')
    .forEach((i) => {
      if (i === '.') {
        stringAns += i;
      } else if (parseFloat(i) > 0) {
        if (stringAns[stringAns.length - 1] === '1') {
          stringAns = stringAns.slice(0, -1);
          stringAns += '0';
        }
        stringAns += '1';
      } else {
        stringAns += '0';
      }
    });
  const parsedAns = parseFloat(stringAns);
  let ans = parsedAns >= 1 ? 1 : parsedAns;
  if (ans === 1 && convertToLastDP) {
    ans = ans - 0.9;
  }
  return ans;
};

/* ===== Function =====
@DESC: thresholdFormatter returns a formatted array of thresholds,
concatenating bands with the same name and adding a max value that sits up
against the next band in the range.
@ARGS: threshold array(derived from API getOverlays call)
*/
const theresholdFormatter = (thArr: Threshold[], activeOverlay: string) => {
  const overlaysTo1DP = ['Zephyr NO2', 'Zephyr PM25', 'UK NO2', 'UK PM25'];

  const convertToLastDP = overlaysTo1DP.includes(activeOverlay);

  const newThArr: EnhancedThreshold[] = [];
  thArr.forEach((th, idx, arr) => {
    const newTH = { ...th, max: null };
    newThArr.push(newTH);

    // if not last
    if (idx !== arr.length - 1) {
      const nextMin = arr[idx + 1].min;
      const subtractor = thresholdDecimalFinder(nextMin, convertToLastDP);
      const finalSubtractor = nextMin - subtractor === 0 ? 0 : subtractor;

      newThArr[newThArr.length - 1].max = parseFloat(
        (nextMin - finalSubtractor).toFixed(4),
      );
    }
  });
  return newThArr;
};

export default theresholdFormatter;
