import {
  AppIntroModelContent,
  HeaderContent,
  AQColourscaleContent,
  AsideOverlaysContentPublic,
  AsideOverlaysContent,
  DataTimePeriodOptionsContent,
  DataTimeAveragingOptionsContent,
  DataChartHeaderContent,
  TodayContent,
  naeiCRLink,
  osmCRLink,
  publicCRLink,
  mapboxCRLink,
  mapboxImproveLink,
  maxarCRLink,
  routePaths,
  annualAvgtext,
  customTimePeriod,
  aqlOtherTab,
  AirAlertsConfiguratorContent,
  AirAlertsOverviewContent,
  AirQuality101Content,
  AQLatestDataContent,
  AQOtherContent,
  AQModelDataContent,
  AQSAContent,
  AQTemporalContent,
  AQUnitContent,
  AQWindContent,
  AsideContent,
  AsidePromptContent,
  AsideUnitContent,
  PermissionCookieBannerContent,
  DataAQSummaryContent,
  DataChartDisclaimerContent,
  DataCustomDateContent,
  dataDiagnosticsModalContent,
  DataDownloadButtonContent,
  DataUnitsContent,
  DownloadModalContent,
  FeedbackQuestionsContent,
  FeedbackTagContent,
  HeaderContactModalContent,
  AddZephyrModalContent,
  RemoveZephyrModalContent,
  headerVersionHistoryModalContent,
  loginContent,
  MappairQuoteModalContent,
  LocationSearchContent,
  VersionLatestModalContent,
  ZephyrQuoteModalContent,
  maptilerCRLink,
} from '../../utils/consts';

const TRANSLATIONS_ES = {
  AppIntroButton: 'OK',
  // #region HeaderContent
  AirQuality101Title: 'Información',
  AnalyticsHeader: 'Análisis',
  AlertsHeader: 'Alertas',
  ContactLinkTitle: 'Contáctenos',
  CouncilProjectTitle: 'Acerca de',
  DataHeader: 'Datos',
  DefaultHeaderTitle: 'EarthSense',
  HelpHeader: 'Ayuda',
  LoadingAURNHeader: 'Cargando los más recientes datos de terceros',
  LoadingZephyrHeader: 'Cargando la más reciente información de calidad del aire', // TODO: check capitalization
  LocationHeader: 'Ubicación',
  LocationHeaderPublic: 'Mapa',
  LogoutLinkTitle: 'Cerrar sesión',
  MappairUpgradePrompt: 'Actualizar a MappAir Enhanced',
  MissingOverlayEnd: 'MappAir',
  MissingOverlayIntro: 'Contáctenos ', // TODO: sentence split might not be correct in spanish
  MissingOverlayMid: 'para tener acceso a ',
  PrivacyLinkTitle: 'Política de privacidad',
  ResourcesLinkTitle: 'Recursos',
  SourceEmissionTooltipIntro: 'Ver capas de fuentes de emisión para',
  SourceEmissionTooltipEnd: '.',
  SourceEmissionTooltipMissingSpecies: 'N/A',
  SourceEmissionTooltipSelectedIntro: 'Viendo',
  SourceEmissionTooltipSelectedEnd: 'capa de fuente de emisión.',
  SourcesSubHeader: 'Fuentes:',
  SpeciesSubHeader: 'Contaminante:',
  TodayTitle: 'Hoy',
  TooltipMarkerContent: '?',
  TourLinkTitle: 'Tour',
  TutorialLinkTitle: 'Tutorial',
  VersionLinkTitle: 'Versión',
  // #endregion

  // #region AQColourscaleContent
  AQCButton: 'Escala de color',
  AQCButtonPublic: 'escala de color del mapa',
  // #endregion

  OverlaysAQMA: 'AQMA',
  OverlaysAURN: 'AURN',
  OverlaysHeader: 'Capas',
  OverlaysDefault: 'Predeterminado',
  OverlaysMappair: 'Modelo de calidad del aire MappAir',
  OverlaysRemoveAll: 'Quitar todo',
  OverlaysSatellite: 'Satélite',
  OverlaysOSM: 'Calles abiertas',
  OverlaysShowAll: 'Mostrar todo',
  OverlaysZephyrs: 'Zephyr',
  MapDetails: 'Detalles del mapa',
  MapTypes: 'Tipo de mapa',
  //
  OverlaysMappairContentPublic: 'Modelo de contaminación',
  OverlaysAQMAContentPublic: 'Área de manejo de calidad de aire',
  OverlaysHeaderContentPublic: AsideOverlaysContentPublic.OverlaysHeader, // not used
  OverlaysHeaderContentPublic: AsideOverlaysContentPublic.OverlaysDefault,
  //
  DataPOHeader: 'Periodo de tiempo',
  DataPOSubheader: 'Mostrar datos para:',
  //
  DataTAHeader: 'Promedio temporal',
  DataTASubheader: 'Promediar datos por:',
  //
  DataChartHeaderTzPrefix: 'Zona horaria de los datos:',
  DataChartHeaderTzUTC: 'UTC',
  DataChartHeaderLoading: 'Cargando datos...', // TODO: bad elipsis in the english version
  DataChartHeaderNull: 'No hay datos para mostrar',
  DataChartSomeTimedOut: 'Algunas solicitudes de datos no terminaron a tiempo.',
  DataChartAllTimedOut: 'Ninguna unidad con datos disponibles respondió a tiempo. Intente solicitar los datos en \
    segmentos más pequeños.',
  ZoomResetButton: 'Reiniciar el nivel de zoom',
  vZephyrLoadingMsg: 'Su Zephyr Virtual está siendo inicializado y los datos estarán disponibles \
    en breve. (1-2 horas)',
  //
  AdviceHeader: 'Recomendaciones',
  BehaviourHeader: 'Su parte', // TODO: check context
  CloseModal: 'x',
  ForecastHeader: 'Pronóstico de Calidad del Aire',
  GeneralAdviceHeader: 'Población en general:',
  HealthAdviceHeader: 'Recomendaciones para la salud',
  HumidityHeader: 'Humedad',
  PollenHeader: 'Polen',
  PollutionHeader: 'Nivel de polución',
  SensitiveAdviceHeader: 'Recomendaciones para grupos sensibles:',
  TempHeader: 'Temperatura',
  WoodBurningHeader: 'Quema de biomasa y fogatas',
  //
  Low: 'Bajo',
  High: 'Alto',

  // #region Copyright links and statements
  naeiCRLink: naeiCRLink.content,
  osmCRLink: osmCRLink.content,
  maptilerCRLink: maptilerCRLink.content,
  publicCRLink: publicCRLink.content,
  mapboxCRLink: mapboxCRLink.content,
  mapboxImproveLink: mapboxImproveLink.content,
  maxarCRLink: maxarCRLink.content,
  routePaths: routePaths.content,
  // #endregion

  // #region
  // Not sure if we need to translate route path, will cause lots of breakage
  alerts: '/alerts',
  analytics: '/analytics',
  app: '/',
  data: '/data',
  login: '/login',
  today: '/today',
  // #endregion

  // #region annualAvgtext
  tableRowforYr: 'Año',
  tabContentTitleForChartPrefix: 'Último(s) ',
  tabContentTitleForChartPostfix: ' año(s)',
  tabContentTitleForListPrefix: 'Área - Último(s) ',
  tabContentTitleForListPostfix: ' año(s)',
  nodata: 'No se encontraron datos para las coordenadas seleccionadas...',
  AAChartHeader: 'Zephyr virtual',
  AADownloadData: 'Descargar datos',
  AASeeMore: 'Ver más datos',
  annualAvgNotAuthorised: annualAvgtext.annualAvgNotAuthorised,
  annualAvgAccordionTitle: annualAvgtext.annualAvgAccordionTitle,
  // #endregion

  aqlOtherTab: aqlOtherTab.label,
  customTimePeriod: customTimePeriod.labelHTML,

  // #region AirAlertsConfiguratorContent
  AACValidationFailure: 'Por favor corrija los errores para enviar esta alerta',
  AACDataSourceHeader: 'Seleccione el origen de datos',
  AACDataSourceSelection: 'Seleccione Zephyrs',
  AACValidationFailureSources: 'Por favor seleccione por lo menos un Zephyr',
  AACAlertDataHeader: 'Información de alerta',
  AACGasColumn: 'Seleccione la variable', // TODO: Change "Gas" to "Variable" in 'en' version
  AACThresholdColumn: 'Umbral',
  AACAlertColumn: 'Nivel de alerta',
  AACPeriodColumn: 'Periodo',
  AACRepeatColumn: 'Repetir',
  AACUnitStatusSelection: 'Enviar alertas de estado para:',
  AACValidationFailureData: 'Por favor seleccione por lo menos una variable o estado a seguir', // TODO: Change "Gas" to "Variable" in 'en' version
  AACOutputHeader: 'Salida',
  AACOutputName: 'Nombre', // TODO: Add "Add a name for this alert" text to component content
  AACValidationFailureName: 'Por favor asigne un nombre a esta alerta',
  AACOuputEmail: 'Direcciones de correo electrónico',
  AACOutputPhoneNumber: 'phone number', // TODO: Add "Add email addresses for this alert" text to component content
  AACValidationFailureEmail: 'Por favor añada por lo menos una dirección de correo electrónico',
  AACValidationFailurePhoneNumber: '',
  AACValidationCommunicationType: '',
  // #endregion

  // #region AirAlertsOverviewContent
  AAOHeader: 'Alertas (Máx 5 por usuario estándar)',
  AAOCreateNew: 'Crear nueva alerta',
  AAONameColumn: 'Nombre',
  AAOAlertDataColumn: 'Datos de alerta',
  AAOStatusColumn: 'Estado',
  AAOActionscolumn: 'Acciones',
  AAONoAlerts: "Todavía no se ha creado ninguna alerta",
  // #endregion

  AQ101header: 'Información de Calidad del Aire',

  // #region AQLatestDataContent
  AQAurnLabel: 'AURN',
  AQLatestDataButton: 'Últimas mediciones',
  AQZephyrLabel: 'Zephyr',
  // #endregion

  // #region AQOtherContent
  AQDisclaimerGFS:'Nota: los datos del modelo GFS presentados en este mapa son de acceso público. Éstos no han \
    sido ratificados y no son propiedad de EarthSense. EarthSense no se hace resposable por la \
    precisión o actualidad de los datos presentados en este mapa.',
  AQDisclaimerMet:'Nota: los datos de la Met Office presentados en este mapa son de acceso público. Éstos no han \
    sido ratificados y no son propiedad de EarthSense. EarthSense no se hace resposable por la \
    precisión o actualidad de los datos presentados en este mapa.',
  AQGFSDesc: 'Pronóstico Global NOAA',
  AQMetDesc: 'Estación Met Office',
  AQPeriodGFS: 'últimas 3 horas',
  AQPeriodMet: 'última hora',
  // #endregion

  // #region AQModelDataContent
  AQModelDataButton: 'Datos del modelo MappAir',
  AQModelDataLoading: 'Cargando datos del modelo',
  AQModelDataClickPrompt:'Haga clic en una zona del mapa para ver datos adicionales del modelo',
  AQModelNoData: 'No hay datos del modelo adicionales disponibles para esta ubicación.',
  // #endregion

  // #region AQSAContent
  AQTotal: 'Total',
  AQUnits: '2018 toneladas/año',
  // #endregion

  // #region AQTemporalContent
  AQChartHeader: 'Histórico y pronóstico',
  AQCloseModal: 'x',
  AQDownloadData: 'Descargar datos',
  // #endregion

  // #region AQUnitContent
  AQSummaryHeader: 'valor: ',
  AQPeriod: 'Promedios horarios más recientes',
  AQNoData: 'No hay datos disponibles.',
  AQNoOwnership: 'No hay datos disponibles.',
  // #endregion

  // #region AQWindContent
  AQChartHeaderOne: 'Frecuencia y velocidad',
  AQChartSubheader: 'Últimos 30 días',
  // #endregion

  // #region AsideContent
  AsideHeader: 'Inventario de productos',
  PublicAsideHeader: 'Fuentes de datos de Calidad del Aire',
  // #endregion

  // #region AsidePromptContent
  AsideButtonContent: 'Solicitar cotización',
  AsidePromptExplainer: 'Expandir la red Zephyr',
  AsideAnalyticsPrompt: 'Volver al Menú de Configuración',
  AsideAnalyticsButton: 'Atrás',
  // #endregion

  // #region AsideUnitContent
  AsideUnitBatteryCharging: 'Cargando',
  AsideUnitBatteryChargingDesc: AsideUnitContent.AsideUnitBatteryChargingDesc,
  AsideUnitBatteryDischarging: 'Descargando',
  AsideUnitBatteryDischargingDesc:
    AsideUnitContent.AsideUnitBatteryDischargingDesc,
  AsideUnitBatteryExplainer: 'Operando con la batería interna',
  AsideUnitBatteryFlat: 'Sin carga',
  AsideUnitBatteryFlatDesc: AsideUnitContent.AsideUnitBatteryFlatDesc,
  AsideUnitBatteryPower: 'Potencia',
  AsideUnitCartridgeBlank: 'Vacío', // TODO: check context
  AsideUnitCartridgeExplainerStandard:'Sensores incluidos: NO<sub>2</sub>, NO, O<sub>3</sub>, PM<sub>1</sub>, PM<sub>2.5</sub>, \
    PM<sub>10</sub>, Presión, Temperatura y Humedad relativa',
  AsideUnitCartridgeExplainerEnhanced:'Sensores incluidos: NO<sub>2</sub>, NO, O<sub>3</sub>, PM<sub>1</sub>, PM<sub>2.5</sub>, \
    PM<sub>10</sub>, CO, SO<sub>2</sub>, H<sub>2</sub>S, Presión, Temperatura y Humedad relativa',
  AsideUnitCartridgeExplainerEnhancedPlus:'Sensores incluidos: NO<sub>2</sub>, NO, O<sub>3</sub>, PM<sub>1</sub>, PM<sub>2.5</sub>, \
    PM<sub>10</sub>, CO, SO<sub>2</sub>, H<sub>2</sub>S, CO<sub>2</sub>, Presión, Temperatura y \
    Humedad relativa',
  AsideUnitCartridgeExplainderEnhancedPP:'Sensores incluidos: NO<sub>2</sub>, NO, O<sub>3</sub>, PM<sub>1</sub>, PM<sub>2.5</sub>, \
    PM<sub>10</sub>, CO, SO<sub>2</sub>, H<sub>2</sub>S, CO<sub>2</sub>, TVOCs, Presión, \
    Temperatura y Humedad relativa',
  AsideUnitConnectionGSM: 'GSM',
  AsideUnitConnectionNetwork: 'Red',
  AsideUnitLocationHeader: 'Ubicación:',
  AsideUnitUpdatedHeader: 'Última actualización:',
  AsideUnitIdHeader: 'ID:',
  AsideUnitStatusHeader: 'Estado',
  AsideUnitFrequencyHeader: 'Frecuencia',
  AsideUnitCertificateDownloadButton: 'Descargar certificados de calibración',
  AsideUnitConnectionExplainer:'El dispositivo está conectado a la red, datos recibidos en las pasadas 2 horas',
  AsideUnitConnectionWarning:'El dispositivo está tempralmente desconectado de la red. No se han recibido datos en las \
    pasadas 2 horas, pero si en las pasadas 24 horas. Las mediciones están almacenadas en el \
    dispositivo',
  AsideUnitConnectionAlert: 'No se han recibido datos en las pasadas 24 horas.',
  AsideUnitEditName: 'Cambiar nombre',
  AsideUnitDeleteName: 'Eliminar',
  AsideUnitOwnership: 'Propietario:', // TODO: check context
  AsideUnitSamplingData: 'Datos',
  AsideUnitSamplingExplainerHigh: 'Frecuencia de muestreo: 10 segundos',
  AsideUnitSamplingExplainerMed: 'Frecuencia de muestreo: 60 segundos',
  AsideUnitSamplingExplainerLow: 'Frecuencia de muestreo: 60 segundos', // TODO: This is likely not correct. Should be a slower sample rate
  AsideUnitSamplingOff: 'Sin muestras',
  AsideUnitSamplingOn: 'Muestreo', // TODO: check context
  AsideUnitStatus: 'Estado:',
  AsideUnitStatusRetired: 'Retirado:',
  AsideUnitType: 'Tipo:',
  AsideUnitTypeAURN: 'AURN',
  AsideUnitTypeVirtualZephyr: 'Zephyr Virtual',
  AsideUnitTypeZephyr: 'Zephyr',
  // #endregion

  // #region PermissionCookieBannerContent
  PCBExplainer:'MyAir usa cookies analíticas de terceros para informarle de publicación de versiones mejoradas en el \
    futuro',
  PCBExplainerPreSpan: 'Usamos',
  PCBExplainerSpan: 'opcionales',
  PCBExplainPostSpan:'cookies analíticas de terceros para entender cómo podemos construir mejores productos', // TODO: sentence split might not be correct in spanish
  PCBButtonAccept: 'Aceptar',
  PCBButtonReject: 'Rechazar',
  PCBButtonOk: 'OK',
  // #endregion

  DataAQSummaryHeader: 'Última hora',
  DataChartDisclaimer:'Nota: los datos de DEFRA AURN presentados en este mapa son de acceso público. Éstos no han \
    sido ratificados y no son propiedad de EarthSense. EarthSense no se hace resposable por la \
    precisión o actualidad de los datos presentados en este mapa.',
  DataCustomDatePickerMessage: 'Seleccione un periodo de hasta 31 días',
  DataCustomeDateSubmit: 'Enviar',

  // #region
  dataDiagnosticsHeader: 'Diagnóstico de datos', // TODO: check context
  dataDiagnosticsIndex: 'Datos solicitados:', // TODO: check context
  dataDiagnosticsModalContentClose:
    dataDiagnosticsModalContent.dataDiagnosticsModalContentClose,
  // #endregion

  // #region
  DataDownloadButtonText: 'Descargar datos',
  DataDiagnosticsButtonText: 'Diagnósticos',
  // #endregion

  // #region
  DataUnitsHeaderMultiUnits: 'Dispositivos:', // TODO: check if "units" or "devices" is more apropiate, avoid confusion between units of measure and zephyr units
  DataUnitsRemoveUnit: 'x',
  DataUnitsSingleUnit: 'Dispositivo:',
  DataUnitsTooltipContent:'Arrastre y suelte los dispositivos que desea de la pestaña de inventario de productos para \
    agregarlos al gráfico',
  DataUnitsTooltipMakerContent: 'i',
  // #endregion

  // #region
  downloadHeader: 'Descargar datos',
  downloadSubheader: 'Obtener datos de',
  downloadCoordinates: 'coordenadas',
  downloadButton: 'Descargar',
  // #endregion

  // #region
  feedbackQuestionsHeader: 'Compartanos sus observaciones',
  feedbackScoreLabelsLow: 'Poco probable',
  feedbackScoreLabelsHigh: 'Muy probable',
  feedbackCommentsPlaceholder: 'Escriba aquí sus observaciones',
  feedbackButtonNext: 'Siguiente',
  feedbackEmailHeader: 'Dirección de correo electrónico',
  feedbackOptional: 'Opcional',
  feedbackConfirmationHeader: 'Gracias por compartirnos sus observaciones',
  feedbackConfirmationText: 'Su opinión es muy importante para nosotros y será usada para mejorar nuestros productos y \
    servicios.',
  feedbackButtonConfirmation: 'Listo',
  feedbackTagButton: 'Observaciones', // TODO: check context
  // #endregion

  // #region
  confirmationHeader: 'Gracias por su mensaje',
  confirmationText: 'Estaremos en contacto con usted en breve.',
  contactHeader: 'Contáctenos',
  contactSubheader: 'Describa en detalle su solicitud',
  contactFirstName: 'Nombre(s)*',
  contactLastName: 'Apellido(s)*',
  contactEmail: 'Dirección de correo electrónico*',
  contactCompany: 'Empresa*',
  contactDetails: 'Detalles',
  contactEnquiry: 'Razón de la solicitud*',
  contactComments: 'Comentarios',
  contactButton: 'Enviar',
  messageSuccess: 'Gracias, su mensaje ha sido enviado.',
  messageProblem: 'Hubo un problema al enviar su mensaje.',
  messageMissingFields: 'Por favor, complete todos los campos requeridos.',
  requiredFields: '*Campos requeridos',
  // #endregion

  // #region
  popoverCTAText: 'Agregar Zephyr Virtual',
  headerText: 'Agregar Zephyr Virtual',
  zephyrNameLabel: 'Nombre',
  secondaryCta: 'Cancelar',
  primaryCta1: 'Agregar',
  totalPrefix: 'Éste será ', // TODO: check context
  totalPostfix: 'Zephyr virtuales',
  maxReachPrefix: 'Ha alcanzado su límite de ',
  limitReachedSubtitle: 'Puede deshabilitar algunos de sus Zephyr virtuales o ponerse en contacto con nosotros para \
    agregar más',
  contactUsCtaTxt: 'Contáctenos',
  // #endregion

  // #region
  RemoveZephyrHeaderText: RemoveZephyrModalContent.RemoveZephyrHeaderText,
  confirmationTextPrefix: "'Está seguro que desea eleminar el Zephyr virtual '", // TODO: sentence split might not be correct in spanish
  confirmationTextPostFix: "'?",
  warningText: 'Atención: Esta acción no se puede deshacer',
  RemoveZephyrSecondaryCta: RemoveZephyrModalContent.RemoveZephyrSecondaryCta,
  primaryCta: 'Eliminar',
  // #endregion

  // #region
  versionHistoryHeader: 'Historial de versiones',
  currentVersionDescriptor: 'Versión actual: ',
  // #endregion

  // #region
  incorrectPassword: 'Credenciales de acceso no válidas, por favor intente de nuevo',
  loginHeader: 'Iniciar sesión',
  welcomeMessage:'Bienvenido. Para continuar, por favor ingrese su nombre de usuario y contraseña.',
  loginButton: 'Iniciar sesión',
  heroHeader: 'Bienvenido a MyAir',
  heroSubheader:'Consulte, administre y descarge su información de calidad del aire de Zephyr y MappAir.',
  usernameField: 'Nombre de usuario',
  passwordField: 'Contraseña',
  // #endregion

  // #region
  aoi: 'Área de interés',
  area: MappairQuoteModalContent.area,
  centre: ' Centro: ',
  comments: 'Comentarios',
  MappairQuoteConfirmationHeader:
    MappairQuoteModalContent.MappairQuoteConfirmationHeader,
  MappairQuoteConfirmationText:
    MappairQuoteModalContent.MappairQuoteConfirmationText,
  MappairQuoteContactHeader: MappairQuoteModalContent.MappairQuoteContactHeader,
  MappairQuoteContactFirstName:
    MappairQuoteModalContent.MappairQuoteContactFirstName,
  MappairQuoteContactLastName:
    MappairQuoteModalContent.MappairQuoteContactLastName,
  MappairQuoteContactEmail: MappairQuoteModalContent.MappairQuoteContactEmail,
  MappairQuoteContactCompany:
    MappairQuoteModalContent.MappairQuoteContactCompany,
  MappairQuoteContactButton: MappairQuoteModalContent.MappairQuoteContactButton,
  MappairQuoteCloseModal: MappairQuoteModalContent.MappairQuoteCloseModal,
  mappairLinkPost:' tienen una resolución de 10 m y utilizan mecánica de fluidos computacional (CDF) para simular \
    la dispersión de contaminantes al rededor de edificios y en cañones urbanos en un modelo 3D.', // TODO: sentence split might not be correct in spanish
    // TODO: review CFD enphasis
  mappairLinkPre: 'Los models MappAir Enhanced ',
  mappairOptionalCommentsPlaceholder:'Por favor, utlice este espacio para incluir comentarios sobre los requerimientos específicos \
    de su proyecto y los detalles de su caso de interés.',
  mappairMessageProblem: MappairQuoteModalContent.mappairMessageProblem,
  mapairMessageMissingFields:
  MappairQuoteModalContent.mapairMessageMissingFields,
  output: 'Salida',
  polygon: 'Polígono personalizado -',
  pollutants: 'Contaminantes',
  mapairRequiredFields: MappairQuoteModalContent.mapairRequiredFields,
  resolution: 'Resolución',
  selectArea: 'Seleccione un área',
  // #endregion

  // #region
  LocationSearchPlaceholder: 'Buscar ubicación',
  InvalidSearchPlaceholder: 'La ubicación está fuera del mapa',
  // #endregion

  // #region
  updateTitle: 'Actualizaciones de MyAir®', // TODO: check use of ® symbol across all components
  // #endregion

  // #region
  ZephyrConfirmationHeader: ZephyrQuoteModalContent.ZephyrConfirmationHeader,
  ZephyrConfirmationText: ZephyrQuoteModalContent.ZephyrConfirmationText,
  ZephyrContactHeader: ZephyrQuoteModalContent.ZephyrContactHeader,
  ZephyrContactFirstName: ZephyrQuoteModalContent.ZephyrContactFirstName,
  ZephyrContactLastName: ZephyrQuoteModalContent.ZephyrContactLastName,
  ZephyrContactEmail: ZephyrQuoteModalContent.ZephyrContactEmail,
  ZephyrContactCompany: ZephyrQuoteModalContent.ZephyrContactCompany,
  ZephyrContactButton: ZephyrQuoteModalContent.ZephyrContactButton,
  measurements: 'Sensores*',
  ZephyrContactMessageProblem:
    ZephyrQuoteModalContent.ZephyrContactMessageProblem,
  ZephyrContactMessageMissingFields:
    ZephyrQuoteModalContent.ZephyrContactMessageMissingFields,
  powerOptions: 'Suministro de energía*',
  ZephyrQuoteRequiredFields: ZephyrQuoteModalContent.ZephyrQuoteRequiredFields,
    zephyrs: 1,
  // #endregion
};

export { TRANSLATIONS_ES };

