// Package Imports
import React, { useState, useEffect } from 'react';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

// Content Imports
import { routePaths } from '../../utils/consts';

import LogoBeta from '../../assets/images/logo_beta.png';
import LogoMain from '../../assets/images/logo.png';

// Type Imports
import { ReduxState } from '../../utils/interface';

var Logo = LogoMain;
var greetingText = 'Welcome to MyAir';
var greetingIntro =
  'View, manage, and download your MappAir and Zephyr air quality data.';
// Asset Imports
if (process.env.REACT_APP_SERVER == 'development') {
  greetingText = 'Welcome to MyAir <sup>dev</sup>';
  greetingIntro =
    'View, manage, and download your MappAir and Zephyr air quality data. Development Version.';
} else if (process.env.REACT_APP_SERVER == 'production') {
} else if (process.env.REACT_APP_SERVER == 'beta') {
  greetingText = 'Welcome to MyAir <sup>Beta</sup>';
  greetingIntro =
    'View, manage, and download your MappAir and Zephyr air quality data, previewing and testing our latest features!';
  Logo = LogoBeta;
}

// Component Interfaces
interface InitState {
  formData: { username: string; password: string };
  queryString: null;
  seePassword: boolean;
  passwordFieldActive: boolean;
}

interface LoginProps extends RouteComponentProps {
  basename: string;
  isAuthenticated: boolean;
  loginFailed: boolean;
  setAuthReq: Function;
}

// Create an array of objects representing environment variables
const envVars: { [key: string]: string | number |undefined }[] = [];
for (let key in process.env) {
    envVars.push({ Variable: key, string: process.env[key] });
}


// Component
const Login = ({
  basename,
  isAuthenticated,
  loginFailed,
  setAuthReq,
}: LoginProps) => {
  const { t: translate } = useTranslation();

  localStorage.removeItem('token');
  localStorage.removeItem('key');
  localStorage.removeItem('user');
  localStorage.removeItem('data');
  localStorage.removeItem('isPublic');
  // State
  const initState: InitState = {
    formData: { username: '', password: '' },
    queryString: null,
    seePassword: false,
    passwordFieldActive: false,
  };

  const [formData, setFormData] = useState(initState.formData);
  const [seePassword, setSeePassword] = useState(initState.seePassword);
  const [passwordFieldActive, setPasswordFieldActive] = useState(
    initState.passwordFieldActive,
  );
  // Consts
  const { username, password } = formData;
  const location = useLocation();

  // Effects
  // Functions
  const usernameChange = (username: string) => {
    setFormData({ ...formData, username });
  };

  const passwordChange = (password: string) => {
    setFormData({ ...formData, password });
  };

  const handleLogin = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setAuthReq({
      internal: basename === 'internal',
      isAuthenticated: false,
      password,
      queryString: initState.queryString,
      username,
    });
  };


var developer_environmentVariableTools = 'developer_environmentVariableTools';

  return (
    <form className="login-view">
      <aside className="login-aside">
        <div className="login-aside-container">
          <div className="login-aside-es-logo">
            <img src={Logo} alt="EarthSense logo" />
          </div>
          <input
            id="loginUsername"
            type="text"
            name="username"
            placeholder="Username"
            value={username}
            onChange={(e) => usernameChange(e.target.value)}
          />
          <div
            className={`login-password ${passwordFieldActive ? 'active' : ''}`}
          >
            <input
              id="loginPassword"
              type={seePassword ? 'text' : 'password'}
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => passwordChange(e.target.value)}
              onFocus={() => setPasswordFieldActive(true)}
              onBlur={() => setPasswordFieldActive(false)}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              onClick={() => setSeePassword(!seePassword)}
              className={seePassword ? 'active' : ''}
            >
              <path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.209 0-4 1.792-4 4 0 2.209 1.791 4 4 4s4-1.791 4-4c0-2.208-1.791-4-4-4z" />
            </svg>
          </div>
          <button className="login-button" onClick={handleLogin}>
            {translate('loginButton')}
          </button>
          {localStorage.getItem(developer_environmentVariableTools) && <button onClick={() => { throw new Error('Breaking the world2!'); }}>Break the world</button>}
          <p className="login-error-message">
            {loginFailed && location.pathname.includes('/login')
              ? translate('incorrectPassword')
              : ''}
          </p>
        </div>
      </aside>
      <main className="login-main">
        <div>
          <h1 className="light">{parse(greetingText)}</h1>
          <h3>{greetingIntro}</h3>
          {/* Conditionally render the table */}
          {localStorage.getItem(developer_environmentVariableTools) && envVars.length > 0 && (
              <table style={{ color: 'white' }}>
              <thead>
                <tr>
                  <th>Variable</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
              {envVars.map((envVar, index) => (
              <tr key={index}>
              <td>{envVar.Variable}</td>
              <td>{envVar.string}</td>
              </tr>
              ))}
              </tbody>
            </table>
          )}
        </div>
      </main>
    </form>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loginFailed: state.auth.loginFailed
});

export default withRouter(connect(mapStateToProps, {})(Login));
