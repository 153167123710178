// Package Imports
import React from 'react';
import { useTranslation } from 'react-i18next';

// Component Imports
import Tooltip from '../shared_components/Tooltip';

// Content Imports

// Type Imports
import { AnnualAvgData, RequestError, Zephyr } from '../../utils/interface';

// Component Interfaces
interface DataUnitsProps {
  handleRemoveUnit: Function;
  isPublic: boolean;
  progressBarOn: boolean;
  requestErrors: RequestError[];
  unitList: Zephyr[];
  zephyrs: Zephyr[];
  annualAvgData: AnnualAvgData;
  showingAnnualAverage: boolean;
}

// Component
const DataUnits = ({
  handleRemoveUnit,
  isPublic,
  progressBarOn,
  requestErrors,
  unitList,
  zephyrs,
  annualAvgData,
  showingAnnualAverage,
}: DataUnitsProps) => {
  const { t: translate } = useTranslation();

  // Functions
  const handleRemoveUnitClick = (unitId: number) => {
    if (!progressBarOn) {
      handleRemoveUnit(unitId);
    }
  };

  return (
    <div className="data-units chart-units tourUnitList">
      <ul>
        <div className="units-header">
          {!isPublic &&
          unitList.length &&
          unitList.length === 1 &&
          zephyrs.length > 1 ? (
            <Tooltip
              containerClassName="unit-list-tooltip"
              content={translate('DataUnitsTooltipContent')}
              tooltipMarkerContent={translate('DataUnitsTooltipMakerContent')}
            />
          ) : (
            <></>
          )}
          <h4>
            {unitList.length > 0
              ? unitList.length === 1
                ? translate('DataUnitsSingleUnit')
                : translate('DataUnitsHeaderMultiUnits')
              : ''}
          </h4>
        </div>
        {unitList.length &&
          unitList.map((unit, idx) => (
            <li
              key={`${unit.zNumber}-${idx}`}
              className={`${idx === 0 ? 'head-unit' : 'chain-unit'} ${
                !progressBarOn &&
                requestErrors.length &&
                requestErrors.filter((e) => e.unitId === unit.zNumber).length
                  ? 'request-failed'
                  : ''
              }`}
            >
              {unit.name ? <div>{unit.name}</div> : null}

              {idx !== 0 && !showingAnnualAverage ? (
                <div
                  className={`${
                    progressBarOn ? 'drop-unit disable' : 'drop-unit'
                  } ${
                    !progressBarOn &&
                    requestErrors.length &&
                    requestErrors.filter((e) => e.unitId === unit.zNumber)
                      .length
                      ? 'request-failed'
                      : ''
                  }`}
                  onClick={() => handleRemoveUnitClick(unit.zNumber)}
                >
                  {translate('DataUnitsRemoveUnit')}
                </div>
              ) : (
                <AADataCoordinates
                  progressBarOn={progressBarOn}
                  annualAvgData={annualAvgData}
                  showingAnnualAverage={showingAnnualAverage}
                />
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};

const AADataCoordinates = ({
  progressBarOn,
  annualAvgData,
  showingAnnualAverage,
}: any) => {
  if (!showingAnnualAverage) return null;
  if (!annualAvgData?.list?.latlon?.lat) return null;

  return (
    <div className={`${progressBarOn ? 'drop-unit disable' : 'drop-unit'}`}>
      Lat, Long :{' '}
      {`${annualAvgData?.list?.latlon?.lat?.toFixed(
        2,
      )} , ${annualAvgData?.list?.latlon?.lon?.toFixed(2)}`}
    </div>
  );
};

export default DataUnits;
