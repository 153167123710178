// Pakage Imports
import { lookupService } from 'dns';
import React from 'react';

// Type Imports
import { DisplayConfig, PolarPlots } from '../../utils/interface';

// Component Interfaces
interface AnalyticsPlotsProps {
  displayConfig: DisplayConfig;
  polarPlots: PolarPlots | null;
}

// Component
const AnalyticsPlots = ({ displayConfig, polarPlots }: AnalyticsPlotsProps) => (
  <div className="analytics-plots-container">
    {polarPlots ? (
      <ul className="analytics-polar-plots-container">
        {Object.keys(polarPlots)
          .filter((key) => key !== 'windRose')
          .map((plotKey) => (
            <li className="analytics-polar-plot-container" key={plotKey}>
              <div className="analytics-polar-plot-header">
                <h5>Species Name</h5>
              </div>
              <img
                src={`data:image/png;base64,${polarPlots[plotKey]}`}
                alt="polar plots"
              />
            </li>
          ))}
      </ul>
    ) : (
      <></>
    )}
  </div>
);

export default AnalyticsPlots;
