// Types Imports
import { GET_AURNS, GET_ZEPHYRS, ADD_ZEPHYRS } from '../actions/types';

// Type Imports
import { Zephyr } from '../utils/interface';

// Type Safety
interface GetZephyrs {
  loading: boolean;
  zephyrs: Zephyr[];
}

interface getZephyrs {
  type: typeof GET_ZEPHYRS;
  payload: Zephyr[];
}

// State
const initialState: GetZephyrs = {
  loading: true,
  zephyrs: [],
};

// Reducer
export default function (state = initialState, action: getZephyrs) {
  const { type, payload } = action;

  switch (type) {
    case ADD_ZEPHYRS:
      return {
        ...state,
        zephyrs: [...state.zephyrs, ...payload],
        loading: false,
      };
    case GET_ZEPHYRS:
      return {
        ...state,
        zephyrs: payload,
        loading: false,
      };

    case GET_AURNS:
      return {
        ...state,
        zephyrs: [...state.zephyrs, ...payload],
        loading: false,
      };

    default:
      return state;
  }
}
