// Package Imports
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import utilsRequest from '../../utils/request';

// Content Imports
import { DataTimeAveragingOptionsContent } from '../../utils/consts';

// Type Imports
import {
  AveragingLabels,
  AveragingPeriods,
  LineChartData,
  TimePeriod,
  Zephyr,
  ZephyrHistoryWithId,
} from '../../utils/interface';
import { current, subtractTimeFromNow, utcConvertor } from '../../utils/functions/dateFinder';
import moment from 'moment';
import { convertVZephyrDataToZephyrHistoricalData } from '../../actions/zephyrs';
import { getAvailableSpecies } from '../../utils/functions/slotValidator';
import { checkSlotABData, getAvailableAveragingOption } from '../../utils/functions/getAvailableAveragingOption';

// Component Interfaces
interface DataTimeAveragingOptionsProps {
  averagingPeriods: AveragingPeriods;
  curAveraging: AveragingLabels;
  handleAveragingOption: Function;
  progressBarOn: boolean;
  unitList: Zephyr[];
  zNumber: number;
  dataConfig: any;
  zephyrType: number;
  zephyrStartDate: string | null;
  zephyrEndDate: string | null;
  zephyr: any;
  curAveragingOption: any;
  setAveragingOption: Function;
  zephyrAvgData: any;
  historicalData: any;
  isHistoricalDataLoading: boolean | null;
  historicalDataZType: any;
  timePeriod: TimePeriod;
  chartData: {
    labels: Date[];
    datasets: LineChartData[];
  };
  unitHistories: any;
}

// Component
const DataTimeAveragingOptions = ({
  averagingPeriods,
  curAveraging,
  handleAveragingOption,
  zephyr,
  historicalData,
  isHistoricalDataLoading,
  historicalDataZType,
  timePeriod,
  chartData,
  unitHistories,
}: DataTimeAveragingOptionsProps) => {
  const { t: translate } = useTranslation();
  const isLoaded = historicalData;
  const lastUnitHistory = unitHistories ? unitHistories[Object.keys(unitHistories)[Object.keys(unitHistories).length - 1]] : null;

  // Time averaging options not updating properly
  const validateAveragingOption = (averagingOption: any) => {
    if ((isLoaded && historicalDataZType === 0 && averagingOption in historicalData) || (unitHistories && unitHistories[Object.keys(unitHistories)[Object.keys(unitHistories).length - 1]] && averagingOption in unitHistories[Object.keys(unitHistories)[Object.keys(unitHistories).length - 1]])) {
      const availableSpeciesSlotA: any = getAvailableSpecies(isLoaded ? historicalData[averagingOption].slotA : lastUnitHistory[averagingOption].slotA);
      const availableSpeciesSlotB: any = getAvailableSpecies(isLoaded ? historicalData[averagingOption].slotB : lastUnitHistory[averagingOption].slotB);
      const concatedSpecies = availableSpeciesSlotA && availableSpeciesSlotB ? availableSpeciesSlotA.concat(availableSpeciesSlotB) : availableSpeciesSlotA && !availableSpeciesSlotB ? availableSpeciesSlotA : !availableSpeciesSlotA && availableSpeciesSlotB ? availableSpeciesSlotB : null;
      let allowAveragingOption: boolean = true;
      if (concatedSpecies) {
        for (let i = 0; i < concatedSpecies.length; i++) {
          allowAveragingOption = getAvailableAveragingOption(availableSpeciesSlotA, availableSpeciesSlotB, historicalData ? historicalData : lastUnitHistory, averagingOption, null, true)
        }
      }
      if (allowAveragingOption) return true;
      return false;
    }
    return isLoaded || lastUnitHistory ? !(averagingOption in (historicalData || lastUnitHistory)
      && (((historicalData[averagingOption]['slotA'] || lastUnitHistory[averagingOption]['slotA'])
        && 'NO2' in (historicalData[averagingOption]['slotA'] || lastUnitHistory[averagingOption]['slotA'])
        && 'data' in (historicalData[averagingOption]['slotA']['NO2'] || lastUnitHistory[averagingOption]['slotA']['NO2'])
        && (!historicalData[averagingOption]['slotA']['NO2'].allNull)) || !lastUnitHistory[averagingOption]['slotA']['NO2'].allNull) || (historicalData[averagingOption]['slotB'] || lastUnitHistory[averagingOption]['slotB'])
      && 'NO2' in (historicalData[averagingOption]['slotB'] || lastUnitHistory[averagingOption]['slotB']) && 'data' in (historicalData[averagingOption]['slotB']['NO2'] || lastUnitHistory[averagingOption]['slotB']['NO2'])
      && (!historicalData[averagingOption]['slotB']['NO2'].allNull || !lastUnitHistory[averagingOption]['slotB']['NO2'].allNull)) : false;
  };

  return (isLoaded || unitHistories) && !isHistoricalDataLoading && chartData.datasets.length > 0 && chartData.labels.length > 0 ? (
    <div className="apSwitch tourAveragingSwitch">
      <h4>{translate('DataTAHeader')}</h4>
      {averagingPeriods.periods
        .map((ap) => (
          <label className="radio" htmlFor={ap.labelHTML} key={ap.labelHTML} style={{ cursor: validateAveragingOption(ap.averagingOption) ? 'not-allowed' : 'pointer' }}
          >
            {ap.labelHTML}
            <input
              type="radio"
              id={ap.labelHTML}
              name="time-averaging"
              checked={curAveraging === ap.labelHTML}
              value={ap.labelHTML}
              onChange={() => handleAveragingOption(ap)}
              disabled={validateAveragingOption(ap.averagingOption)}
            />
            <span className="checkmark" />
          </label>
        ))}
    </div>
  ) : isHistoricalDataLoading ?
    <div className="apSwitch tourAveragingSwitch">
      <h4>{translate('DataTAHeader')}</h4>
      {
        <label className="radio" htmlFor="Loading" key="Loading"
        >
          Loading...
        </label>
      }
    </div>
    :
    <div className="apSwitch tourAveragingSwitch">
      <h4>{translate('DataTAHeader')}</h4>
      {
        <label className="radio" htmlFor="no-data" key="no-data"
        >
          No Data
        </label>
      }
    </div>
};

export default DataTimeAveragingOptions;
