import React, { useState } from 'react';
import { ThresholdExceedingSpecie } from '../views/Data';


interface ExceededThresholdProps {
    thresholdExceedingSpecies: ThresholdExceedingSpecie[],
    tab: any,
};


const ExceededThreshold = ({ thresholdExceedingSpecies, tab }: ExceededThresholdProps) => {
    const [isTooltipShowing, setIsTooltipShowing] = useState<boolean>(false);
    const getSpeciesWithExceededThresholds = (group: string) => {
        if (group === 'NO2') {
            return thresholdExceedingSpecies.map((z: any, idx: number) => {
                const specie: any = Object.keys(z)[0];
                if (z[specie].NO2 > 0 && z[specie].O3 > 0) {
                    return <div key={idx}>
                        <p style={{ fontStyle: 'bold' }}>Zephyr {specie}</p>
                        <p>NO2 - {z[specie].NO2} incidents</p>
                        <p>O3 - {z[specie].O3} incidents</p>
                    </div>
                }
                if (z[specie].NO2 > 0) {
                    return <div key={idx}>
                        <p style={{ fontStyle: 'bold' }}>Zephyr {specie}</p>
                        <p>NO2 - {z[specie].NO2} incidents</p>
                    </div>
                }
                if (z[specie].O3 > 0) {
                    return <div key={idx}>
                        <p style={{ fontStyle: 'bold' }}>Zephyr {specie}</p>
                        <p>O3 - {z[specie].O3} incidents</p>
                    </div>
                }
            })
        }
        if (tab.group === 'PM') {
            return thresholdExceedingSpecies.map((z: any, idx: number) => {
                const specie: any = Object.keys(z)[0];
                if (z[specie].particulatePM10 > 0 && z[specie].particulatePM25 > 0) {
                    return <div key={idx}>
                        <p style={{ fontStyle: 'bold' }}>Zephyr {specie}</p>
                        <p>PM10 - {z[specie].particulatePM10} incidents</p>
                        <p>PM2.5 - {z[specie].particulatePM25} incidents</p>
                    </div>
                }
                if (z[specie].particulatePM10 > 0) {
                    return <div key={idx}>
                        <p style={{ fontStyle: 'bold' }}>Zephyr {specie}</p>
                        <p>PM10 - {z[specie].particulatePM10} incidents</p>
                    </div>
                }
                if (z[specie].particulatePM25 > 0) {
                    return <div key={idx}>
                        <p style={{ fontStyle: 'bold' }}>Zephyr {specie}</p>
                        <p>PM2.5 - {z[specie].particulatePM25} incidents</p>
                    </div>
                }
            })
        }
    }

    return (
        <>
            {isTooltipShowing &&
                <div className='species-tooltip' style={{ display: 'flex', flexDirection: 'column', top: 42, right: 0, height: 'auto', borderRadius: 13, zIndex: 803, cursor: 'initial' }}>
                    <p>A number of breaches of WHO pollutant guidelines have been identified for the requested temporal range.</p>
                    <div style={{ display: 'flex', justifyContent: thresholdExceedingSpecies.length < 2 ? 'initial' : 'space-around' }}>

                        {getSpeciesWithExceededThresholds(tab.group)}
                    </div>
                </div>
            }
            <svg onMouseEnter={() => { setIsTooltipShowing(true) }} onMouseLeave={() => { setIsTooltipShowing(false) }} style={{ position: 'absolute', top: '50%', right: 0, transform: 'translate(-50%, -50%)' }} xmlns="http://www.w3.org/2000/svg"
                width="20" height="20" fill="currentColor"
                className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" fill="#ffa500"></path>
            </svg>
        </>
    )
};

export default ExceededThreshold;