import {
  AppIntroModelContent,
  HeaderContent,
  AQColourscaleContent,
  AsideOverlaysContentPublic,
  AsideOverlaysContent,
  DataTimePeriodOptionsContent,
  DataTimeAveragingOptionsContent,
  DataChartHeaderContent,
  TodayContent,
  naeiCRLink,
  osmCRLink,
  publicCRLink,
  mapboxCRLink,
  mapboxImproveLink,
  maxarCRLink,
  routePaths,
  annualAvgtext,
  customTimePeriod,
  aqlOtherTab,
  AirAlertsConfiguratorContent,
  AirAlertsOverviewContent,
  AirQuality101Content,
  AQLatestDataContent,
  AQOtherContent,
  AQModelDataContent,
  AQSAContent,
  AQTemporalContent,
  AQUnitContent,
  AQWindContent,
  AsideContent,
  AsidePromptContent,
  AsideUnitContent,
  PermissionCookieBannerContent,
  DataAQSummaryContent,
  DataChartDisclaimerContent,
  DataCustomDateContent,
  dataDiagnosticsModalContent,
  DataDownloadButtonContent,
  DataUnitsContent,
  DownloadModalContent,
  FeedbackQuestionsContent,
  FeedbackTagContent,
  HeaderContactModalContent,
  AddZephyrModalContent,
  RemoveZephyrModalContent,
  headerVersionHistoryModalContent,
  loginContent,
  MappairQuoteModalContent,
  LocationSearchContent,
  VersionLatestModalContent,
  ZephyrQuoteModalContent,
  WiFiCredentialsContent,
  maptilerCRLink,
} from '../../utils/consts';

const TRANSLATIONS_EN = {
  AppIntroButton: AppIntroModelContent.AppIntroButton,
  // #region HeaderContent
  AirQuality101Title: HeaderContent.AirQuality101Title,
  AnalyticsHeader: HeaderContent.AnalyticsHeader,
  AlertsHeader: HeaderContent.AlertsHeader,
  FleetManagementHeader: HeaderContent.FleetManagementHeader,
  DataAnalyticsHeader: HeaderContent.DataAnalyticsHeader,
  ContactLinkTitle: HeaderContent.ContactLinkTitle,
  CouncilProjectTitle: HeaderContent.CouncilProjectTitle,
  DataHeader: HeaderContent.DataHeader,
  DefaultHeaderTitle: HeaderContent.DefaultHeaderTitle,
  HelpHeader: HeaderContent.HelpHeader,
  LoadingAURNHeader: HeaderContent.LoadingAURNHeader,
  LoadingZephyrHeader: HeaderContent.LoadingZephyrHeader,
  LocationHeader: HeaderContent.LocationHeader,
  LocationHeaderPublic: HeaderContent.LocationHeaderPublic,
  LogoutLinkTitle: HeaderContent.LogoutLinkTitle,
  MappairUpgradePrompt: HeaderContent.MappairUpgradePrompt,
  MissingOverlayEnd: HeaderContent.MissingOverlayEnd,
  MissingOverlayIntro: HeaderContent.MissingOverlayIntro,
  MissingOverlayMid: HeaderContent.MissingOverlayMid,
  PrivacyLinkTitle: HeaderContent.PrivacyLinkTitle,
  ResourcesLinkTitle: HeaderContent.ResourcesLinkTitle,
  SourceEmissionTooltipIntro: HeaderContent.SourceEmissionTooltipIntro,
  SourceEmissionTooltipEnd: HeaderContent.SourceEmissionTooltipEnd,
  SourceEmissionTooltipMissingSpecies:
    HeaderContent.SourceEmissionTooltipMissingSpecies,
  SourceEmissionTooltipSelectedIntro:
    HeaderContent.SourceEmissionTooltipSelectedIntro,
  SourceEmissionTooltipSelectedEnd:
    HeaderContent.SourceEmissionTooltipSelectedEnd,
  SourcesSubHeader: HeaderContent.SourcesSubHeader,
  SpeciesSubHeader: HeaderContent.SpeciesSubHeader,
  TodayTitle: HeaderContent.TodayTitle,
  TooltipMarkerContent: HeaderContent.TooltipMarkerContent,
  TourLinkTitle: HeaderContent.TourLinkTitle,
  TutorialLinkTitle: HeaderContent.TutorialLinkTitle,
  VersionLinkTitle: HeaderContent.VersionLinkTitle,
  FeedbackPrompt: HeaderContent.FeedbackPrompt,
  // #endregion

  // #region AQColourscaleContent
  AQCButton: AQColourscaleContent.AQCButton,
  AQCButtonPublic: AQColourscaleContent.AQCButtonPublic,
  // #endregion

  OverlaysAQMA: AsideOverlaysContent.OverlaysAQMA,
  OverlaysSchools: AsideOverlaysContent.OverlaysSchools,
  OverlaysUKSmokeControlZones: AsideOverlaysContent.OverlaysUKSmokeControlZones,
  OverlaysAURN: AsideOverlaysContent.OverlaysAURN,
  OverlaysHeader: AsideOverlaysContent.OverlaysHeader,
  OverlaysDefault: AsideOverlaysContent.OverlaysDefault,
  OverlaysMappair: AsideOverlaysContent.OverlaysMappair,
  OverlaysRemoveAll: AsideOverlaysContent.OverlaysRemoveAll,
  OverlaysSatellite: AsideOverlaysContent.OverlaysSatellite,
  OverlaysOSM: AsideOverlaysContent.OverlaysOSM,
  OverlaysShowAll: AsideOverlaysContent.OverlaysShowAll,
  OverlaysZephyrs: AsideOverlaysContent.OverlaysZephyrs,
  MapDetails: AsideOverlaysContent.MapDetails,
  MapTypes: AsideOverlaysContent.MapTypes,
  OverlaysAA: AsideOverlaysContent.OverlaysAA,
  ModelLayers: AsideOverlaysContent.ModelLayers,
  ChooseYear: AsideOverlaysContent.ChooseYear,
  BaseMaps: AsideOverlaysContent.BaseMaps,
  //
  OverlaysMappairContentPublic: AsideOverlaysContentPublic.OverlaysMappair,
  OverlaysAQMAContentPublic: AsideOverlaysContentPublic.OverlaysAQMA,
  OverlaysSchoolsContentPublic: AsideOverlaysContentPublic.OverlaysSchools,
  OverlaysUKSmokeControlZonesPublic: AsideOverlaysContentPublic.OverlaysUKSmokeControlZones,
  OverlaysHeaderContentPublic: AsideOverlaysContentPublic.OverlaysHeader,
  OverlaysDefaultContentPublic: AsideOverlaysContentPublic.OverlaysDefault,
  OverlaysAAContentPublic: AsideOverlaysContent.OverlaysAA,
  //
  DataPOHeader: DataTimePeriodOptionsContent.DataPOHeader,
  DataPOSubheader: DataTimePeriodOptionsContent.DataPOSubheader,
  //
  DataTAHeader: DataTimeAveragingOptionsContent.DataTAHeader,
  DataTASubheader: DataTimeAveragingOptionsContent.DataTASubheader,
  //
  DataChartHeaderTzPrefix: DataChartHeaderContent.DataChartHeaderTzPrefix,
  DataChartHeaderTzUTC: DataChartHeaderContent.DataChartHeaderTzUTC,
  DataChartHeaderLoading: DataChartHeaderContent.DataChartHeaderLoading,
  DataChartHeaderNull: DataChartHeaderContent.DataChartHeaderNull,
  DataChartSomeTimedOut: DataChartHeaderContent.DataChartSomeTimedOut,
  DataChartAllTimedOut: DataChartHeaderContent.DataChartAllTimedOut,
  ZoomResetButton: DataChartHeaderContent.ZoomResetButton,
  vZephyrLoadingMsg: DataChartHeaderContent.vZephyrLoadingMsg,
  DataChart_240: DataChartHeaderContent.DataChart_240,
  DataChart_400: DataChartHeaderContent.DataChart_400,
  DataChart_401: DataChartHeaderContent.DataChart_401,
  DataChart_403: DataChartHeaderContent.DataChart_403,
  DataChart_404: DataChartHeaderContent.DataChart_404,
  DataChart_429: DataChartHeaderContent.DataChart_429,
  DataChart_500: DataChartHeaderContent.DataChart_500,

  //
  AdviceHeader: TodayContent.AdviceHeader,
  BehaviourHeader: TodayContent.BehaviourHeader,
  CloseModal: TodayContent.CloseModal,
  ForecastHeader: TodayContent.ForecastHeader,
  GeneralAdviceHeader: TodayContent.GeneralAdviceHeader,
  HealthAdviceHeader: TodayContent.HealthAdviceHeader,
  HumidityHeader: TodayContent.HumidityHeader,
  PollenHeader: TodayContent.PollenHeader,
  PollutionHeader: TodayContent.PollutionHeader,
  SensitiveAdviceHeader: TodayContent.SensitiveAdviceHeader,
  TempHeader: TodayContent.TempHeader,
  WoodBurningHeader: TodayContent.WoodBurningHeader,
  //
  Low: TodayContent.Low,
  High: TodayContent.High,

  // #region Copyright links and statements
  naeiCRLink: naeiCRLink.content,
  osmCRLink: osmCRLink.content,
  maptilerCRLink: maptilerCRLink.content,
  publicCRLink: publicCRLink.content,
  mapboxCRLink: mapboxCRLink.content,
  mapboxImproveLink: mapboxImproveLink.content,
  maxarCRLink: maxarCRLink.content,
  routePaths: routePaths.content,
  // #endregion

  // #region
  // Not sure if we need to translate route path, will cause lots of breakage
  alerts: routePaths.alerts,
  analytics: routePaths.analytics,
  app: routePaths.app,
  data: routePaths.data,
  login: routePaths.login,
  today: routePaths.today,
  // #endregion

  // #region annualAvgtext
  tableRowforYr: annualAvgtext.tableRowforYr,
  tabContentTitleForChartPrefix: annualAvgtext.tabContentTitleForChartPrefix,
  tabContentTitleForChartPostfix: annualAvgtext.tabContentTitleForChartPostfix,
  tabContentTitleForListPrefix: annualAvgtext.tabContentTitleForListPrefix,
  tabContentTitleForListPostfix: annualAvgtext.tabContentTitleForListPostfix,
  nodata: annualAvgtext.nodata,
  AAChartHeader: annualAvgtext.AAChartHeader,
  AADownloadData: annualAvgtext.AADownloadData,
  AASeeMore: annualAvgtext.AASeeMore,
  annualAvgNotAuthorised: annualAvgtext.annualAvgNotAuthorised,
  annualAvgAccordionTitle: annualAvgtext.annualAvgAccordionTitle,
  // #endregion

  aqlOtherTab: aqlOtherTab.label,
  customTimePeriod: customTimePeriod.labelHTML,

  // #region AirAlertsConfiguratorContent
  AACValidationFailure: AirAlertsConfiguratorContent.AACValidationFailure,
  AACDataSourceHeader: AirAlertsConfiguratorContent.AACDataSourceHeader,
  AACDataSourceSelection: AirAlertsConfiguratorContent.AACDataSourceSelection,
  AACValidationFailureSources:
    AirAlertsConfiguratorContent.AACValidationFailureSources,
  AACAlertDataHeader: AirAlertsConfiguratorContent.AACAlertDataHeader,
  AACGasColumn: AirAlertsConfiguratorContent.AACGasColumn,
  AACThresholdColumn: AirAlertsConfiguratorContent.AACThresholdColumn,
  AACAlertColumn: AirAlertsConfiguratorContent.AACAlertColumn,
  AACPeriodColumn: AirAlertsConfiguratorContent.AACPeriodColumn,
  AACRepeatColumn: AirAlertsConfiguratorContent.AACRepeatColumn,
  AACUnitStatusSelection: AirAlertsConfiguratorContent.AACUnitStatusSelection,
  AACValidationFailureData:
    AirAlertsConfiguratorContent.AACValidationFailureData,
  AACOutputHeader: AirAlertsConfiguratorContent.AACOutputHeader,
  AACOutputName: AirAlertsConfiguratorContent.AACOutputName,
  AACValidationFailureName:
    AirAlertsConfiguratorContent.AACValidationFailureName,
  AACOuputEmail: AirAlertsConfiguratorContent.AACOuputEmail,
  AACOutputPhoneNumber: AirAlertsConfiguratorContent.AACOutputPhoneNumber,
  AACValidationFailureEmail:
    AirAlertsConfiguratorContent.AACValidationFailureEmail,
  AACValidationFailurePhoneNumber: AirAlertsConfiguratorContent.AACValidationFailurePhoneNumber,
  AACValidationFailureCommunication: AirAlertsConfiguratorContent.AACValidationFailureCommunication,
  // #endregion

  // #region AirAlertsOverviewContent
  AAOHeader: AirAlertsOverviewContent.AAOHeader,
  AAOCreateNew: AirAlertsOverviewContent.AAOCreateNew,
  AAONameColumn: AirAlertsOverviewContent.AAONameColumn,
  AAOAlertDataColumn: AirAlertsOverviewContent.AAOAlertDataColumn,
  AAOStatusColumn: AirAlertsOverviewContent.AAOStatusColumn,
  AAOActionscolumn: AirAlertsOverviewContent.AAOActionscolumn,
  AAONoAlerts: AirAlertsOverviewContent.AAONoAlerts,
  // #endregion

  AQ101header: AirQuality101Content.AQ101header,

  // #region AQLatestDataContent
  AQAurnLabel: AQLatestDataContent.AQAurnLabel,
  AQLatestDataButton: AQLatestDataContent.AQLatestDataButton,
  AQZephyrLabel: AQLatestDataContent.AQZephyrLabel,
  AQVZephyrLabel: AQLatestDataContent.AQVZephyrLabel,
  // #endregion

  // #region AQOtherContent
  AQDisclaimerGFS: AQOtherContent.AQDisclaimerGFS,
  AQDisclaimerMet: AQOtherContent.AQDisclaimerMet,
  AQGFSDesc: AQOtherContent.AQGFSDesc,
  AQMetDesc: AQOtherContent.AQMetDesc,
  AQPeriodGFS: AQOtherContent.AQPeriodGFS,
  AQPeriodMet: AQOtherContent.AQPeriodMet,
  // #endregion

  // #region AQModelDataContent
  AQModelDataButton: AQModelDataContent.AQModelDataButton,
  AQModelDataLoading: AQModelDataContent.AQModelDataLoading,
  AQModelDataClickPrompt: AQModelDataContent.AQModelDataClickPrompt,
  AQModelNoData: AQModelDataContent.AQModelNoData,
  // #endregion

  // #region AQSAContent
  AQTotal: AQSAContent.AQTotal,
  AQUnits: AQSAContent.AQUnits,
  // #endregion

  // #region AQTemporalContent
  AQChartHeader: AQTemporalContent.AQChartHeader,
  AQCloseModal: AQTemporalContent.AQCloseModal,
  AQDownloadData: AQTemporalContent.AQDownloadData,
  // #endregion

  // #region AQUnitContent
  AQSummaryHeader: AQUnitContent.AQSummaryHeader,
  AQPeriod: AQUnitContent.AQPeriod,
  AQNoData: AQUnitContent.AQNoData,
  AQNoOwnership: AQUnitContent.AQNoOwnership,
  // #endregion

  // #region AQWindContent
  AQChartHeaderOne: AQWindContent.AQChartHeaderOne,
  AQChartSubheader: AQWindContent.AQChartSubheader,
  // #endregion

  // #region AsideContent
  AsideHeader: AsideContent.AsideHeader,
  PublicAsideHeader: AsideContent.PublicAsideHeader,
  // #endregion

  // #region AsidePromptContent
  AsideButtonContent: AsidePromptContent.AsideButtonContent,
  AsidePromptExplainer: AsidePromptContent.AsidePromptExplainer,
  AsideAnalyticsPrompt: AsidePromptContent.AsideAnalyticsPrompt,
  AsideAnalyticsButton: AsidePromptContent.AsideAnalyticsButton,
  // #endregion

  // #region AsideUnitContent
  AsideUnitBatteryCharging: AsideUnitContent.AsideUnitBatteryCharging,
  AsideUnitBatteryChargingDesc: AsideUnitContent.AsideUnitBatteryChargingDesc,
  AsideUnitBatteryDischarging: AsideUnitContent.AsideUnitBatteryDischarging,
  AsideUnitBatteryDischargingDesc:
    AsideUnitContent.AsideUnitBatteryDischargingDesc,
  AsideUnitBatteryExplainer: AsideUnitContent.AsideUnitBatteryExplainer,
  AsideUnitBatteryFlat: AsideUnitContent.AsideUnitBatteryFlat,
  AsideUnitBatteryFlatDesc: AsideUnitContent.AsideUnitBatteryFlatDesc,
  AsideUnitBatteryPower: AsideUnitContent.AsideUnitBatteryPower,
  AsideUnitCartridgeBlank: AsideUnitContent.AsideUnitCartridgeBlank,
  AsideUnitCartridgeExplainerStandard:
    AsideUnitContent.AsideUnitCartridgeExplainerStandard,
  AsideUnitCartridgeExplainerEnhanced:
    AsideUnitContent.AsideUnitCartridgeExplainerEnhanced,
  AsideUnitCartridgeExplainerEnhancedPlus:
    AsideUnitContent.AsideUnitCartridgeExplainerEnhancedPlus,
  AsideUnitCartridgeExplainderEnhancedPP:
    AsideUnitContent.AsideUnitCartridgeExplainderEnhancedPP,
  AsideUnitConnectionGSM: AsideUnitContent.AsideUnitConnectionGSM,
  AsideUnitConnectionNetwork: AsideUnitContent.AsideUnitConnectionNetwork,
  AsideUnitLocationHeader: AsideUnitContent.AsideUnitLocationHeader,
  AsideUnitUpdatedHeader: AsideUnitContent.AsideUnitUpdatedHeader,
  AsideUnitIdHeader: AsideUnitContent.AsideUnitIdHeader,
  AsideUnitStatusHeader: AsideUnitContent.AsideUnitStatusHeader,
  AsideUnitFrequencyHeader: AsideUnitContent.AsideUnitFrequencyHeader,
  AsideUnitCertificateDownloadButton:
    AsideUnitContent.AsideUnitCertificateDownloadButton,
  AsideUnitConnectionExplainer: AsideUnitContent.AsideUnitConnectionExplainer,
  AsideUnitConnectionWarning: AsideUnitContent.AsideUnitConnectionWarning,
  AsideUnitConnectionAlert: AsideUnitContent.AsideUnitConnectionAlert,
  AsideUnitEditName: AsideUnitContent.AsideUnitEditName,
  VZUnitDeleteName: AsideUnitContent.VZUnitDeleteName,
  AsideUnitEditNetwork: AsideUnitContent.AsideUnitEditNetwork,
  AsideUnitDeleteName: AsideUnitContent.AsideUnitDeleteName,
  AsideUnitOwnership: AsideUnitContent.AsideUnitOwnership,
  AsideUnitSamplingData: AsideUnitContent.AsideUnitSamplingData,
  AsideUnitSamplingExplainerHigh:
    AsideUnitContent.AsideUnitSamplingExplainerHigh,
  AsideUnitSamplingExplainerMed: AsideUnitContent.AsideUnitSamplingExplainerMed,
  AsideUnitSamplingExplainerLow: AsideUnitContent.AsideUnitSamplingExplainerLow,
  AsideUnitSamplingOff: AsideUnitContent.AsideUnitSamplingOff,
  AsideUnitSamplingOn: AsideUnitContent.AsideUnitSamplingOn,
  AsideUnitStatus: AsideUnitContent.AsideUnitStatus,
  AsideUnitStatusRetired: AsideUnitContent.AsideUnitStatusRetired,
  AsideUnitType: AsideUnitContent.AsideUnitType,
  AsideUnitTypeAURN: AsideUnitContent.AsideUnitTypeAURN,
  AsideUnitTypeVirtualZephyr: AsideUnitContent.AsideUnitTypeVirtualZephyr,
  AsideUnitTypeZephyr: AsideUnitContent.AsideUnitTypeZephyr,
  // #endregion

  EditWifiCredentials: WiFiCredentialsContent.EditWifiCredentials,
  EditWifiCloseModal: WiFiCredentialsContent.EditWifiCloseModal,
  EditWifiSaveButton: WiFiCredentialsContent.EditWifiSaveButton,
  StaticWiFiCredentials: WiFiCredentialsContent.StaticWiFiCredentials,
  DynamicWiFiCredentials: WiFiCredentialsContent.DynamicWiFiCredentials,
  NetworkNameLabel: WiFiCredentialsContent.NetworkNameLabel,
  NetworkPasswordLabel: WiFiCredentialsContent.NetworkPasswordLabel,
  NetworkCredentialsError: WiFiCredentialsContent.NetworkCredentialsError,

  // #region PermissionCookieBannerContent
  PCBExplainer: PermissionCookieBannerContent.PCBExplainer,
  PCBExplainerPreSpan: PermissionCookieBannerContent.PCBExplainerPreSpan,
  PCBExplainerSpan: PermissionCookieBannerContent.PCBExplainerSpan,
  PCBExplainPostSpan: PermissionCookieBannerContent.PCBExplainPostSpan,
  PCBButtonAccept: PermissionCookieBannerContent.PCBButtonAccept,
  PCBButtonReject: PermissionCookieBannerContent.PCBButtonReject,
  PCBButtonOk: PermissionCookieBannerContent.PCBButtonOk,
  // #endregion

  DataAQSummaryHeader: DataAQSummaryContent.DataAQSummaryHeader,
  DataChartDisclaimer: DataChartDisclaimerContent.DataChartDisclaimer,
  DataCustomDatePickerMessage:
    DataCustomDateContent.DataCustomDatePickerMessage,
  DataCustomeDateSubmit: DataCustomDateContent.DataCustomeDateSubmit,
  DataCustomDateSave: DataCustomDateContent.DataCustomDateSave,

  // #region
  dataDiagnosticsHeader: dataDiagnosticsModalContent.dataDiagnosticsHeader,
  dataDiagnosticsIndex: dataDiagnosticsModalContent.dataDiagnosticsIndex,
  dataDiagnosticsModalContentClose:
    dataDiagnosticsModalContent.dataDiagnosticsModalContentClose,
  // #endregion

  // #region
  DataDownloadButtonText: DataDownloadButtonContent.DataDownloadButtonText,
  DataDiagnosticsButtonText:
    DataDownloadButtonContent.DataDiagnosticsButtonText,
  // #endregion

  // #region
  DataUnitsHeaderMultiUnits: DataUnitsContent.DataUnitsHeaderMultiUnits,
  DataUnitsRemoveUnit: DataUnitsContent.DataUnitsRemoveUnit,
  DataUnitsSingleUnit: DataUnitsContent.DataUnitsSingleUnit,
  DataUnitsTooltipContent: DataUnitsContent.DataUnitsTooltipContent,
  DataUnitsTooltipMakerContent: DataUnitsContent.DataUnitsTooltipMakerContent,
  // #endregion

  // #region
  downloadHeader: DownloadModalContent.downloadHeader,
  downloadSubheader: DownloadModalContent.downloadSubheader,
  downloadCoordinates: DownloadModalContent.downloadCoordinates,
  downloadButton: DownloadModalContent.downloadButton,
  // #endregion

  // #region
  feedbackQuestionsHeader: FeedbackQuestionsContent.feedbackQuestionsHeader,
  feedbackScoreLabelsLow: FeedbackQuestionsContent.feedbackScoreLabelsLow,
  feedbackScoreLabelsHigh: FeedbackQuestionsContent.feedbackScoreLabelsHigh,
  feedbackCommentsPlaceholder:
    FeedbackQuestionsContent.feedbackCommentsPlaceholder,
  feedbackButtonNext: FeedbackQuestionsContent.feedbackButtonNext,
  feedbackEmailHeader: FeedbackQuestionsContent.feedbackEmailHeader,
  feedbackOptional: FeedbackQuestionsContent.feedbackOptional,
  feedbackConfirmationHeader:
    FeedbackQuestionsContent.feedbackConfirmationHeader,
  feedbackConfirmationText: FeedbackQuestionsContent.feedbackConfirmationText,
  feedbackButtonConfirmation:
    FeedbackQuestionsContent.feedbackButtonConfirmation,
  feedbackTagButton: FeedbackTagContent.feedbackTagButton,
  // #endregion

  // #region
  confirmationHeader: HeaderContactModalContent.confirmationHeader,
  confirmationText: HeaderContactModalContent.confirmationText,
  contactHeader: HeaderContactModalContent.contactHeader,
  contactSubheader: HeaderContactModalContent.contactSubheader,
  contactFirstName: HeaderContactModalContent.contactFirstName,
  contactLastName: HeaderContactModalContent.contactLastName,
  contactEmail: HeaderContactModalContent.contactEmail,
  contactCompany: HeaderContactModalContent.contactCompany,
  contactDetails: HeaderContactModalContent.contactDetails,
  contactEnquiry: HeaderContactModalContent.contactEnquiry,
  contactComments: HeaderContactModalContent.contactComments,
  contactButton: HeaderContactModalContent.contactButton,
  messageSuccess: HeaderContactModalContent.messageSuccess,
  messageProblem: HeaderContactModalContent.messageProblem,
  messageMissingFields: HeaderContactModalContent.messageMissingFields,
  requiredFields: HeaderContactModalContent.requiredFields,
  // #endregion

  // #region
  popoverCTAText: AddZephyrModalContent.popoverCTAText,
  headerText: AddZephyrModalContent.headerText,
  zephyrNameLabel: AddZephyrModalContent.zephyrNameLabel,
  secondaryCta: AddZephyrModalContent.secondaryCta,
  primaryCta1: AddZephyrModalContent.primaryCta1,
  totalPrefix: AddZephyrModalContent.totalPrefix,
  totalPostfix: AddZephyrModalContent.totalPostfix,
  maxReachPrefix: AddZephyrModalContent.maxReachPrefix,
  limitReachedSubtitle: AddZephyrModalContent.limitReachedSubtitle,
  contactUsCtaTxt: AddZephyrModalContent.contactUsCtaTxt,
  // #endregion

  // #region
  RemoveZephyrHeaderText: RemoveZephyrModalContent.RemoveZephyrHeaderText,
  confirmationTextPrefix: RemoveZephyrModalContent.confirmationTextPrefix,
  confirmationTextPostFix: RemoveZephyrModalContent.confirmationTextPostFix,
  warningText: RemoveZephyrModalContent.warningText,
  RemoveZephyrSecondaryCta: RemoveZephyrModalContent.RemoveZephyrSecondaryCta,
  primaryCta: RemoveZephyrModalContent.primaryCta,
  // #endregion

  // #region
  versionHistoryHeader: headerVersionHistoryModalContent.versionHistoryHeader,
  currentVersionDescriptor:
    headerVersionHistoryModalContent.currentVersionDescriptor,
  // #endregion

  // #region
  incorrectPassword: loginContent.incorrectPassword,
  loginHeader: loginContent.loginHeader,
  welcomeMessage: loginContent.welcomeMessage,
  loginButton: loginContent.loginButton,
  heroHeader: loginContent.heroHeader,
  heroSubheader: loginContent.heroSubheader,
  usernameField: loginContent.usernameField,
  passwordField: loginContent.passwordField,
  // #endregion

  // #region
  aoi: MappairQuoteModalContent.aoi,
  area: MappairQuoteModalContent.area,
  centre: MappairQuoteModalContent.centre,
  comments: MappairQuoteModalContent.comments,
  MappairQuoteConfirmationHeader:
    MappairQuoteModalContent.MappairQuoteConfirmationHeader,
  MappairQuoteConfirmationText:
    MappairQuoteModalContent.MappairQuoteConfirmationText,
  MappairQuoteContactHeader: MappairQuoteModalContent.MappairQuoteContactHeader,
  MappairQuoteContactFirstName:
    MappairQuoteModalContent.MappairQuoteContactFirstName,
  MappairQuoteContactLastName:
    MappairQuoteModalContent.MappairQuoteContactLastName,
  MappairQuoteContactEmail: MappairQuoteModalContent.MappairQuoteContactEmail,
  MappairQuoteContactCompany:
    MappairQuoteModalContent.MappairQuoteContactCompany,
  MappairQuoteContactButton: MappairQuoteModalContent.MappairQuoteContactButton,
  MappairQuoteCloseModal: MappairQuoteModalContent.MappairQuoteCloseModal,
  mappairLinkPost: MappairQuoteModalContent.mappairLinkPost,
  mappairLinkPre: MappairQuoteModalContent.mappairLinkPre,
  mappairOptionalCommentsPlaceholder:
    MappairQuoteModalContent.mappairOptionalCommentsPlaceholder,
  mappairMessageProblem: MappairQuoteModalContent.mappairMessageProblem,
  mapairMessageMissingFields:
    MappairQuoteModalContent.mapairMessageMissingFields,
  output: MappairQuoteModalContent.output,
  polygon: MappairQuoteModalContent.polygon,
  pollutants: MappairQuoteModalContent.pollutants,
  mapairRequiredFields: MappairQuoteModalContent.mapairRequiredFields,
  resolution: MappairQuoteModalContent.resolution,
  selectArea: MappairQuoteModalContent.selectArea,
  // #endregion

  // #region
  LocationSearchPlaceholder: LocationSearchContent.LocationSearchPlaceholder,
  InvalidSearchPlaceholder: LocationSearchContent.InvalidSearchPlaceholder,
  // #endregion

  // #region
  updateTitle: VersionLatestModalContent.updateTitle,
  // #endregion

  // #region
  ZephyrConfirmationHeader: ZephyrQuoteModalContent.ZephyrConfirmationHeader,
  ZephyrConfirmationText: ZephyrQuoteModalContent.ZephyrConfirmationText,
  ZephyrContactHeader: ZephyrQuoteModalContent.ZephyrContactHeader,
  ZephyrContactFirstName: ZephyrQuoteModalContent.ZephyrContactFirstName,
  ZephyrContactLastName: ZephyrQuoteModalContent.ZephyrContactLastName,
  ZephyrContactEmail: ZephyrQuoteModalContent.ZephyrContactEmail,
  ZephyrContactCompany: ZephyrQuoteModalContent.ZephyrContactCompany,
  ZephyrContactButton: ZephyrQuoteModalContent.ZephyrContactButton,
  measurements: ZephyrQuoteModalContent.measurements,
  ZephyrContactMessageProblem:
    ZephyrQuoteModalContent.ZephyrContactMessageProblem,
  ZephyrContactMessageMissingFields:
    ZephyrQuoteModalContent.ZephyrContactMessageMissingFields,
  powerOptions: ZephyrQuoteModalContent.powerOptions,
  ZephyrQuoteRequiredFields: ZephyrQuoteModalContent.ZephyrQuoteRequiredFields,
  zephyrs: ZephyrQuoteModalContent.zephyrs,
  // #endregion
};

export { TRANSLATIONS_EN };
