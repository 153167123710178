// Types Imports=
import {
  SET_UNIT_HISTORIES,
  SET_UNIT_HISTORIES_LOADING,
  SET_THRESHOLD_LIMITS,
  SET_ZEPHYR_COVERAGES_LIST,
  SET_IS_DATA_AVAILABILITY_CHART_LOADING,
  SET_DSE_CHECKBOX_STATE,
  INCREASE_ARRIVED_REQUESTS,
  SET_ARRIVED_REQUESTS_TO_INIT_STATE,
  SET_NULLABLE_UNITS,
  SET_PROCEED_WITH_ANALYSIS,
  SET_IS_VIEW_LOADED,
  SET_IS_CARTRIDGE_DATA_LOADING,
} from '../actions/types';

// Type Imports
import {
  UnitHistoriesOptions,
  Zephyr,
  ZephyrCoverages,
} from '../utils/interface';

interface setUnitHistoriesOptions {
  type:
  | typeof SET_UNIT_HISTORIES
  | typeof SET_UNIT_HISTORIES_LOADING
  | typeof SET_THRESHOLD_LIMITS
  | typeof SET_ZEPHYR_COVERAGES_LIST
  | typeof SET_IS_DATA_AVAILABILITY_CHART_LOADING
  | typeof SET_DSE_CHECKBOX_STATE
  | typeof INCREASE_ARRIVED_REQUESTS
  | typeof SET_ARRIVED_REQUESTS_TO_INIT_STATE
  | typeof SET_NULLABLE_UNITS
  | typeof SET_PROCEED_WITH_ANALYSIS
  | typeof SET_IS_VIEW_LOADED
  | typeof SET_IS_CARTRIDGE_DATA_LOADING;
  payload: any;
  // payload: any | Zephyr | ZephyrCoverages[];
}

export const thresholdLimitsInitState = {
  thresholds: {
    lowerThreshold: {
      title: '',
      value: null,
    },
    upperThreshold: {
      title: '',
      value: null,
    }
  },
  hidden: false
};

//State
const initState: UnitHistoriesOptions = {
  unitHistories: [],
  isUnitHistoriesLoading: false,
  thresholdLimits: thresholdLimitsInitState,
  zephyrCoveragesList: [],
  isDataAvailabilityChartLoading: false,
  dseOn: false,
  requests: 0,
  nullableUnits: [],
  toProceed: null,
  viewLoaded: false,
  isCartridgeDataLoading: null,
};

// Reducer
export default function (state = initState, action: setUnitHistoriesOptions) {
  const { type, payload } = action;

  switch (type) {
    case SET_UNIT_HISTORIES:
      return {
        ...state,
        unitHistories: payload,
      };

    case SET_UNIT_HISTORIES_LOADING:
      return {
        ...state,
        isUnitHistoriesLoading: payload,
      };

    case SET_THRESHOLD_LIMITS:
      return {
        ...state,
        thresholdLimits: payload,
      };

    case SET_ZEPHYR_COVERAGES_LIST:
      const { name, zNumber, type } = payload;
      const zephyrExists = state.zephyrCoveragesList.find(
        (zephyr: ZephyrCoverages) => zephyr.zephyrNumber === zNumber,
      );
      if (zephyrExists) {
        const zephyr =
          state.zephyrCoveragesList[
          state.zephyrCoveragesList.indexOf(zephyrExists)
          ];
        zephyr.isSelected = !zephyr.isSelected;
      }
      const newState = !zephyrExists
        ? [
          ...state.zephyrCoveragesList,
          {
            name,
            zephyrNumber: zNumber,
            zType: type,
            isSelected: true,
            requestMade: false,
            coverageData: null,
          },
        ]
        : [...state.zephyrCoveragesList];
      return {
        ...state,
        zephyrCoveragesList: 'zNumber' in payload ? newState : payload,
      };

    case SET_IS_DATA_AVAILABILITY_CHART_LOADING:
      return {
        ...state,
        isDataAvailabilityChartLoading: payload,
      };
    case SET_DSE_CHECKBOX_STATE:
      return {
        ...state,
        dseOn: !state.dseOn,
      };
    case INCREASE_ARRIVED_REQUESTS:
      return {
        ...state,
        requests: ++state.requests,
      };
    case SET_ARRIVED_REQUESTS_TO_INIT_STATE:
      return {
        ...state,
        requests: 0,
      };
    case SET_NULLABLE_UNITS:
      return {
        ...state,
        nullableUnits:
          typeof payload === 'number' ? [...state.nullableUnits, payload] : [],
      };
    case SET_PROCEED_WITH_ANALYSIS:
      return {
        ...state,
        toProceed: payload,
      };
    case SET_IS_VIEW_LOADED:
      return {
        ...state,
        viewLoaded: payload,
      };
    case SET_IS_CARTRIDGE_DATA_LOADING:
      return {
        ...state,
        isCartridgeDataLoading: payload,
      };
    default:
      return state;
  }
}
