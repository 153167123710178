// Package Imports
import React from 'react';

// Component Interfaces
interface ConfirmationProps {
  additionalClass?: string;
  buttonText?: string;
  confirmationOpen: Function;
  header: string;
  showTick: boolean;
  text: string;
}

// Component
const Confirmation = ({
  additionalClass,
  buttonText,
  confirmationOpen,
  header,
  showTick,
  text,
}: ConfirmationProps) => (
  <div
    className={`modal-container confirmation-modal-container ${additionalClass}`}
  >
    {showTick ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 24 24"
        fill="#4E73C3"
      >
        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" />
      </svg>
    ) : (
      <></>
    )}
    <h4 className="confirmation-header">{header}</h4>
    <p className="confirmation-text">{text}</p>
    <button
      type="button"
      className="button primary send-modal confirmation-button"
      onClick={() => confirmationOpen(false)}
    >
      {buttonText}
    </button>
  </div>
);

// Default Props
Confirmation.defaultProps = {
  additionalClass: '',
  buttonText: 'Done',
};

export default Confirmation;
