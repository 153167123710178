import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { startOfDay, subDays, subWeeks, subMonths } from './utils';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
`;

const PresetContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 0.875rem;
  padding: 16px;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 14px;
`;

const RadioInput = styled.input`
  cursor: pointer;
`;

type DateRange = Array<Date | null>;
type PresetValue = '24' | '48' | 'week' | 'month' | null;

interface DateRangePickerProps {
  value?: DateRange;
  defaultValue?: DateRange;
  onChange?: (dateRange: DateRange) => void;
  preset?: PresetValue;
  onPresetChange?: (preset: PresetValue) => void;
}

const DateRangePicker = ({
  value,
  defaultValue = [null, null],
  onChange,
  preset,
  onPresetChange,
}: DateRangePickerProps) => {
  const [internalDateRange, setInternalDateRange] = useState<DateRange>(
    defaultValue,
  );
  const [internalPreset, setInternalPreset] = useState<PresetValue>(null);

  const isControlled = value !== undefined;
  const isPresetControlled = preset !== undefined;
  const dateRange = isControlled ? value : internalDateRange;
  const activePreset = isPresetControlled ? preset : internalPreset;

  const handleDateChange = (update: DateRange) => {
    if (!isControlled) {
      setInternalDateRange(update);
    }
    onChange?.(update);
    handlePresetChange(null);
  };

  const handlePresetChange = (newPreset: PresetValue) => {
    if (!isPresetControlled) {
      setInternalPreset(newPreset);
    }
    onPresetChange?.(newPreset);
  };

  const setPresetRange = (start: Date, end: Date, presetValue: PresetValue) => {
    const newRange: DateRange = [start, end];
    if (!isControlled) {
      setInternalDateRange(newRange);
    }
    onChange?.(newRange);
    handlePresetChange(presetValue);
  };

  const now = new Date();
  const presets = [
    {
      label: 'Last 24 hours',
      value: '24' as const,
      start: subDays(startOfDay(now), 1),
      end: now,
    },
    {
      label: 'Last 48 hours',
      value: '48' as const,
      start: subDays(startOfDay(now), 2),
      end: now,
    },
    {
      label: 'Last week',
      value: 'week' as const,
      start: subWeeks(startOfDay(now), 1),
      end: now,
    },
    {
      label: 'Last month',
      value: 'month' as const,
      start: subMonths(startOfDay(now), 1),
      end: now,
    },
  ];

  return (
    <Container>
      <PresetContainer>
        {presets.map((presetOption) => (
          <RadioLabel key={presetOption.value}>
            <RadioInput
              type="radio"
              name="datePreset"
              value={presetOption.value}
              checked={activePreset === presetOption.value}
              onChange={() =>
                setPresetRange(
                  presetOption.start,
                  presetOption.end,
                  presetOption.value,
                )
              }
            />
            {presetOption.label}
          </RadioLabel>
        ))}
      </PresetContainer>
      <DatePicker
        selectsRange
        startDate={dateRange[0]}
        endDate={dateRange[1]}
        onChange={handleDateChange}
        monthsShown={2}
        inline
      />
    </Container>
  );
};

export default DateRangePicker;
