import { SlotOptions, ZephyrHistory, ZephyrHistoryWithId } from "../interface";
import { slotFinder } from "./slotFinder";

const slotValidator = (newZephyrHistory: ZephyrHistory, unitHistories?: ZephyrHistoryWithId | null): SlotOptions[] => {
    const unitValues: ZephyrHistory[] | null = unitHistories ? Object.values(unitHistories) : null;
    let availableSlots: SlotOptions[] = [];
    if (unitValues && unitValues.length > 0) {
        for (let i = 0; i < unitValues.length; i++) {
            const slots = slotFinder(unitValues[i]);
            for (const [_, value] of Object.entries(unitValues[i])) {
                if (slots) {
                    slots.map((v: SlotOptions) => {
                        const availableSpeciesArr = getAvailableSpecies(value[v]);
                        const availableSlotsArr: any = [];
                        availableSpeciesArr?.forEach((s: any) => {
                            const speciesData = value[v]![s].data;
                            availableSlotsArr.push(...speciesData);
                        })
                        const isNull = availableSlotsArr?.every((v: number | null) => v === null);
                        if (availableSlotsArr && !isNull) {
                            if (!availableSlots.includes(v))
                                availableSlots.push(v);
                        }
                    })
                }
            }
        }
    } else if (newZephyrHistory) {
        const slots = slotFinder(newZephyrHistory);
        for (const [_, value] of Object.entries(newZephyrHistory)) {
            if (slots) {
                slots.map((v: SlotOptions) => {
                    const availableSpeciesArr = getAvailableSpecies(value[v]);
                    const availableSlotsArr: any = [];
                    availableSpeciesArr?.forEach((s: any) => {
                        const speciesData = value[v]![s].data;
                        availableSlotsArr.push(...speciesData);
                    })
                    const isNull = availableSlotsArr?.every((v: number | null) => v === null);
                    if (availableSlotsArr && !isNull) {
                        if (!availableSlots.includes(v))
                            availableSlots.push(v);
                    }
                })
            }
        }
    }
    return availableSlots;
}


export const getAvailableSpecies = (slot: any) => {
    if (!slot) return null
    const slotKeys = Object.keys(slot);
    return slotKeys.filter((s: string) => {
        if (s !== 'UTS' && s !== 'dateTime' && s !== 'humidity' && s !== 'localDateTime' && s !== 'tempC' && s !== 'latitude' && s !== 'longitude') return s;
    })
}

export { slotValidator };
