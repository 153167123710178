// Type Imports
import { SET_AVERAGING_PERIODS } from '../actions/types';

// Types Imports
import { AveragingPeriods } from '../utils/interface';

// Type Safety
interface SetAveragingPeriods {
  type: typeof SET_AVERAGING_PERIODS;
  payload: AveragingPeriods;
}

// State
const initState: AveragingPeriods = { periods: [] };

// Reducer
export default function (state = initState, action: SetAveragingPeriods) {
  const { type, payload } = action;

  switch (type) {
    case SET_AVERAGING_PERIODS:
      return payload;

    default:
      return state;
  }
}
