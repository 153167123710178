// Util Imports
import { Dispatch } from 'redux';
import utilsRequest from '../utils/request';

// Type Imports
import {
  TOUR_LAUNCHED,
  TOUR_FINISHED,
  TOUR_OPEN_ASIDE_FILTER,
  TOUR_CLOSE_ASIDE_FILTER,
  TOUR_OPEN_AQ_DATA_INDEX,
  TOUR_CLOSE_AQ_DATA_INDEX,
  TOUR_OPEN_AQ_COLORSCALE_INDEX,
  TOUR_CLOSE_AQ_COLORSCALE_INDEX,
  TOUR_OPEN_AQMA_INDEX,
  TOUR_CLOSE_AQMA_INDEX,
  TOUR_OPEN_ASIDE,
  TOUR_CLOSE_ASIDE,
  TOUR_OPEN_HELP,
  TOUR_CLOSE_HELP,
  TOUR_OPEN_LOCATION_SEARCH,
  TOUR_CLOSE_LOCATION_SEARCH,
  TOUR_MAP_CLICK,
  TOUR_CLOSE_MODEL_DATA_INDEX,
  TOUR_OPEN_MODEL_DATA_INDEX,
} from './types';

// Action Creators
export const startTour = () => (dispatch: Dispatch) => {
  dispatch({
    type: TOUR_LAUNCHED,
  });
};

export const closeTour = (bearerToken: string | null) => (dispatch: Dispatch) => {
  dispatch({
    type: TOUR_FINISHED,
  });
  //need to get bearer token here somehow
  utilsRequest.setShowTour(false, bearerToken);
};

export const tourOpenAsideFilter = () => (dispatch: Dispatch) => {
  dispatch({
    type: TOUR_OPEN_ASIDE_FILTER,
  });
};

export const tourCloseAsideFilter = () => (dispatch: Dispatch) => {
  dispatch({
    type: TOUR_CLOSE_ASIDE_FILTER,
  });
};

export const tourOpenAQDataIndex = () => (dispatch: Dispatch) => {
  dispatch({
    type: TOUR_OPEN_AQ_DATA_INDEX,
  });
};

export const tourCloseAQDataIndex = () => (dispatch: Dispatch) => {
  dispatch({
    type: TOUR_CLOSE_AQ_DATA_INDEX,
  });
};

export const tourOpenAQColorscaleIndex = () => (dispatch: Dispatch) => {
  dispatch({
    type: TOUR_OPEN_AQ_COLORSCALE_INDEX,
  });
};

export const tourCloseAQColorscaleIndex = () => (dispatch: Dispatch) => {
  dispatch({
    type: TOUR_CLOSE_AQ_COLORSCALE_INDEX,
  });
};

export const tourOpenAQMAIndex = () => (dispatch: Dispatch) => {
  dispatch({
    type: TOUR_OPEN_AQMA_INDEX,
  });
};

export const tourCloseAQMAIndex = () => (dispatch: Dispatch) => {
  dispatch({
    type: TOUR_CLOSE_AQMA_INDEX,
  });
};

export const tourOpenAside = () => (dispatch: Dispatch) => {
  dispatch({
    type: TOUR_OPEN_ASIDE,
  });
};

export const tourCloseAside = () => (dispatch: Dispatch) => {
  dispatch({
    type: TOUR_CLOSE_ASIDE,
  });
};

export const tourOpenHelp = () => (dispatch: Dispatch) => {
  dispatch({
    type: TOUR_OPEN_HELP,
  });
};

export const tourCloseHelp = () => (dispatch: Dispatch) => {
  dispatch({
    type: TOUR_CLOSE_HELP,
  });
};

export const tourOpenLocationSearch = () => (dispatch: Dispatch) => {
  dispatch({
    type: TOUR_OPEN_LOCATION_SEARCH,
  });
};

export const tourCloseLocationSearch = () => (dispatch: Dispatch) => {
  dispatch({
    type: TOUR_CLOSE_LOCATION_SEARCH,
  });
};

export const tourMapClick = () => (dispatch: Dispatch) => {
  dispatch({
    type: TOUR_MAP_CLICK,
  });
};

export const tourCloseModelDataIndex = () => (dispatch: Dispatch) => {
  dispatch({
    type: TOUR_CLOSE_MODEL_DATA_INDEX,
  });
};

export const tourOpenModelDataIndex = () => (dispatch: Dispatch) => {
  dispatch({
    type: TOUR_OPEN_MODEL_DATA_INDEX,
  });
};
