import html2canvas from 'html2canvas';
import React from 'react';
import { CSVLink } from 'react-csv';
import { csvHeaders } from '../../utils/consts';

interface FleetManagementGeneratorProps {
    tableData: any[],
    table: React.MutableRefObject<any>,
    csvOption: boolean | null,
    setCsvOption: Function,
};

const FleetManagementGenerator = ({ tableData, table, csvOption, setCsvOption }: FleetManagementGeneratorProps) => {

    const updateCsvOption = (event: any) => {
        if (!csvOption && event.target.value === 'cur-entries') { setCsvOption(true); return; }
        setCsvOption(false);
    };

    const tableToCSV = () => {
        var csv_data: any = [];

        const getTable = document.getElementById('table_id');
        var rows = getTable!.getElementsByTagName('tr');
        for (var i = 0; i < rows.length; i++) {
            if (rows[i].classList.contains('odd') || rows[i].classList.contains('even') || rows[i].querySelector('.sorting') !== null) {
                // Get each column data
                var cols = rows[i].querySelectorAll('th, td');
                // Stores each csv row data
                var csvrow = [];
                for (var j = 0; j < cols.length; j++) {
                    // Get the text data of each cell of a row and push it to csvrow
                    if (!cols[j].classList.contains('dt-control')) csvrow.push(cols[j].innerHTML);
                }
                // Combine each column value with comma
                csv_data.push(csvrow.join(","));
            }
        }
        // combine each row data with new line character
        csv_data = csv_data.join('\n');
        downloadCSVFile(csv_data);
    };

    const downloadCSVFile = (csv_data: any) => {

        let CSVFile = new Blob([csv_data], { type: "text/csv" });
        var temp_link = document.createElement('a');
        temp_link.download = "Fleet_Management.csv";
        var url = window.URL.createObjectURL(CSVFile);
        temp_link.href = url;

        temp_link.style.display = "none";
        document.body.appendChild(temp_link);

        temp_link.click();
        document.body.removeChild(temp_link);
    };


    const exportAsPNG = async (element: any, imageFileName: string) => {
        const canvas = await html2canvas(element);
        const image = canvas.toDataURL("image/png", 1.0);
        downloadImage(image, imageFileName);
    };

    const downloadImage = (blob: any, fileName: any) => {
        const temp_link = window.document.createElement("a");
        temp_link.style.display = 'none';
        temp_link.download = fileName;
        temp_link.href = blob;
        document.body.appendChild(temp_link);
        temp_link.click();
        document.body.removeChild(temp_link);
        temp_link.remove();
    };

    const processTableDataForCSV = () => {
        const processedData = tableData.map((v) => {
            const lastIdx = v.length - 1;
            return v.slice(1, lastIdx);
        });
        processedData.unshift(csvHeaders);
        return processedData;
    };

    return (
        <div className='csv-screenshot-generation-elements__holder'>
            <div className='csv-generation-elements__container'>
                <select name="csv-generation" id="csvGeneration" className='csv-generation' onChange={updateCsvOption}>
                    <option value="generate-csv-by" disabled selected>Generate CSV By:</option>
                    <option value="cur-entries">Current Entries</option>
                    <option value="all-entries">All Entries</option>
                </select>
                <button className='table-csv-generating-button prompt-button' onClick={csvOption === true ? tableToCSV : undefined}>{csvOption === false ? <CSVLink data={processTableDataForCSV()} >Generate CSV</CSVLink> : <a>Generate CSV</a>}</button>
                <button onClick={() => { exportAsPNG(table.current, 'fleet-management_table') }} className='table-screenshot-button prompt-button'>Take Screenshot</button>
            </div>
        </div>
    )
};

export default FleetManagementGenerator;