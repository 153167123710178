// Util Imports
import { current } from './dateFinder';

// Types Imports
import {
  AirAlerts,
  AlertObject,
  AlertPeriod,
  AlertRepeat,
  AlertThreshold,
  APIAirAlert,
  AlertModel,
  Auth,
  SpeciesDataIdentifiers,
  BatteryAlert,
    APIAirAlertTarget,
    SpeciesAlert,
    SpeciesData,
    APIAirAlertParameter
} from '../interface';

const repeatMapper: { [key: number]: AlertRepeat } = {
  0: 'never',
  60: 'hourly',
  1440: 'daily',
};

const periodMapperReverse: { [key: string]: number } = {
  instant: 0,
  '15 min': 15,
  '30 min': 30,
  '1 hr': 60,
};

const repeatMapperRerverse: { [key: string]: number } = {
  never: 0,
  hourly: 60,
  daily: 1440,
};

// Functions
/* Function
DESC: airAlertsApiPackager returns a packaged and formatted air alerts state, translating the air alerts api format
ARGS: an api GetAlerts response
*/
const airAlertsApiPackager = (apiAlerts: APIAirAlert[]) => {
    const packagedAirAlerts = apiAlerts.map((aA) => {
        let returnValue: AlertObject = {
            airAlerts: [],
            batteryAlert: {
                id: '',
                znum: 0,
                comparator: '',
                batteryCharge: 0,
                batteryVoltage: 0,
                externalVoltage: 0,
                status: false,
            },
            id: 0,
            name: '',
            units: [],
            status: true,
            email: [],
            phoneNumbers: []
        }
        returnValue.airAlerts = JSON.parse(aA.jsonOutput) as SpeciesData[]
        returnValue.airAlerts.map((ap) =>{
            returnValue.units = ap.Znumbers;
        })
        //aA.Parameters.map((ap) => {
        //    returnValue.units = ap.Znumbers;
        //    let alert: SpeciesData = {
        //        Comparator: ap.Comparator,
        //        Duration: ap.Duration,
        //        username: ap.username,
        //        userkey: ap.userkey,
        //        Species: ap.Species,
        //        Threshold: ap.Threshold,
        //        Znumbers: ap.Znumbers
        //    };
        //    returnValue.airAlerts.push(alert);
        //});
        if(returnValue.id === null || returnValue.id === 0){
            returnValue.id = aA.alertId;
        }
        returnValue.name = `${aA.name ?? aA.alertId}`;

        // should come from targets param - currently being moved from separate endpoint
        aA.targets.map((aT) => {
            if (aT.emailAddress && !returnValue.email.includes(aT.emailAddress) && aT.emailAddress != '') {
                returnValue.email.push(aT.emailAddress)
            }
        });
        aA.targets.map((aT) => {
            if (aT.phoneNumber && !returnValue.phoneNumbers.includes(aT.phoneNumber) && aT.phoneNumber != '') {
                returnValue.phoneNumbers.push(aT.phoneNumber)
            }
        })
        return returnValue;
    });
    const formattedPackagedAirAlerts: AirAlerts = { alerts: packagedAirAlerts };
  return formattedPackagedAirAlerts;
};


/* Function
DESC: airAlertsConfiguratorPackager returns a packaged and formatted api alert array ready for API processing
ARGS: an array of api GetAlerts request bodies for the AddAlerts endpoint
*/
const airAlertsConfiguratorPackager = (
  auth: Auth,
  configuredAlerts: AlertObject,
) => {
    
    const newAlertMetaTargets: APIAirAlertTarget[] = [];

    if(configuredAlerts.email.length > 0){
        for(let i = 0; i < configuredAlerts.email.length; i++){
            newAlertMetaTargets.push({
                targetId: i,
                firstName: '',
                lastName: '',
                emailAddress: configuredAlerts.email[i],
                phoneNumber: '',
                organisation: auth.user,
                alertId: configuredAlerts.id
            });
        }
    }

    if(configuredAlerts.phoneNumbers.length > 0){
        for(let i = 0; i < configuredAlerts.phoneNumbers.length; i++){
            newAlertMetaTargets.push({
                targetId: i,
                firstName: '',
                lastName: '',
                emailAddress: '',
                phoneNumber: configuredAlerts.phoneNumbers[i],
                organisation: auth.user,
                alertId: configuredAlerts.id
            });
        }
    }

    const packagedAirAlertRequests: AlertModel = {
        AlertId: configuredAlerts.id,
        AlertClassId: 4,
        Name: configuredAlerts.name,
        Organisation: auth.user,
        FirstName: String(auth.user),
        LastName: String(auth.user),
        PhoneNumber: newAlertMetaTargets[0].phoneNumber ?? null,
        EmailAddress: newAlertMetaTargets[0].emailAddress ?? null,
        StartDate: current('YYYY-MM-DD'),
        EndDate: null,
        //We can do this because the configurator only accepts a single duration so they should all be the same when coming from MyAir
        Interval: configuredAlerts.airAlerts[0].Duration,
        TimeOffset: 0,
        Targets: newAlertMetaTargets,
        JsonInput: configuredAlerts.airAlerts
    };
    return packagedAirAlertRequests;
}

const makeSpeciesAlert = (alertTargets: APIAirAlertTarget[], auth: Auth, alertId: number, zNumber: number, configuredAlert: SpeciesData[], alias: string) => {
    const alert: AlertModel = {
        AlertId: alertId,
        Name: alias,
        AlertClassId: 4,
        Organisation: auth.user,
        FirstName: String(auth.user),
        LastName: String(auth.user),
        PhoneNumber: '',
        EmailAddress: alertTargets[0].emailAddress ?? '',
        StartDate: current('YYYY-MM-DD'),
        EndDate: null,
        Interval: 0,
        TimeOffset: 0,
        Targets: alertTargets,
        JsonInput: configuredAlert
    };
    return alert;
}

const makeBatteryAlert = (alertTargets: APIAirAlertTarget[], batteryCharge: number, zNumber: number, alertId: number, auth: Auth, configuredAlert: BatteryAlert, alias: string) => {
    const alert: AlertModel = {
        AlertId: alertId,
        Name: alias,
        AlertClassId: 4,
        Organisation: auth.user,
        FirstName: String(auth.user),
        LastName: String(auth.user),
        PhoneNumber: '',
        EmailAddress: alertTargets[0].emailAddress ?? '',
        StartDate: current('YYYY-MM-DD'),
        EndDate: null,
        Interval: 0,
        TimeOffset: 0,
        Targets: alertTargets,
        JsonInput: []
    };
    return alert;
}

export { airAlertsApiPackager, airAlertsConfiguratorPackager }
