// Type Imports
import { SlotOptions, ZephyrHistory } from '../interface';

/* Function
DESC: slotFinder returns an array of available slots that have available data for a given unit, slot, 
      and time period
ARGS: unitData(derived from API)
*/
const slotFinder = (unitData: ZephyrHistory) => {
  const slots: SlotOptions[] = ['slotA', 'slotB', 'head'];

  if (!unitData) return slots;

  for (const [avg, avgInfo] of Object.entries(unitData)) {
    if (avgInfo) {
      const availableSlots = slots.filter((slot) => avgInfo[slot]);
      return availableSlots;
    }
  }

  return slots;
};

export { slotFinder };
