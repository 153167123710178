type SelectChartOpenAction = {
  type: 'SET_CHART_VIEW_MODE';
  payload: State;
};

type State = 'deviceView' | 'networkView' | 'comparisonView';

export default function selectedChartViewMode(
  state: State = 'deviceView',
  action: SelectChartOpenAction,
) {
  switch (action.type) {
    case 'SET_CHART_VIEW_MODE':
      return action.payload;
    default:
      return state;
  }
}
