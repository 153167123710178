// Types Imports
import { ALERTS_ON, ALERTS_OFF } from '../actions/types';

// Type Imports
import { Agreement } from '../utils/interface';

// Type Safety
interface AlertsState {
  on: boolean;
  agreements: any[];
}

interface SetAlerts {
  type: typeof ALERTS_ON | typeof ALERTS_OFF;
  payload: Agreement[];
}

// State
const initState: AlertsState = {
  on: false,
  agreements: [],
};

// Reducer
export default function (state = initState, action: SetAlerts) {
  const { type, payload } = action;

  switch (type) {
    case ALERTS_ON:
      return {
        ...state,
        on: true,
        agreements: payload,
      };

    case ALERTS_OFF:
      return {
        ...state,
        on: false,
      };

    default:
      return state;
  }
}
