// Types Imports
import { Dispatch } from 'redux';
import { ALERTS_ON, ALERTS_OFF } from './types';

// Action Creators
export const setAlertsOn = () => (dispatch: Dispatch) => {
  dispatch({
    type: ALERTS_ON,
  });
};

export const setAlertsOff = () => (dispatch: Dispatch) => {
  dispatch({
    type: ALERTS_OFF,
  });
};
