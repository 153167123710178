// Type Imports
import { VZephyrsConfig, Zephyr, ZephyrTypes } from '../interface';

const getUnitTypeCountTxt = (
  unitList: Zephyr[],
  unitType: number,
  vZephyrsConfig: VZephyrsConfig,
) => {
  const { length } = unitList.filter((z: Zephyr) => z.type === unitType);
  let max = 0;
  if (unitType === ZephyrTypes.virtual) {
    max = vZephyrsConfig.max;
  }

  return length;
  // if (!max) return length;
  // return `${length}/${max}`;
};

export { getUnitTypeCountTxt };
