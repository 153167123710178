import React, { useEffect, useState } from 'react';
import {
  AveragingPeriod,
  DisplayConfig,
  Pollutant,
  ReduxState,
  ThresholdLimits,
  ThresholdsList,
  TimePeriod,
  Zephyr,
  ZephyrCoverages,
} from '../../utils/interface';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import {
  proceedWithAnalysis,
  setIsViewLoaded,
  setUnitHistoriesList,
  setZephyrCoveragesList,
} from '../../actions/dataAnalytics';
import AnalyticsView from './AnalyticsView';
import { compress, decompress } from 'lz-string';
import utilsRequest from '../../utils/request';
import { speciesExtentFinder } from '../../utils/functions/speciesExtentFinder';
import { setThresholds } from '../../actions/thresholdsActions';

interface AnalyticsViewModalProps {
  unitHistories: any;
  thresholdLimits: ThresholdLimits;
  setZephyrCoveragesList: Function;
  zephyrCoveragesList: ZephyrCoverages[];
  zephyrs: Zephyr[];
  proceedWithAnalysis: Function;
  setUnitHistoriesList: Function;
  nullableUnits: number[];
  setIsViewLoaded: Function;
  zephyrsPollutantData: any;
  setZephyrsPollutantData: Function | null;
  selectedTimePeriod: TimePeriod | null;
  setSelectedTimePeriod: Function | null;
  selectedTimeAveraging: AveragingPeriod | null;
  setSelectedTimeAveraging: Function | null;
  bearerToken: string | null;
  views: any[] | null;
  setViews: Function | null;
  timePeriods: TimePeriod[];
  averagingPeriods: AveragingPeriod[];
  displayConfig: DisplayConfig;
  setIsSaveViewOpened?: Function;
  setIsLoadViewOpened?: Function;
  nullableUnitsBoolean?: boolean;
  isLoadViewOpened?: boolean;
  isViewNameValidated?: boolean;
  setIsViewNameValidated?: Function;
  setViewLoaded?: Function;
  customDate?: { start: Date, end: Date };
  setCustomDate?: Function;
  startDate?: Date;
  setStartDate?: Function;
  endDate?: Date;
  setEndDate?: Function;
}

const AnalyticsViewModal = ({
  unitHistories,
  thresholdLimits,
  zephyrCoveragesList,
  zephyrs,
  setZephyrCoveragesList,
  setUnitHistoriesList,
  setIsSaveViewOpened,
  setIsLoadViewOpened,
  nullableUnitsBoolean,
  proceedWithAnalysis,
  nullableUnits,
  setIsViewLoaded,
  zephyrsPollutantData,
  setZephyrsPollutantData,
  selectedTimePeriod,
  setSelectedTimePeriod,
  selectedTimeAveraging,
  setSelectedTimeAveraging,
  bearerToken,
  isLoadViewOpened,
  isViewNameValidated,
  setIsViewNameValidated,
  views,
  setViews,
  timePeriods,
  averagingPeriods,
  setViewLoaded,
  displayConfig,
  customDate,
  setCustomDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: AnalyticsViewModalProps) => {
  const [viewName, setViewName] = useState<string>(
    views && views.find((view: any) => view.id === unitHistories.id)
      ? views.find((view: any) => view.id === unitHistories.id).viewName
      : '',
  );
  const [isFieldBlank, setIsFieldBlank] = useState<boolean>(false);
  const [isUnitListEmpty, setIsUnitListEmpty] = useState<boolean>(false);
  const [noViewSelected, setNoViewSelected] = useState<boolean>(false);
  const [noViews, setNoViews] = useState<boolean>(false);

  const thresholdsList = useSelector((state: ReduxState) => state.thresholds);
  const dispatch = useDispatch();

  const getZephyrNumbersOfAllSelectedUnits = () => {
    const { filteredZephyrs } = zephyrsPollutantData;
    return filteredZephyrs.filter((unit: Zephyr) => unit.isSelected).map((unit: Zephyr) => unit.zNumber);
  };

  const getSelectedPollutants = () => {
    const { pollutantData } = zephyrsPollutantData;
    return pollutantData.filter((pollutant: Pollutant) => pollutant.isChecked).map((pollutant: Pollutant) => pollutant.dataIdentifier);
  };

  const getSelectedAveragingOpiton = () => {
    return selectedTimeAveraging?.averagingOption;
  };

  const getSelectedTimePeriod = () => {
    return selectedTimePeriod?.labelHTML !== 'Custom' ? selectedTimePeriod?.labelHTML : JSON.stringify(customDate!);
  };

  const thresholdsPOSTReformatter = (thresholdsList: ThresholdsList) => {
    const reformattedThresholdList: any = {};
    for (const pollutant of Object.keys(thresholdsList)) {
      reformattedThresholdList[pollutant] = {
        lowerthreshold: thresholdsList[pollutant as keyof ThresholdsList].thresholds.lowerThreshold,
        upperthreshold: thresholdsList[pollutant as keyof ThresholdsList].thresholds.upperThreshold,
        hidden: thresholdsList[pollutant as keyof ThresholdsList].hidden
      }
    }
    return reformattedThresholdList;
  };

  const thresholdsGETReformatter = (thresholdsList: any) => {
    const reformattedThresholdList: any = {};
    for (const pollutant of Object.keys(thresholdsList)) {
      reformattedThresholdList[pollutant] = {
        thresholds: {
          lowerThreshold: thresholdsList[pollutant].lowerthreshold,
          upperThreshold: thresholdsList[pollutant].upperthreshold
        },
        hidden: thresholdsList[pollutant].hidden
      }
    }
    return reformattedThresholdList;
  };

  const getAllRgls = () => {
    let rgls: { [key: string]: string } = {};
    for (let i = localStorage.length - 1; i >= 0; i--) {
      const key = localStorage.key(i);
      if (key && key.startsWith('rgl')) {
        rgls[key] = localStorage.getItem(key) as string;
      }
    }
    return rgls;
  }

  const saveViewToDB = () => {
    if (viewName) {
      const dataToPost = {
        // viewid: 0, // optional
        viewid: 0,
        viewname: viewName,
        savedate: new Date(),
        selectionCriteria: {
          devices: getZephyrNumbersOfAllSelectedUnits(),
          pollutants: getSelectedPollutants(),
          averageoption: getSelectedAveragingOpiton(),
          timeperiod: getSelectedTimePeriod()
        },
        layout: [{ ...getAllRgls() }],
        thresholds: thresholdsPOSTReformatter(thresholdsList),
      }
      return dataToPost;
    }
  };

  const validateViewDuplication = (unitHistoriesCopy: any, savedViews: any) => {
    const { id } = unitHistoriesCopy;
    const idCheck = savedViews.find((v: any) => v.id == id);
    return idCheck ? true : false;
  };

  const validateViewName = async (event: any) => {
    event.preventDefault();
    if (!viewName.replace(/\s/g, '').length) {
      setIsFieldBlank(true);
      return;
    }
    if (isFieldBlank) setIsFieldBlank(false);
    if (!unitHistories || !('historiesList' in unitHistories)) {
      setIsUnitListEmpty(true);
      return;
    }
    if (isUnitListEmpty) setIsUnitListEmpty(false);
    try {
      const view = saveViewToDB();
      await utilsRequest.saveView(view, bearerToken);
      setIsViewNameValidated!(true);
      setIsSaveViewOpened!(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (isLoadViewOpened && isViewNameValidated) {
      (async () => {
        const userViewsCopy = [...views!];
        const newUserViews = await utilsRequest.getUserViews(bearerToken);
        userViewsCopy.push(newUserViews[newUserViews.length - 1]);
        userViewsCopy[userViewsCopy.length - 1].isSelected = true;
        setViews!([...userViewsCopy]);
        setIsViewNameValidated!(false);
      })();
    }
  }, [isLoadViewOpened]);

  const updateViewName = (event: any) => {
    setViewName(event.target.value);
  };

  const getSelectedZephyrsFromView = (filteredZephyrs: Zephyr[], devices: number[]) => {
    const hashTable: { [key: number]: boolean } = {};
    const filteredZephyrsCopy = [...filteredZephyrs];
    for (const device of devices) {
      hashTable[device] = true;
    }
    for (const zephyr of filteredZephyrsCopy) {
      if (hashTable[zephyr.zNumber]) {
        if (!zephyr.isSelected) zephyr.isSelected = hashTable[zephyr.zNumber];
      } else {
        if (zephyr.isSelected) zephyr.isSelected = false;
      }
    }
    return filteredZephyrsCopy;
  };

  const getSelectedPollutantsFromView = (prevPollutants: Pollutant[], pollutants: string[]) => {
    const hashTable: { [key: string]: boolean } = {};
    const pollutantsCopy = [...prevPollutants];
    for (const pollutant of pollutants) {
      hashTable[pollutant] = true;
    }
    for (const pollutant of pollutantsCopy) {
      if (hashTable[pollutant.dataIdentifier]) {
        if (!pollutant.isChecked) pollutant.isChecked = hashTable[pollutant.dataIdentifier];
      } else {
        if (pollutant.isChecked) pollutant.isChecked = false;
      }
    }
    return pollutantsCopy;
  };

  const getAvailablePollutants = (selectedZephyrs: Zephyr[]) => {
    return speciesExtentFinder(displayConfig, selectedZephyrs as any, null);
  };

  const getLatestPollutants = (
    filteredZephyrs: Zephyr[],
    pollutantData: Pollutant[] | null,
  ) => {
    const selectedZephyrs = filteredZephyrs.filter((z: Zephyr) => z.isSelected);
    if (selectedZephyrs.length) {
      const newPollutantZephyrData = getAvailablePollutants(
        selectedZephyrs.filter((z: Zephyr) => z.type !== 1),
      );
      const newPollutantData = newPollutantZephyrData
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.dataIdentifier === value.dataIdentifier),
        );
      const updatedPollutantData = (newPollutantData as Pollutant[]).map(
        (pollutant: Pollutant) => {
          if (pollutantData) {
            const prevPolutant = pollutantData.find(
              (p: Pollutant) => p.dataIdentifier === pollutant.dataIdentifier,
            );
            if (prevPolutant) {
              pollutant = prevPolutant;
              return pollutant;
            }
            pollutant.isChecked = false;
          } else if (!('isChecked' in pollutant)) pollutant.isChecked = false;
          pollutant.isSelected = false;
          return pollutant;
        },
      );
      return updatedPollutantData;
    }
    return [];
  };

  const updateLocalStorageRgls = (layout: { [key: string]: string }[]): void => {
    Object.entries(layout[0]).forEach(([rglKey, rglValue]) => {
      if (localStorage.getItem(rglKey)) localStorage.setItem(rglKey, rglValue);
    })
  };

  const loadView = (event: any) => {
    event.preventDefault();
    if (views!.length === 0) {
      setNoViews(true);
      return;
    }
    const viewToLoad = views!.find((view: any) => view.isSelected);
    if (!viewToLoad) {
      setNoViewSelected(true);
      return;
    }
    if (noViewSelected) setNoViewSelected(false);
    if (noViews) setNoViews(false);
    const { filteredZephyrs, pollutantData } = zephyrsPollutantData;
    const { selectionCriteria: { devices, averageoption, timeperiod }, thresholds, layout } = viewToLoad;
    if (layout.length > 0 && Object.entries(layout[0]).length > 0) updateLocalStorageRgls(layout);
    const newAverageOption = averagingPeriods.find((aV: AveragingPeriod) => aV.averagingOption === averageoption);
    const newTimePeriod = !timeperiod.includes('start') ? timePeriods.find((tP: TimePeriod) => tP.labelHTML === timeperiod) : { labelHTML: 'Custom', timeOption: 'custom' };
    const filteredZephyrsCopy = getSelectedZephyrsFromView(filteredZephyrs, devices);
    setZephyrsPollutantData!({ filteredZephyrs: filteredZephyrsCopy, pollutantData: pollutantData });
    setSelectedTimeAveraging!(newAverageOption);
    setSelectedTimePeriod!(newTimePeriod);
    dispatch(setThresholds(thresholdsGETReformatter(thresholds)));
    if (newTimePeriod?.labelHTML === 'Custom') {
      const convertedTimePeriod = JSON.parse(timeperiod);
      const { start, end } = convertedTimePeriod;
      setCustomDate!(convertedTimePeriod);
      setStartDate!(start);
      setEndDate!(end);
    }
    setIsLoadViewOpened!(false);
    setViewLoaded!(true);
  };

  const displayViews = () => {
    return views!.map((view: any) => {
      return (
        <AnalyticsView
          key={view.id}
          view={view}
          views={views}
          setViews={setViews!}
        />
      );
    });
  };

  const closeViewModal = () => {
    if (setIsSaveViewOpened) setIsSaveViewOpened(false);
    else if (setIsLoadViewOpened) setIsLoadViewOpened(false);
  };

  const getExistingUser = () => {
    let view = '';
    if (views) {
      const existingView = views.find(
        (view: any) => view.id === unitHistories.id,
      );
      if (existingView) view = existingView.viewName;
    }
    return view;
  };

  const handleProceedingWithAnalysis = (event: any, toProceed: boolean) => {
    event.preventDefault();
    proceedWithAnalysis(toProceed);
  };

  const extractNullableUnits = () => {
    return nullableUnits.map((unit: number, idx: number) => {
      return (
        <li key={idx} style={{ marginTop: 0, paddingLeft: 0, fontWeight: 600 }}>
          Unit with ID {unit} has no data for selected time period
        </li>
      );
    });
  };

  return (
    <div className="modal-container header-contact-modal-container">
      {setIsSaveViewOpened && <h4>Save Your View</h4>}
      {setIsLoadViewOpened && <h4>Load a View</h4>}
      {nullableUnitsBoolean && <h4 style={{ paddingLeft: 0 }}>Warning!</h4>}
      {(setIsSaveViewOpened || setIsLoadViewOpened) && (
        <button
          className="button close close-modal"
          type="button"
          onClick={closeViewModal}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 24 24"
            fill="#000"
          >
            <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"></path>
          </svg>
        </button>
      )}
      <form>
        <div className="form-content-container">
          <div className="row">
            <div className="col-md-12 p-0">
              {setIsSaveViewOpened && (
                <>
                  <label className="contact-form__label" htmlFor="viewName">
                    View Name*
                  </label>
                  <input
                    className="contact-form__input"
                    type="text"
                    name="viewName"
                    defaultValue={getExistingUser()}
                    onChange={updateViewName}
                  ></input>
                  {isFieldBlank && (
                    <p style={{ color: '#FF0000' }}>Field cannot be blank!</p>
                  )}
                  {isUnitListEmpty && (
                    <p style={{ color: '#FF0000' }}>No analysis generated!</p>
                  )}
                </>
              )}
              {isLoadViewOpened && (
                <>
                  {views!.length === 0 && (
                    <h4 style={{ textAlign: 'center' }}>No views saved yet.</h4>
                  )}
                  {views!.length > 0 && displayViews()}
                </>
              )}
              {nullableUnitsBoolean && (
                <>
                  <ul style={{ paddingLeft: 25 }}>{extractNullableUnits()}</ul>
                </>
              )}
            </div>
          </div>
          <div className="row" style={{ display: 'initial' }}>
            {setIsSaveViewOpened && (
              <div className="col text-left validation-dialog">
                <p className="air-alerts-error-item" style={{ marginTop: 0 }}>
                  *Required fields
                </p>
              </div>
            )}
            {setIsLoadViewOpened && noViewSelected && (
              <div className="col text-left validation-dialog">
                <p className="air-alerts-error-item" style={{ marginTop: 0 }}>
                  Please select a view
                </p>
              </div>
            )}
            {setIsLoadViewOpened && noViews && (
              <div className="col text-left validation-dialog">
                <p className="air-alerts-error-item" style={{ marginTop: 0 }}>
                  You do not have any saved views
                </p>
              </div>
            )}
            <div className="col">
              {setIsSaveViewOpened && (
                <button
                  type="submit"
                  className="button primary send-modal save-view"
                  onClick={validateViewName}
                >
                  Save View
                </button>
              )}
              {setIsLoadViewOpened && (Array.isArray(views) && views.length > 0) && (
                <button
                  style={{ marginTop: '1rem' }}
                  type="submit"
                  className="button primary send-modal save-view"
                  onClick={loadView}
                >
                  Load View
                </button>
              )}
            </div>
            {nullableUnitsBoolean && (
              <>
                <div style={{ paddingLeft: 0 }}>
                  <p
                    className="air-alerts-error-item"
                    style={{
                      marginTop: 0,
                      fontSize: 14,
                      fontWeight: 600,
                      color: '#000',
                    }}
                  >
                    Do you wish to proceed?
                  </p>
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <button
                    className="button primary send-modal proceed"
                    onClick={(event) => {
                      handleProceedingWithAnalysis(event, true);
                    }}
                  >
                    Proceed
                  </button>
                  <button
                    className="button primary send-modal cancel"
                    onClick={(event) => {
                      handleProceedingWithAnalysis(event, false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  unitHistories: state.unitHistoriesOptions.unitHistories,
  thresholdLimits: state.unitHistoriesOptions.thresholdLimits,
  zephyrCoveragesList: state.unitHistoriesOptions.zephyrCoveragesList,
  zephyrs: state.getZephyrs.zephyrs,
  nullableUnits: state.unitHistoriesOptions.nullableUnits,
  bearerToken: state.auth.bearerToken,
  timePeriods: state.setTimePeriods.periods,
  averagingPeriods: state.setAveragingPeriods.periods,
  displayConfig: state.setDisplayConfig,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setUnitHistoriesList,
      setZephyrCoveragesList,
      proceedWithAnalysis,
      setIsViewLoaded,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsViewModal);
