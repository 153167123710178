// Package Imports
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Styled Component
const DataChartDisclaimerWrapper = styled.div`
  font-size: 0.9rem;
  color: #8e8e93;
  margin-top: 5px;
`;

interface DataChartDisclaimerProps {
  on: boolean;
}

// Component
const DataChartDisclaimer = ({ on }: DataChartDisclaimerProps) => {
  const { t: translate } = useTranslation();

  return on ? (
    <DataChartDisclaimerWrapper>
      {translate('DataChartDisclaimer')}
    </DataChartDisclaimerWrapper>
  ) : (
    <></>
  );
};

export default DataChartDisclaimer;
