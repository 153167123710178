// Types Imports=
import { SET_AIR_ALERTS } from '../actions/types';

// Type Imports
import { AirAlerts } from '../utils/interface';

//State
const initState: AirAlerts | null = {
  alerts: [],
};

interface SetAirAlerts {
  type: typeof SET_AIR_ALERTS;
  payload: AirAlerts;
}

// Reducer
export default function (state = initState, action: SetAirAlerts) {
  const { type, payload } = action;

  switch (type) {
    case SET_AIR_ALERTS:
      return {
        ...state,
        alerts: payload.alerts,
      };

    default:
      return state;
  }
}
