// Types Imports
import { LocationCoordinates, MappairSpecies } from '../interface';

// Util Imports
import utilsRequest from '../request';
import { formatGoogleAddress } from './googleFormatter';
import { dateFinder } from './dateFinder';

const averageAQIData = (data: any[], startIndex: number, endIndex: number) => {
  const subset = data.slice(startIndex, endIndex + 1);

  // Calculate the average value for the subset
  const total = subset.reduce((sum, item) => sum + item.value, 0);
  const averageValue = (total / subset.length).toFixed(); // Round the average to a whole number

  // Find the object in the subset whose value is closest to the average
  let closestMatch = subset[0];
  let smallestDifference = Math.abs(subset[0].value - +averageValue);

  subset.forEach(item => {
    const difference = Math.abs(item.value - +averageValue);
    if (difference < smallestDifference) {
      closestMatch = item;
      smallestDifference = difference;
    }
  });

  // Extract the first part of the timestamp (the date)
  const date = closestMatch.timestamp.split(" ")[0];

  // Return the object with the averaged value and matching properties
  return {
    timestamp: date,
    value: Number(averageValue),
    units: closestMatch.units,
    layer: closestMatch.layer,
    label: closestMatch.label,
    advice: closestMatch.advice,
    advice_sensitive: closestMatch.advice_sensitive,
    behavioural_change: closestMatch.behavioural_change,
  };
}

export const todayDataPackager = async (
  species: MappairSpecies,
  latlon: LocationCoordinates,
  bearerToken: string | null,
) => {
  const startDate = dateFinder(new Date, 0, false);
  const endDate = dateFinder(new Date(), 2, false);

  startDate.setUTCHours(0, 0, 0, 0); // Set to 00:00:00 UTC
  endDate.setUTCHours(23, 59, 59, 999); // Set to 23:59:59 UTC

  const dates = { startDT: startDate.toISOString(), endDT: endDate.toISOString() };
  const speciesList = `${species}`;
  const rawTodayData = await utilsRequest.getTodayData(
    speciesList,
    latlon,
    1,
    bearerToken,
    dates,
  );
  if (rawTodayData && rawTodayData.meta.timestamps) {
    rawTodayData.meta.timestamps = [rawTodayData.meta.timestamps[0].split(' ')[0], rawTodayData.meta.timestamps[24].split(' ')[0], rawTodayData.meta.timestamps[48].split(' ')[0]];
    const { values_coordinates } = rawTodayData.results[0];
    const { values_timestamps } = values_coordinates[0];
    const day1 = averageAQIData(values_timestamps, 0, 23);
    const day2 = averageAQIData(values_timestamps, 24, 47);
    const day3 = averageAQIData(values_timestamps, 48, 72);
    rawTodayData.results[0].values_coordinates[0].values_timestamps = [day1, day2, day3];
  }
  const googleLocation = await utilsRequest.getLocation(latlon);
  const locationDesc =
    googleLocation.results && googleLocation.results.length
      ? formatGoogleAddress(googleLocation.results[0].formatted_address)
      : 'Unknown';
  const enhancedTodayData = {
    ...rawTodayData,
    location: { latlon, desc: locationDesc },
  };
  return enhancedTodayData;
};