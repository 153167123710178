// Package Imports
import { Dispatch } from 'redux';

// Types Imports
import { SET_THRESHOLD_LIMITS, SET_UNIT_HISTORIES, SET_UNIT_HISTORIES_LOADING, SET_ZEPHYR_COVERAGES_LIST, SET_IS_DATA_AVAILABILITY_CHART_LOADING, SET_DSE_CHECKBOX_STATE, INCREASE_ARRIVED_REQUESTS, SET_ARRIVED_REQUESTS_TO_INIT_STATE, SET_NULLABLE_UNITS, SET_PROCEED_WITH_ANALYSIS, SET_IS_VIEW_LOADED, SET_IS_CARTRIDGE_DATA_LOADING } from './types';
import { ThresholdLimits, Zephyr, ZephyrCoverages } from '../utils/interface';

// Action Creators
export const setUnitHistoriesList = (payload: any) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_UNIT_HISTORIES,
    payload,
  });
};

export const setUnitHistoriesLoading = (payload: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_UNIT_HISTORIES_LOADING,
    payload,
  })
};

export const setThresholdLimits = (payload: ThresholdLimits) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_THRESHOLD_LIMITS,
    payload,
  })
};

export const setZephyrCoveragesList = (payload: Zephyr | ZephyrCoverages[] | ZephyrCoverages) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_ZEPHYR_COVERAGES_LIST,
    payload,
  });
};

export const setIsDataAvailabilityChartLoading = (payload: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_IS_DATA_AVAILABILITY_CHART_LOADING,
    payload,
  })
};

export const setDSECheckboxState = () => (dispatch: Dispatch) => {
  dispatch({
    type: SET_DSE_CHECKBOX_STATE,
  })
};

export const increaseArrivedRequests = () => (dispatch: Dispatch) => {
  dispatch({
    type: INCREASE_ARRIVED_REQUESTS,
  })
};

export const restartArrivedRequests = () => (dispatch: Dispatch) => {
  dispatch({
    type: SET_ARRIVED_REQUESTS_TO_INIT_STATE,
  })
};

export const setNullableUnits = (payload: any) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_NULLABLE_UNITS,
    payload
  })
};

export const proceedWithAnalysis = (payload: boolean | null) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_PROCEED_WITH_ANALYSIS,
    payload
  })
};

export const setIsViewLoaded = (payload: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_IS_VIEW_LOADED,
    payload
  })
};


export const setIsCartridgeDataLoading = (payload: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_IS_CARTRIDGE_DATA_LOADING,
    payload
  })
};


