// Package Imports
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

// OL Imports
// @ts-ignore
import { fromLonLat, toLonLat } from 'ol/proj';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import Draw from 'ol/interaction/Draw';
import { Fill, Stroke, Circle, Style } from 'ol/style';
import { getArea } from 'ol/sphere';
import { toStringHDMS } from 'ol/coordinate';
import { getCenter } from 'ol/extent';

// Component Imports
import Polygon from 'ol/geom/Polygon';
import LocationControls from './LocationControls';
import LocationLicensing from './LocationLicensing';

// Action Imports
import { setLocation } from '../../actions/locations';

// Const Imports
import { defaultAreaConfig, defaultMappairOptions } from '../../utils/consts';

// Type Imports
import {
  Overlay as OverlayType,
  ReduxState,
  ShowAQMALayer,
  UserInfo,
} from '../../utils/interface';

// Component Interfaces
interface InitState {
  hasFeatures: boolean;
  map: Map | null;
}

interface AreaOfInterestProps {
  asideOn: boolean;
  curOverlayName: string;
  location: any;
  mappairOptions: ShowAQMALayer;
  handleAreaOfInterest: Function;
  overlays: OverlayType[];
  userInfo: UserInfo;
}

// Component
const AreaOfInterest = ({
  asideOn,
  curOverlayName,
  location,
  mappairOptions,
  overlays,
  handleAreaOfInterest,
  userInfo,
}: AreaOfInterestProps) => {
  const initState: InitState = {
    hasFeatures: false,
    map: null,
  };

  // State
  const [hasFeatures, setHasFeatures] = useState(initState.hasFeatures);
  const [map, setMap] = useState(initState.map);

  // Refs
  const mapElement = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<Map | null>(null);
  mapRef.current = map;

  // Effects
  useEffect(() => {
    const mapExtent: any = [
      ...fromLonLat([-180, -90]),
      ...fromLonLat([180, 90]),
    ];

    const fill = new Fill({
      color: 'rgba(255,255,255,0.5)',
    });
    const solidFill = new Fill({
      color: '#1d1e30',
    });
    const stroke = new Stroke({
      color: '#1d1e30',
      width: 2,
    });

    const style = new Style({
      fill,
      stroke,
    });

    const source = new VectorSource({ wrapX: false });
    const featuresLayer = new VectorLayer({ source, style });
    featuresLayer.setProperties({ name: 'area' });

    const initMap = new Map({
      target: mapElement.current || '',
      layers: [
        new TileLayer({
          preload: Infinity,
          source: new OSM({
            wrapX: false,
          }),
          zIndex: 0,
        }),
        featuresLayer,
      ],
      view: new View({
        center: fromLonLat(location),
        zoom: 10,
        extent: mapExtent,
      }),
    });

    const styles: any = {
      Point: new Style({
        image: new Circle({
          stroke,
          radius: 4,
          fill: solidFill,
        }),
      }),
      LineString: new Style({
        stroke,
      }),
      Polygon: new Style({
        fill,
      }),
    };
    function styleFunction(feature: any) {
      return styles[feature.getGeometry().getType()];
    }

    const draw = new Draw({
      source,
      type: 'Polygon' as any,
      style: styleFunction,
    });

    draw.on('drawstart', () => removeAllAreas());
    draw.on('drawend', () => setHasFeatures(true));

    initMap.addInteraction(draw);
    setMap(initMap);
  }, []);

  useEffect(() => {
    if (map) {
      const updatedCenter = fromLonLat(location);
      const view = map.getView();
      view.animate({
        center: updatedCenter,
        duration: 0,
      });
    }
  }, [map, location]);

  // Functions
  const handleAreasComplete = () => {
    let areaConfig = defaultAreaConfig;
    if (map) {
      map
        .getLayers()
        .getArray()
        .forEach((l) => {
          const layerProps = l.getProperties();
          if ('name' in layerProps) {
            (l as VectorLayer)
              .getSource()
              .getFeatures()
              .forEach((f) => {
                const polygonGeometry = f.getGeometry();
                if (polygonGeometry) {
                  const areaSize =
                    Math.round((getArea(polygonGeometry) / 1000000) * 100) /
                    100;
                  const latLngGeometry = polygonGeometry.transform(
                    'EPSG:3857',
                    'EPSG:4326',
                  );
                  const centre = getCenter(polygonGeometry.getExtent());
                  const centreHdmsRaw = hdmsSplitter(toStringHDMS(centre));
                  const centreHdms = `${centreHdmsRaw[0]}, ${centreHdmsRaw[1]}`;
                  areaConfig = {
                    areaSize,
                    centreHdms,
                    latLngGeometry,
                  };
                }
              });
          }
        });
    }
    handleAreaOfInterest(areaConfig);
  };

  const handleLocationSearch = async (locationSearch: string) => {
    setLocation(locationSearch, 'null');
    return true;
  };

  const hdmsSplitter = (hdms: string) => {
    if (hdms) {
      const hdmsWithDelimiter = hdms.replace(/N/g, 'N,');
      const hdmsArr = hdmsWithDelimiter.split(',');
      return hdmsArr;
    }
    return '';
  };

  const removeAllAreas = () => {
    setHasFeatures(false);
    if (mapRef.current) {
      mapRef.current
        .getLayers()
        .getArray()
        .forEach((l) => {
          const layerProps = l.getProperties();
          if ('name' in layerProps) {
            (l as VectorLayer).getSource().clear();
          }
        });
    }
  };

  const zoom = (increase: boolean) => {
    if (map) {
      const mapView = map.getView();
      let currentZoomLevel = mapView.getZoom();
      if (currentZoomLevel) {
        increase ? (currentZoomLevel += 1) : (currentZoomLevel -= 1);
        mapView.setZoom(currentZoomLevel);
      }
    }
  };

  return (
    <div className="modal-container header-contact-modal-container aoi-modal-container">
      <h4>Area of Interest</h4>
      <div className="aoi-select-content">
        <div className="aoi-modal-info-bar">
          <p>Use the map below to draw your area of interest.</p>
          {hasFeatures ? (
            <div>
              <button
                onClick={() => handleAreasComplete()}
                type="button"
                className="aoi-select-button button primary send-modal"
              >
                Done
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="aoi-map-container">
          <div ref={mapElement} id="aoi-map" />
          <LocationLicensing
            asideOn={asideOn}
            curOverlayName={curOverlayName}
            mappairOptions={defaultMappairOptions}
            overlays={overlays}
            userInfo={userInfo}
          />
          <LocationControls
            additionalClass="aoi-map-controls"
            asideOn={asideOn}
            handleLocationSearch={handleLocationSearch}
            showLocationSearch={false}
            userInfo={userInfo}
            zoom={zoom}
            curTimeSliderOption={null}
            setCurTimeSliderOption={null}
            wmsDateTime={null}
            setWMSDateTime={null}
          />
        </div>
      </div>
      <button
        className="button close close-modal"
        onClick={() => handleAreaOfInterest(defaultAreaConfig)}
        type="button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 24 24"
          fill="#000"
        >
          <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
        </svg>
      </button>
    </div>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  asideOn: state.aside.on,
  curOverlayName: state.showAQMALayer.overlay,
  displayConfig: state.setDisplayConfig,
  location: state.setLocation.location,
  mappairOptions: state.showAQMALayer,
  overlays: state.setOverlays,
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, {})(AreaOfInterest);
