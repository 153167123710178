// Package Imports
import React, { useEffect } from 'react';

// Component Imports
import DataChartLabelsCanvas from './DataChartLabelCanvas';

// Util Imports
import { hasAvailableData } from '../../utils/functions/chartHelper';

// Type Imports
import { ChartData } from '../../utils/interface';

// Component Interfaces
interface DataChartLabelsProps {
  chartData: ChartData;
  handleUnitSpeciesFilter: Function;
}

// Component
const DataChartLabels = ({
  chartData,
  handleUnitSpeciesFilter,
}: DataChartLabelsProps) => {

  return (
    <>
      <div className="chart-header-label-list">
        {chartData.datasets.length && hasAvailableData(chartData.datasets) ? (
          hasAvailableData(chartData.datasets).map((dataset) => (
            <li
              key={dataset.label}
              onClick={() =>
                handleUnitSpeciesFilter(dataset.label, dataset.speciesLabel)
              }
            >
              <DataChartLabelsCanvas
                canvasId={`${dataset.label}-canvas`}
                dashPattern={dataset.borderDash}
                strokeColor={dataset.featureColour}
              />
              <div className={`label ${dataset.hidden ? 'hidden' : ''}`}>
                {dataset.label}
              </div>
            </li>
          ))
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default DataChartLabels;
