import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { setUnitHistoriesList } from '../../actions/dataAnalytics';
import BinIcon from '../../assets/images/bin.svg';
import utilsRequest from '../../utils/request';
import { ReduxState } from '../../utils/interface';

interface AnalyticsViewProps {
  view: any;
  views: any;
  setViews: Function;
  setUnitHistoriesList: Function;
  bearerToken: string | null;
}

const AnalyticsView = ({
  view,
  views,
  setViews,
  setUnitHistoriesList,
  bearerToken
}: AnalyticsViewProps) => {
  const [deletePrompt, setDeletePrompt] = useState<boolean>(false);

  // Refs
  const deleteButton: React.MutableRefObject<any> = useRef(null);
  const noButton: React.MutableRefObject<any> = useRef(null);
  const yesButton: React.MutableRefObject<any> = useRef(null);

  const toggleViewSelection = (event: any, view: any) => {
    const buttonClicked =
      (deleteButton.current && deleteButton.current.contains(event.target)) ||
      (yesButton.current && yesButton.current.contains(event.target)) ||
      (noButton.current && noButton.current.contains(event.target));

    if (buttonClicked) return;
    const viewsCopy = [...views];
    const currentView = viewsCopy[viewsCopy.indexOf(view)];
    viewsCopy.forEach((view) => {
      // reset other views isSelected state
      if (view.viewid !== currentView.id && view.isSelected)
        view.isSelected = false;
    });
    currentView.isSelected = !currentView.isSelected;
    setViews(viewsCopy);
  };

  const toggleViewDeletionButtons = (action: boolean) => {
    switch (action) {
      case true:
        setDeletePrompt(true);
        break;
      case false:
        setDeletePrompt(false);
        break;
    }
  };

  const deleteView = async (event: any, view: any) => {
    event.preventDefault();
    if (view.isLoaded) setUnitHistoriesList([]);
    const { viewid } = view;
    try {
      await utilsRequest.deleteView(viewid, bearerToken);
      const filteredViews = [...views].filter((v: any) => v.viewid !== view.viewid);
      setViews(filteredViews);
    } catch (e) {
      alert("Something went wrong!");
    }
  };

  return (
    <div
      style={{
        padding: '0 10px',
        width: '100%',
        marginBottom: '1rem',
        backgroundColor: view.isSelected ? '#cdcdcd' : '#ffffff',
        cursor: 'pointer',
      }}
      onClick={(event) => {
        toggleViewSelection(event, view);
      }}
    >
      <div className="d-flex justify-content-between">
        <div>
          <h4
            className="view-name"
            style={{ padding: 0, marginBottom: '0.25rem' }}
          >
            {view.viewname}
          </h4>
          <div
            className="view-name-info"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'end',
            }}
          >
            <p style={{ margin: 0 }}>{view.savedate}</p>
          </div>
        </div>
        <div className="d-flex align-items-center">
          {!deletePrompt && (
            <button
              aria-label="Delete"
              className="prompt-button icon-button-delete"
              ref={deleteButton}
              style={{ cursor: 'default' }}
              onClick={() => {
                toggleViewDeletionButtons(true);
              }}
            >
              <img src={BinIcon} />
            </button>
          )}
          {deletePrompt && (
            <div>
              <button
                className="prompt-button"
                ref={yesButton}
                style={{ marginRight: 5, cursor: 'default' }}
                onClick={async (event) => {
                  await deleteView(event, view);
                }}
              >
                Yes
              </button>
              <button
                className="prompt-button"
                ref={noButton}
                style={{ cursor: 'default' }}
                onClick={() => {
                  toggleViewDeletionButtons(false);
                }}
              >
                No
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  bearerToken: state.auth.bearerToken,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setUnitHistoriesList,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsView);
