import moment from 'moment';

// Util Imports
import { Dispatch } from 'redux';
import utilsRequest from '../utils/request';
import {
  current,
  subtractTimeFromNow,
  subtractTwoDates,
} from '../utils/functions/dateFinder';

// Type Imports
import {
  CLEAR_DRAGGED_UNIT,
  GET_AURNS,
  GET_ZEPHYRS,
  GET_ZEPHYR_HISTORICAL_DATA,
  SET_DRAGGED_UNIT,
  SET_ZEPHYR,
  SET_ZEPHYR_IN_EDIT_MODE,
  SET_ZEPHYR_HISTORICAL_DATA_LOADING,
  ADD_ZEPHYRS,
  SET_HAS_INITIAL_SET,
} from './types';
import { DataConfig, Zephyr, ZephyrTypes, Keys, Pollutant } from '../utils/interface';
import { proxy } from 'jquery';
import { truncate } from 'fs';

// Type Safety
interface TPValue {
  [key: string]: number;
}

// Consts
const tpValues: TPValue = { '24': 1, '48': 2, week: 7 };

// Action Creators
export const setAurns = (aurns: Zephyr[]) => (dispatch: Dispatch) => {
  dispatch({
    type: GET_AURNS,
    payload: aurns,
  });
};

export const setZephyrs = (zephyrs: Zephyr[]) => (dispatch: Dispatch) => {
  dispatch({
    type: GET_ZEPHYRS,
    payload: zephyrs,
  });
};

export const addZephyrs = (zephyr: any[]) => (dispatch: Dispatch) => {
  dispatch({
    type: ADD_ZEPHYRS,
    payload: zephyr,
  });
};

export const setZephyrInEditMode = (zephyr: Zephyr) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_ZEPHYR_IN_EDIT_MODE,
    payload: zephyr,
  });
};

export const setZephyr = (zephyr: Zephyr) => (dispatch: Dispatch) => {
  if (zephyr) {
    dispatch({
      type: SET_ZEPHYR,
      payload: zephyr,
    });
  }
};

export const setInitialUnitSet = () => (dispatch: Dispatch) => {
  dispatch({
    type: SET_HAS_INITIAL_SET
  })
};

export const setDraggedUnit = (unit: Zephyr) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_DRAGGED_UNIT,
    payload: unit,
  });
};

export const clearDraggedUnit = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_DRAGGED_UNIT,
  });
};

export const startLoading = () => (dispatch: Dispatch) => {
  dispatch({
    type: SET_ZEPHYR_HISTORICAL_DATA_LOADING,
    payload: true,
  });
};

export const endLoading = () => (dispatch: Dispatch) => {
  dispatch({
    type: SET_ZEPHYR_HISTORICAL_DATA_LOADING,
    payload: false,
  });
};

export const getZephyrData = (
  zephyr: Zephyr,
  timePeriod: string,
  start: string,
  end: string,
  dataConfig: DataConfig,
  // multiMode is applicable to multi Data load and anywhere else we want to get Z data without updating global state
  multiMode: boolean,
  bearerToken: string | null,
  caching: number,
  isPathLocation: boolean,
  dse?: boolean,
  selectedPollutants?: Pollutant[],
  isDA?: boolean,
  averagingOption?: string
) => async (dispatch: Dispatch) => {
  if (!multiMode) {
    dispatch({
      type: SET_ZEPHYR_HISTORICAL_DATA_LOADING,
      payload: true,
    });
  }

  // check whether the data already exists in the cache
  const dataInCache = checkCacheForZephyrData(
    zephyr.zNumber,
    timePeriod,
    start,
    end,
  );
  let zephyrHistoricalData = null;
  let proxyVZHistoricalData = null;
  let proxyVZSourceApportionmentData = null;
  let statusCode = null;
  // disqualify certain time periods from caching owing to size
  const disqualifiedFromCache = ['24', '48', 'week', 'month', 'custom', '72'];
  if (dataInCache) {
    zephyrHistoricalData = dataInCache;
  } else {
    const startDateUTC = moment.utc(start, 'YYYYMMDDHHmm')
    const endDateUTC = moment.utc(end, 'YYYYMMDDHHmm')
    const startDateISO = startDateUTC.toISOString().substr(0, 19);
    const endDateISO = endDateUTC.toISOString().substr(0, 19);

    if (zephyr.type === ZephyrTypes.virtual) {
      const vZephyrDataResponse = await utilsRequest.getVZephyrData(
        bearerToken,
        zephyr.zNumber,
        dataConfig,
        startDateISO,
        endDateISO,
        averagingOption,
      );
      const vZephyrSourceApportionmentDataResponse = await utilsRequest.getVZephyrData(
        bearerToken,
        zephyr.zNumber,
        dataConfig,
        startDateISO,
        endDateISO,
        averagingOption,
        true,
      );
      zephyrHistoricalData = convertVZephyrDataToZephyrHistoricalData(
        vZephyrDataResponse,
        zephyr,
        startDateISO,
        endDateISO,
        false,
        zephyr.type
      );
      proxyVZSourceApportionmentData = convertVZephyrDataToZephyrHistoricalData(vZephyrSourceApportionmentDataResponse, zephyr, startDateISO, endDateISO, false, zephyr.type, true);
    } else {
      zephyrHistoricalData = await utilsRequest.getZephyrMeasurementData(
        zephyr,
        start,
        end,
        dataConfig,
        bearerToken,
        isPathLocation,
        dse ? dse : undefined,
        selectedPollutants,
        isDA,
        averagingOption
      );
      const proxyVZUnformattedData = await utilsRequest.getProxyVZephyrData(zephyr.id_pod, bearerToken, startDateISO, endDateISO, averagingOption, false);
      const proxyVZSourceApportionmentUnformattedData = await utilsRequest.getProxyVZephyrData(zephyr.id_pod, bearerToken, startDateISO, endDateISO, averagingOption, true);
      proxyVZHistoricalData = convertVZephyrDataToZephyrHistoricalData(proxyVZUnformattedData, zephyr, startDateISO, endDateISO, true, zephyr.type);
      proxyVZSourceApportionmentData = convertVZephyrDataToZephyrHistoricalData(proxyVZSourceApportionmentUnformattedData, zephyr, startDateISO, endDateISO, true, zephyr.type, true);
    }
    zephyrHistoricalData.zType = zephyr.type;
    statusCode = zephyrHistoricalData.statusCode;
    // check whether either slot has any data
    if (zephyrHistoricalData.data) {
      const containsData =
        zephyrHistoricalData.data[isDA ? averagingOption as string : 'Hourly average on the hour'];

      // Check whether there is something other than nulls in either of the slots
      const slotAContainsData = containsData && "slotA" in containsData
        ? checkDataResponseForData(containsData.slotA, "slotA")
        : null;

      const slotBContainsData = containsData && "slotB" in containsData
        ? checkDataResponseForData(containsData.slotB, "slotB")
        : null;

      const headContainsData = containsData && "head" in containsData
        ? checkDataResponseForData(containsData.head, "head")
        : null;

      if (slotAContainsData || slotBContainsData || headContainsData) {
        // check whether the tp is excluded from caching owing to size constraints
        if (!disqualifiedFromCache.includes(timePeriod)) {
          // check if this is a custom date request
          if (timePeriod === 'custom') {
            const customTP = subtractTwoDates(start, end);
            // check whether the custom date request is up to 7 days in size
            if (customTP <= 7) {
              populateCustomCache(
                zephyr.zNumber,
                customTP,
                start,
                end,
                zephyrHistoricalData,
              );
            }
          } else {
            populateStandardCache(
              zephyr.zNumber,
              timePeriod,
              zephyrHistoricalData,
            );
          }
        }
      } else {
        zephyrHistoricalData = null;
      }
    } else {
      zephyrHistoricalData = false;
    }
  }
  // data: null references data requests that have retrieved null data for both slots
  // data: false references data requests that have failed with a 500
  const output =
    zephyrHistoricalData === null
      ? zephyr.type === 0 ? { data: null, statusCode, proxyVZData: proxyVZHistoricalData, proxyVZSourceApportionmentData } : { data: null, statusCode, proxyVZSourceApportionmentData }
      : zephyrHistoricalData === false
        ? zephyr.type === 0 ? { data: false, statusCode, proxyVZData: proxyVZHistoricalData, proxyVZSourceApportionmentData } : { data: false, statusCode, proxyVZSourceApportionmentData }
        : zephyr.type === 0 ? { ...zephyrHistoricalData, proxyVZData: proxyVZHistoricalData, proxyVZSourceApportionmentData } : { ...zephyrHistoricalData, proxyVZSourceApportionmentData };

  // const populatedData = unitList.length > 1 && output.data === null ? output.data = unitHistories[Object.keys(unitHistories)[0]] : null
  // const newOutput = unitList.length === 1 ? output : unitList.length > 1 && output.data === null ? populatedData : output;
  // zephyrHistoricalData === null ? newOutput.isHistoricalDataNull = true : newOutput.isHistoricalDataNull = false;

  if (!multiMode) {
    dispatch({
      type: GET_ZEPHYR_HISTORICAL_DATA,
      payload: output,
    });
    dispatch({
      type: SET_ZEPHYR_HISTORICAL_DATA_LOADING,
      payload: false,
    });
  }
  // if (zephyrHistoricalData) { output.data = null; }
  // if output.data is null, dont push it to chart dataset state
  return output;
};

// Functions
const getLocalStorageData = () => localStorage.getItem('data');

const clearSpaceInStorage = (stringCache: string, timePeriod: string) => {
  const newCache = JSON.parse(stringCache);
  try {
    localStorage.setItem('data', stringCache);
  } catch (e) {
    const updatedUnitsCache = newCache;
    // remove oldest accessed unit data to free-up LS space
    let dataCount = 0;
    while (dataCount < tpValues[timePeriod]) {
      // grab the first element from updatedUnits and remove it
      const curUnit = updatedUnitsCache.units.shift();
      if (curUnit && newCache[curUnit]) {
        // count how many tps exist for the unit in the standard and custom caches
        for (const stdKey in newCache[curUnit].standard) {
          // increment dataCount
          dataCount += tpValues[stdKey];
        }
        for (const custKey in newCache[curUnit].custom) {
          // calculate number of days
          const [start, end] = custKey.split('_');
          dataCount += subtractTwoDates(start, end);
        }
        // update stringCache
        delete updatedUnitsCache[curUnit];
      }
    }
    // set localStorage with updatedUnitsCache
    const stringUpdatedUnitsCache = JSON.stringify(newCache);
    try {
      localStorage.setItem('data', stringUpdatedUnitsCache);
    } catch (e) {
      console.error(e);
    }
  }
};

const populateStandardCache = (
  zNumber: number,
  timePeriod: string,
  newData: any,
) => {
  const data = getLocalStorageData();
  if (data) {
    const cache = JSON.parse(data);
    let newCache = cache;
    if (newCache[zNumber]) {
      newCache = {
        ...cache,
        [zNumber]: {
          ...cache[zNumber],
          standard: {
            ...cache[zNumber].standard,
            [timePeriod]: { data: newData, createdAt: current() },
          },
        },
      };
    } else {
      newCache = {
        ...cache,
        [zNumber]: {
          standard: { [timePeriod]: { data: newData, createdAt: current() } },
          custom: {},
        },
      };
    }
    const stringCache = JSON.stringify(newCache);
    clearSpaceInStorage(stringCache, timePeriod);
  }
};

const populateCustomCache = (
  zNumber: number,
  timePeriod: string,
  start: string,
  end: string,
  newData: any,
) => {
  const data = getLocalStorageData();
  if (data) {
    const cache = JSON.parse(data);
    let newCache = cache;
    if (newCache[zNumber]) {
      newCache = {
        ...cache,
        [zNumber]: {
          ...cache[zNumber],
          custom: { ...cache[zNumber].custom, [`${start}_${end}`]: newData },
        },
      };
    } else {
      newCache = {
        ...cache,
        [zNumber]: { standard: {}, custom: { [`${start}_${end}`]: newData } },
      };
    }
    const stringCache = JSON.stringify(newCache);
    clearSpaceInStorage(stringCache, timePeriod);
  }
};

const checkCacheForZephyrData = (
  zNumber: number,
  timePeriod: string,
  start: string,
  end: string,
) => {
  let output = false;
  const data = getLocalStorageData();
  // check whether the cache has been established or not
  if (!data) {
    // make the data request and create the localStorage item
    localStorage.setItem('data', JSON.stringify({ units: [zNumber] }));
  } else {
    // retrieve the current cache from local storage
    const cache = JSON.parse(data);
    // check if the unit exsist already in the unit list
    const newUnitList = cache.units.slice();
    if (cache.units.includes(zNumber)) {
      const idx = newUnitList.indexOf(zNumber);
      newUnitList.splice(idx, 1);
    }
    // update the accessed units list
    const newCache = { ...cache, units: [...newUnitList, zNumber] };
    localStorage.setItem('data', JSON.stringify(newCache));
    const zCache = newCache[zNumber];
    // attempt to locate the data
    if (zCache) {
      if (timePeriod !== 'custom') {
        if (zCache.standard[timePeriod]) {
          if (
            zCache.standard[timePeriod].createdAt >=
            subtractTimeFromNow(15, 'minutes')
          ) {
            output = zCache.standard[timePeriod].data;
          }
        }
      } else if (timePeriod === 'custom') {
        if (zCache.custom[`${start}_${end}`]) {
          output = zCache.custom[`${start}_${end}`];
        }
      }
    }
    return output;
  }
};

export const checkDataResponseForData = (
  unaveragedSlotData: {
    [key: string]: { data: number[] };
  },
  objKey: string,
) => {
  let hasData: any = false;
  if (unaveragedSlotData) {
    const discountedSpecies = [
      'UTS',
      'dateTime',
      'localDateTime',
      'latitude',
      'longitude',
    ];
    for (const [speciesKey, speciesValues] of Object.entries(
      unaveragedSlotData,
    )) {
      if (speciesValues.data) {
        const notNull = speciesValues.data.find(
          (dp) => dp !== null && !discountedSpecies.includes(speciesKey),
        );

        hasData = notNull;
        if (notNull) {
          hasData = true;
          return hasData;
        }
      }
    }
  }
  return hasData;
};

export const convertVZephyrDataToZephyrHistoricalData = (
  unformattedData: any,
  zephyr: Zephyr,
  startDateISO: string,
  endDateISO: string,
  isModelled: boolean,
  zephyrType?: number,
  isSa?: boolean,
) => {
  const convertedData = zephyrType ? {
    data: {
      'Hourly average on the hour': {},
    },
    zType: zephyrType
  } : {
    data: {
      'Hourly average on the hour': {},
    }
  }

  if (unformattedData) {
    const currentDate = new Date();
    const estimateDataDelayHrs = 1;
    const dateDataDueISO = new Date(
      new Date(zephyr.userStartTimeDate).setHours(
        new Date(zephyr.userStartTimeDate).getHours() + estimateDataDelayHrs,
      ),
    ).toISOString();
    // check if estimateDataDelayHrs has passed
    if (currentDate.toISOString() > dateDataDueISO) {
      // now check if we have the data
      if (!Array.isArray(unformattedData) && unformattedData && unformattedData.outputs.results.length > 0 && unformattedData.outputs.results[0].values_timestamps.length) {
        // data is ready
        convertedData.data = convertVZephyrRawToCSV(unformattedData, isModelled, !!isSa);
      } else {
        // data is there(empty)because its not ready
      }
    } else {
      // data is not due
    }
  }
  return convertedData;
};

const convertVZephyrRawToCSV = (raw: {
  latlon: { longitude: any; latitude: any };
  outputs: { results: any };
}, isModelled: boolean, isSa: boolean) => {
  const unitHistory: any = {
    'Hourly average on the hour': { head: {}, slotA: null, slotB: {} },
    '8 hour average at midnight and 8am and 4pm': {
      head: {},
      slotA: null,
      slotB: {},
    },
    '15 min average on the quarter hours': { head: {}, slotA: null, slotB: {} },
    '5 minute averaging on the hour': { head: {}, slotA: null, slotB: {} },
    '1 min average on the minute': { head: {}, slotA: null, slotB: {} },
    'Daily average at midnight': { head: {}, slotA: null, slotB: {} },
    Unaveraged: { head: {}, slotA: null, slotB: {} },
  };
  for (const [avgKey, avgValue] of Object.entries(unitHistory)) {
    setAveragePties(raw, avgKey, avgValue, unitHistory, isModelled, isSa);
  }
  return unitHistory;
};

const setAveragePties = (raw: any, avgKey: any, avgValue: any, unitHistory: any, isModelled: boolean, isSa: boolean) => {
  const localDateTimeDataArr: string[] = [];
  const UTSDataArr: string[] = [];
  const dateTimeDataArr: string[] = [];
  const PM2P5Keys = {
    headerspecimen: 'particulatePM25',
    headerHTMLLabel: 'PM<sub>2.5</sub>',
    headergroup: 'particulatePM',
    headerlabel: 'PM2.5',
  };
  const { head, slotB }: any = avgValue;

  const dateToLocalISO = (date: any) => {
    const off = new Date(date).getTimezoneOffset();
    const absoff = Math.abs(off);
    return (
      new Date(date.getTime() - off * 60 * 1000).toISOString().substr(0, 23) +
      (off > 0 ? '-' : '+') +
      Math.floor(absoff / 60)
        .toFixed(0)
        .padStart(2, '0') +
      ':' +
      (absoff % 60).toString().padStart(2, '0')
    );
  };

  const { longitude, latitude } = raw.latlon;
  const { results } = raw.outputs;
  const formattedResults = !isModelled ? results : results.filter((res: { species: string, values_timestamps: any[], units: any, averaging_hours: number }) => {
    if (avgKey === 'Hourly average on the hour' && !isSa) return res.averaging_hours === 1;
    if (avgKey === '8 hour average at midnight and 8am and 4pm' && !isSa) return res.averaging_hours === 8;
    if (avgKey === 'Daily average at midnight' && !isSa) return res.averaging_hours === 24;
    return res;
  });
  
  if (isSa) {
    formattedResults.filter((res: any) => !res.keyword.includes('all')).map((res: any) => {
      switch (avgKey) {
        case 'Hourly average on the hour':
        case '8 hour average at midnight and 8am and 4pm':
        case 'Daily average at midnight': {
          slotB[res.keyword] = {
            data: res && res.values_timestamps && res.values_timestamps.length > 0 ? res.values_timestamps[0].proportion_percentage : null,
            allNull: res.allNull,
            keyword: res.keyword,
            label: res.sa_layer_HTML_label,
            species: res.species,
            units: res.units,
          }
          break;
        }
        default:
          slotB[res.keyword] = {
            data: null
          }
      }
    })
  }
  else {
    formattedResults.map(
      (
        result: { species: string, values_timestamps: any[], units: any } | { species: string, values_timestamps: any[], units: any, averaging_hours: number },
        i: any,
      ) => {
        // hardcoding here as backend data is not similar for vZephyr.
        // need to make it similar for PM2P5
        let headerspecimen = result.species === 'temperature' ? 'ambTempC' : result.species === 'relhum' ? 'ambHumidity' : result.species.toUpperCase();

        // they are all the same except for PM2P5
        let headerHTMLLabel = headerspecimen;
        let headergroup = headerspecimen;
        let headerkey = headerspecimen;
        let headerlabel = headerspecimen;

        if (headerspecimen === 'PM2P5') {
          headerspecimen = PM2P5Keys.headerspecimen;
          headerHTMLLabel = PM2P5Keys.headerHTMLLabel;
          headergroup = PM2P5Keys.headergroup;
          headerkey = PM2P5Keys.headerspecimen;
          headerlabel = PM2P5Keys.headerlabel;
        }

        const startOfDay = new Date();

        switch (avgKey) {
          case 'Hourly average on the hour': {
            // make species data
            slotB[headerspecimen] = {
              data: result.values_timestamps.map((values, j) => {
                values.timestamp_start = values.timestamp_start + '+00:00';
                values.timestamp_end = values.timestamp_end + '+00:00';
                // time will be the same for other species,so we get only the first
                const { averaging_hours } = result as { species: string, values_timestamps: any[], units: any, averaging_hours: number };
                if (averaging_hours === 1 || !isModelled) {
                  if (i === 0) {
                    localDateTimeDataArr.push(
                      dateToLocalISO(new Date(!isModelled ? values.timestamp || values.timestamp_start : values.timestamp_start)),
                    );
                    dateTimeDataArr.push(`${!isModelled ? new Date(values.timestamp || values.timestamp_end).toISOString().slice(0, -5) : values.timestamp_end}+00:00`);
                    UTSDataArr.push(
                      String(new Date(!isModelled ? values.timestamp || values.timestamp_start : values.timestamp_start).getTime()).slice(0, -3),
                    );
                  }
                  return values.value;
                }
              }),

              header: {
                CSVOrder: Object.keys(head).length + i,
                HTMLLabel: headerHTMLLabel,
                group: headergroup,
                key: headerkey,
                label: headerlabel,
                units: result.units,
              },
            };
            break;
          }

          case '8 hour average at midnight and 8am and 4pm': {
            // every 8 hrs in a day
            const dataToAdd = !isModelled ? !result.hasOwnProperty('averaging_hours') ? new Array(24 / 8).fill(null) : result.values_timestamps : result.values_timestamps;
            slotB[headerspecimen] = {
              data: dataToAdd.map((values, j) => {
                const { averaging_hours } = result as { species: string; values_timestamps: any[]; units: any, averaging_hours: number };
                if (averaging_hours === 8 || !isModelled) {
                  if (i === 0) {
                    !isModelled && startOfDay.setUTCHours(j * 8, 0, 0, 0);
                    localDateTimeDataArr.push(
                      dateToLocalISO(new Date(!isModelled ? !result.hasOwnProperty('averaging_hours') ? startOfDay : values.timestamp_start : values.timestamp_start)),
                    );
                    dateTimeDataArr.push(`${!isModelled ? !result.hasOwnProperty('averaging_hours') ? startOfDay.toISOString().slice(0, -5) : values.timestamp_end : values.timestamp_end}+00:00`);
                    UTSDataArr.push(
                      String(new Date(!isModelled ? !result.hasOwnProperty('averaging_hours') ? startOfDay : values.timestamp_start : values.timestamp_start).getTime()).slice(0, -3),
                    );
                  }
                  return !isModelled ? !result.hasOwnProperty('averaging_hours') ? values : values.value : values.value;
                }
              }),

              header: {
                CSVOrder: Object.keys(head).length + i,
                HTMLLabel: headerHTMLLabel,
                group: headergroup,
                key: headerkey,
                label: headerlabel,
                units: result.units,
              },
            };
            break;
          }
          case '15 min average on the quarter hours': {
            // every 15mins in a day
            const dataToAdd = new Array(24 * 4).fill(null);
            slotB[headerspecimen] = {
              data: dataToAdd.map((value, j) => {
                if (i === 0) {
                  startOfDay.setUTCHours(j * 0.25, 0, 0, 0);
                  localDateTimeDataArr.push(dateToLocalISO(new Date(startOfDay)));
                  dateTimeDataArr.push(`${!isModelled ? startOfDay.toISOString().slice(0, -5) : startOfDay}+00:00`);
                  UTSDataArr.push(String(startOfDay.getTime()).slice(0, -3));
                }
                return value;
              }),

              header: {
                CSVOrder: Object.keys(head).length + i,
                HTMLLabel: headerHTMLLabel,
                group: headergroup,
                key: headerkey,
                label: headerlabel,
                units: result.units,
              },
            };
            break;
          }
          case '5 minute averaging on the hour': {
            // every 15mins in a day
            const dataToAdd = new Array(24 * 12).fill(null);
            slotB[headerspecimen] = {
              data: dataToAdd.map((value, j) => {
                if (i === 0) {
                  startOfDay.setUTCHours(j * 0.25, 0, 0, 0);
                  localDateTimeDataArr.push(dateToLocalISO(new Date(startOfDay)));
                  dateTimeDataArr.push(`${startOfDay}+00:00`);
                  UTSDataArr.push(String(startOfDay.getTime()).slice(0, -3));
                }
                return value;
              }),

              header: {
                CSVOrder: Object.keys(head).length + i,
                HTMLLabel: headerHTMLLabel,
                group: headergroup,
                key: headerkey,
                label: headerlabel,
                units: result.units,
              },
            };
            break;
          }
          case '1 min average on the minute': {
            // every 15mins in a day
            const dataToAdd = new Array(24 * 60).fill(null);
            slotB[headerspecimen] = {
              data: dataToAdd.map((value, j) => {
                if (i === 0) {
                  startOfDay.setUTCHours(j * 0.25, 0, 0, 0);
                  localDateTimeDataArr.push(dateToLocalISO(new Date(startOfDay)));
                  dateTimeDataArr.push(`${startOfDay}+00:00`);
                  UTSDataArr.push(String(startOfDay.getTime()).slice(0, -3));
                }
                return value;
              }),

              header: {
                CSVOrder: Object.keys(head).length + i,
                HTMLLabel: headerHTMLLabel,
                group: headergroup,
                key: headerkey,
                label: headerlabel,
                units: result.units,
              },
            };
            break;
          }
          case 'Daily average at midnight': {
            const dataToAdd = !isModelled ? !result.hasOwnProperty('averaging_hours') ? [null] : result.values_timestamps : result.values_timestamps;
            slotB[headerspecimen] = {
              data: dataToAdd.map((values, j) => {
                // time will be the same for other species,so we get only the first
                const { averaging_hours } = result as { species: string; values_timestamps: any[]; units: any, averaging_hours: number };
                if (averaging_hours === 24 || !isModelled) {
                  if (i === 0) {
                    localDateTimeDataArr.push(
                      dateToLocalISO(new Date(!isModelled ? !result.hasOwnProperty('averaging_hours') ? startOfDay : values.timestamp_start : values.timestamp_start)),
                    );
                    dateTimeDataArr.push(`${!isModelled ? !result.hasOwnProperty('averaging_hours') ? startOfDay.toISOString().slice(0, -5) : values.timestamp_end : values.timestamp_end}+00:00`);
                    UTSDataArr.push(
                      String(new Date(!isModelled ? !result.hasOwnProperty('averaging_hours') ? startOfDay : values.timestamp_start : values.timestamp_start).getTime()).slice(0, -3),
                    );
                  }
                  return !isModelled ? !result.hasOwnProperty('averaging_hours') ? null : values.value : values.value;
                }
              }),

              header: {
                CSVOrder: Object.keys(head).length + i,
                HTMLLabel: headerHTMLLabel,
                group: headergroup,
                key: headerkey,
                label: headerlabel,
                units: result.units,
              },
            };
            break;
          }
          case 'Unaveraged':
            break;

          default:
            break;
        }
        slotB.UTS = { data: UTSDataArr };
        slotB.localDateTime = { data: localDateTimeDataArr };
        slotB.dateTime = { data: dateTimeDataArr };
      },
    );
  }

  avgValue.head = {
    latitude: {
      data: new Array(localDateTimeDataArr.length).fill(latitude),
      header: {
        CSVOrder: 4,
        HTMLLabel: 'Latitude',
        group: 'latitude',
        key: 'latitude',
        label: 'Latitude',
        units: 'degrees',
      },
    },
    longitude: {
      data: new Array(localDateTimeDataArr.length).fill(longitude),
      header: {
        CSVOrder: 3,
        HTMLLabel: 'Longitude',
        group: 'Longitude',
        key: 'Longitude',
        label: 'Longitude',
        units: 'degrees',
      },
    },
    UTS: {
      data: UTSDataArr,
      header: {
        CSVOrder: 2,
        HTMLLabel: 'Timestamp-UTS',
        group: 'UTS',
        key: 'UTS',
        label: 'Timestamp-UTS',
        units: '',
      },
    },
    localDateTime: {
      data: localDateTimeDataArr,
      header: {
        CSVOrder: 1,
        HTMLLabel: 'Local Timestamp',
        group: 'localDateTime',
        key: 'localDateTime',
        label: 'Timestamp-Local',
        units: ' ',
      },
    },
    dateTime: {
      data: dateTimeDataArr,
      header: {
        CSVOrder: 0,
        HTMLLabel: 'Timestamp',
        group: 'dateTime',
        key: 'dateTime',
        label: 'Timestamp',
        units: ' ',
      },
    },
  };
};