// Package Imports
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import {
  DateTimePicker,
} from '@material-ui/pickers';

// Hooks
import { useTranslation } from 'react-i18next';
import useOutsideClick from '../../hooks/useOutsideClick';

// Action Imports
import {
  setSelectedFilters,
  mappAirFilters,
} from '../../actions/mappAirFilters';
import {
  showAQMALayer,
  showOverlayLayer,
  showSatelliteLayer,
  showOSMLayer,
  showSchoolsLayer,
  showSmokeControlZones,
  showAALayer,
  setAnnualAverage,
} from '../../actions/layers';

// Component Imports
import LocationSearch from './LocationSearch';

// Util Imports
import analyticsEventFirer from '../../utils/functions/analyticsEventFirer';


// Const Imports
import { gtmEventIdentifiers, annualAveragesArr } from '../../utils/consts';

// Type Imports
import { UserInfo, ReduxState, YearObj } from '../../utils/interface';

// Asset Imports
import layers from '../../assets/images/layers.png';
import mapairLayer from '../../assets/images/mappair-layer-with-black-icon.png';
import aqmaLayer from '../../assets/images/layer-aqma.png';
import schoolsLayer from '../../assets/images/layer-schools.png';
import osmLayer from '../../assets/images/layer-default.png';
import defaultLayer from '../../assets/images/layer-grayscale.png';
import satelliteLayer from '../../assets/images/layer-satellite.png';
import aaLayer from '../../assets/images/annual-averages-with-black-icon.png';

import airQualityIcon from '../../assets/icons/air_quality_icon.png';
import schoolsIcon from '../../assets/icons/schools_icon.png';
import realTimeIcon from '../../assets/icons/real_time_icon.png';
import annualAvgIcon from '../../assets/icons/annual_averages_icon.png';
import smokeControlIcon from '../../assets/icons/smoke-control_icon.png';

import TimeSlider from './TimeSlider';
import { ModularFeatures } from '../../reducers/modularFeatures';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Alert, Snackbar } from '@mui/material';

interface LocationControlsProps {
  additionalClass?: String;
  asideOn: boolean;
  handleLocationSearch: Function;
  showLocationSearch: boolean;
  userInfo: UserInfo;
  zoom: Function;
  mappAirFilters: Function;
  showOverlayLayer: Function;
  showAQMALayer: Function;
  showSchoolsLayer: Function;
  showSmokeControlZones: Function;
  showSatelliteLayer: Function;
  showOSMLayer: Function;
  mappairOn: boolean;
  satelliteOn: boolean;
  osmOn: boolean;
  aqmaOn: boolean;
  schoolsOn: boolean;
  smokeControlZonesOn: boolean;
  curTimeSliderOption: number | null;
  setCurTimeSliderOption: Function | null;
  wmsDateTime: Date | null;
  setWMSDateTime: Function | null;
  showAALayer: Function;
  annualAveragesOn: boolean;
  setAnnualAverage: Function;
  annualAverage: number | null;
  curOverlayName?: string;
  modularFeatures: ModularFeatures;
}

// Component Interfaces
interface InitState {
  isLayerOpen: boolean;
  isAnnualAvgOptionsOpened: boolean;
  selectedYear: number | null;
  aqiError: boolean;
}

// Component
const LocationControls = ({
  additionalClass,
  asideOn,
  handleLocationSearch,
  showLocationSearch,
  userInfo,
  zoom,
  mappAirFilters,
  showOverlayLayer,
  showAQMALayer,
  showSchoolsLayer,
  showSmokeControlZones,
  showSatelliteLayer,
  showOSMLayer,
  mappairOn,
  satelliteOn,
  osmOn,
  aqmaOn,
  schoolsOn,
  smokeControlZonesOn,
  curTimeSliderOption,
  setCurTimeSliderOption,
  wmsDateTime,
  setWMSDateTime,
  showAALayer,
  annualAveragesOn,
  setAnnualAverage,
  annualAverage,
  curOverlayName,
  modularFeatures,
}: LocationControlsProps) => {
  const location = useLocation();
  const { t: translate } = useTranslation();

  // Consts

  const dateTimePickerPositionAsideOn = curTimeSliderOption === 0 ? 333 : 535;
  const dateTimePickerPositionAsideOff = curTimeSliderOption === 0 ? 0 : 202;

  // State
  const initState: InitState = {
    isLayerOpen: false,
    isAnnualAvgOptionsOpened: false,
    selectedYear: 2015,
    aqiError: false,
  };

  const [isLayerOpen, setLayerOpen] = useState(initState.isLayerOpen);
  const [isAnnualAvgOptionsOpened, setIsAnnualAvgOptionsOpened] = useState(initState.isAnnualAvgOptionsOpened);
  const [selectedYear, setSelectedYear] = useState(initState.selectedYear);
  const [aqiError, setAQIError] = useState(initState.aqiError);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [dateTimePickerOpen, setDateTimePickerOpen] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);
  const aqiErrorModal = useRef<any>(null);

  useOutsideClick(ref, (event: any) => {
    if (isLayerOpen && event.target.getAttribute('id') !== 'closeTooltipPath' && event.target.getAttribute('id') !== 'closeTooltipBtn') { setLayerOpen(false); if (aqiError) setAQIError(false); }
  });

  const fireToggleAnalyticsEvent = (
    eventIdentifier: string,
    itemStatus: boolean,
  ) => {
    const labelEnrichment = itemStatus ? 'Off' : 'On';
    analyticsEventFirer(eventIdentifier, labelEnrichment);
  };

  const closeAnnualAvgModalAndOpenLayer = (event: any) => {
    event.stopPropagation();
    setIsAnnualAvgOptionsOpened(false);
    setLayerOpen(true);
  };

  const closeAQIErrorModal = (event: any) => {
    event.stopPropagation();
    setAQIError(false);
    setLayerOpen(true);
  };

  const setAAOverlay = (event: any) => {
    if (selectedYear) {
      if (mappairOn) { showOverlayLayer(false); mappAirFilters(false) };
      showAALayer();
      setAnnualAverage(selectedYear);
      fireToggleAnalyticsEvent(
        gtmEventIdentifiers.annualAveragesToggle,
        annualAveragesOn,
      );
      closeAnnualAvgModalAndOpenLayer(event)
    }
  };

  const calculateMinMaxDates = (min: number, max: number) => {
    const now = new Date();

    let minDate: Date = new Date();
    let maxDate: Date = new Date();

    if (curTimeSliderOption === 0) {
      minDate = new Date(now);
      minDate.setDate(now.getDate() - min);
      maxDate = new Date(now);
    } else if (curTimeSliderOption === 2) {
      minDate = new Date(now);
      minDate.setHours(0, 0, 0, 0);
      maxDate = new Date(now);
      maxDate.setDate(now.getDate() + max);
    }

    return { minDate, maxDate };
  };

  const handleDateChange = (newDate: MaterialUiPickersDate) => {
    if (!newDate) return;

    const selectedDate = newDate instanceof Date ? newDate : newDate.toDate();
    const isToday = selectedDate.toDateString() === new Date().toDateString();
    const currentTime = new Date();

    const isSelectedDateInFuture = selectedDate > currentTime;
    const isSelectedDateInPast = selectedDate < currentTime;

    if (curTimeSliderOption === 0 && isToday && isSelectedDateInFuture) {
      setSnackbarMessage("You cannot select a time later than the current time today.");
      setSnackbarOpen(true);
      setDateTimePickerOpen(true);
      return;
    }

    if (curTimeSliderOption === 2 && isToday && isSelectedDateInPast) {
      setSnackbarMessage("You cannot select a time earlier than the current time today.");
      setSnackbarOpen(true);
      setDateTimePickerOpen(true);
      return;
    }
    setSnackbarMessage('');
    setSnackbarOpen(false);
    setDateTimePickerOpen(false);
    setWMSDateTime!(selectedDate.toISOString());
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleDatePickerOpen = () => {
    setDateTimePickerOpen(true);
  };

  const handleDatePickerClose = () => {
    setDateTimePickerOpen(false);
  };

  const handleClickOutsideOrCloseButton = (event: any) => {
    const isOutsideClick = event.target.className === 'MuiDialog-container MuiDialog-scrollPaper';
    const isCancelButton = event.target.className === 'MuiButton-label' && event.target.innerHTML === 'Cancel';
    if (isOutsideClick || isCancelButton) handleDatePickerClose();
  };

  return (
    <div className={`map-layer-control ${userInfo.isPublic ? 'public' : ''}`} onClick={handleClickOutsideOrCloseButton}>
      {!location.pathname.includes('data') &&
        !location.pathname.includes('alerts') &&
        !location.pathname.includes('analytics') ? (
        <>
          {mappairOn &&
            modularFeatures.timeSlider[
            userInfo.isPublic ? 'isPublic' : 'isPrivate'
            ] && (
              <div>
                {(curTimeSliderOption === 0 || curTimeSliderOption === 2) && (
                  <>
                    <DateTimePicker
                      onChange={handleDateChange}
                      onOpen={handleDatePickerOpen}
                      open={dateTimePickerOpen}
                      minDate={calculateMinMaxDates(14, 2).minDate}
                      maxDate={calculateMinMaxDates(14, 2).maxDate}
                      value={
                        wmsDateTime ? new Date(wmsDateTime) : calculateMinMaxDates(14, 2).minDate
                      }
                      className={`map-layers-group ${asideOn ? '' : 'expand'}`}
                      style={{
                        top: 60,
                        left: asideOn
                          ? dateTimePickerPositionAsideOn
                          : dateTimePickerPositionAsideOff,
                        borderBottom: 'none',
                      }}
                    />
                  </>
                )}
                <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={6000}
                  onClose={handleSnackbarClose}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  <Alert onClose={handleSnackbarClose} severity="error" sx={{
                    width: '100%',
                    fontSize: '1.2rem',
                    padding: '16px 24px',
                    borderRadius: '8px',
                    color: '#000',
                  }}>
                    {snackbarMessage}
                  </Alert>
                </Snackbar>
                <TimeSlider
                  asideOn={asideOn}
                  curTimeSliderOption={curTimeSliderOption}
                  setCurTimeSliderOption={setCurTimeSliderOption}
                  wmsDateTime={wmsDateTime}
                  setWMSDateTime={setWMSDateTime}
                />
              </div>
            )}
          <div className={`map-layers-group ${asideOn ? '' : 'expand'}`}>
            <button
              onClick={(event) => {
                event.stopPropagation();
                setLayerOpen(!isLayerOpen);
              }}
            >
              <img
                src={layers}
                alt="map layers"
                width="72"
                height="72"
                className="map-layers-button"
              />
            </button>

            <div
              className={`position-relative ${isLayerOpen || isAnnualAvgOptionsOpened ? '' : 'd-none'
                }`}
              ref={ref}
            >
              <div className="map-layer-modal">
                <div className="map-layers-list container py-4 px-0">
                  <div className="row justify-content-between">
                    <div className="col map-details">
                      {!isAnnualAvgOptionsOpened
                        ? translate('OverlaysHeader')
                        : translate('ChooseYear')}
                    </div>
                    <div className="col close-btn">
                      <button
                        className="button close close-modal p-0"
                        onClick={() => {
                          if (isAnnualAvgOptionsOpened)
                            setIsAnnualAvgOptionsOpened(false);
                          setLayerOpen(false);
                        }}
                        type="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 24 24"
                          fill="#000"
                        >
                          <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
                        </svg>
                      </button>
                    </div>
                  </div>

                  {!isAnnualAvgOptionsOpened ? (
                    <>
                      <div className="row mt-4">
                        <div className="col">
                          <button
                            type="button"
                            onClick={() => {
                              showAQMALayer();
                              // Analytics
                              fireToggleAnalyticsEvent(
                                gtmEventIdentifiers.aqmaToggle,
                                aqmaOn,
                              );
                            }}
                            className={`image-layer ${aqmaOn ? 'selected' : ''
                              }`}
                          >
                            <img
                              src={airQualityIcon}
                              alt="aqma layer"
                              className="bg-blue rounded-circle"
                            />
                            <div className="layer-type-text">
                              {userInfo.isPublic
                                ? translate('OverlaysAQMAContentPublic')
                                : translate('OverlaysAQMA')}
                            </div>
                          </button>
                        </div>
                        {modularFeatures.schools[
                          userInfo.isPublic ? 'isPublic' : 'isPrivate'
                        ] ? (
                          <div className="col">
                            <button
                              type="button"
                              onClick={() => {
                                showSchoolsLayer();
                                // Analytics
                                fireToggleAnalyticsEvent(
                                  gtmEventIdentifiers.schoolsToggle,
                                  schoolsOn,
                                );
                              }}
                              className={`w-100 image-layer ${schoolsOn ? 'selected' : ''}`}
                            >
                              <img
                                src={schoolsIcon}
                                alt="schools layer"
                                className="bg-blue rounded-circle"
                              />
                              <div className="layer-type-text">
                                {userInfo.isPublic
                                  ? translate('OverlaysSchoolsContentPublic')
                                  : translate('OverlaysSchools')}
                              </div>
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      {
                        modularFeatures.smokeControlZones[
                        userInfo.isPublic ? 'isPublic' : 'isPrivate'
                        ] && <div className="row mt-4">
                          <div className="col">
                            <button
                              type="button"
                              onClick={() => {
                                showSmokeControlZones();
                                // Analytics
                                fireToggleAnalyticsEvent(
                                  gtmEventIdentifiers.smokeControlZonesToggle,
                                  smokeControlZonesOn,
                                );
                              }}
                              className={`w-100 image-layer ${smokeControlZonesOn ? 'selected' : ''}`}
                            >
                              <img
                                src={smokeControlIcon}
                                alt="smoke control zones layer"
                                className="bg-blue rounded-circle"
                              />
                              <div className="layer-type-text">
                                {userInfo.isPublic
                                  ? translate('OverlaysUKSmokeControlZonesPublic')
                                  : translate('OverlaysUKSmokeControlZones')}
                              </div>
                            </button>
                          </div>
                          <div className="col" />
                        </div>
                      }
                      <div className="row">
                        <div className="col separator m-3" />
                      </div>

                      <div className="row">
                        <div className="col-12 mb-4 map-type">
                          {translate('ModelLayers')}
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            onClick={() => {
                              mappAirFilters();
                              showOverlayLayer();
                              if (annualAveragesOn) showAALayer(false);
                              // Analytics
                              fireToggleAnalyticsEvent(
                                gtmEventIdentifiers.mappairToggle,
                                mappairOn,
                              );
                            }}
                            className={`w-100 image-layer ${mappairOn ? 'selected' : ''
                              }`}
                          >
                            <img
                              src={realTimeIcon}
                              alt="mapair layer"
                              className="bg-green rounded-circle"
                            />
                            <div className="layer-type-text mappair-overlay-text">
                              {userInfo.isPublic
                                ? translate('OverlaysMappairContentPublic')
                                : translate('OverlaysMappair')}
                            </div>
                          </button>
                        </div>
                        {aqiError && (
                          <div className="aqi-error">
                            <span>
                              No data for AQI. Please choose another pollutant.
                            </span>
                            <div className="col-1 close-btn">
                              <button
                                className="button close close-modal p-0"
                                onClick={closeAQIErrorModal}
                                type="button"
                              >
                                <svg
                                  id="closeTooltipBtn"
                                  className="close-aqi-error-tooltip-btn"
                                  ref={aqiErrorModal}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="13"
                                  viewBox="0 0 24 24"
                                  fill="#fff"
                                >
                                  <path
                                    d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"
                                    id="closeTooltipPath"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        )}
                        {modularFeatures.annualAverages[
                          userInfo.isPublic ? 'isPublic' : 'isPrivate'
                        ] ? (
                          <div className="col">
                            <button
                              type="button"
                              onClick={() => {
                                if (curOverlayName?.includes('AQI')) {
                                  setAQIError(true);
                                  return;
                                }
                                if (
                                  !isAnnualAvgOptionsOpened &&
                                  !annualAveragesOn
                                )
                                  setIsAnnualAvgOptionsOpened(true);
                                else if (annualAveragesOn) showAALayer();
                                if (aqiError) setAQIError(false);
                              }}
                              className={`w-100 image-layer  ${annualAveragesOn ? 'selected' : ''
                                }`}
                            >
                              <img
                                src={annualAvgIcon}
                                alt="aqma layer"
                                className="rounded-circle bg-green"
                              />
                              <div className="layer-type-text aa-overlay-text">
                                {userInfo.isPublic
                                  ? translate('OverlaysAAContentPublic')
                                  : annualAverage && annualAveragesOn
                                    ? `${translate(
                                      'OverlaysAA',
                                    )} (${annualAverage})`
                                    : `${translate('OverlaysAA')}`}
                              </div>
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="row">
                        <div className="col separator m-3" />
                      </div>

                      <div className="row mb-4">
                        <div className="col-12 mb-4 map-type">
                          {translate('BaseMaps')}
                        </div>

                        <div className="col">
                          <button
                            type="button"
                            onClick={() => {
                              // setSelectedFilters([]);
                              if (aqmaOn) showAQMALayer();
                              if (schoolsOn) showSchoolsLayer();
                              if (smokeControlZonesOn) showSmokeControlZones();
                              if (satelliteOn) showSatelliteLayer();
                              if (osmOn) showOSMLayer();
                              if (annualAveragesOn) showAALayer();
                              if (mappairOn) {
                                mappAirFilters();
                                showOverlayLayer();
                              }

                              // Analytics
                              fireToggleAnalyticsEvent(
                                gtmEventIdentifiers.allToggle,
                                false,
                              );
                            }}
                            className={`image-layer ${!aqmaOn &&
                              !mappairOn &&
                              !satelliteOn &&
                              !osmOn &&
                              !schoolsOn &&
                              !smokeControlZonesOn &&
                              !annualAveragesOn
                              ? 'selected'
                              : ''
                              }`}
                          >
                            <img
                              src={defaultLayer}
                              alt="default layer"
                              style={{ padding: 1 }}
                            />
                            <div className="layer-type-text">
                              {' '}
                              {userInfo.isPublic
                                ? translate('OverlaysDefaultContentPublic')
                                : translate('OverlaysDefault')}
                            </div>
                          </button>
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            onClick={() => {
                              showSatelliteLayer();
                              if (osmOn) showOSMLayer();
                              // Analytics
                              fireToggleAnalyticsEvent(
                                gtmEventIdentifiers.satelliteToggle,
                                satelliteOn,
                              );
                            }}
                            className={`w-100 image-layer ${satelliteOn ? 'selected' : ''
                              }`}
                          >
                            <img
                              src={satelliteLayer}
                              alt="satellite layer"
                              style={{ padding: 1 }}
                            />
                            <div className="layer-type-text">
                              {translate('OverlaysSatellite')}
                            </div>
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <button
                            type="button"
                            onClick={() => {
                              showOSMLayer();
                              if (satelliteOn) showSatelliteLayer();
                              fireToggleAnalyticsEvent(
                                gtmEventIdentifiers.osmToggle,
                                osmOn,
                              );
                            }}
                            className={`image-layer ${osmOn && !satelliteOn ? 'selected' : ''
                              }`}
                          >
                            <img
                              src={osmLayer}
                              alt="osm layer"
                              style={{ padding: 1 }}
                            />
                            <div className="layer-type-text">
                              {translate('OverlaysOSM')}
                            </div>
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col">
                          {annualAveragesArr.map((a: YearObj, idx: number) => (
                            <label
                              className="radio"
                              htmlFor={`radio-${idx}`}
                              key={a.label}
                            >
                              {a.value}
                              <input
                                type="radio"
                                id={`radio-${idx}`}
                                name="radio"
                                checked={
                                  selectedYear
                                    ? selectedYear === a.label
                                    : undefined
                                }
                                value={parseInt(a.value)}
                                onChange={(event) => {
                                  setSelectedYear(parseInt(event.target.value));
                                }}
                              />
                              <span className="checkmark" />
                            </label>
                          ))}
                        </div>
                      </div>
                      <div className="row annual-av-btns__container">
                        <button
                          className="annual-avg-btn annual-avg-select-btn"
                          onClick={setAAOverlay}
                        >
                          Select
                        </button>
                        <button
                          className="annual-avg-btn annual-avg-cancel-btn"
                          onClick={(event) => {
                            if (isAnnualAvgOptionsOpened) {
                              closeAnnualAvgModalAndOpenLayer(event);
                            }
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div
        className={`map-controls tourZoomButtons ${additionalClass} ${userInfo.isPublic ? 'public-map-controls' : ''
          } ${asideOn ? '' : 'expand'}`}
      >
        <div className="control-container">
          <button
            onClick={() => zoom(true)}
            type="button"
            className="zoom-in"
          />
        </div>

        <div className="control-container">
          <button
            onClick={() => zoom(false)}
            type="button"
            className="zoom-out"
          />
        </div>

        <LocationSearch
          on={showLocationSearch}
          userInfo={userInfo}
          handleLocationSearch={handleLocationSearch}
        />
      </div>
    </div>
  );
};

// Default Props
LocationControls.defaultProps = {
  additionalClass: '',
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  aqmaOn: state.showAQMALayer.showAQMALayer,
  schoolsOn: state.showAQMALayer.showSchoolsLayer,
  smokeControlZonesOn: state.showAQMALayer.showSmokeControlZones,
  mappairOn: state.showAQMALayer.showOverlayLayer,
  tourFilterOpen: state.tour.openAsideFilter,
  satelliteOn: state.showAQMALayer.showSatelliteLayer,
  osmOn: state.showAQMALayer.showOSM,
  selectedFilters: state.mappAirFilters.selectedFilters,
  annualAveragesOn: state.showAQMALayer.showAALayer,
  annualAverage: state.showAQMALayer.annualAverage,
  modularFeatures: state.modularFeatures
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      mappAirFilters,
      setSelectedFilters,
      showAQMALayer,
      showSchoolsLayer,
      showSmokeControlZones,
      showOverlayLayer,
      showSatelliteLayer,
      showOSMLayer,
      showAALayer,
      setAnnualAverage,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LocationControls);

