// Package Imports
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// Component Imports
import Modal from '../shared_components/Modal';
import ZephyrQuoteModal from './ZephyrQuoteModal';

// Util Imports
import {
  zephyrMeasurementOptions,
} from '../../utils/consts';

// Type Imports
import { AveragingPeriod, Modal as ModalType, Pollutant, TimePeriod, Zephyr } from '../../utils/interface';
import { AnalyticsOptions } from '../shared_components/Aside';

// Component Interfaces
interface AsidePromptsProps {
  modal: ModalType;
  setModalsOff: Function;
  setZephyrQuoteModalOn: Function;
  dataAnalytics?: boolean;
  filteredZephyrs?: Zephyr[];
  pollutantData?: any;
  selectedTimePeriod?: TimePeriod | null;
  selectedAveragingPeriod?: AveragingPeriod | null;
  generateHistoricalData?: Function;
  options?: AnalyticsOptions;
  setOptions?: Function;
}

const runAnalysisButtonStyles = {
  width: '100%',
  height: '80%',
  margin: 'auto',
};

const asidePromptBackgroundStyles = {
  height: 65,
  backgroundColor: '#1d1e30'
}

const AsidePrompt = ({
  modal,
  setModalsOff,
  setZephyrQuoteModalOn,
}: AsidePromptsProps) => {
  const { t: translate } = useTranslation();
  // Consts
  const contactFormOptions = {
    formData: {
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      zephyrs: 1,
      powerOption: '',
      species: [...zephyrMeasurementOptions.slice(0, 2)],
    },
    userMessage: '',
  };

  // Component
  return (
    <div className="aside-prompt-container">
      <div className="aside-prompt-explainer">
        {translate('AsidePromptExplainer')}
      </div>
      <button
        type="button"
        className="prompt-button"
        onClick={() => {
          setZephyrQuoteModalOn();
        }}
      >
        {translate('AsideButtonContent')}
      </button>
      
        <Modal
          modalComponent={
            <ZephyrQuoteModal
              contactFormOptions={contactFormOptions}
              setModalsOff={setModalsOff}
            />
          }
          on={modal.zephyrQuote}
        />
      
    </div>
  );
};

export default AsidePrompt;
