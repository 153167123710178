// Package Imports
import React, { useState, useEffect } from 'react';
import Tour from 'reactour';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Path Imports
import { routePaths } from '../../utils/consts';

// Action Imports
import {
  startTour,
  closeTour,
  tourOpenAsideFilter,
  tourCloseAsideFilter,
  tourOpenAQDataIndex,
  tourCloseAQDataIndex,
  tourOpenAQColorscaleIndex,
  tourCloseAQColorscaleIndex,
  tourOpenHelp,
  tourCloseHelp,
  tourOpenLocationSearch,
  tourCloseLocationSearch,
  tourMapClick,
  tourCloseModelDataIndex,
  tourOpenModelDataIndex,
} from '../../actions/tour';
import { mappAirFilters } from '../../actions/mappAirFilters';
import { showOverlayLayer } from '../../actions/layers';
import { setUnitLocation } from '../../actions/locations';
import { setZephyr, getZephyrData } from '../../actions/zephyrs';

import { openAside, closeAside } from '../../actions/aside';

// Const Imports
import { helpResourceLink } from '../../utils/consts';

const stepStyle = {
  height: 250,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const UserTour = withRouter(
  ({
    alertsOn,
    appLoading,
    asideOn,
    closeAside,
    closeTour,
    openAside,
    openTour,
    mappAirOn,
    mappAirFilters,
    showOverlayLayer,
    showTour,
    setUnitLocation,
    setZephyr,
    startTour,
    tourCloseAQColorscaleIndex,
    tourCloseAsideFilter,
    tourCloseHelp,
    tourCloseLocationSearch,
    tourMapClick,
    tourCloseModelDataIndex,
    tourOpenAQColorscaleIndex,
    tourOpenAQDataIndex,
    tourOpenAsideFilter,
    tourOpenHelp,
    tourOpenLocationSearch,
    tourOpenModelDataIndex,
    userInfo,
    zephyrList,
    bearerToken,
  }) => {
    // State
    const [initialAsideOn, setInitialAsideOn] = useState(asideOn);

    // hooks the current history and location objects
    const [history, location] = [useHistory(), useLocation()];

    // check to see if user is private and hasn't viewed a tour before
    useEffect(() => {
      if (
        userInfo &&
        !userInfo.isInternal &&
        !userInfo.isPublic &&
        showTour &&
        !alertsOn &&
        !appLoading &&
        zephyrList.length &&
        zephyrList[0].averageOfLastHourOfData
      ) {
        startTour();
      }
    }, [userInfo, startTour, showTour, alertsOn, appLoading]);

    // prevents and re-enables scrolling when the tour is launched and closed
    const disableBody = (target) => disableBodyScroll(target);
    const enableBody = (target) => enableBodyScroll(target);

    const toggleMappAir = () => {
      mappAirFilters();
      showOverlayLayer();
    };

    const handleMappAirClose = () => {
      // close MappAir and filters
      tourMapClick();
      toggleMappAir();
      tourCloseAsideFilter();
      tourCloseAQColorscaleIndex();
    };

    const handleZephyrSelection = () => {
      // set either the first Zephyr with data or the first Zephyr
      let selectedZephyr;
      for (let i = 0; i < zephyrList.length; i++) {
        if (zephyrList[i].averageOfLastHourOfData.NO2) {
          selectedZephyr = zephyrList[i];
          break;
        } else if (i === zephyrList.length - 1) {
          selectedZephyr = zephyrList[0];
        }
      }
      setUnitLocation(selectedZephyr.longitude, selectedZephyr.latitude);
      setZephyr(selectedZephyr);
      // open the AQ data tab
      tourOpenAQDataIndex();
    };

    const handleFiltersHeaderScroll = () => {
      const filtersHeader = document.querySelector('.filters-header');
      filtersHeader.scrollIntoView();
    };

    const handleProductInventory = () => {
      openAside();
      tourCloseLocationSearch();
      // tourOpenAsideFilter();
    };

    const handleAQIndexTabSwitch = () => {
      tourCloseModelDataIndex();
      tourOpenAQColorscaleIndex();
    };

    const handleOpenTour = () => {
      // Find the status of the aside on tour open to reset at the end
      setInitialAsideOn(asideOn);
      // Push to main page for tour start
      if (location.pathname.includes('data')) {
        history.push(routePaths.app);
      }
      // Make sure MappAir is turned off
      if (mappAirOn) {
        toggleMappAir();
      }
    };

    const handleCloseTour = () => {
      closeTour(bearerToken);
      // Re-establish PI status to what it was when tour started
      if (initialAsideOn !== asideOn) {
        initialAsideOn ? openAside() : closeAside();
      }
      // Push back to main page when tour is finished
      if (location.pathname.includes('data')) {
        history.push(routePaths.app);
      }
      // Turn off MappAir
      if (mappAirOn) {
        toggleMappAir();
      }
    };

    // outlines each step of the tour (has to be able to see history)
    const steps = [
      {
        selector: '',
        content: () => (
          <div className="tour-content">
            Welcome to <strong>MyAir®</strong>. Please take a moment to follow
            this tour to get started.
          </div>
        ),
        style: stepStyle,
        // push to the main page to start the tour
      },
      {
        selector: '[data-user-tour="step-1"]',
        position: 'center',
        content: () => (
          <div className="tour-content">
            The Product Inventory displays the devices you have available to you
            and their statuses. Here you can update the names of devices.
          </div>
        ),
        action: () => handleProductInventory(),
        style: stepStyle,
      },

      {
        selector: '[data-user-tour="step-2"]',
        position: 'center',
        content: () => (
          <div className="tour-content">
            Click on one of your devices to select it on the interactive map.
          </div>
        ),
        action() {
          handleAQIndexTabSwitch();
        },
        style: stepStyle,
      },

      {
        selector: '[data-user-tour="step-3"]',
        position: 'center',
        content: () => (
          <div className="tour-content">
            The map and devices are all colour-coded by pollution level using
            the selected colourscale.
          </div>
        ),
        style: stepStyle,
      },

      {
        selector: '[data-user-tour="step-4"]',
        position: 'center',
        content: () => (
          <div className="tour-content">
            Switch between key pollutants in the Pollutant menu.
          </div>
        ),
        action() {
          tourCloseAQColorscaleIndex();
          tourOpenAQDataIndex();
        },
        style: stepStyle,
      },

      {
        selector: '[data-user-tour="step-5"]',
        position: 'center',
        content: () => (
          <div className="tour-content">
            Measurement Data shows a summary of the last hourly averages for the
            selected device as well as regional weather information.
          </div>
        ),
        style: stepStyle,
      },

      {
        selector: '.map-layers-group  img',
        position: 'center',
        content: () => (
          <div className="tour-content">
            You can access additional map layers and overlays with the Layers
            menu.
          </div>
        ),
        style: stepStyle,
      },

      {
        selector: '[data-user-tour="step-7"]',
        position: 'center',
        content: () => (
          <div className="tour-content">
            Click anywhere on the map to get modelled air quality data and to
            create a Virtual Zephyr. Virtual Zephyrs act like an air-quality
            sensor but are powered by our air quality models.
          </div>
        ),
        style: stepStyle,
      },

      {
        selector: '[data-user-tour="step-8"]',
        position: 'center',
        content: () => (
          <div className="tour-content">
            The Analysis tab provides tools to view and interrogate your data,
            whereas Alerts let you set notifications when sensors hit selected
            values.
          </div>
        ),
        style: stepStyle,
      },

      {
        selector: '[data-user-tour="step-9"]',
        position: 'center',
        content: () => (
          <div className="tour-content">
            Each tab has its own tour in the Help menu, including this one.
            Check them out as you explore the MyAir platform.
          </div>
        ),
        style: stepStyle,
      },

      // push to the data tab for the data portion of the tour
      // ...(location.pathname === '/' || location.pathname === '/internal'
      //   ? [
      //       {
      //         selector: '',
      //         content: '',
      //         action: () => history.push(routePaths.data),
      //       },
      //     ]
      //   : [
      //       // Data tab journey from / pathname
      //       {
      //         selector: '.tourChart',
      //         content: () => (
      //           <div className="tour-content">
      //             The chart visualises data over time for your selected Zephyr
      //             with a default of hourly averaged data for the last 48 hours.
      //           </div>
      //         ),
      //         action: () => handleFiltersHeaderScroll(),
      //       },
      //       {
      //         selector: '.tourUnitList',
      //         content: () => (
      //           <div className="tour-content">
      //             The unit list shows which units are plotted on the chart. You
      //             can overplot up to 5 units by dragging and dropping them from
      //             the <strong>Product Inventory</strong> tab.
      //           </div>
      //         ),
      //       },
      //       {
      //         selector: '.tourPeriodSwitch',
      //         content: () => (
      //           <div className="tour-content">
      //             View data for different time periods including custom periods.
      //           </div>
      //         ),
      //       },
      //       {
      //         selector: '.tourAveragingSwitch',
      //         content: () => (
      //           <div className="tour-content">
      //             Select different time averaging options.
      //           </div>
      //         ),
      //       },
      //       {
      //         selector: '.tourSpeciesSwitch',
      //         content: () => (
      //           <div className="tour-content">
      //             Toggle between different species as well as temperature and
      //             humidity.
      //           </div>
      //         ),
      //       },
      //       {
      //         selector: '.tourDataDownload',
      //         content: () => (
      //           <div className="tour-content">
      //             Data downloads are available via CSV or KML.
      //           </div>
      //         ),
      //       },
      //       {
      //         selector: '',
      //         content: () => (
      //           <div className="tour-content">
      //             Tour complete! Click the <strong>Help</strong> menu to view
      //             the tour again.
      //           </div>
      //         ),
      //       },
      //     ]),
    ];

    const maxSteps = '10';

    return (
      <Tour
        // Functionality
        steps={steps}
        isOpen={openTour}
        onRequestClose={handleCloseTour}
        update={location.pathname}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        startAt={0}
        closeWithMask={false}
        disableInteraction
        disableDotsNavigation
        showNavigationNumber={false}
        // prevStep={() => {}}
        // prevButton={<div />}
        lastStepNextButton={<p style={{ margin: 0, fontSize: 16 }}>Finish</p>}
        badgeContent={(curr) => `${curr}/${maxSteps}`}
        // Styles
        accentColor="#4e73c3"
        rounded={3}
      />
    );
  },
);

const mapStateToProps = (state) => ({
  alertsOn: state.alerts.on,
  appLoading: state.getZephyrs.loading,
  asideOn: state.aside.on,
  dataConfig: state.dataConfig,
  mappAirOn: state.showAQMALayer.showOverlayLayer,
  openTour: state.tour.openTour,
  showTour: state.setUserConfig.showTour,
  userInfo: state.auth.userInfo,
  zephyrList: state.getZephyrs.zephyrs,
  bearerToken: state.auth.bearerToken,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeAside,
      closeTour,
      getZephyrData,
      mappAirFilters,
      openAside,
      showOverlayLayer,
      startTour,
      tourCloseAQColorscaleIndex,
      tourCloseAQDataIndex,
      tourCloseAsideFilter,
      tourOpenAQColorscaleIndex,
      tourOpenAQDataIndex,
      tourOpenAsideFilter,
      setUnitLocation,
      setZephyr,
      tourCloseHelp,
      tourCloseLocationSearch,
      tourOpenHelp,
      tourOpenLocationSearch,
      tourMapClick,
      tourCloseModelDataIndex,
      tourOpenModelDataIndex,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserTour);
