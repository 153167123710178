// Package Imports
import React from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

// Const Imports
import { VersionLatestModalContent } from '../../utils/consts';

// Type Imports
import { Version } from '../../utils/interface';

// Component Interfaces
interface VersionLatestModalProps {
  version: Version;
  updateLatestVersionViewed: Function;
}

// Component
const VersionLatestModal = ({
  version,
  updateLatestVersionViewed,
}: VersionLatestModalProps) => {
  const { t: translate } = useTranslation();

  return (
    <div className="modal-container version-modal-container">
      <div className="modal-text version-modal-text">
        <h5>{translate('updateTitle')}</h5>
        <div>{parse(version.versionNotes)}</div>
      </div>
      <button
        className="button primary close-modal-button"
        onClick={() => updateLatestVersionViewed(version.versionId)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 24 24"
          fill="#000"
        >
          <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
        </svg>
      </button>
    </div>
  );
};
export default VersionLatestModal;
